import React from "react";
import TopBarButton from "./TopBarButton";
import OpticSelector from "./OpticSelector";

export default function TopBar(props) {
  return (
    <div className="topbar">
      <TopBarButton icon="/images/soporte.png" title="Soporte" />
      <TopBarButton icon="/images/contacto.png" title="Contacto" />
      <TopBarButton icon="/images/pedidos.png" title="Pedidos" />
      <TopBarButton icon="/images/sugerencias.png" title="Sugerencias" />
      <div className="topBarDiv" />
      <OpticSelector topbar={true} onChange={() => {}} />
      <div className="topBarDiv" />
      <OpticSelector
        topbar={false}
        onChange={() => {
          props.onChangeOptics();
        }}
      />
    </div>
  );
}
