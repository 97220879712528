import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import RadioButton from "./RadioButton";
import CheckBox from "./CheckBox";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  oculomotorStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
  MOELIST,
  focusElement,
} from "../js/utils";
import moment from "moment";

export default function AdvancedOptometryOculomotor(props) {
  const [currentOculomotor, setCurrentOculomotor] =
    useState(oculomotorStructure);
  const [currentOculomotorEdit, setCurrentOculomotorEdit] =
    useState(oculomotorStructure);
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [oculomotorId, setOculomotorId] = useState(props.oculomotorId);

  console.log("currentOculomotorEdit: ", currentOculomotorEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getOculomotorHistory = async () => {
    const response = await post("ListarFechasHistoricoOculomotor.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idOculomotor === oculomotorId;
      });
      if (found) {
        setSelectedDate({
          id: found.idOculomotor,
          text: found.fecha,
        });
        setOculomotorId(found.idOculomotor);
      }
    }
    setDateHistoryList(response);
  };

  const getOculomotor = async (oculomotorId) => {
    const response = await post("ObtenerOculomotor.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOculomotor: oculomotorId,
    });

    setCurrentOculomotorEdit({
      ...response,
      idPaciente: props.patientId,
      idOculomotor: oculomotorId,
    });
    setCurrentOculomotor({
      ...response,
      idPaciente: props.patientId,
      idOculomotor: oculomotorId,
    });
  };

  const resetOculomotorState = () => {
    setCurrentOculomotorEdit({
      ...oculomotorStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentOculomotor({
      ...oculomotorStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setOculomotorId(-1);
    props.saveLastOculomotorIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveOculomotor = async () => {
    const response = await post("GuardarOculomotor.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOculomotor: oculomotorId,
      idPaciente: props.patientId,
      ...currentOculomotorEdit,
    });
    setSelectedDate({
      id: response.idOculomotor,
      text: currentOculomotorEdit.fecha,
    });
    setOculomotorId(response.idOculomotor);
    setCurrentOculomotor(currentOculomotorEdit);
    props.saveLastOculomotorIdVisited(response.idOculomotor);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteOculomotor = async () => {
    await post("EliminarOculomotor.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOculomotor: oculomotorId,
      idUsuario: userData.id,
    });
  };

  const selectOculomotorAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoOculomotor.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idOculomotor,
        text: response[0].fecha,
      });
      setOculomotorId(response[0].idOculomotor);
      setDateHistoryList(response);
      await getOculomotor(response[0].idOculomotor);
      props.saveLastOculomotorIdVisited(response[0].idOculomotor);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setOculomotorId(-1);
      props.saveLastOculomotorIdVisited(-1);
      setCurrentOculomotor(oculomotorStructure);
      setCurrentOculomotorEdit(oculomotorStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (oculomotorId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idOculomotor,
          text: dateHistoryList[0].fecha,
        });
        setOculomotorId(dateHistoryList[0].idOculomotor);
        props.saveLastOculomotorIdVisited(dateHistoryList[0].idOculomotor);
        await getOculomotor(dateHistoryList[0].idOculomotor);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentOculomotorEdit(currentOculomotor);
      }
    } else {
      setCurrentOculomotorEdit(currentOculomotor);
    }
  };

  const checkAndAlert = (field, message, focus) => {
    if (currentOculomotorEdit[field] === "") {
      props.createPopupQuestion(
        `${message} es un campo obligatorio.`,
        async () => {
          props.removePopupQuestion();
          focusElement(focus);
        },
        null
      );
      return true;
    }
    return false;
  };

  const calculateDEM = () => {
    if (
      checkAndAlert("tva", "TVA", 54) ||
      checkAndAlert("tiempo_h", "Tiempo H", 56) ||
      checkAndAlert("error_s", "S Error", 57) ||
      checkAndAlert("error_o", "O Error", 59) ||
      checkAndAlert("error_a", "A Error", 58) ||
      checkAndAlert("error_t", "T Error", 60)
    ) {
      return;
    } else {
      // CÁLCULO DE DEM IMPLEMENTADO POR OPTIWIN
    }
  };

  useEffect(() => {
    getOculomotorHistory();
    getOptometristList();
    if (oculomotorId !== -1) {
      getOculomotor(oculomotorId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentOculomotorEdit({
        ...currentOculomotorEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentOculomotor({
        ...currentOculomotor,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [oculomotorId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentOculomotor, currentOculomotorEdit),
      dataToSave: currentOculomotorEdit,
    });
  }, [currentOculomotorEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentOculomotorEdit.diagnostico) {
      setCurrentOculomotorEdit({
        ...currentOculomotorEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentOculomotorEdit.fecha}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idOculomotor,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idOculomotor,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={oculomotorId === -1}
                onChange={async (e) => {
                  setCurrentOculomotorEdit(oculomotorStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setOculomotorId(e.id);
                  props.saveLastOculomotorIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={0}
                optionList={optometristList}
                size={0}
                op={currentOculomotorEdit.optometrista}
                onChange={(e) => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              oculomotorId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (oculomotorId !== -1) {
                if (
                  !areObjectsEqual(currentOculomotor, currentOculomotorEdit)
                ) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveOculomotor();
                      props.removePopupQuestion();
                      resetOculomotorState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetOculomotorState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetOculomotorState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              oculomotorStructure.fecha = currentOculomotorEdit.fecha;
              if (areObjectsEqual(currentOculomotorEdit, oculomotorStructure)) {
                props.createPopupQuestion(
                  `La ficha de oculomotor actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveOculomotor();
                await getOculomotorHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentOculomotor, currentOculomotorEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentOculomotor, currentOculomotorEdit) ||
                oculomotorId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              oculomotorId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (oculomotorId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de oculomotor?`,
                  async () => {
                    await getOculomotor(oculomotorId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              oculomotorId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (oculomotorId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de oculomotor?`,
                  async () => {
                    await deleteOculomotor();
                    await getOculomotorHistory();
                    await selectOculomotorAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              oculomotorId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (oculomotorId !== -1) {
                props.openDiagnosisPopup(currentOculomotorEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              oculomotorId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (oculomotorId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-30">
          <div className="opt-table-container">
            <div className="opt-table-title">Seguimientos</div>
            <div className="opt-table-content grid-5">
              <div className="cell cell-label">OD</div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_od:
                      currentOculomotorEdit.seguimientos_od === "0"
                        ? "-1"
                        : "0",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Más de Dos Pérdidas de Fijación
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+1</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_od === "0"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_od:
                      currentOculomotorEdit.seguimientos_od === "1"
                        ? "-1"
                        : "1",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Más de Dos Pérdidas de Fijación
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+2</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_od === "1"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_od:
                      currentOculomotorEdit.seguimientos_od === "2"
                        ? "-1"
                        : "2",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Una Pérdida de Fijación</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+3</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_od === "2"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_od:
                      currentOculomotorEdit.seguimientos_od === "3"
                        ? "-1"
                        : "3",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Movimientos Suaves y Precisos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+4</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_od === "3"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div className="cell cell-label">OI</div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_oi:
                      currentOculomotorEdit.seguimientos_oi === "0"
                        ? "-1"
                        : "0",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Más de Dos Pérdidas de Fijación
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+1</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_oi === "0"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_oi:
                      currentOculomotorEdit.seguimientos_oi === "1"
                        ? "-1"
                        : "1",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Dos Pérdidas de Fijación</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+2</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_oi === "1"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_oi:
                      currentOculomotorEdit.seguimientos_oi === "2"
                        ? "-1"
                        : "2",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Una Pérdida de Fijación</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+3</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_oi === "2"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    seguimientos_oi:
                      currentOculomotorEdit.seguimientos_oi === "3"
                        ? "-1"
                        : "3",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Movimientos Suaves y Precisos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+4</span>
                  <CheckBox
                    active={currentOculomotorEdit.seguimientos_oi === "3"}
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Sacádicos</div>
            <div className="opt-table-content grid-5">
              <div className="cell"></div>
              <div className="cell combined-cells-4 cell-start-content opt-table-subtitle">
                Gran Amplitud
              </div>
              <div className="cell cell-label">OD</div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_od:
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "0"
                        ? "-1"
                        : "0",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Incapaz</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+1</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "0"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_od:
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "1"
                        ? "-1"
                        : "1",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Exagerados, Movimientos Largos o Cortos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+2</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "1"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_od:
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "2"
                        ? "-1"
                        : "2",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Ligero Movimiento Corto</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+3</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "2"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_od:
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "3"
                        ? "-1"
                        : "3",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Movimientos Suaves y Precisos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+4</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_od === "3"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div className="cell cell-label">OI</div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_oi:
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "0"
                        ? "-1"
                        : "0",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Incapaz</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+1</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "0"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_oi:
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "1"
                        ? "-1"
                        : "1",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Exagerados, Movimientos Largos o Cortos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+2</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "1"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_oi:
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "2"
                        ? "-1"
                        : "2",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Ligero Movimiento Corto</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+3</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "2"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div
                className="cell square-cell"
                onClick={() => {
                  setCurrentOculomotorEdit({
                    ...currentOculomotorEdit,
                    sacadicos_gran_amplitud_oi:
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "3"
                        ? "-1"
                        : "3",
                  });
                }}
              >
                <div
                  className="tooltip-container-content"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Movimientos Suaves y Precisos
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  <span>+4</span>
                  <CheckBox
                    active={
                      currentOculomotorEdit.sacadicos_gran_amplitud_oi === "3"
                    }
                    onClick={() => {}}
                  ></CheckBox>
                </div>
              </div>
              <div className="cell"></div>
            </div>
            <div className="opt-table-content grid-8">
              <div className="cell combined-cells-8 opt-table-subtitle cell-start-content">
                Pequeña Amplitud (DEM)
              </div>
              <div className="cell cell-label combined-cells-2">TVA</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={54}
                  id="cell"
                  type="type-float2"
                  size={0}
                  input={currentOculomotorEdit.tva}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      tva: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-3"></div>
              <div className="cell cell-label">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content">Edad</div>
                          <div className="cell cell-start-content">
                            Tiempo Ver.
                          </div>
                          <div className="cell cell-start-content">
                            Tiempo Hor.
                          </div>
                          <div className="cell cell-start-content">Errores</div>
                          <div className="cell cell-start-content">Ratio</div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content">
                            6.0-6.11
                          </div>
                          <div className="cell cell-start-content">
                            63.11 (16.59)
                          </div>
                          <div className="cell cell-start-content">
                            98.26 (32.61)
                          </div>
                          <div className="cell cell-start-content">
                            15.22 (11.49)
                          </div>
                          <div className="cell cell-start-content">
                            1.58 (.45)
                          </div>
                          <div className="cell cell-start-content">
                            7.0-7.11
                          </div>
                          <div className="cell cell-start-content">
                            54.83 (9.20)
                          </div>
                          <div className="cell cell-start-content">
                            87.94 (28.18)
                          </div>
                          <div className="cell cell-start-content">
                            12.50 (12.91)
                          </div>
                          <div className="cell cell-start-content">
                            1.60 (.41)
                          </div>
                          <div className="cell cell-start-content">
                            8.0-8.11
                          </div>
                          <div className="cell cell-start-content">
                            46.76 (7.89)
                          </div>
                          <div className="cell cell-start-content">
                            57.73 (12.32)
                          </div>
                          <div className="cell cell-start-content">
                            4.61 (6.91)
                          </div>
                          <div className="cell cell-start-content">
                            1.24 (.18)
                          </div>
                          <div className="cell cell-start-content">
                            9.0-9.11
                          </div>
                          <div className="cell cell-start-content">
                            42.33 (8.20)
                          </div>
                          <div className="cell cell-start-content">
                            51.13 (13.30)
                          </div>
                          <div className="cell cell-start-content">
                            2.17 (4.10)
                          </div>
                          <div className="cell cell-start-content">
                            1.21 (.19)
                          </div>
                          <div className="cell cell-start-content">
                            10.0-10.11
                          </div>
                          <div className="cell cell-start-content">
                            40.28 (7.43)
                          </div>
                          <div className="cell cell-start-content">
                            47.64 (10.11)
                          </div>
                          <div className="cell cell-start-content">
                            1.91 (2.68)
                          </div>
                          <div className="cell cell-start-content">
                            1.19 (.17)
                          </div>
                          <div className="cell cell-start-content">
                            11.0-11.11
                          </div>
                          <div className="cell cell-start-content">
                            37.14 (5.24)
                          </div>
                          <div className="cell cell-start-content">
                            42.62 (7.61)
                          </div>
                          <div className="cell cell-start-content">
                            1.68 (2.34)
                          </div>
                          <div className="cell cell-start-content">
                            1.15 (.13)
                          </div>
                          <div className="cell cell-start-content">
                            12.0-12.11
                          </div>
                          <div className="cell cell-start-content">
                            35.14 (5.87)
                          </div>
                          <div className="cell cell-start-content">
                            39.35 (8.11)
                          </div>
                          <div className="cell cell-start-content">
                            1.11 (1.17)
                          </div>
                          <div className="cell cell-start-content">
                            1.12 (.10)
                          </div>
                          <div className="cell cell-start-content">
                            13.0-13.11
                          </div>
                          <div className="cell cell-start-content">
                            33.75 (6.53)
                          </div>
                          <div className="cell cell-start-content">
                            37.56 (7.23)
                          </div>
                          <div className="cell cell-start-content">
                            1.61 (2.15)
                          </div>
                          <div className="cell cell-start-content">
                            1.12 (.12)
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label combined-cells-2">
                TVA Errores
              </div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={55}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.tva_errores}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      tva_errores: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-4"></div>
              <div className="cell cell-label combined-cells-2">Tiempo H</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={56}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.tiempo_h}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      tiempo_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-4">
                <button
                  className="cell-icon"
                  onClick={() => {
                    calculateDEM();
                  }}
                >
                  <img
                    src="/images/extract-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: <div className="cell">Calcular DEM</div>,
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell cell-label combined-cells-2">S Error</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={57}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.error_s}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      error_s: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2">O Error</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={59}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.error_o}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      error_o: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2">A Error</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={58}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.error_a}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      error_a: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2">T Error</div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={60}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.error_t}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      error_t: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2">TVA</div>
              <div className="cell cell-label combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Tiempo x 80 / (80-O+A)</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  THA
                </span>
              </div>
              <div className="cell cell-label combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">S+O+A+T</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  Errores
                </span>
              </div>
              <div className="cell cell-label combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">THA / TVA</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  Ratio
                </span>
              </div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={61}
                  id="cell"
                  type="type-float2"
                  size={0}
                  input={currentOculomotorEdit.tva}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      tva: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={62}
                  id="cell"
                  type="type-float2"
                  size={0}
                  input={currentOculomotorEdit.tha}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      tha: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={63}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentOculomotorEdit.errores}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      errores: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-2">
                <InputData
                  tabIndex={64}
                  id="cell"
                  type="type-float2"
                  size={0}
                  input={currentOculomotorEdit.ratio}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      ratio: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-70">
          <div className="double-col">
            <div className="opt-table-container w-30">
              <div className="opt-table-title">Fijación</div>
              <div className="opt-table-content grid-2">
                <div className="cell cell-label cell-start-content">
                  <div
                    className="check-container-content"
                    onClick={() => {
                      setCurrentOculomotorEdit({
                        ...currentOculomotorEdit,
                        fijacion_si: !currentOculomotorEdit.fijacion_si,
                        fijacion_no: currentOculomotorEdit.fijacion_si
                          ? false
                          : currentOculomotorEdit.fijacion_si,
                      });
                    }}
                  >
                    Sí
                    <div className="radio-op">
                      <CheckBox
                        active={currentOculomotorEdit.fijacion_si}
                        onClick={() => {}}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
                <div className="cell cell-label cell-start-content">
                  <div
                    className="check-container-content"
                    onClick={() => {
                      setCurrentOculomotorEdit({
                        ...currentOculomotorEdit,
                        fijacion_no: !currentOculomotorEdit.fijacion_no,
                        fijacion_si: currentOculomotorEdit.fijacion_no
                          ? false
                          : currentOculomotorEdit.fijacion_no,
                      });
                    }}
                  >
                    No
                    <div className="radio-op">
                      <CheckBox
                        active={currentOculomotorEdit.fijacion_no}
                        onClick={() => {}}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opt-table-container w-70">
              <div className="opt-table-title">Versiones y Deducciones</div>
              <div className="opt-table-content grid-3">
                <div className="cell cell-label cell-start-content">
                  <div
                    className="check-container-content"
                    onClick={() => {
                      setCurrentOculomotorEdit({
                        ...currentOculomotorEdit,
                        versiones_y_deducciones:
                          currentOculomotorEdit.versiones_y_deducciones === "0"
                            ? "-1"
                            : "0",
                      });
                    }}
                  >
                    Paresia
                    <div className="radio-op">
                      <CheckBox
                        active={
                          currentOculomotorEdit.versiones_y_deducciones === "0"
                        }
                        onClick={() => {}}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
                <div className="cell cell-label cell-start-content">
                  <div
                    className="check-container-content"
                    onClick={() => {
                      setCurrentOculomotorEdit({
                        ...currentOculomotorEdit,
                        versiones_y_deducciones:
                          currentOculomotorEdit.versiones_y_deducciones === "1"
                            ? "-1"
                            : "1",
                      });
                    }}
                  >
                    Parálisis
                    <div className="radio-op">
                      <CheckBox
                        active={
                          currentOculomotorEdit.versiones_y_deducciones === "1"
                        }
                        onClick={() => {}}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
                <div className="cell cell-label cell-start-content">
                  <div
                    className="check-container-content"
                    onClick={() => {
                      setCurrentOculomotorEdit({
                        ...currentOculomotorEdit,
                        versiones_y_deducciones:
                          currentOculomotorEdit.versiones_y_deducciones === "2"
                            ? "-1"
                            : "2",
                      });
                    }}
                  >
                    Restricción Mecánica
                    <div className="radio-op">
                      <CheckBox
                        active={
                          currentOculomotorEdit.versiones_y_deducciones === "2"
                        }
                        onClick={() => {}}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">
              Modalidad Ocular Extrínseca (MOE)
            </div>
            <div className="opt-table-content grid-5">
              <div className="cell cell-label combined-cells-2 cell-start-content">
                Diagnóstico
                <Dropdown
                  id="moe-diagnostico"
                  tabIndex={65}
                  optionList={MOELIST}
                  size={0}
                  op={currentOculomotorEdit.moe_filtro_rojo_diagnostico}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      moe_filtro_rojo_diagnostico: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
            </div>
            <div className="layer-grid-container">
              <div className="opt-table-content grid-12" id="filtro-rojo">
                <div className="cell opt-table-subtitle combined-cells-12 cell-start-content">
                  Filtro Rojo
                </div>
                <div className="cell combined-cells-2"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rsd: !currentOculomotorEdit.rsd,
                    });
                  }}
                >
                  RSD
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rsd}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rsd: !currentOculomotorEdit.rsd,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      oid: !currentOculomotorEdit.oid,
                    });
                  }}
                >
                  OID
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.oid}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          oid: !currentOculomotorEdit.oid,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-4"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      oii: !currentOculomotorEdit.oii,
                    });
                  }}
                >
                  OII
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.oii}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          oii: !currentOculomotorEdit.oii,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rsi: !currentOculomotorEdit.rsi,
                    });
                  }}
                >
                  RSI
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rsi}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rsi: !currentOculomotorEdit.rsi,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-2"></div>
                <div className="cell combined-cells-12"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rld: !currentOculomotorEdit.rld,
                    });
                  }}
                >
                  RLD
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rld}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rld: !currentOculomotorEdit.rld,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-4"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rmd: !currentOculomotorEdit.rmd,
                    });
                  }}
                >
                  RMD
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rmd}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rmd: !currentOculomotorEdit.rmd,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rmi: !currentOculomotorEdit.rmi,
                    });
                  }}
                >
                  RMI
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rmi}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rmi: !currentOculomotorEdit.rmi,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-4"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rli: !currentOculomotorEdit.rli,
                    });
                  }}
                >
                  RLI
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rli}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rli: !currentOculomotorEdit.rli,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-12"></div>
                <div className="cell combined-cells-2"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rid: !currentOculomotorEdit.rid,
                    });
                  }}
                >
                  RID
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rid}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rid: !currentOculomotorEdit.rid,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      osd: !currentOculomotorEdit.osd,
                    });
                  }}
                >
                  OSD
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.osd}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          osd: !currentOculomotorEdit.osd,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-4"></div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      osi: !currentOculomotorEdit.osi,
                    });
                  }}
                >
                  OSI
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.osi}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          osi: !currentOculomotorEdit.osi,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div
                  className="cell cell-label cell-fill"
                  onClick={() => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      rii: !currentOculomotorEdit.rii,
                    });
                  }}
                >
                  RII
                  <div className="radio-op">
                    <RadioButton
                      active={currentOculomotorEdit.rii}
                      onClick={() => {
                        setCurrentOculomotorEdit({
                          ...currentOculomotorEdit,
                          rii: !currentOculomotorEdit.rii,
                        });
                      }}
                    ></RadioButton>
                  </div>
                </div>
                <div className="cell combined-cells-2"></div>
              </div>
              <div className="opt-table-content grid-12" id="line-grid">
                <div className="cell combined-cells-12"></div>
                <div className="cell combined-cells-12 cell-fill-extra"></div>
                <div className="cell combined-cells-12"></div>
                <div className="cell cell-fill-extra"></div>
                <div
                  className={`cell cell-fill-extra  ${
                    currentOculomotorEdit.rld ? "right-line" : ""
                  }`}
                ></div>
                <div
                  className={`cell cell-fill-extra ${
                    currentOculomotorEdit.rsd ? "top-line" : ""
                  } ${currentOculomotorEdit.rid ? "bottom-line" : ""}`}
                ></div>
                <div
                  className={`cell cell-fill-extra ${
                    currentOculomotorEdit.oid ? "top-line" : ""
                  } ${currentOculomotorEdit.osd ? "bottom-line" : ""}`}
                ></div>
                <div
                  className={`cell cell-fill-extra  ${
                    currentOculomotorEdit.rmd ? "left-line" : ""
                  }`}
                ></div>
                <div className="cell cell-fill-extra"></div>
                <div className="cell cell-fill-extra"></div>
                <div
                  className={`cell cell-fill-extra  ${
                    currentOculomotorEdit.rmi ? "right-line" : ""
                  }`}
                ></div>
                <div
                  className={`cell cell-fill-extra ${
                    currentOculomotorEdit.oii ? "top-line" : ""
                  } ${currentOculomotorEdit.osi ? "bottom-line" : ""}`}
                ></div>
                <div
                  className={`cell cell-fill-extra ${
                    currentOculomotorEdit.rsi ? "top-line" : ""
                  } ${currentOculomotorEdit.rii ? "bottom-line" : ""}`}
                ></div>
                <div
                  className={`cell cell-fill-extra  ${
                    currentOculomotorEdit.rli ? "left-line" : ""
                  }`}
                ></div>
                <div className="cell cell-fill-extra"></div>
                <div className="cell combined-cells-12"></div>
                <div className="cell combined-cells-12 cell-fill-extra"></div>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Observaciones</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={66}
                  id="observaciones"
                  type="type-full"
                  size={0}
                  input={currentOculomotorEdit.observaciones}
                  onChange={(e) => {
                    setCurrentOculomotorEdit({
                      ...currentOculomotorEdit,
                      observaciones: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
