import React, { useState, useRef, useEffect } from "react";
import "../css/ObservationsPanel.css";
import { focusElement, userData, selectedOptic } from "../js/utils";
import { post } from "../js/post";
import moment from "moment";
import ButtonIconH from "./ButtonIconH";
import PopupMessage from "./PopupMessage";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import LoadingScreen from "./LoadingScreen";

export default function ObservationsPanel(props) {
  const [selectedObservation, setSelectedObservation] = useState("");
  const [newObservation, setNewObservation] = useState(false);
  const [popupQuestion, setPopupQuestion] = useState(null);
  const [observationList, setObservationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentObservation, setCurrentObservation] = useState({
    titulo: "",
    observaciones: "",
  });
  const detailRefs = useRef([]);

  const getObservations = async () => {
    setLoading(true);
    const response = await post("ListarObservaciones.php", {
      token: userData.token,
      optica: selectedOptic.id,
      id: props.objectInfo,
      categoria: props.category,
    });
    setObservationList(response);
    setLoading(false);
  };

  const addObservation = async () => {
    await post("AnadirObservacion.php", {
      token: userData.token,
      optica: selectedOptic.id,
      id: props.objectInfo,
      titulo: currentObservation.titulo,
      categoria: props.category,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      observaciones: currentObservation.observaciones,
      idUsuario: userData.id,
    });
  };

  const deleteObservation = async (id) => {
    await post("EliminarObservacion.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idObservacion: id,
      idUsuario: userData.id,
    });
  };

  const handleItemClick = (index) => {
    detailRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  const createPopupQuestion = (
    text,
    onClickAccept,
    onClickCancel,
    textAccept,
    textCancel
  ) => {
    setPopupQuestion({
      text,
      onClickCancel,
      onClickAccept,
      textAccept,
      textCancel,
    });
  };

  const removePopupQuestion = () => {
    setPopupQuestion(null);
  };

  useEffect(() => {
    getObservations();
  }, []);

  useEffect(() => {
    if (newObservation) {
      focusElement(300);
    }
  }, [newObservation]);

  return (
    <div className="external-window-bg">
      {popupQuestion && (
        <PopupMessage
          text={popupQuestion.text}
          onClickCancel={popupQuestion.onClickCancel}
          onClickAccept={popupQuestion.onClickAccept}
          textAccept={popupQuestion.textAccept}
          textCancel={popupQuestion.textCancel}
        ></PopupMessage>
      )}
      <div className="external-window external-window-obs">
        <div className="external-window-header">
          <button className="popup-action-icon close-icon">
            <img
              src="/images/close-white.svg"
              loading="lazy"
              alt="Icon"
              onClick={() => {
                props.onClose();
              }}
            ></img>
          </button>
          <span className="popup-screen-header-title">Observaciones</span>
        </div>
        <div className="observations-container">
          {loading && (
            <LoadingScreen message="Cargando observaciones..."></LoadingScreen>
          )}
          {!loading && (
            <div className="obs-sections">
              <div className="observation-list">
                <span className="section-history-header">
                  Histórico ({observationList.length})
                </span>
                <ul className="item-list-container">
                  {observationList.map((e, i) => {
                    return (
                      <li
                        className={`observation-info-container ${
                          selectedObservation === e.id
                            ? "selected-observation"
                            : ""
                        }`}
                        key={e.id}
                        onClick={() => {
                          setSelectedObservation(
                            selectedObservation !== e.id ? e.id : ""
                          );
                          handleItemClick(i);
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>
                          {e.titulo ? e.titulo : "(Sin título)"}
                        </span>
                        <span>{e.nombre}</span>
                        <span>
                          {moment(e.fecha).format("DD/MM/YYYY HH:mm:ss")}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="observation-list-section">
                {!newObservation && (
                  <div className="obs-options-container">
                    <ButtonIconH
                      text="Nueva Observación"
                      small={true}
                      onClick={() => {
                        setNewObservation(true);
                      }}
                    ></ButtonIconH>
                  </div>
                )}
                {newObservation && (
                  <div className="new-observation-container">
                    <span className="section-history-header">
                      Nueva observación
                    </span>
                    <div className="section-content-data">
                      <div className="data-field-row">
                        <div className="data-field d-medium">
                          <span className="label">Título</span>
                          <InputData
                            id="title"
                            tabIndex={300}
                            size={0}
                            maxLength={60}
                            input={currentObservation.titulo}
                            onChange={(e) => {
                              setCurrentObservation({
                                ...currentObservation,
                                titulo: e,
                              });
                            }}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-column">
                        <div
                          className="data-field d-medium data-field-column"
                          style={{ alignItems: "flex-start" }}
                        >
                          <span className="label">Observación</span>
                          <InputTextArea
                            tabIndex={301}
                            id="observacion"
                            type="type-full"
                            size={0}
                            input={currentObservation.observaciones}
                            onChange={(e) => {
                              setCurrentObservation({
                                ...currentObservation,
                                observaciones: e,
                              });
                            }}
                          ></InputTextArea>
                        </div>
                      </div>
                    </div>
                    <div className="obs-options-container-extra">
                      <ButtonIconH
                        text="Guardar"
                        small={true}
                        onClick={async () => {
                          await addObservation();
                          await getObservations();
                          setCurrentObservation({
                            titulo: "",
                            observaciones: "",
                          });
                          setNewObservation(false);
                        }}
                      ></ButtonIconH>
                      <ButtonIconH
                        text="Cancelar"
                        small={true}
                        onClick={() => {
                          setNewObservation(false);
                        }}
                      ></ButtonIconH>
                    </div>
                  </div>
                )}
                <ul className="observation-list-extended">
                  {observationList.map((e, index) => {
                    return (
                      <li
                        className={`observation-info-container obs-card ${
                          selectedObservation === e.id
                            ? "selected-observation-card"
                            : ""
                        }`}
                        key={e.id}
                        ref={(e) => (detailRefs.current[index] = e)}
                      >
                        <div className="obs-card-header">
                          <span style={{ fontWeight: 600 }}>{e.titulo}</span>
                          {" | "}
                          <span>
                            {e.nombre +
                              " (" +
                              moment(e.fecha).format("DD/MM/YYYY HH:mm:ss") +
                              ")"}
                          </span>
                        </div>
                        <span className="obs-info-text">{e.observaciones}</span>
                        <div className="obs-card-footer">
                          {e.idUsuario === userData.id && (
                            <ButtonIconH
                              text="Eliminar"
                              small={true}
                              onClick={() => {
                                createPopupQuestion(
                                  "¿Desea eliminar esta observación?",
                                  async () => {
                                    await deleteObservation(e.id);
                                    await getObservations();
                                    removePopupQuestion();
                                  },
                                  () => {
                                    removePopupQuestion();
                                  },
                                  "Sí",
                                  "No"
                                );
                              }}
                            ></ButtonIconH>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
