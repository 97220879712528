import React, { useState } from "react";
import "../css/CheckBox.css";
import { inputOnEnter } from "../js/utils";

export default function CheckBox(props) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      props.onClick();
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <button
      className={`checkbox-container ${
        props.active ? "checkbox-selected" : ""
      }`}
      onClick={() => {
        props.onClick();
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <input
        className={`checkbox-input ${
          isInputFocused ? "checkbox-input-focused" : ""
        }`}
        tabIndex={props.tabIndex}
        onKeyUp={inputOnEnter}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      ></input>
      {props.active && (
        <img
          src="/images/checkbox-selected.svg"
          loading="lazy"
          alt="Icon"
          className="selected-img"
        />
      )}
    </button>
  );
}
