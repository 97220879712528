import React from "react";
import "../css/ErrorBox.css";

export default function ErrorBox(props) {
  return (
    <div className="error-box">
      <img src="/images/icon-error.svg" alt="Icon"></img>
      <span>{props.text}</span>
    </div>
  );
}
