import React, { useEffect, useState } from "react";
import PopupMessage from "./PopupMessage";
import { post } from "../js/post";
import "../css/OptometryScreen.css";
import moment from "moment";
import Tooltip from "./Tooltip";
import {
  ACUFENOSLIST,
  CONVERSACIONENGRUPOLIST,
  CONVERSACIONENGRUPORUIDOLIST,
  CONVERSACIONINDIVIDUALLIST,
  CONVERSACIONINDIVIDUALRUIDOLIST,
  CONVERSACIONTLFLIST,
  DOMINANCIALIST,
  EVENTOSPUBLICOSABIERTOSLIST,
  EVENTOSPUBLICOSCERRADOSLIST,
  OTORREALIST,
  PERFORACIONLIST,
  SONIDOAMBIENTELIST,
  SONIDOSAGUDOSLIST,
  SONIDOSGRAVESLIST,
  TAPONESLIST,
  TESTDERINNELIST,
  TESTDEWEBERLIST,
  TIMPANOMETRIALIST,
  VOLUMENTVLIST,
  audiometryStructure,
  inputOnEnter,
  svgIcons,
  userData,
  selectedOptic,
  areObjectsEqual,
} from "../js/utils";
import InputData from "./InputData";
import DropdownFilter from "./DropdownFilter";
import Dropdown from "./Dropdown";
import ButtonIconV from "./ButtonIconV";
import InputTextArea from "./InputTextArea";
import CheckBox from "./CheckBox";
import ButtonIconH from "./ButtonIconH";
import AudiometryChart from "./AudiometryChart";

export default function Audiometry(props) {
  const [popupQuestion, setPopupQuestion] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [audiometryId, setAudiometryId] = useState(props.audiometryId);
  const [toggleAudiometry, setToggleAudiometry] = useState(false);
  const [currentAudiometry, setCurrentAudiometry] =
    useState(audiometryStructure);
  const [currentAudiometryEdit, setCurrentAudiometryEdit] =
    useState(audiometryStructure);
  const [hearingAidSpecialistList, setHearingAidSpecialistList] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({
    changes: false,
    dataToSave: null,
  });

  /*   console.log("**********");
  console.log("currentAudiometry: ", currentAudiometry);
  console.log("currentAudiometryEdit: ", currentAudiometryEdit);
  console.log("dateHistoryList: ", dateHistoryList);
  console.log("selectedDate: ", selectedDate); */

  const getHearingAidSpecialistList = async () => {
    let response = await post("ListarAudioprotesistas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setHearingAidSpecialistList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e.id, text: e.nombreApellidos })),
    ]);
  };

  const getAudiometryHistory = async () => {
    const response = await post("ListarFechasHistoricoAudiometria.php", {
      token: userData.token,
      idPaciente: props.patientInfo.id,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idAudiometria === audiometryId;
      });
      if (found) {
        setSelectedDate({
          id: found.idAudiometria,
          text: found.fecha,
        });
        setAudiometryId(found.idAudiometria);
      }
    }
    setDateHistoryList(response);
  };

  const resetAudiometryState = () => {
    setCurrentAudiometry({
      ...audiometryStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentAudiometryEdit({
      ...audiometryStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setAudiometryId(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const createPopupQuestion = (
    text,
    onClickAccept,
    onClickCancel,
    textAccept,
    textCancel
  ) => {
    setPopupQuestion({
      text,
      onClickCancel,
      onClickAccept,
      textAccept,
      textCancel,
    });
  };

  const removePopupQuestion = () => {
    setPopupQuestion(null);
  };

  const getAudiometry = async (audiometryId) => {
    const response = await post("ObtenerAudiometria.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAudiometria: audiometryId,
    });
    setCurrentAudiometry({
      ...response,
      idPaciente: props.patientInfo.id,
      idOptometria: audiometryId,
    });
    setCurrentAudiometryEdit({
      ...response,
      idPaciente: props.patientInfo.id,
      idOptometria: audiometryId,
    });
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (audiometryId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idAudiometria,
          text: dateHistoryList[0].fecha,
        });
        setAudiometryId(dateHistoryList[0].idAudiometria);
        await getAudiometry(dateHistoryList[0].idAudiometria);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentAudiometryEdit(currentAudiometry);
      }
    } else {
      setCurrentAudiometryEdit(currentAudiometry);
    }
  };

  const deleteAudiometry = async () => {
    await post("EliminarAudiometria.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAudiometria: audiometryId,
      idUsuario: userData.id,
    });
  };

  const selectAudiometryAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoAudiometria.php", {
      token: userData.token,
      idPaciente: props.patientInfo.id,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idAudiometria,
        text: response[0].fecha,
      });
      setAudiometryId(response[0].idAudiometria);
      setDateHistoryList(response);
      await getAudiometry(response[0].idAudiometria);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setAudiometryId(-1);
      setCurrentAudiometry(audiometryStructure);
      setCurrentAudiometryEdit(audiometryStructure);
    }
  };

  const saveAudiometry = async () => {
    const response = await post("GuardarAudiometria.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAudiometria: audiometryId,
      idPaciente: props.patientInfo.id,
      ...currentAudiometryEdit,
    });

    setSelectedDate({
      id: response.idAudiometria,
      text: currentAudiometryEdit.fecha,
    });
    setAudiometryId(response.idAudiometria);
    setCurrentAudiometry(currentAudiometryEdit);
    createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const insertNewValues = (e) => {
    setCurrentAudiometryEdit({
      ...currentAudiometryEdit,
      aerea_d_125: e[0].y,
      aerea_d_250: e[1].y,
      aerea_d_500: e[2].y,
      aerea_d_1000: e[3].y,
      aerea_d_1500: e[4].y,
      aerea_d_2000: e[5].y,
      aerea_d_3000: e[6].y,
      aerea_d_4000: e[7].y,
      aerea_d_6000: e[8].y,
      aerea_d_8000: e[9].y,
      osea_d_125: e[10].y,
      osea_d_250: e[11].y,
      osea_d_500: e[12].y,
      osea_d_1000: e[13].y,
      osea_d_1500: e[14].y,
      osea_d_2000: e[15].y,
      osea_d_3000: e[16].y,
      osea_d_4000: e[17].y,
      osea_d_6000: e[18].y,
      osea_d_8000: e[19].y,
      umbral_d_125: e[20].y,
      umbral_d_250: e[21].y,
      umbral_d_500: e[22].y,
      umbral_d_1000: e[23].y,
      umbral_d_1500: e[24].y,
      umbral_d_2000: e[25].y,
      umbral_d_3000: e[26].y,
      umbral_d_4000: e[27].y,
      umbral_d_6000: e[28].y,
      umbral_d_8000: e[29].y,
      aerea_i_125: e[30].y,
      aerea_i_250: e[31].y,
      aerea_i_500: e[32].y,
      aerea_i_1000: e[33].y,
      aerea_i_1500: e[34].y,
      aerea_i_2000: e[35].y,
      aerea_i_3000: e[36].y,
      aerea_i_4000: e[37].y,
      aerea_i_6000: e[38].y,
      aerea_i_8000: e[39].y,
      osea_i_125: e[40].y,
      osea_i_250: e[41].y,
      osea_i_500: e[42].y,
      osea_i_1000: e[43].y,
      osea_i_1500: e[44].y,
      osea_i_2000: e[45].y,
      osea_i_3000: e[46].y,
      osea_i_4000: e[47].y,
      osea_i_6000: e[48].y,
      osea_i_8000: e[49].y,
      umbral_i_125: e[50].y,
      umbral_i_250: e[51].y,
      umbral_i_500: e[52].y,
      umbral_i_1000: e[53].y,
      umbral_i_1500: e[54].y,
      umbral_i_2000: e[55].y,
      umbral_i_3000: e[56].y,
      umbral_i_4000: e[57].y,
      umbral_i_6000: e[58].y,
      umbral_i_8000: e[59].y,
    });
  };

  useEffect(() => {
    getAudiometryHistory();
    getHearingAidSpecialistList();
    if (audiometryId !== -1) {
      getAudiometry(audiometryId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAudiometryEdit({
        ...currentAudiometryEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAudiometry({
        ...currentAudiometry,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [audiometryId]);

  useEffect(() => {
    /*console.log("CURRENT", currentAudiometry);
    console.log("CURRENT EDIT", currentAudiometryEdit);*/
    setPendingChanges({
      changes: !areObjectsEqual(currentAudiometry, currentAudiometryEdit),
      dataToSave: currentAudiometryEdit,
    });
  }, [currentAudiometry, currentAudiometryEdit]);

  return (
    <div className="popup-container">
      {toggleAudiometry && (
        <AudiometryChart
          onClose={() => {
            setToggleAudiometry(false);
          }}
          data={currentAudiometryEdit}
          patientName={props.patientInfo.name}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          onSave={(e) => {
            insertNewValues(e);
          }}
        ></AudiometryChart>
      )}
      {popupQuestion && (
        <PopupMessage
          text={popupQuestion.text}
          onClickCancel={popupQuestion.onClickCancel}
          onClickAccept={popupQuestion.onClickAccept}
          textAccept={popupQuestion.textAccept}
          textCancel={popupQuestion.textCancel}
        ></PopupMessage>
      )}
      {tooltip && (
        <Tooltip
          top={tooltip.top}
          left={tooltip.left}
          content={tooltip.content}
          style={tooltip.style}
        ></Tooltip>
      )}
      <div className="popup-screen h-auto">
        <div className="popup-screen-header">
          <button className="popup-action-icon close-icon">
            <img
              src="/images/close-white.svg"
              loading="lazy"
              alt="Icon"
              onClick={async () => {
                if (pendingChanges.changes) {
                  createPopupQuestion(
                    "Existen cambios que no han sido guardados. ¿Desea guardar antes de salir?",
                    async () => {
                      await saveAudiometry();
                      removePopupQuestion();
                      props.onClose();
                    },
                    () => {
                      removePopupQuestion();
                      props.onClose();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  props.onClose();
                }
                await getAudiometryHistory();
              }}
            ></img>
          </button>
          <span className="popup-screen-header-title">
            Ficha de Audiometría del Cliente {props.patientInfo.name} (
            {props.patientInfo.id})
          </span>
        </div>
        <div className="tab-content">
          <div className="popup-screen-actions">
            <div className="popup-screen-box">
              <div className="popup-screen-box-info">
                <div className="cell-extra">
                  <span className="label">
                    Histórico ({dateHistoryList.length})
                  </span>
                </div>
                <div className="cell-extra">
                  <DropdownFilter
                    tabIndex={52}
                    optionList={dateHistoryList.map((item) => ({
                      id: item.idAudiometria,
                      text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY HH:mm:ss"
                      ),
                      active: selectedDate.id === item.idAudiometria,
                    }))}
                    size={0}
                    op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                    disabled={audiometryId === -1}
                    onChange={async (e) => {
                      setCurrentAudiometryEdit(audiometryStructure);
                      setSelectedDate({
                        id: e.id,
                        text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                      });
                      setAudiometryId(e.id);
                    }}
                  ></DropdownFilter>
                </div>
              </div>
            </div>
            <div className="popup-screen-box">
              <ButtonIconV
                text="Nuevo"
                icon={
                  audiometryId !== -1
                    ? "/images/create-blue.svg"
                    : "/images/create-gray.svg"
                }
                onClick={() => {
                  if (audiometryId !== -1) {
                    if (
                      !areObjectsEqual(currentAudiometry, currentAudiometryEdit)
                    ) {
                      createPopupQuestion(
                        `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                        async () => {
                          await saveAudiometry();
                          removePopupQuestion();
                          resetAudiometryState();
                        },
                        async () => {
                          removePopupQuestion();
                          resetAudiometryState();
                        },
                        "Sí",
                        "No"
                      );
                    } else {
                      resetAudiometryState();
                    }
                  }
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Guardar"
                icon="/images/save-blue.svg"
                onClick={async () => {
                  audiometryStructure.fecha = currentAudiometryEdit.fecha;
                  if (
                    /* areObjectsEqual(currentAudiometryEdit, audiometryStructure) */
                    currentAudiometryEdit.audioprotesista === ""
                  ) {
                    createPopupQuestion(
                      `El campo audioprotesista no puede estar vacío.`,
                      async () => {
                        removePopupQuestion();
                      },
                      null,
                      "Aceptar",
                      null
                    );
                  } else {
                    await saveAudiometry();
                    await getAudiometryHistory();
                  }
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Cancelar"
                icon="/images/cancel-blue.svg"
                onClick={() => {
                  if (
                    dateHistoryList.length === 0 &&
                    areObjectsEqual(currentAudiometry, currentAudiometryEdit)
                  ) {
                    removePopupQuestion();
                  } else if (
                    !areObjectsEqual(
                      currentAudiometry,
                      currentAudiometryEdit
                    ) ||
                    audiometryId === -1
                  ) {
                    createPopupQuestion(
                      `¿Desea cancelar los cambios realizados?`,
                      async () => {
                        await cancelChanges();
                        removePopupQuestion();
                      },
                      async () => {
                        removePopupQuestion();
                      },
                      "Sí",
                      "No"
                    );
                  }
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Recargar"
                icon={
                  audiometryId !== -1
                    ? "/images/update-blue.svg"
                    : "/images/update-gray.svg"
                }
                onClick={() => {
                  if (audiometryId !== -1) {
                    createPopupQuestion(
                      `¿Desea recargar esta ficha de audiometría?`,
                      async () => {
                        await getAudiometry(audiometryId);
                        removePopupQuestion();
                      },
                      async () => {
                        removePopupQuestion();
                      },
                      "Sí",
                      "No"
                    );
                  }
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Eliminar"
                icon={
                  audiometryId !== -1
                    ? "/images/remove-blue.svg"
                    : "/images/remove-gray.svg"
                }
                onClick={async () => {
                  if (audiometryId !== -1) {
                    createPopupQuestion(
                      `¿Desea eliminar esta ficha de audiometría?`,
                      async () => {
                        await deleteAudiometry();
                        await getAudiometryHistory();
                        await selectAudiometryAfterDeleting();
                        removePopupQuestion();
                      },
                      async () => {
                        removePopupQuestion();
                      },
                      "Sí",
                      "No"
                    );
                  }
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Notas"
                icon={
                  audiometryId !== -1
                    ? "/images/write-blue.svg"
                    : "/images/write-gray.svg"
                }
                onClick={() => {}}
              ></ButtonIconV>
              <ButtonIconV
                text="Imprimir"
                icon={
                  audiometryId !== -1
                    ? "/images/print-blue.svg"
                    : "/images/print-gray.svg"
                }
                onClick={() => {}}
              ></ButtonIconV>
            </div>
          </div>
          <div className="sep-line"></div>
          <div className="popup-screen-content">
            <div className="popup-screen-content-col w-100">
              <div className="opt-table-container">
                <div className="opt-table-content grid-5">
                  <div className="cell opt-table-subtitle cell-start-content">
                    Audífonos
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    Síntomas
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    Patologías
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    Tratamientos
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    Antecedentes
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    <InputTextArea
                      tabIndex={53}
                      type="type-full"
                      size={0}
                      maxLength={100}
                      input={currentAudiometryEdit.audifonos}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          audifonos: e,
                        });
                      }}
                      bkg={true}
                    ></InputTextArea>
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    <InputTextArea
                      tabIndex={54}
                      type="type-full"
                      size={0}
                      maxLength={100}
                      input={currentAudiometryEdit.sintomas}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          sintomas: e,
                        });
                      }}
                      bkg={true}
                    ></InputTextArea>
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    <InputTextArea
                      tabIndex={55}
                      type="type-full"
                      size={0}
                      maxLength={100}
                      input={currentAudiometryEdit.patologias}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          patologias: e,
                        });
                      }}
                      bkg={true}
                    ></InputTextArea>
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    <InputTextArea
                      tabIndex={56}
                      type="type-full"
                      size={0}
                      maxLength={100}
                      input={currentAudiometryEdit.tratamientos}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          tratamientos: e,
                        });
                      }}
                      bkg={true}
                    ></InputTextArea>
                  </div>
                  <div className="cell opt-table-subtitle cell-start-content">
                    <InputTextArea
                      tabIndex={57}
                      type="type-full"
                      size={0}
                      maxLength={100}
                      input={currentAudiometryEdit.antecedentes}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          antecedentes: e,
                        });
                      }}
                      bkg={true}
                    ></InputTextArea>
                  </div>
                  <div className="cell combined-cells-5"></div>
                </div>
                <div className="opt-table-content grid-10" id="audiometry-grid">
                  <div className="cell cell-label cell-start-content">
                    <div
                      className="check-container-content"
                      onClick={() => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          dolor: !currentAudiometryEdit.dolor,
                        });
                      }}
                    >
                      Dolor
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="check-container-content">
                      <div className="radio-op">
                        <CheckBox
                          tabIndex={58}
                          active={currentAudiometryEdit.dolor}
                          onClick={() => {
                            setCurrentAudiometryEdit({
                              ...currentAudiometryEdit,
                              dolor: !currentAudiometryEdit.dolor,
                            });
                          }}
                        ></CheckBox>
                      </div>
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Acúfenos
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="acufenos-tipos"
                      tabIndex={63}
                      disabled={false}
                      optionList={ACUFENOSLIST}
                      size={1}
                      op={currentAudiometryEdit.acufenos}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          acufenos: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Volumen TV
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="volumen-tv"
                      tabIndex={68}
                      disabled={false}
                      optionList={VOLUMENTVLIST}
                      size={1}
                      op={currentAudiometryEdit.volumen_tv}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          volumen_tv: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Eventos Públicos Cerrados
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={73}
                      disabled={false}
                      optionList={EVENTOSPUBLICOSCERRADOSLIST}
                      size={1}
                      op={currentAudiometryEdit.eventos_publicos_cerrados}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          eventos_publicos_cerrados: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Eventos Públicos Abiertos
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={74}
                      disabled={false}
                      optionList={EVENTOSPUBLICOSABIERTOSLIST}
                      size={0}
                      op={currentAudiometryEdit.eventos_publicos_abiertos}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          eventos_publicos_abiertos: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div
                      className="check-container-content"
                      onClick={() => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          perdida_progresiva:
                            !currentAudiometryEdit.perdida_progresiva,
                        });
                      }}
                    >
                      Pérdida Progresiva
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="check-container-content">
                      <div className="radio-op">
                        <CheckBox
                          tabIndex={59}
                          active={currentAudiometryEdit.perdida_progresiva}
                          onClick={() => {
                            setCurrentAudiometryEdit({
                              ...currentAudiometryEdit,
                              perdida_progresiva:
                                !currentAudiometryEdit.perdida_progresiva,
                            });
                          }}
                        ></CheckBox>
                      </div>
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Dominancia
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="acufenos-tipos"
                      tabIndex={64}
                      disabled={false}
                      optionList={DOMINANCIALIST}
                      size={1}
                      op={currentAudiometryEdit.dominancia_auditiva}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          dominancia_auditiva: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Conversación Tlf.
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="volumen-tv"
                      tabIndex={69}
                      disabled={false}
                      optionList={CONVERSACIONTLFLIST}
                      size={1}
                      op={currentAudiometryEdit.telefono}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          telefono: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Conversación Individual
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={75}
                      disabled={false}
                      optionList={CONVERSACIONINDIVIDUALLIST}
                      size={1}
                      op={currentAudiometryEdit.conv_individual_sr}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          conv_individual_sr: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Conversación Individual Ruido
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={76}
                      disabled={false}
                      optionList={CONVERSACIONINDIVIDUALRUIDOLIST}
                      size={0}
                      op={currentAudiometryEdit.conv_individual_cr}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          conv_individual_cr: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div
                      className="check-container-content"
                      onClick={() => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          sensible_ruidos:
                            !currentAudiometryEdit.sensible_ruidos,
                        });
                      }}
                    >
                      Sensible a Ruidos
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="check-container-content">
                      <div className="radio-op">
                        <CheckBox
                          tabIndex={60}
                          active={currentAudiometryEdit.sensible_ruidos}
                          onClick={() => {
                            setCurrentAudiometryEdit({
                              ...currentAudiometryEdit,
                              sensible_ruidos:
                                !currentAudiometryEdit.sensible_ruidos,
                            });
                          }}
                        ></CheckBox>
                      </div>
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Otorrea
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="acufenos-tipos"
                      tabIndex={65}
                      disabled={false}
                      optionList={OTORREALIST}
                      size={1}
                      op={currentAudiometryEdit.otorrea}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          otorrea: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Sonidos Agudos
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="volumen-tv"
                      tabIndex={70}
                      disabled={false}
                      optionList={SONIDOSAGUDOSLIST}
                      size={1}
                      op={currentAudiometryEdit.sonidos_agudos}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          sonidos_agudos: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Conversación en Grupo
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={77}
                      disabled={false}
                      optionList={CONVERSACIONENGRUPOLIST}
                      size={1}
                      op={currentAudiometryEdit.conv_grupo_sr}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          conv_grupo_sr: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Conversación en Grupo Ruido
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="eventos-publicos-cerrados"
                      tabIndex={78}
                      disabled={false}
                      optionList={CONVERSACIONENGRUPORUIDOLIST}
                      size={0}
                      op={currentAudiometryEdit.conv_grupo_cr}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          conv_grupo_cr: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div
                      className="check-container-content"
                      onClick={() => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          trabajo_ruidoso:
                            !currentAudiometryEdit.trabajo_ruidoso,
                        });
                      }}
                    >
                      Trabajo Ruidoso
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="check-container-content">
                      <div className="radio-op">
                        <CheckBox
                          tabIndex={61}
                          active={currentAudiometryEdit.trabajo_ruidoso}
                          onClick={() => {
                            setCurrentAudiometryEdit({
                              ...currentAudiometryEdit,
                              trabajo_ruidoso:
                                !currentAudiometryEdit.trabajo_ruidoso,
                            });
                          }}
                        ></CheckBox>
                      </div>
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Perforación
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="acufenos-tipos"
                      tabIndex={66}
                      disabled={false}
                      optionList={PERFORACIONLIST}
                      size={1}
                      op={currentAudiometryEdit.perforacion}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          perforacion: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Sonidos Graves
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="volumen-tv"
                      tabIndex={71}
                      disabled={false}
                      optionList={SONIDOSGRAVESLIST}
                      size={1}
                      op={currentAudiometryEdit.sonidos_graves}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          sonidos_graves: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell combined-cells-4"></div>
                  <div className="cell cell-label cell-start-content">
                    <div
                      className="check-container-content"
                      onClick={() => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          vertigo: !currentAudiometryEdit.vertigo,
                        });
                      }}
                    >
                      Vértigo
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="check-container-content">
                      <div className="radio-op">
                        <CheckBox
                          tabIndex={62}
                          active={currentAudiometryEdit.vertigo}
                          onClick={() => {
                            setCurrentAudiometryEdit({
                              ...currentAudiometryEdit,
                              vertigo: !currentAudiometryEdit.vertigo,
                            });
                          }}
                        ></CheckBox>
                      </div>
                    </div>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Tapones
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="acufenos-tipos"
                      tabIndex={67}
                      disabled={false}
                      optionList={TAPONESLIST}
                      size={1}
                      op={currentAudiometryEdit.tapones}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          tapones: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Sonido Ambiente
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      id="volumen-tv"
                      tabIndex={72}
                      disabled={false}
                      optionList={SONIDOAMBIENTELIST}
                      size={1}
                      op={currentAudiometryEdit.sonido_ambiental}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          sonido_ambiental: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell combined-cells-4"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-screen-content">
            <div className="popup-screen-content-col w-60">
              <div className="opt-table-container">
                <div
                  className="opt-table-content grid-6"
                  id="audiometry-testing"
                >
                  <div className="cell opt-table-title combined-cells-6 cell-start-content">
                    Pruebas
                  </div>
                  <div className="cell opt-table-subtitle combined-cells-2 cell-start-content">
                    Test de Weber
                  </div>
                  <div className="cell opt-table-subtitle combined-cells-2 cell-start-content">
                    Test de Rinne
                  </div>
                  <div className="cell opt-table-subtitle combined-cells-2 cell-start-content">
                    Timpanometría
                  </div>
                  <div className="cell cell-label cell-start-content">128</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={79}
                      disabled={false}
                      optionList={TESTDEWEBERLIST}
                      size={1}
                      op={currentAudiometryEdit.weber_128}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*weber_128: e.text,*/
                          weber_128: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">128</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={84}
                      disabled={false}
                      optionList={TESTDERINNELIST}
                      size={1}
                      op={currentAudiometryEdit.rinne_128}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*rinne_128: e.text,*/
                          rinne_128: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">OD</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={89}
                      disabled={false}
                      optionList={TIMPANOMETRIALIST}
                      size={2}
                      op={currentAudiometryEdit.timpanometria_d}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          timpanometria_d: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">256</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={80}
                      disabled={false}
                      optionList={TESTDEWEBERLIST}
                      size={1}
                      op={currentAudiometryEdit.weber_256}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*weber_256: e.text,*/
                          weber_256: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">256</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={85}
                      disabled={false}
                      optionList={TESTDERINNELIST}
                      size={1}
                      op={currentAudiometryEdit.rinne_256}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*rinne_256: e.text,*/
                          rinne_256: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">OI</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={90}
                      disabled={false}
                      optionList={TIMPANOMETRIALIST}
                      size={2}
                      op={currentAudiometryEdit.timpanometria_i}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          timpanometria_i: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">512</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={81}
                      disabled={false}
                      optionList={TESTDEWEBERLIST}
                      size={1}
                      op={currentAudiometryEdit.weber_512}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*weber_512: e.text,*/
                          weber_512: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">512</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={86}
                      disabled={false}
                      optionList={TESTDERINNELIST}
                      size={1}
                      op={currentAudiometryEdit.rinne_512}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*rinne_512: e.text,*/
                          rinne_512: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell combined-cells-2"></div>
                  <div className="cell cell-label cell-start-content">1024</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={82}
                      disabled={false}
                      optionList={TESTDEWEBERLIST}
                      size={1}
                      op={currentAudiometryEdit.weber_1024}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*weber_1024: e.text,*/
                          weber_1024: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">1024</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={87}
                      disabled={false}
                      optionList={TESTDERINNELIST}
                      size={1}
                      op={currentAudiometryEdit.rinne_1024}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*rinne_1024: e.text,*/
                          rinne_1024: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell combined-cells-2">OBSERVACIONES ¿?</div>
                  <div className="cell cell-label cell-start-content">2048</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={83}
                      disabled={false}
                      optionList={TESTDEWEBERLIST}
                      size={1}
                      op={currentAudiometryEdit.weber_2048}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*weber_2048: e.text,*/
                          weber_2048: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">2048</div>
                  <div className="cell cell-label cell-start-content">
                    <Dropdown
                      tabIndex={88}
                      disabled={false}
                      optionList={TESTDERINNELIST}
                      size={1}
                      op={currentAudiometryEdit.rinne_2048}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          /*rinne_2048: e.text,*/
                          rinne_2048: e.id,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell combined-cells-2"></div>
                </div>
              </div>
            </div>
            <div
              className="popup-screen-content-col w-40"
              id="DatosAudiometria"
            >
              <div className="opt-table-container">
                <div className="opt-table-content grid-3">
                  <div className="cell opt-table-title combined-cells-3 cell-start-content">
                    Datos
                  </div>
                  <div className="cell cell-label combined-cells-3"></div>
                  <div className="cell cell-label cell-start-content">
                    Audioprotesista
                  </div>
                  <div className="cell cell-label combined-cells-2 cell-start-content">
                    <Dropdown
                      tabIndex={91}
                      optionList={hearingAidSpecialistList}
                      size={2}
                      op={currentAudiometryEdit.audioprotesista}
                      onChange={(e) => {
                        setCurrentAudiometryEdit({
                          ...currentAudiometryEdit,
                          audioprotesista: e.text,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label cell-start-content">
                    Creación
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="input-data input-full">
                      <input
                        type="datetime-local"
                        step="1"
                        tabIndex={92}
                        className="input-field"
                        value={currentAudiometryEdit.fecha}
                        onChange={(e) => {
                          setCurrentAudiometryEdit({
                            ...currentAudiometryEdit,
                            fecha: e,
                          });
                        }}
                        onKeyUp={(e) => {
                          inputOnEnter(e);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="cell"></div>
                  <div className="cell cell-label cell-start-content">
                    Revisión
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <div className="input-data input-full">
                      <input
                        type="datetime-local"
                        step="1"
                        tabIndex={93}
                        className="input-field"
                        value={currentAudiometryEdit.fecha_revision}
                        onChange={(e) => {
                          setCurrentAudiometryEdit({
                            ...currentAudiometryEdit,
                            fecha_revision: e,
                          });
                        }}
                        onKeyUp={(e) => {
                          inputOnEnter(e);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="cell"></div>
                  <div className="cell cell-label cell-start-content">
                    Actualización
                  </div>
                  <div className="cell cell-label cell-start-content">
                    <InputData
                      size={0}
                      input={moment(
                        currentAudiometryEdit.fecha_actualizacion
                      ).format("DD/MM/YYYY HH:mm:ss")}
                      inactive={true}
                    ></InputData>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-3"></div>
                  <div className="cell"></div>
                  <div className="cell cell-start-content">
                    <ButtonIconH
                      text="Audiometría"
                      icon={svgIcons.chart}
                      onClick={() => {
                        setToggleAudiometry(true);
                      }}
                    ></ButtonIconH>
                  </div>
                  <div className="cell"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
