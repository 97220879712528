import React from "react";
import "../css/Panel.css";
import "../css/Patient.css";
import "../css/PatientSearch.css";
import "../css/custom-datatable.css";

export default function PatientSearch(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Página por definir</span>
      </div>
      <div className="patient-data-content"></div>
    </div>
  );
}
