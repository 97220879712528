import React from "react";

export default function ConfigListItem(props) {
  return (
    <div className="cfg-log-row" key={props.idElement}>
      <div className="cfg-entered-value">
        <div className="cfg-text-body" id={props.idElement}>
          {props.descripcion}
        </div>
      </div>
      <div className="cfg-text-button">
        <div
          className="cfg-texts"
          onClick={() => {
            props.onClickFunction(props.idElement);
          }}
        >
          Eliminar
        </div>
      </div>
    </div>
  );
}
