import React, { useEffect, useState } from "react";
import "../css/ComboBox.css";

export default function ComboBox(props) {
  const [togglePanel, setTogglePanel] = useState(false);
  const [selectedOp, setSelectedOP] = useState(props.optionList[0]);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    const refineOptionList = () => {
      const list = props.optionList.map((e, i) => {
        return (
          <div
            className="dropdown-op"
            key={i}
            onClick={() => {
              setSelectedOP(e);
              setTogglePanel(false);
            }}
          >
            {e.img !== "" && (
              <img
                src={e.img}
                loading="lazy"
                alt="Op Icon"
                className="op-icon"
              />
            )}
            <span className={e.id === selectedOp.id ? "selected-op" : ""}>
              {e.text}
            </span>
          </div>
        );
      });
      setOptionList(list);
    };
    refineOptionList();
  }, [props, selectedOp]);

  return (
    <div className="combobox-container">
      <div
        className="dropdown"
        onClick={() => {
          if (!props.disabled) setTogglePanel(!togglePanel);
        }}
      >
        <div className="languagetext">
          {selectedOp.img !== "" && (
            <img
              src={selectedOp.img}
              loading="lazy"
              alt="Option Icon"
              className="op-icon"
            />
          )}
          <div className="body-p2">{selectedOp.text}</div>
        </div>
        <div className="downicon">
          <img
            src="/images/down-arrow.svg"
            loading="lazy"
            alt="Panel Icon"
            className="vectors-wrapper-11"
          />
        </div>
      </div>
      {togglePanel && <div className="dropdown-options">{optionList}</div>}
    </div>
  );
}
