import React, { useRef, useEffect } from "react";
import "../css/Tooltip.css";

export default function Tooltip({ top, left, content, style = "" }) {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipWidth = tooltipRef.current.offsetWidth;
      let newLeft = left - tooltipWidth / 2;
      if (left + tooltipWidth >= window.innerWidth) {
        newLeft = left - (tooltipWidth / 2) * 1.75;
      }
      tooltipRef.current.style.left = `${newLeft}px`;
    }
  }, [left]);
  return (
    <div
      className={`tooltip  ${style}`}
      style={{ top: top + 30, left: left }}
      ref={tooltipRef}
    >
      {content}
    </div>
  );
}
