import React, { useState } from "react";
import InputData from "../../components/InputData";
import "../../css/Panel.css";
import "../../css/Patient.css";
import "../../css/PatientSearch.css";

export default function PatientSearch(props) {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [text4, setText4] = useState("");
  const [text5, setText5] = useState("");
  const [text6, setText6] = useState("");
  const [text7, setText7] = useState("");
  const [text8, setText8] = useState("");
  const [text9, setText9] = useState("");
  const [text10, setText10] = useState("");
  const [text11, setText11] = useState("");
  const [text12, setText12] = useState("");
  const [text13, setText13] = useState("");
  const [text14, setText14] = useState("");
  const [text15, setText15] = useState("");
  const [text16, setText16] = useState("");

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Textfields</span>
        <div className="patient-header-buttons"></div>
      </div>
      <div className="patient-data-content">
        <div className="action-bar">
          <div className="action-bar-resume">
            <span>Debug</span>
            <span>|</span>
            <span className="signed-text">Textfields</span>
          </div>
          <div
            className="action-bar-return"
            onClick={() => {
              props.onGoBack();
            }}
          >
            <span>Volver</span>
            <img
              srcSet="/images/return.svg"
              loading="lazy"
              alt="Icon"
              className="return-icon"
            />
          </div>
        </div>
        <div className="patient-sections">
          <div className="patient-section" id="Audífonos">
            <div className="section-content">
              <div className="section-todo-tests">
                <div className="data-field data-field-tests">
                  <span className="label">Normal</span>
                  <InputData
                    id="text1"
                    input={text1}
                    onChange={(e) => {
                      setText1(e);
                    }}
                  />
                  <span className="label">type-only-chars - Sólo letras</span>
                  <InputData
                    id="text2"
                    type="type-only-chars"
                    input={text2}
                    onChange={(e) => {
                      setText2(e);
                    }}
                  />
                  <span className="label">
                    type-only-numbers - Sólo números
                  </span>
                  <InputData
                    id="text3"
                    type="type-only-numbers"
                    input={text3}
                    onChange={(e) => {
                      setText3(e);
                    }}
                  />
                  <span className="label">
                    type-only-charnumbers - Sólo letras y números
                  </span>
                  <InputData
                    id="text4"
                    type="type-only-charnumbers"
                    input={text4}
                    onChange={(e) => {
                      setText4(e);
                    }}
                  />
                  <span className="label">type-alpha - Alfabeto</span>
                  <InputData
                    id="text5"
                    type="type-alpha"
                    input={text5}
                    onChange={(e) => {
                      setText5(e);
                    }}
                  />
                  <span className="label">type-alphanum - Alfanumérico</span>
                  <InputData
                    id="text6"
                    type="type-alphanum"
                    input={text6}
                    onChange={(e) => {
                      setText6(e);
                    }}
                  />
                  <span className="label">
                    type-alpha-ext - Alfabeto + Caracteres especiales más
                    populares
                  </span>
                  <InputData
                    id="text12"
                    type="type-alpha"
                    input={text12}
                    onChange={(e) => {
                      setText12(e);
                    }}
                  />
                  <span className="label">
                    type-alphanum-ext - Alfanumérico + Caracteres especiales más
                    populares
                  </span>
                  <InputData
                    id="text13"
                    type="type-alphanum"
                    input={text13}
                    onChange={(e) => {
                      setText13(e);
                    }}
                  />
                  <span className="label">type-integer - Entero</span>
                  <InputData
                    id="text7"
                    type="type-integer"
                    input={text7}
                    onChange={(e) => {
                      setText7(e);
                    }}
                  />
                  <span className="label">type-float - Real</span>
                  <InputData
                    id="text8"
                    type="type-float"
                    input={text8}
                    onChange={(e) => {
                      setText8(e);
                    }}
                  />
                  <span className="label">
                    type-float2 - Real con dos decimales
                  </span>
                  <InputData
                    id="text15"
                    type="type-float2"
                    input={text15}
                    onChange={(e) => {
                      setText15(e);
                    }}
                  />
                  <span className="label">type-email - Email</span>
                  <InputData
                    id="text9"
                    type="type-email"
                    input={text9}
                    onChange={(e) => {
                      setText9(e);
                    }}
                    onMalformed={() => alert("Error de formato.")}
                  />
                  <span className="label">type-phone - Teléfono</span>
                  <InputData
                    id="text11"
                    type="type-phone"
                    input={text11}
                    onChange={(e) => {
                      setText11(e);
                    }}
                  />
                  <span className="label">
                    type-optofloat - Real para Optometría
                  </span>
                  <InputData
                    id="text14"
                    type="type-optofloat"
                    input={text14}
                    onChange={(e) => {
                      setText14(e);
                    }}
                  />
                  <span className="label">
                    type-optoacomodativo - Tipo /1 para Optometría Avanzada
                    Acomodativo
                  </span>
                  <InputData
                    id="text16"
                    type="type-optoacomodativo"
                    input={text16}
                    onChange={(e) => {
                      setText16(e);
                    }}
                    considerFocus={true}
                  />
                  <span className="label">
                    dontForceUppercase - No forzar mayúsculas
                  </span>
                  <InputData
                    id="text10"
                    input={text10}
                    dontForceUppercase={true}
                    onChange={(e) => {
                      setText10(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
