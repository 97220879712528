import React, { useState, useEffect, useRef } from "react";
import Input from "../components/Input";
import LinkCard from "../components/LinkCard";
import RRSSIcon from "../components/RRSSIcon";
import Button from "../components/Button";
import ComboBox from "../components/ComboBox";
import ErrorBox from "../components/ErrorBox";
import { post } from "../js/post";
import { loginOptions } from "../js/utils";
import moment from "moment";
import "../css/Login.css";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");

  const validateLogin = async () => {
    if (username === "") setErrorUsername(true);
    if (password === "") setErrorPassword(true);
    if (username !== "" && password !== "") {
      const response = await post("Login.php", {
        username: username,
        password: password,
      });
      if (response.error) {
        setErrorLogin(response.error);
      } else {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            token: response.token,
            username: username,
            pic: response.foto,
            optics: response.opticas,
            id: response.id,
          })
        );
        localStorage.setItem(
          "selectedOptic",
          JSON.stringify(response.opticas[0])
        );
        setErrorLogin("");
        window.location = "/main";
      }
    } else {
      setErrorLogin("Debe rellenar usuario y contraseña.");
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (document.activeElement.id === "user") {
        document.getElementById("pass").focus();
      } else if (document.activeElement.id === "pass") {
        await validateLogin();
      }
    }
  };

  return (
    <div className="login" onKeyDown={handleKeyPress} tabIndex={0}>
      <div className="sidebar">
        <img
          loading="lazy"
          src="/images/Logo.png"
          sizes="(max-width: 1919px) 287.625px, 479.375px"
          alt="Logo"
          className="logo"
        ></img>
        <div className="logincontainer">
          <div className="h3">Iniciar sesión</div>
          <div className="loginfields">
            <div className="inputscontainer">
              <Input
                icon="/images/user-blue-fill.svg"
                iconError="/images/username-error.svg"
                placeholder="Usuario"
                id="user"
                error={errorUsername}
                onAccess={(username) => {
                  setUsername(username);
                  setErrorUsername(false);
                }}
              ></Input>
              <Input
                icon="/images/password-blue-fill.svg"
                iconError="/images/password-error.svg"
                placeholder="Contraseña"
                id="pass"
                pwd={true}
                error={errorPassword}
                onAccess={(password) => {
                  setPassword(password);
                  setErrorPassword(false);
                }}
              ></Input>
            </div>
            <div className="links">
              <a href="/" className="button" rel="noreferrer">
                <div className="body-p2 lightblue">¿Olvidó su contraseña?</div>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=34722359681&text=Hola+%C2%BFen+qu%C3%A9+puedo+ayudarte%3F"
                target="_blank"
                className="button"
                rel="noreferrer"
              >
                <div className="body-p2 lightblue">Ayuda</div>
              </a>
            </div>
          </div>
          <Button
            title="Acceder"
            login={true}
            onPress={async () => {
              await validateLogin();
            }}
          ></Button>
          <div className="error-container">
            {errorLogin && <ErrorBox text={errorLogin}></ErrorBox>}
          </div>
        </div>
        <div className="terms">
          <div className="subtitle">OPTICAL THINKING</div>
          <img
            src="/images/spacer.svg"
            loading="lazy"
            alt="Space"
            className="spacer"
          />
          <div className="legal-terms">
            <a
              href="https://www.optiwin.com/aviso-legal"
              className="button"
              target="_blank"
              rel="noreferrer"
            >
              <div className="body-s lightblue">
                Política de Privacidad y Condiciones del Servicio
              </div>
            </a>
            <a
              href="https://www.optiwin.com/politica-de-cookies"
              target="_blank"
              className="button"
              rel="noreferrer"
            >
              <div className="body-s lightblue">Política de Cookies</div>
            </a>
          </div>
        </div>
      </div>
      <div className="dashboard">
        <div className="topcontent topcontent-language">
          <ComboBox disabled={false} optionList={loginOptions}></ComboBox>
        </div>
        <div className="banner">
          <div className="header">
            <div className="h2">OPTIWIN Summer {moment().format("YYYY")}</div>
            <Button title="Comprar SMS" onPress={() => {}}></Button>
          </div>
          <img
            loading="lazy"
            src="/images/Image.png"
            sizes="(max-width: 479px) 100vw, (max-width: 1919px) 600px, 800px"
            alt="Banner"
            className="image banner"
          />
        </div>
        <div className="cards-container">
          <LinkCard
            imgTitle="/images/image01.svg"
            imgCard="/images/Image_1.png"
            link="https://www.optiwin.com/blogs-grid"
          ></LinkCard>
          <LinkCard
            imgTitle="/images/image02.svg"
            imgCard="/images/Image_2.png"
            link="https://www.optiwin.com/descubre/academia"
          ></LinkCard>
        </div>
        <div className="social-container">
          <div className="links">
            <RRSSIcon
              icon="/images/facebook-logo.svg"
              link="https://www.facebook.com/OPTIWIN"
            ></RRSSIcon>
            <RRSSIcon
              icon="/images/youtube-logo.svg"
              link="https://www.youtube.com/@optiwinsoftware7372"
            ></RRSSIcon>
            <RRSSIcon
              icon="/images/instagram-logo.png"
              link="https://www.instagram.com/optiwin_software/"
            ></RRSSIcon>
          </div>
          <img
            src="/images/dark-spacer.svg"
            loading="lazy"
            alt="Spacer"
            className="spacer"
          />
          <div className="body-p2 semibold">
            ¡Síguenos en las redes sociales!
          </div>
        </div>
      </div>
    </div>
  );
}
