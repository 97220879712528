import React from "react";
import "../css/OptionMenu.css";

export default function OptionMenu(props) {
  return (
    <div
      className={`menu-inicio ${props.active ? "active-op" : ""}`}
      onClick={props.onClick}
      key={props.id}
    >
      <div className={`section-title ${props.subOp ? "subsection-op" : ""}`}>
        <img
          src={props.icon}
          loading="lazy"
          width="14"
          height="15"
          alt="Icon"
          className="vectors-wrapper"
        />
        <div className="h4-p2 white">{props.text}</div>
      </div>
    </div>
  );
}
