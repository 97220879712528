import React from "react";
import "../css/LinkCard.css";

export default function LinkCard(props) {
  return (
    <a href={props.link} target="_blank">
      <div className="link-card">
        <img
          src={props.imgTitle}
          loading="lazy"
          width="241"
          height="45"
          alt="Img title"
          className="linktitle"
        />
        <img
          loading="lazy"
          width="269"
          height="187"
          src={props.imgCard}
          sizes="(max-width: 1919px) 172.609375px, 287.6875px"
          alt="Img card"
          className="imagecard"
        />
      </div>
    </a>
  );
}
