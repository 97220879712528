import React, { useState } from "react";

import Tab from "../components/Tab";
import EmpresasOpticas from "./EmpresasOpticas";
import AgentesPermisos from "./AgentesPermisos";
import PerfilesControlInformacion from "./PerfilesControlInformacion";
import PredeterminadosPaciente from "./PredeterminadosPaciente";

import "../../css/Patient.css";
import "../../css/Panel.css";

import "../css/Settings.css";

export default function Settings(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data patient-data-settings">
      <div className="patient-data-header">
        <span className="header-text">Configuración</span>
        <div className="patient-header-buttons"></div>
      </div>
      <div className="patient-data-content patient-data-content-settings">
        <div className="settings-panel">
          <div className="settings-tab-container">
            <Tab
              text="Empresas y Ópticas"
              icon="/images/settings/oficina.svg"
              selected={selectedTab === 0}
              onClick={() => setSelectedTab(0)}
            />
            <Tab
              text="Agentes y Permisos"
              icon="/images/settings/los-usuarios.svg"
              selected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            />
            <Tab
              text="Perfiles Control de Información"
              icon="/images/settings/llave.svg"
              selected={selectedTab === 2}
              onClick={() => setSelectedTab(2)}
            />
            <Tab
              text="Predeterminados de Paciente"
              icon="/images/settings/pacientes.svg"
              selected={selectedTab === 3}
              onClick={() => setSelectedTab(3)}
            />
          </div>
          <div className="settings-body">
            <EmpresasOpticas {...props} show={selectedTab === 0} />
            <AgentesPermisos {...props} show={selectedTab === 1} />
            <PerfilesControlInformacion {...props} show={selectedTab === 2} />
            <PredeterminadosPaciente {...props} show={selectedTab === 3} />
          </div>
        </div>
      </div>
    </div>
  );
}
