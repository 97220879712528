import React from "react";
import ButtonIconH from "./ButtonIconH";
import "../css/WebCam.css";

export default function WebCam(props) {
  const takePhoto = () => {
    if (
      props.videoRef.current &&
      props.canvasRef.current &&
      props.photoRef.current
    ) {
      const context = props.canvasRef.current.getContext("2d");

      const videoWidth = props.videoRef.current.videoWidth;
      const videoHeight = props.videoRef.current.videoHeight;

      // Resize 1:1
      const squareSize = Math.min(videoWidth, videoHeight);
      const xOffset = (videoWidth - squareSize) / 2;
      const yOffset = (videoHeight - squareSize) / 2;

      // Set canvas dimension
      props.canvasRef.current.width = squareSize;
      props.canvasRef.current.height = squareSize;

      context.clearRect(0, 0, squareSize, squareSize);
      context.drawImage(
        props.videoRef.current,
        xOffset,
        yOffset,
        squareSize,
        squareSize,
        0,
        0,
        squareSize,
        squareSize
      );

      // Convert to base64
      const dataURL = props.canvasRef.current.toDataURL("image/png");
      props.onTakePhoto(dataURL);
    }
  };

  return (
    <div className="webcam-popup">
      <div className="webcam-popup-container">
        <video
          ref={props.videoRef}
          width="500"
          height="500"
          autoPlay
          style={{ display: "block" }}
        />
        <canvas
          ref={props.canvasRef}
          width="640"
          height="480"
          style={{ display: "none" }}
        />
        <img ref={props.photoRef} alt="pic" style={{ display: "none" }} />
        <div className="popup-buttons">
          <ButtonIconH text="Tomar foto" onClick={takePhoto}></ButtonIconH>
          <ButtonIconH
            text="Cancelar"
            onClick={() => {
              props.onCancel();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
