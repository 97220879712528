export const patientSearchTemplate = `<head>
<style>
  body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }

  table {
    width: 100%;
    margin-top: 30px;
  }

  table,
  th,
  td {
    border: 1px solid #c0c0c0;
    border-collapse: collapse;
  }

  tr.table-header {
    background-color: #4682b4;
    color: white;
  }

  th,
  td {
    padding: 2px 5px 2px 5px;
    text-align: left;
  }

  th {
    font-weight: normal;
    font-size: 13px;
  }

  td {
    font-size: 11px;
  }

  div {
    margin-bottom: 10px;
  }

  img {
    width: 15px;
    margin: 0px 2px;
  }

  td.preferencias {
    text-align: center;
  }
</style>
</head>
<body>
<div>Exportación de Pacientes</div>
<div>Óptica: {{OPTICA}}</div>
<table>
  <tr class="table-header">
    <th>Código</th>
    <th>Nombre</th>
    <th>Apellidos</th>
    <th>Preferencias</th>
    <th>Dirección</th>
    <th>Localidad</th>
    <th>Teléfono</th>
    <th>Email</th>
  </tr>
  {{ROW}}
</table>
</body>`;
