import React, { useState, useEffect, useRef } from "react";

import PatientSearch from "./PatientSearch";
import Panel from "./Panel";
import Patient from "./Patient";
import Empty from "./Empty";
import Settings from "../support/screens/Settings";
import Textfields from "./debug/TextFields";
import Exports from "./debug/Exports";
import Skeleton from "./debug/Skeleton";
import OptionMenu from "../components/OptionMenu";
import TopBar from "../components/TopBar";
import PopupMessage from "../components/PopupMessage";
import MenuOption from "../components/MenuOption";
import OptometryScreen from "../components/OptometryScreen";
import Audiometry from "../components/Audiometry";
import ObservationsPanel from "../components/ObservationsPanel";
import SuppliersPanel from "../components/SuppliersPanel";
import Store from "./Store";

import { useParams } from "react-router-dom";

import { menu } from "../js/menu";
import { disconnect } from "../js/utils";

export default function Main() {
  let { screenId, params } = useParams();

  let [screens, setScreens] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(
    screenId ? screenId : "panel"
  );
  const [currentShownScreen, setCurrentShownScreen] = useState(
    screenId ? screenId : "panel"
  );
  const componentVars = useRef();
  const [paramsPatient, setParamsPatient] = useState(
    screenId === "patient-datos" ? { patientId: params } : null
  );
  const [paramsPatientSearch, setParamsPatientSearch] = useState({ reload: 0 });
  const [popupQuestion, setPopupQuestion] = useState(null);

  const [showOptoPanel, setShowOptoPanel] = useState(false);
  const [optoPatient, setOptoPatient] = useState(null);
  const [obsObjectId, setObsObjectId] = useState(null);
  const [optoTabIndex, setOptoTabIndex] = useState(-1);
  const [lastOptoIds, setLastOptoIds] = useState(null);
  const [lastAudiometryId, setLastAudiometryId] = useState(-1);
  const [optoOnCloseAction, setOptoOnCloseAction] = useState(null);
  const [audiometryOnCloseAction, setAudiometryOnCloseAction] = useState(null);
  const [optoNewOptometry, setOptoNewOptometry] = useState(false);
  const [audioNewAudiometria, setAudioNewAudiometria] = useState(false);
  const [showAudiometryPanel, setShowAudiometryPanel] = useState(false);
  const [showObservationsPanel, setShowObservationsPanel] = useState(false);
  const [showSuppliersPanel, setShowSuppliersPanel] = useState(false);
  const [observationsOnCloseAction, setObservationsOnCloseAction] =
    useState(null);
  const [suppliersOnCloseAction, setSuppliersOnCloseAction] = useState(null);
  const [obsCategory, setObsCategory] = useState("");

  let prevW = -1;
  let menuHTML = null;
  let contentHTML = null;

  const MAX_W = 1080;

  let openOptoPanel = (patientInfo, tabId, ids, onCloseAction) => {
    setOptoPatient(patientInfo);
    setOptoTabIndex(tabId);
    setLastOptoIds(ids);
    setOptoOnCloseAction(() => onCloseAction);
    setShowOptoPanel(true);
  };

  let closeOptoPanel = () => {
    setOptoPatient(null);
    setOptoTabIndex(-1);
    setLastOptoIds(null);
    setOptoOnCloseAction(null);
    setShowOptoPanel(false);
    setOptoNewOptometry(false);
  };

  let openAudiometryPanel = (patientInfo, id, onCloseAction) => {
    setOptoPatient(patientInfo);
    setLastAudiometryId(id);
    setAudiometryOnCloseAction(() => onCloseAction);
    setShowAudiometryPanel(true);
  };

  let closeAudiometryPanel = () => {
    setOptoPatient(null);
    setLastAudiometryId(-1);
    setAudiometryOnCloseAction(null);
    setShowAudiometryPanel(false);
    setAudioNewAudiometria(false);
  };

  let openObservationsPanel = (
    patientInfo,
    objectId,
    onCloseAction,
    category
  ) => {
    setOptoPatient(patientInfo);
    setObsObjectId(objectId);
    setObsCategory(category);
    setShowObservationsPanel(true);
    setObservationsOnCloseAction(() => onCloseAction);
  };

  let closeObservationsPanel = () => {
    setOptoPatient(null);
    setObsObjectId(null);
    setShowObservationsPanel(false);
    setObservationsOnCloseAction(null);
  };

  let openSuppliersPanel = (onCloseAction) => {
    setShowSuppliersPanel(true);
    setSuppliersOnCloseAction(() => onCloseAction);
  };

  let closeSuppliersPanel = () => {
    setObsObjectId(null);
    setShowSuppliersPanel(false);
    setSuppliersOnCloseAction(null);
  };

  let menuOver = () => {
    menuHTML.classList.remove("sidebar-app-hidden");
  };

  let menuOut = () => {
    menuHTML.classList.add("sidebar-app-hidden");
  };

  let goBack = async () => {
    componentVars.prevScreens.pop();
    componentVars.prevScreensParams.pop();
    if (componentVars.prevScreens.length === 0) {
      clickMenuOption("panel");
    } else {
      clickMenuOption(
        componentVars.prevScreens[componentVars.prevScreens.length - 1],
        componentVars.prevScreensParams[
          componentVars.prevScreensParams.length - 1
        ]
      );
    }
  };

  let createMenu = () => {
    menu.forEach((m) => {
      m.submenuOpen = false;
      m.subsections.forEach((s) => {
        s.submenuOpen = false;
      });
    });
    setScreens(menu);
    screens = menu;
  };

  useEffect(() => {
    createMenu();

    menuHTML = document.querySelector("div.sidebar-app");
    contentHTML = document.querySelector("div.dashboard-app");

    const w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (w <= MAX_W) {
      menuHTML.classList.add("sidebar-app-hidden");
      menuHTML.classList.add("sidebar-app-hidden-abs");
      contentHTML.classList.add("dashboard-app-reduced");
      menuHTML.addEventListener("mouseover", menuOver);
      menuHTML.addEventListener("mouseout", menuOut);
    }
    if (w > MAX_W) {
      menuHTML.classList.remove("sidebar-app-hidden");
      menuHTML.classList.remove("sidebar-app-hidden-abs");
      contentHTML.classList.remove("dashboard-app-reduced");
      menuHTML.removeEventListener("mouseover", menuOver);
      menuHTML.removeEventListener("mouseout", menuOut);
    }

    prevW = w;

    window.addEventListener("resize", () => {
      const w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (prevW > MAX_W && w <= MAX_W) {
        menuHTML.classList.add("sidebar-app-hidden");
        menuHTML.classList.add("sidebar-app-hidden-abs");
        menuHTML.addEventListener("mouseover", menuOver);
        menuHTML.addEventListener("mouseout", menuOut);
      }
      if (prevW <= MAX_W && w > MAX_W) {
        menuHTML.classList.remove("sidebar-app-hidden");
        menuHTML.classList.remove("sidebar-app-hidden-abs");
        menuHTML.removeEventListener("mouseover", menuOver);
        menuHTML.removeEventListener("mouseout", menuOut);
      }

      prevW = w;
    });

    componentVars.prevScreens = [];
    componentVars.prevScreensParams = [];

    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, document.URL);
      createMenu();
      goBack();
    });
  }, []);

  let scrollTo = (id) => {
    const element = document.querySelectorAll("div.dashboard-options")[0];
    if (element) {
      const el = document.querySelector(id);
      if (el) {
        let scrollDiv = el.offsetTop;
        element.scrollTo({
          top: scrollDiv,
          behavior: "smooth",
        });
      }
    }
  };

  let createPopupQuestion = (text, onClickAccept, onClickCancel) => {
    setPopupQuestion({
      text,
      onClickCancel,
      onClickAccept,
    });
  };

  let removePopupQuestion = () => {
    setPopupQuestion(null);
  };

  let changeOptics = () => {
    window.location = "/main";
  };

  let clickMenuOption = async (screenId, params = null) => {
    // Ventanas especiales

    if (screenId === "patient-datos-nuevo") {
      // Creación de nuevo paciente
      clickMenuOption("patient-datos", { patientId: -1 });
      return;
    }
    if (screenId === "patient-datos") {
      if (currentShownScreen === "patient-datos") {
        if (!params) {
          // Scroll
          scrollTo("[id='Datos']");
          setCurrentScreen(screenId);
          return;
        }
      } else {
        let screen = screens.find((s) => {
          return s.id === "patient-search";
        });
        if (screen) {
          if (screen.submenuOpen) {
            // Vuelve a mostrar la ficha
            setCurrentScreen(screenId);
            setCurrentShownScreen("patient-datos");
            setTimeout(() => scrollTo("[id='Datos']", 500));
            return;
          }
        }
      }
    }
    if (screenId === "patient-compras") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Compras']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Compras']", 500));
      }
      return;
    }
    if (screenId === "patient-optometria") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Optometría']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Optometría']", 500));
      }
      return;
    }
    if (screenId === "patient-audiometria") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Audiometría']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Audiometría']", 500));
      }
      return;
    }
    if (screenId === "patient-gafas") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Gafas']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Gafas']", 500));
      }
      return;
    }
    if (screenId === "patient-lentes-contacto") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Lentes de contacto']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Lentes de contacto']", 500));
      }
      return;
    }
    /*if (screenId === "patient-audiometria") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Audiometría']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Audiometría']", 500));
      }
      return;
    }*/
    if (screenId === "patient-audifonos") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Audífonos']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Audífonos']", 500));
      }
      return;
    }
    if (screenId === "patient-varios") {
      if (currentShownScreen === "patient-datos") {
        // Scroll
        scrollTo("[id='Varios']");
        setCurrentScreen(screenId);
      } else {
        // Vuelve a mostrar la ficha
        setCurrentScreen(screenId);
        setCurrentShownScreen("patient-datos");
        setTimeout(() => scrollTo("[id='Varios']", 500));
      }
      return;
    }

    if (screenId === "patient-optometria-nuevo") {
      // Creación de nueva optometría

      setOptoNewOptometry(true);

      return;
    }
    if (screenId === "patient-audiometria-nuevo") {
      // Creación de nueva audiometría

      setAudioNewAudiometria(true);

      return;
    }

    // Gestión del go back
    if (
      componentVars.prevScreens.length === 0 ||
      (componentVars.prevScreens.length > 0 &&
        componentVars.prevScreens[componentVars.prevScreens.length - 1] !==
          screenId)
    ) {
      componentVars.prevScreens.push(screenId);
      componentVars.prevScreensParams.push(params);
    }

    let screen = null;
    screens.forEach((sc) => {
      sc.subsections.forEach((s_) => {
        if (s_.id === screenId) {
          screen = s_;
        }
      });
      if (sc.id === screenId) {
        screen = sc;
      }
    });

    if (screen) {
      setCurrentScreen(screenId);
      setCurrentShownScreen(screen.screenToShow);
      screen.submenuOpen = false;

      let parentScreen = screens.find((sc) => {
        const p = sc.subsections.find((s) => {
          return s.id === screenId;
        });
        return p ? sc : null;
      });
      let parent = "";
      if (parentScreen) {
        parentScreen.submenuOpen = screenId;
        parent = parentScreen.id;
      }

      switch (screen.screenToShow) {
        // Only pages with extra params
        case "patient-datos":
          setParamsPatient(params);
          break;
      }

      if (screenId === "almacen") {
        setCurrentScreen(screenId);
        setCurrentShownScreen(screenId);
      }

      // Especial navegación Debug
      if (screenId === "debug") {
        screen.submenuOpen = true;
      } else {
        if (parent !== "debug") {
          let s = screens.find((sc) => {
            return sc.id === "debug";
          });
          s.submenuOpen = false;
        }
      }

      setScreens([...screens]);
    } else {
      setCurrentScreen("");
      setCurrentShownScreen("");
    }
  };

  return (
    <div className="welcome-screen">
      {showOptoPanel && (
        <OptometryScreen
          onClose={() => {
            if (optoOnCloseAction) {
              optoOnCloseAction();
            }
          }}
          patientInfo={optoPatient}
          tabId={optoTabIndex}
          optIds={lastOptoIds}
          openObservationsPanel={(
            patientInfo,
            objectId,
            onCloseAction,
            category
          ) => {
            openObservationsPanel(
              patientInfo,
              objectId,
              onCloseAction,
              category
            );
          }}
          closeObservationsPanel={() => {
            closeObservationsPanel();
          }}
        />
      )}
      {showAudiometryPanel && (
        <Audiometry
          onClose={() => {
            if (audiometryOnCloseAction) {
              audiometryOnCloseAction();
            }
          }}
          patientInfo={optoPatient}
          audiometryId={lastAudiometryId}
        ></Audiometry>
      )}
      {showObservationsPanel && (
        <ObservationsPanel
          objectInfo={obsObjectId}
          category={obsCategory}
          onClose={() => {
            if (observationsOnCloseAction) {
              observationsOnCloseAction();
            }
            setShowObservationsPanel(false);
          }}
        ></ObservationsPanel>
      )}
      {showSuppliersPanel && (
        <SuppliersPanel
          onClose={() => {
            if (suppliersOnCloseAction) {
              suppliersOnCloseAction();
            }
            setShowSuppliersPanel(false);
          }}
        ></SuppliersPanel>
      )}
      {popupQuestion && (
        <PopupMessage
          text={popupQuestion.text}
          onClickCancel={popupQuestion.onClickCancel}
          onClickAccept={popupQuestion.onClickAccept}
        ></PopupMessage>
      )}
      <div className="sidebar-app">
        <div className="sidebar-top">
          <img
            loading="lazy"
            sizes="7vw"
            src="/images/logo-h.png"
            alt="Logo"
            className="optiwin-logo"
          />
          <img
            loading="lazy"
            sizes="7vw"
            src="/images/optiwin-white.png"
            alt="Logo"
            className="optiwin-logo-small"
          />

          {/** MENU */}
          <div className="menu">
            {screens.map((e, i) => {
              return (
                <MenuOption
                  key={i}
                  item={e}
                  currentScreen={currentScreen}
                  clickMenuOption={clickMenuOption}
                />
              );
            })}
          </div>
          {/** */}
        </div>
        <div className="sidebar-down">
          <OptionMenu
            icon="/images/logout-icon.svg"
            text="Salir"
            onClick={() =>
              createPopupQuestion(
                "¿Desea cerrar sesión y salir de OPTIWIN?",
                () => disconnect(),
                () => removePopupQuestion()
              )
            }
          />
        </div>
      </div>
      <div className="dashboard-app">
        <TopBar onChangeOptics={() => changeOptics()} />
        <div className="dashboard-options">
          <Panel
            show={currentShownScreen === "panel"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            onGoBack={() => goBack()}
          />
          <PatientSearch
            show={currentShownScreen === "patient-search"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            onGoBack={() => goBack()}
            reload={paramsPatientSearch.reload}
          />
          <Patient
            show={currentShownScreen === "patient-datos"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            patientId={paramsPatient && paramsPatient.patientId}
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            openOptoPanel={(patientInfo, tabId, optIds, onCloseAction) =>
              openOptoPanel(patientInfo, tabId, optIds, onCloseAction)
            }
            closeOptoPanel={() => closeOptoPanel()}
            onGoBack={() => goBack()}
            newOptometry={optoNewOptometry}
            newAudiometry={audioNewAudiometria}
            openAudiometryPanel={(patientInfo, id, onCloseAction) => {
              openAudiometryPanel(patientInfo, id, onCloseAction);
            }}
            closeAudiometryPanel={() => {
              closeAudiometryPanel();
            }}
            openObservationsPanel={(
              patientInfo,
              objectId,
              onCloseAction,
              category
            ) => {
              openObservationsPanel(
                patientInfo,
                objectId,
                onCloseAction,
                category
              );
            }}
            closeObservationsPanel={() => {
              closeObservationsPanel();
            }}
          />
          <Store
            show={currentShownScreen === "almacen"}
            openSuppliersPanel={(onCloseAction) => {
              openSuppliersPanel(onCloseAction);
            }}
            closeSuppliersPanel={() => {
              closeSuppliersPanel();
            }}
          ></Store>
          <Settings
            show={currentShownScreen === "configuracion"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            onGoBack={() => goBack()}
          />
          <Textfields
            show={currentShownScreen === "debug-textfields"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            onGoBack={() => goBack()}
          />
          <Exports
            show={currentShownScreen === "debug-exports"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            onGoBack={() => goBack()}
          />
          <Skeleton
            show={currentShownScreen === "debug-skeleton"}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            onGoBack={() => goBack()}
          />
          <Empty
            show={currentShownScreen === ""}
            onClickMenuOption={(screenId, params) =>
              clickMenuOption(screenId, params)
            }
            createPopupQuestion={(text, onClickAccept, onClickCancel) =>
              createPopupQuestion(text, onClickAccept, onClickCancel)
            }
            removePopupQuestion={() => removePopupQuestion()}
            onGoBack={() => goBack()}
          />
        </div>
      </div>
    </div>
  );
}
