import { exportCSV } from "./gen/export-csv";
import { exportExcel } from "./gen/export-excel";

import { patientSearchTemplate } from "./templates/patient-search";

const html2pdf = require("html2pdf.js");

export const exportPatientSearch = async (
  patients,
  opticaName,
  format,
  filename
) => {
  if (format === "csv") {
    const data = patients.map((p) => {
      return {
        Código: p.codigo,
        Nombre: p.nombre,
        Apellidos: p.apellidos,
        "Pref. Gafas Grad.": p.preferencias.preferenciaGafasGraduadas
          ? "x"
          : "",
        "Pref. Gafas Sol": p.preferencias.preferenciaGafasDeSol ? "x" : "",
        "Pref. Audífonos": p.preferencias.preferenciaAudifonos ? "x" : "",
        "Pref. Lentillas": p.preferencias.preferenciaLentillas ? "x" : "",
        "Pref. Varios": p.preferencias.preferenciaVarios ? "x" : "",
        Dirección: p.direccion,
        Localidad: p.localidad,
        Teléfono: p.telefono,
        Email: p.email,
      };
    });
    exportCSV(data, filename);
  }
  if (format === "excel") {
    const data = patients.map((p) => {
      return {
        Código: p.codigo,
        Nombre: p.nombre,
        Apellidos: p.apellidos,
        "Pref. Gafas Grad.": p.preferencias.preferenciaGafasGraduadas
          ? "x"
          : "",
        "Pref. Gafas Sol": p.preferencias.preferenciaGafasDeSol ? "x" : "",
        "Pref. Audífonos": p.preferencias.preferenciaAudifonos ? "x" : "",
        "Pref. Lentillas": p.preferencias.preferenciaLentillas ? "x" : "",
        "Pref. Varios": p.preferencias.preferenciaVarios ? "x" : "",
        Dirección: p.direccion,
        Localidad: p.localidad,
        Teléfono: p.telefono,
        Email: p.email,
      };
    });
    exportExcel(data, filename);
  }
  if (format === "pdf") {
    let data = patientSearchTemplate;
    data = data.replace("{{OPTICA}}", opticaName);

    const rows = patients.reduce((acc, curr) => {
      let imgs = "";
      if (curr.preferencias.preferenciaGafasGraduadas) {
        imgs += `<img src="/images/preferenciaGafasGraduadas.svg" />`;
      }
      if (curr.preferencias.preferenciaGafasDeSol) {
        imgs += `<img src="/images/preferenciaGafasDeSol.svg" />`;
      }
      if (curr.preferencias.preferenciaAudifonos) {
        imgs += `<img src="/images/preferenciaAudifonos.svg" />`;
      }
      if (curr.preferencias.preferenciaLentillas) {
        imgs += `<img src="/images/preferenciaLentillas.svg" />`;
      }
      if (curr.preferencias.preferenciaVarios) {
        imgs += `<img src="/images/preferenciaVarios.svg" />`;
      }

      const row = `<tr>
                <td>${curr.codigo}</td>
                <td>${curr.nombre}</td>
                <td>${curr.apellidos}</td>
                <td class="preferencias">${imgs}</td>
                <td>${curr.direccion}</td>
                <td>${curr.localidad}</td>
                <td>${curr.telefono}</td>
                <td>${curr.email}</td>
            </tr>`;
      acc += row;
      return acc;
    }, "");
    data = data.replace("{{ROW}}", rows);

    const options = {
      margin: 10,
      filename,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };

    html2pdf(data, options);
  }
};
