import React, { useState, useEffect, useMemo } from "react";
import Dropdown from "../components/Dropdown";
import DropdownFilter from "../components/DropdownFilter";
import ButtonGrad from "../components/ButtonGrad";
import InputIconSearch from "../components/InputIconSearch";
import ButtonIcon from "../components/ButtonIcon";
import InputData from "../components/InputData";
import ButtonIconH from "../components/ButtonIconH";
import "../css/Panel.css";
import "../css/Patient.css";
import "../css/PatientSearch.css";
import "../css/custom-datatable.css";
import { post } from "../js/post";
import {
  LIMIT,
  userData,
  selectedOptic,
  provinceOptions,
  inputOnEnter,
} from "../js/utils";
import DataTable from "react-data-table-component";
import { exportPatientSearch } from "../js/exports/exports";
import "../css/PatientResultRow.css";

export default function PatientSearch(props) {
  const [inputSearch, setInputSearch] = useState("");
  const [totalPatients, setTotalPatients] = useState("");
  const [totalSearch, setTotalSearch] = useState("");
  const [patientList, setPatientList] = useState([]);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);
  const [limit, setLimit] = useState(LIMIT);
  const [filterPatientWith, setFilterPatientWith] = useState([]);
  const [filterPreferences, setFilterPreferences] = useState([]);
  const [filterRange, setFilterRange] = useState("");
  const [advancedSearchFields, setAdvancedSearchFields] = useState({
    provincia: "",
    nif: "",
    telefono: "",
    ametropias: "",
    aficiones: "",
    rxDesde: "",
    rxHasta: "",
  });

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: (row) => row.fotoPaciente,
        sortable: false,
        width: "60px",
        cell: (row) => (
          <div>
            <img
              src={row.fotoPaciente}
              className="ps-user-img"
              alt="icon"
            ></img>
          </div>
        ),
      },
      {
        name: "Código",
        selector: (row) => row.codigo,
        sortable: true,
        width: "100px",
      },
      {
        name: "Nombre",
        selector: (row) => row.nombre,
        sortable: true,
      },
      {
        name: "Apellidos",
        selector: (row) => row.apellidos,
        sortable: true,
      },
      {
        name: "Preferencias",
        selector: (row) => row.preferencias,
        sortable: false,
        cell: (row) => (
          <div className="patient-prefs">
            {Object.entries(row.preferencias).map(
              ([preferencia, valor], index) => {
                return (
                  valor && (
                    <img
                      key={index}
                      src={`/images/${preferencia}.svg`}
                      className="icon-pref"
                      alt="icon"
                    />
                  )
                );
              }
            )}
          </div>
        ),
      },
      {
        name: "Dirección",
        selector: (row) => row.direccion,
        sortable: true,
      },
      {
        name: "Localidad",
        selector: (row) => row.localidad,
        sortable: true,
      },
      {
        name: "Teléfono",
        selector: (row) => row.telefono,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "",
        sortable: false,
        width: "50px",
        cell: (row) => (
          <div
            onClick={() =>
              window.open("/patient-datos/" + row.idPaciente, "_blank")
            }
          >
            <img
              src={`/images/eye-orange.svg`}
              className="icon-pref"
              alt="icon"
            ></img>
          </div>
        ),
      },
    ],
    []
  );

  console.log("columns: ", columns);
  console.log("patientList: ", patientList);
  console.log("sortColumn: ", sortColumn);
  console.log("sortDirection: ", sortDirection);

  const handleAdvancedSearch = () => {
    setToggleAdvancedSearch(!toggleAdvancedSearch);
  };

  const handleSort = (column, sortDirection) => {
    setTimeout(() => {
      setSortColumn(column);
      setSortDirection(sortDirection);

      const sortedData = [...patientList].sort((a, b) => {
        const isAscending = sortDirection === "asc";
        const valueA = a[column.selector] || "";
        const valueB = b[column.selector] || "";
        return (
          String(valueA).localeCompare(String(valueB)) * (isAscending ? 1 : -1)
        );
      });

      setPatientList(sortedData);
    }, 0);
  };

  const getPatientPicture = async (idPaciente) => {
    const response = await post("ObtenerFotoPaciente.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente,
    });
    return response.foto ? response.foto : "/images/avatar.svg";
  };

  const searchPatients = async (from, total, add) => {
    const response = await post("BuscarPacientes.php", {
      token: userData.token,
      optica: selectedOptic.id,
      texto: inputSearch,
      pacientes: filterPatientWith,
      preferencias: filterPreferences,
      rango: filterRange,
      busquedaAvanzada: advancedSearchFields,
      start: from,
      total,
    });
    let patientListAux = response.pacientes;
    patientListAux = await Promise.all(
      patientListAux.map(async (e) => {
        e.fotoPaciente = await getPatientPicture(e.idPaciente);
        return e;
      })
    );
    setTotalSearch(response.totalBusqueda);
    if (add) {
      setPatientList([...patientList, ...patientListAux]);
    } else {
      setPatientList(patientListAux);
    }
  };

  const getTotalPatients = async () => {
    const response = await post("ObtenerNumPacientes.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setTotalPatients(response.num);
  };

  const exportPatientList = async (format) => {
    await exportPatientSearch(
      patientList,
      selectedOptic.nombre,
      format,
      "listado-pacientes"
    );
  };

  useEffect(() => {
    if (props.show === true) {
      getTotalPatients();
      searchPatients(
        0,
        patientList.length > limit ? patientList.length : limit,
        false
      );
    }
  }, [props.show]);

  useEffect(() => {
    searchPatients(0, limit, false);
  }, [
    inputSearch,
    filterPatientWith,
    filterPreferences,
    filterRange,
    advancedSearchFields,
  ]);

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Pacientes</span>
        <div className="patient-header-buttons">
          <ButtonIcon
            text="Nuevo paciente"
            icon="/images/add-white.svg"
            onClick={() => {
              props.onClickMenuOption("patient-datos", {
                patientId: -1,
              });
            }}
          ></ButtonIcon>
        </div>
      </div>
      <div className="patient-data-content">
        <div className="action-bar">
          <div className="action-bar-resume">
            <span>Pacientes</span>
            <span>|</span>
            <span className="signed-text">Búsqueda de pacientes</span>
          </div>
          <button
            className="action-bar-return"
            onClick={() => {
              props.onGoBack();
            }}
          >
            <span>Volver</span>
            <img
              src="/images/return.svg"
              loading="lazy"
              alt="Icon"
              className="return-icon"
            />
          </button>
        </div>
        <div className="patient-search-content">
          <div className="ps-title">
            <div className="title-h2">Búsqueda de pacientes</div>
            <div className="ps-info">
              <img
                src="/images/users.svg"
                loading="lazy"
                width="12"
                height="13"
                alt=""
                className="vectors-wrapper-63"
              />
              <div className="title-h3">{totalPatients}</div>
            </div>
          </div>
          <div className="breaker-gr"></div>
          <div className="title-h4">Introduce los datos del paciente</div>
          <div className="ps-parameters">
            <InputIconSearch
              id="search"
              size={3}
              input={inputSearch}
              placeholder="Buscar paciente..."
              onChange={async (e) => {
                setInputSearch(e);
              }}
              onClear={async () => {
                setInputSearch("");
              }}
            ></InputIconSearch>
            <div className="parameters-dropdown-content">
              <DropdownFilter
                icon={"/images/time-blue.svg"}
                disabled={false}
                optionList={[
                  {
                    id: "optometria",
                    text: "Con optometría",
                    active: filterPatientWith.includes("optometria"),
                  },
                  {
                    id: "audiometria",
                    text: "Con audiometría",
                    active: filterPatientWith.includes("audiometria"),
                  },
                  {
                    id: "encargo",
                    text: "Con encargo",
                    active: filterPatientWith.includes("encargo"),
                  },
                  {
                    id: "venta",
                    text: "Con venta",
                    active: filterPatientWith.includes("venta"),
                  },
                ]}
                op={"PACIENTES"}
                onChange={(e) => {
                  const updatedFilterPatientWith = filterPatientWith.includes(
                    e.id
                  )
                    ? filterPatientWith.filter((p) => p !== e.id)
                    : [...filterPatientWith, e.id];
                  setFilterPatientWith(updatedFilterPatientWith);
                }}
              ></DropdownFilter>
              <DropdownFilter
                icon={"/images/prefs-blue.svg"}
                disabled={false}
                optionList={[
                  {
                    id: "gafas",
                    text: "Gafas",
                    active: filterPreferences.includes("gafas"),
                  },
                  {
                    id: "gafasdesol",
                    text: "Gafas de Sol",
                    active: filterPreferences.includes("gafasdesol"),
                  },
                  {
                    id: "lentesdecontacto",
                    text: "Lentes de Contacto",
                    active: filterPreferences.includes("lentesdecontacto"),
                  },
                  {
                    id: "audifonos",
                    text: "Audífonos",
                    active: filterPreferences.includes("audifonos"),
                  },
                  {
                    id: "varios",
                    text: "Varios",
                    active: filterPreferences.includes("varios"),
                  },
                ]}
                op={"PREFERENCIAS"}
                onChange={(e) => {
                  const updatedFilterPreferences = filterPreferences.includes(
                    e.id
                  )
                    ? filterPreferences.filter((p) => p !== e.id)
                    : [...filterPreferences, e.id];
                  setFilterPreferences(updatedFilterPreferences);
                }}
              ></DropdownFilter>
              <DropdownFilter
                icon={"/images/date-blue.svg"}
                disabled={false}
                optionList={[
                  {
                    id: 7,
                    text: "Últimos 7 días",
                    active: filterRange === 7,
                  },
                  {
                    id: 15,
                    text: "Últimos 15 días",
                    active: filterRange === 15,
                  },
                  {
                    id: 30,
                    text: "Últimos 30 días",
                    active: filterRange === 30,
                  },
                  { id: 365, text: "Último año", active: filterRange === 365 },
                  { id: "", text: "Todo", active: filterRange === "" },
                ]}
                op={"RANGO"}
                onChange={(e) => {
                  if (e.id !== filterRange) {
                    setFilterRange(e.id);
                  }
                }}
              ></DropdownFilter>
              <ButtonGrad
                text="Buscar"
                onClick={async () => {
                  searchPatients(0, limit, false);
                }}
              ></ButtonGrad>
            </div>
          </div>
          <div className="breaker-gr mtop-16"></div>
          <div className="title-h4-blue" onClick={handleAdvancedSearch}>
            <div className="text-17">Búsqueda avanzada</div>
            <img
              src="/images/Vectors-Wrapper_21.svg"
              loading="lazy"
              width="16"
              height="4"
              alt="Icon"
              className="vectors-wrapper-68 action-icon"
            />
          </div>
          {toggleAdvancedSearch && (
            <div className="advanced-search-cols">
              <div className="advanced-search-col">
                <div className="data-field">
                  <span className="label">Provincia</span>
                  <Dropdown
                    id="provincia"
                    disabled={false}
                    optionList={provinceOptions}
                    size={2}
                    op={advancedSearchFields.provincia}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        provincia: e.text,
                      });
                    }}
                  ></Dropdown>
                </div>
                <div className="data-field">
                  <span className="label">NIF</span>
                  <InputData
                    id="nombre"
                    type="type-only-charnumbers"
                    size={2}
                    input={advancedSearchFields.nif}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        nif: e,
                      });
                    }}
                  ></InputData>
                </div>
                <div className="data-field">
                  <span className="label">Teléfono</span>
                  <InputData
                    id="telefono"
                    type="type-phone"
                    size={2}
                    input={advancedSearchFields.telefono}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        telefono: e,
                      });
                    }}
                  ></InputData>
                </div>
              </div>
              <div className="advanced-search-col">
                <div className="data-field">
                  <span className="label">Ametropías</span>
                  <InputData
                    id="ametropias"
                    type="type-alpha"
                    size={2}
                    input={advancedSearchFields.ametropias}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        ametropias: e,
                      });
                    }}
                  ></InputData>
                </div>
                <div className="data-field">
                  <span className="label">Aficiones</span>
                  <InputData
                    id="aficiones"
                    type="type-alpha"
                    size={2}
                    input={advancedSearchFields.aficiones}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        aficiones: e,
                      });
                    }}
                  ></InputData>
                </div>
              </div>
              <div className="advanced-search-col">
                <div className="data-field">
                  <span className="label">Rx Desde</span>
                  <input
                    type="date"
                    className="input-field"
                    value={advancedSearchFields.rxDesde}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        rxDesde: e.target.value,
                      });
                    }}
                    onKeyUp={(e) => inputOnEnter(e)}
                  ></input>
                </div>
                <div className="data-field">
                  <span className="label">Rx Hasta</span>
                  <input
                    type="date"
                    className="input-field"
                    value={advancedSearchFields.rxHasta}
                    onChange={(e) => {
                      setAdvancedSearchFields({
                        ...advancedSearchFields,
                        rxHasta: e.target.value,
                      });
                    }}
                    onKeyUp={(e) => inputOnEnter(e)}
                  ></input>
                </div>
                <ButtonIconH
                  text="Limpiar"
                  onClick={() => {
                    const isAnyFieldFilled = Object.values(
                      advancedSearchFields
                    ).some((field) => field !== "");

                    if (isAnyFieldFilled) {
                      setAdvancedSearchFields({
                        provincia: "",
                        nif: "",
                        telefono: "",
                        ametropias: "",
                        aficiones: "",
                        rxDesde: "",
                        rxHasta: "",
                      });
                    }
                  }}
                ></ButtonIconH>
              </div>
            </div>
          )}
        </div>
        <div className="patient-search-result-content">
          <div className="psr-header">
            <div className="ps-title">
              <div className="title-h2">Lista de pacientes</div>
              <div className="ps-info">
                <div className="title-h3">({patientList.length})</div>
              </div>
            </div>
            <div className="psr-parameters">
              <DropdownFilter
                disabled={false}
                optionList={[
                  { id: "00", text: "25", active: limit === 25 },
                  { id: "01", text: "50", active: limit === 50 },
                  { id: "02", text: "100", active: limit === 100 },
                ]}
                op={"MOSTRAR"}
                onChange={(e) => {
                  setLimit(parseInt(e.text));
                }}
              ></DropdownFilter>
              <DropdownFilter
                disabled={false}
                optionList={[
                  { id: "csv", text: "CSV" },
                  { id: "excel", text: "Excel" },
                  { id: "pdf", text: "PDF" },
                ]}
                op={"EXPORTAR COMO"}
                onChange={(e) => {
                  exportPatientList(e.id);
                }}
              ></DropdownFilter>
            </div>
          </div>
          <div className="breaker-gr mb-8"></div>
          <DataTable
            columns={columns}
            data={patientList}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={handleSort}
            className="custom-datatable"
            noDataComponent={""}
            onRowClicked={(row, event) => {
              props.onClickMenuOption("patient-datos", {
                patientId: row.idPaciente,
              });
            }}
          />
          <div className="load-more">
            <img
              src="/images/load.svg"
              loading="lazy"
              width="22"
              height="22"
              alt="load"
              className="vectors-wrapper-76"
              onClick={() => {
                if (patientList.length < totalPatients) {
                  searchPatients(patientList.length, limit, true);
                }
              }}
            />
            <div className="label-title">Cargar más</div>
          </div>
          <div className="pag-info">
            <div className="text-19">
              <div className="text-19 text-19">
                <span className="mostrando-25-de-157-6">Mostrando </span>
                <span className="mostrando-25-de-157-1">
                  {patientList.length}
                </span>
                <span className="mostrando-25-de-157-6"> de </span>
                <span className="mostrando-25-de-157-3">{totalSearch}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
