import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import CheckBox from "./CheckBox";
import ButtonIconV from "./ButtonIconV";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  sensoryStructure,
  hessLancasterStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
  HESSLANCASTERLIST,
  STEREOPSISLIST,
  deviationFactor,
} from "../js/utils";
import moment from "moment";

export default function AdvancedOptometrySensory(props) {
  const [currentSensory, setCurrentSensory] = useState(sensoryStructure);
  const [currentSensoryEdit, setCurrentSensoryEdit] =
    useState(sensoryStructure);
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [sensoryId, setSensoryId] = useState(props.sensoryId);
  const [currentHessLancasterEdit, setCurrentHessLancasterEdit] = useState(
    hessLancasterStructure
  );

  console.log("currentSensoryEdit: ", currentSensoryEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getSensoryHistory = async () => {
    const response = await post("ListarFechasHistoricoSensorial.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idSensorial === sensoryId;
      });
      if (found) {
        setSelectedDate({
          id: found.idSensorial,
          text: found.fecha,
        });
        setSensoryId(found.idSensorial);
      }
    }
    setDateHistoryList(response);
  };

  const getSensory = async (sensoryId) => {
    const response = await post("ObtenerSensorial.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSensorial: sensoryId,
    });

    setCurrentSensoryEdit({
      ...response,
      idPaciente: props.patientId,
      idSensorial: sensoryId,
    });
    setCurrentSensory({
      ...response,
      idPaciente: props.patientId,
      idSensorial: sensoryId,
    });
  };

  const getHessLancaster = async (sensoryId) => {
    const response = await post("ObtenerHessLancaster.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSensorial: sensoryId,
    });
    setCurrentHessLancasterEdit({
      ...response,
      punto1_od_x: parseInt(response.punto1_od_x) + deviationFactor,
      punto1_od_y: parseInt(response.punto1_od_y) + deviationFactor,
      punto2_od_x: parseInt(response.punto2_od_x) + deviationFactor,
      punto2_od_y: parseInt(response.punto2_od_y) + deviationFactor,
      punto3_od_x: parseInt(response.punto3_od_x) + deviationFactor,
      punto3_od_y: parseInt(response.punto3_od_y) + deviationFactor,
      punto4_od_x: parseInt(response.punto4_od_x) + deviationFactor,
      punto4_od_y: parseInt(response.punto4_od_y) + deviationFactor,
      punto5_od_x: parseInt(response.punto5_od_x) + deviationFactor,
      punto5_od_y: parseInt(response.punto5_od_y) + deviationFactor,
      punto6_od_x: parseInt(response.punto6_od_x) + deviationFactor,
      punto6_od_y: parseInt(response.punto6_od_y) + deviationFactor,
      punto7_od_x: parseInt(response.punto7_od_x) + deviationFactor,
      punto7_od_y: parseInt(response.punto7_od_y) + deviationFactor,
      punto8_od_x: parseInt(response.punto8_od_x) + deviationFactor,
      punto8_od_y: parseInt(response.punto8_od_y) + deviationFactor,
      punto9_od_x: parseInt(response.punto9_od_x) + deviationFactor,
      punto9_od_y: parseInt(response.punto9_od_y) + deviationFactor,
      punto1_oi_x: parseInt(response.punto1_oi_x) + deviationFactor,
      punto1_oi_y: parseInt(response.punto1_oi_y) + deviationFactor,
      punto2_oi_x: parseInt(response.punto2_oi_x) + deviationFactor,
      punto2_oi_y: parseInt(response.punto2_oi_y) + deviationFactor,
      punto3_oi_x: parseInt(response.punto3_oi_x) + deviationFactor,
      punto3_oi_y: parseInt(response.punto3_oi_y) + deviationFactor,
      punto4_oi_x: parseInt(response.punto4_oi_x) + deviationFactor,
      punto4_oi_y: parseInt(response.punto4_oi_y) + deviationFactor,
      punto5_oi_x: parseInt(response.punto5_oi_x) + deviationFactor,
      punto5_oi_y: parseInt(response.punto5_oi_y) + deviationFactor,
      punto6_oi_x: parseInt(response.punto6_oi_x) + deviationFactor,
      punto6_oi_y: parseInt(response.punto6_oi_y) + deviationFactor,
      punto7_oi_x: parseInt(response.punto7_oi_x) + deviationFactor,
      punto7_oi_y: parseInt(response.punto7_oi_y) + deviationFactor,
      punto8_oi_x: parseInt(response.punto8_oi_x) + deviationFactor,
      punto8_oi_y: parseInt(response.punto8_oi_y) + deviationFactor,
      punto9_oi_x: parseInt(response.punto9_oi_x) + deviationFactor,
      punto9_oi_y: parseInt(response.punto9_oi_y) + deviationFactor,
    });
  };

  const resetSensoryState = () => {
    setCurrentSensory({
      ...sensoryStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentSensoryEdit({
      ...sensoryStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setSensoryId(-1);
    props.saveLastSensoryIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveSensory = async () => {
    const response = await post("GuardarSensorial.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSensorial: sensoryId,
      idPaciente: props.patientId,
      ...currentSensoryEdit,
    });
    setSelectedDate({
      id: response.idSensorial,
      text: currentSensoryEdit.fecha,
    });
    await props.saveHessLancaster(response.idSensorial);
    setSensoryId(response.idSensorial);
    setCurrentSensory(currentSensoryEdit);
    props.saveLastSensoryIdVisited(response.idSensorial);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteSensory = async () => {
    await post("EliminarSensorial.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSensorial: sensoryId,
      idUsuario: userData.id,
    });
  };

  const selectSensoryAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoSensorial.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idSensorial,
        text: response[0].fecha,
      });
      setSensoryId(response[0].idSensorial);
      setDateHistoryList(response);
      await getSensory(response[0].idSensorial);
      await getHessLancaster(response[0].idSensorial);
      props.saveLastSensoryIdVisited(response[0].idSensorial);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setSensoryId(-1);
      props.saveLastSensoryIdVisited(-1);
      setCurrentSensory(sensoryStructure);
      setCurrentSensoryEdit(sensoryStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (sensoryId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idSensorial,
          text: dateHistoryList[0].fecha,
        });
        setSensoryId(dateHistoryList[0].idSensorial);
        props.saveLastSensoryIdVisited(dateHistoryList[0].idSensorial);
        await getSensory(dateHistoryList[0].idSensorial);
        await getHessLancaster(dateHistoryList[0].idSensorial);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentSensoryEdit(currentSensory);
      }
    } else {
      setCurrentSensoryEdit(currentSensory);
    }
  };

  useEffect(() => {
    getSensoryHistory();
    getOptometristList();
    if (sensoryId !== -1) {
      getSensory(sensoryId);
      getHessLancaster(sensoryId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentSensoryEdit({
        ...currentSensoryEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentSensory({
        ...currentSensory,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [sensoryId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentSensory, currentSensoryEdit),
      dataToSave: currentSensoryEdit,
    });
  }, [currentSensoryEdit]);

  useEffect(() => {
    if (props.testHL !== null) {
      setCurrentSensoryEdit({
        ...currentSensoryEdit,
        hess_lancaster: props.testHL,
      });
    }
  }, [props.testHL]);

  useEffect(() => {
    if (props.diagnosisValue !== currentSensoryEdit.diagnostico) {
      setCurrentSensoryEdit({
        ...currentSensoryEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentSensoryEdit.fecha}
                  onChange={(e) => {
                    setCurrentSensoryEdit({
                      ...currentSensoryEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idSensorial,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idSensorial,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={sensoryId === -1}
                onChange={async (e) => {
                  setCurrentSensoryEdit(sensoryStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setSensoryId(e.id);
                  props.saveLastSensoryIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentSensoryEdit.optometrista}
                onChange={(e) => {
                  setCurrentSensoryEdit({
                    ...currentSensoryEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              sensoryId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (sensoryId !== -1) {
                if (!areObjectsEqual(currentSensory, currentSensoryEdit)) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveSensory();
                      props.removePopupQuestion();
                      resetSensoryState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetSensoryState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetSensoryState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              sensoryStructure.fecha = currentSensoryEdit.fecha;
              if (areObjectsEqual(currentSensoryEdit, sensoryStructure)) {
                props.createPopupQuestion(
                  `La ficha de sensorial actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveSensory();
                await getSensoryHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentSensory, currentSensoryEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentSensory, currentSensoryEdit) ||
                sensoryId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              sensoryId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (sensoryId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de sensorial?`,
                  async () => {
                    await getSensory(sensoryId);
                    await getHessLancaster(sensoryId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              sensoryId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (sensoryId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de sensorial?`,
                  async () => {
                    await deleteSensory();
                    await getSensoryHistory();
                    await selectSensoryAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              sensoryId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (sensoryId !== -1) {
                props.openDiagnosisPopup(currentSensoryEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              sensoryId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (sensoryId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-100">
          <div className="opt-table-container opt-table-container-npb">
            <div className="opt-table-title">
              Fusión
              <div>
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados para la Fusión:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Fusión en todas las distancias.
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            CSN sin supresión.
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
            </div>
          </div>
          <div className="double-col">
            <div className="w-50">
              <div className="opt-table-container opt-table-container-npt">
                <div className="opt-table-content grid-9" id="sensory-fusion">
                  <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                    Test de Worth
                  </div>
                  <div className="cell cell-label combined-cells-9">Lejos</div>
                  <div className="worth-test-container cell combined-cells-9">
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_lejos === "0"
                            ? "/images/worth/test-worth-01-on.png"
                            : "/images/worth/test-worth-01-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={54}
                        active={currentSensoryEdit.test_worth_lejos === "0"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_lejos:
                              currentSensoryEdit.test_worth_lejos === "0"
                                ? -1
                                : "0",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Fusión</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_lejos === "1"
                            ? "/images/worth/test-worth-02-on.png"
                            : "/images/worth/test-worth-02-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={55}
                        active={currentSensoryEdit.test_worth_lejos === "1"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_lejos:
                              currentSensoryEdit.test_worth_lejos === "1"
                                ? -1
                                : "1",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Supresión OI</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_lejos === "2"
                            ? "/images/worth/test-worth-03-on.png"
                            : "/images/worth/test-worth-03-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={56}
                        active={currentSensoryEdit.test_worth_lejos === "2"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_lejos:
                              currentSensoryEdit.test_worth_lejos === "2"
                                ? -1
                                : "2",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Supresión OD</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_lejos === "3"
                            ? "/images/worth/test-worth-04-on.png"
                            : "/images/worth/test-worth-04-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={57}
                        active={currentSensoryEdit.test_worth_lejos === "3"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_lejos:
                              currentSensoryEdit.test_worth_lejos === "3"
                                ? -1
                                : "3",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: (
                              <div className="cell">ENDO Desviación</div>
                            ),
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_lejos === "4"
                            ? "/images/worth/test-worth-05-on.png"
                            : "/images/worth/test-worth-05-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={58}
                        active={currentSensoryEdit.test_worth_lejos === "4"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_lejos:
                              currentSensoryEdit.test_worth_lejos === "4"
                                ? -1
                                : "4",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">EXO Desviación</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
              </div>

              <div className="opt-table-container opt-table-container-npt">
                <div className="opt-table-content grid-9" id="sensory-fusion">
                  <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                    Observaciones
                  </div>
                  <div className="cell combined-cells-9">
                    <InputTextArea
                      tabIndex={64}
                      maxLength={400}
                      id="observaciones"
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.observaciones_test_worth_lejos}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          observaciones_test_worth_lejos: e,
                        });
                      }}
                    ></InputTextArea>
                  </div>
                  <div className="cell combined-cells-9"></div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Filtro Rojo
                  </div>
                  <div className="cell"></div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Bagolini
                  </div>
                  <div className="cell cell-label combined-cells-4 cell-start-content">
                    Lejos
                  </div>
                  <div className="cell"></div>
                  <div className="cell cell-label combined-cells-4 cell-start-content">
                    Lejos
                  </div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={66}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.filtro_rojo_lejos}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          filtro_rojo_lejos: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={68}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.bagolini_lejos}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          bagolini_lejos: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell cell-label combined-cells-4 cell-start-content">
                    Cerca
                  </div>
                  <div className="cell"></div>
                  <div className="cell cell-label combined-cells-4 cell-start-content">
                    Cerca
                  </div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={67}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.filtro_rojo_cerca}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          filtro_rojo_cerca: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={69}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.bagolini_cerca}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          bagolini_cerca: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                </div>
              </div>
              <div className="opt-table-container opt-table-container-npt">
                <div className="opt-table-title">Percepción del Color</div>
                <div className="opt-table-content grid-9">
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Test de Ishihara
                  </div>
                  <div className="cell"></div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Test de Farnsworth
                  </div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={70}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.test_ishihara}
                      bkg={true}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          test_ishihara: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={71}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.test_farnsworth}
                      bkg={true}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          test_farnsworth: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="opt-table-container opt-table-container-npt">
                <div className="opt-table-content grid-9" id="sensory-fusion">
                  <div className="cell opt-table-subtitle combined-cells-9 cell-start-content"></div>
                  <div className="cell cell-label combined-cells-9">Cerca</div>
                  <div className="worth-test-container cell combined-cells-9">
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_cerca === "0"
                            ? "/images/worth/test-worth-01-on.png"
                            : "/images/worth/test-worth-01-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={59}
                        active={currentSensoryEdit.test_worth_cerca === "0"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_cerca:
                              currentSensoryEdit.test_worth_cerca === "0"
                                ? -1
                                : "0",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Fusión</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_cerca === "1"
                            ? "/images/worth/test-worth-02-on.png"
                            : "/images/worth/test-worth-02-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={60}
                        active={currentSensoryEdit.test_worth_cerca === "1"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_cerca:
                              currentSensoryEdit.test_worth_cerca === "1"
                                ? -1
                                : "1",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Supresión OI</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_cerca === "2"
                            ? "/images/worth/test-worth-03-on.png"
                            : "/images/worth/test-worth-03-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={61}
                        active={currentSensoryEdit.test_worth_cerca === "2"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_cerca:
                              currentSensoryEdit.test_worth_cerca === "2"
                                ? -1
                                : "2",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">Supresión OD</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_cerca === "3"
                            ? "/images/worth/test-worth-04-on.png"
                            : "/images/worth/test-worth-04-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={62}
                        active={currentSensoryEdit.test_worth_cerca === "3"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_cerca:
                              currentSensoryEdit.test_worth_cerca === "3"
                                ? -1
                                : "3",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: (
                              <div className="cell">ENDO Desviación</div>
                            ),
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                    <div className="worth-test-op">
                      <img
                        src={
                          currentSensoryEdit.test_worth_cerca === "4"
                            ? "/images/worth/test-worth-05-on.png"
                            : "/images/worth/test-worth-05-off.png"
                        }
                        loading="lazy"
                        alt="Icon"
                        className="worth-img"
                      ></img>
                      <CheckBox
                        tabIndex={63}
                        active={currentSensoryEdit.test_worth_cerca === "4"}
                        onClick={() => {
                          setCurrentSensoryEdit({
                            ...currentSensoryEdit,
                            test_worth_cerca:
                              currentSensoryEdit.test_worth_cerca === "4"
                                ? -1
                                : "4",
                          });
                        }}
                        onMouseEnter={(event) => {
                          const data = {
                            top: event.clientY,
                            left: event.clientX,
                            content: <div className="cell">EXO Desviación</div>,
                          };
                          props.openTooltip(data);
                        }}
                        onMouseLeave={() => {
                          props.closeTooltip();
                        }}
                      ></CheckBox>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opt-table-container opt-table-container-npt">
                <div className="opt-table-content grid-9" id="sensory-fusion">
                  <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                    Observaciones
                  </div>
                  <div className="cell combined-cells-9">
                    <InputTextArea
                      tabIndex={65}
                      maxLength={400}
                      id="observaciones"
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.observaciones_test_worth_cerca}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          observaciones_test_worth_cerca: e,
                        });
                      }}
                    ></InputTextArea>
                  </div>
                  <div className="cell combined-cells-9"></div>
                  <div className="cell opt-table-subtitle combined-cells-2 cell-start-content">
                    Hess - Lancaster
                  </div>
                  <div className="cell">
                    <button
                      onClick={() => {
                        props.toggleHessLancaster(
                          currentHessLancasterEdit,
                          currentSensoryEdit.hess_lancaster
                        );
                      }}
                    >
                      <img
                        src="/images/axis-blue.svg"
                        loading="lazy"
                        alt="Icon"
                        className="cell-icon"
                      ></img>
                    </button>
                  </div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                Valores Normalizados para la Fusión:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                Versiones normales sin HIPER o hIPO acciones
                                musculares.
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                  <div className="cell"></div>
                  <div className="cell opt-table-subtitle combined-cells-3 cell-start-content">
                    Estereopsis
                  </div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                Valores Normales para la Estereopsis:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                Valores orientativos: 20 ± 10 " de arco.
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                  <div className="cell combined-cells-9"></div>
                  <div className="cell cell-label">Test</div>
                  <div className="cell cell-label combined-cells-3 cell-start-content">
                    <Dropdown
                      tabIndex={72}
                      optionList={HESSLANCASTERLIST}
                      size={0}
                      op={currentSensoryEdit.hess_lancaster}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          hess_lancaster: e.text,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell cell-label">Test</div>
                  <div className="cell cell-label combined-cells-2 cell-start-content">
                    <Dropdown
                      tabIndex={73}
                      optionList={STEREOPSISLIST}
                      size={0}
                      op={currentSensoryEdit.estereopsis}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          estereopsis: e.text,
                        });
                      }}
                    ></Dropdown>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={74}
                      type="type-float2"
                      size={0}
                      input={currentSensoryEdit.valor_estereopsis}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          valor_estereopsis: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">"</div>
                  <div className="cell combined-cells-9"></div>
                  <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                    Prisma de 4 Δ
                  </div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={76}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.prisma_4a}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          prisma_4a: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={75}
                      maxLength={400}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.observaciones_estereopsis}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          observaciones_estereopsis: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell combined-cells-9"></div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Rejilla de Amsler
                  </div>
                  <div className="cell"></div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    Sensibilidad al Contraste
                  </div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={77}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.rejilla_amsler}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          rejilla_amsler: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4 cell-start-content">
                    <InputTextArea
                      tabIndex={78}
                      maxLength={200}
                      type="type-full"
                      size={0}
                      input={currentSensoryEdit.sensibilidad_contraste}
                      onChange={(e) => {
                        setCurrentSensoryEdit({
                          ...currentSensoryEdit,
                          sensibilidad_contraste: e,
                        });
                      }}
                      extra="small-height"
                    ></InputTextArea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
