import React, { useState } from "react";
import "../css/RadioButton.css";
import { inputOnEnter } from "../js/utils";

export default function RadioButton(props) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      props.onClick();
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <button
      className={`radio-button-container
        ${props.active ? "radio-button-selected" : ""} ${
        isInputFocused ? "radio-op-focused" : ""
      }`}
      onClick={() => {
        props.onClick();
      }}
    >
      <input
        className="radio-button-input"
        tabIndex={props.tabIndex}
        onKeyUp={inputOnEnter}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      ></input>
    </button>
  );
}
