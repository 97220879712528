import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import ButtonIcon from "../components/ButtonIcon";
import ButtonIconH from "../components/ButtonIconH";
import Tag from "../components/Tag";
import Dropdown from "../components/Dropdown";
import {
  sexOptions,
  avatarImg,
  userData,
  selectedOptic,
  svgIcons,
  provinceOptions,
  patientData,
  inputOnEnter,
} from "../js/utils";
import { post } from "../js/post";
import InputData from "../components/InputData";
import CheckBox from "../components/CheckBox";
import RadioButton from "../components/RadioButton";
import InputSearch from "../components/InputSearch";
import PopupNIFTypes from "../components/PopupNIFTypes";
import LoadingScreen from "../components/LoadingScreen";
import WebCam from "../components/WebCam";
import ButtonSectionHeader from "../components/ButtonSectionHeader";
import OptometryHistoryBox from "../components/OptometryHistoryBox";
import ButtonIconV from "../components/ButtonIconV";
import moment from "moment";
import "../css/Patient.css";
import "../css/Panel.css";

export default function Patient(props) {
  const [addHobby, setAddHobby] = useState(false);
  const [addAmetropia, setAddAmetropia] = useState(false);
  const [toggleDataSection, setToggleDataSection] = useState(true);
  const [togglePurchasingSection, setTogglePurchasingSection] = useState(true);
  const [toggleOptometrySection, setToggleOptometrySection] = useState(true);
  const [toggleEyeglassesSection, setToggleEyeglassesSection] = useState(true);
  const [toggleContactLensesSection, setToggleContactLensesSection] =
    useState(true);
  const [toggleAudiometrySection, setToggleAudiometrySection] = useState(true);
  const [toggleHearingAidsSection, setToggleHearingAidsgSection] =
    useState(true);
  const [toggleOthersSection, setToggleOthersSection] = useState(true);
  const [existPatient, setExistPatient] = useState(false);
  const [identityNumberTypes, setIdentityNumberTypes] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(patientData);
  const [currentPatientEdit, setCurrentPatientEdit] = useState(patientData);
  const [patientAge, setPatientAge] = useState(0);
  const [selectedHobby, setSelectedHobby] = useState("");
  const [selectedAmetropia, setSelectedAmetropia] = useState("");
  const [patientPicture, setPatientPicture] = useState(avatarImg);
  const [patientPictureName, setPatientPictureName] = useState("");
  const [flagNewPicture, setFlagNewPicture] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [recommendedList, setRecommendedList] = useState([]);
  const [hobbiesList, setHobbiesList] = useState([]);
  const [ametropiaList, setAmetropiaList] = useState([]);
  const [headerName, setHeaderName] = useState("NUEVO PACIENTE");
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [patientDataChanges, setPatientDataChanges] = useState(false);
  const [patientReadyToSave, setPatientReadyToSave] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [optometrySectionSeeMore, setOptometrySectionSeeMore] = useState(false);
  const [audiometrySectionSeeMore, setAudiometrySectionSeeMore] =
    useState(false);
  const [optometryHistoryList, setOptometryHistoryList] = useState([]);
  const [audiometryHistoryList, setAudiometryHistoryList] = useState([]);
  const [errorEmail, setErrorEmail] = useState(false);
  const [numOptometries, setNumOptometries] = useState(null);
  const [numFiles, setNumFiles] = useState(0);
  const [recentOptometryIds, setRecentOptometryIds] = useState({
    optometria: null,
    anamnesis: null,
    refractivo: null,
    oculomotor: null,
    binocular: null,
    acomodativo: null,
    sensorial: null,
    saludOcular: null,
  });
  let [patientId, setPatientId] = useState(null);
  const [numObservations, setNumObservations] = useState(0);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const photoRef = useRef(null);
  const [cameraActive, setCameraActive] = useState(false);

  //console.log("currentPatientEdit: ", currentPatientEdit);
  //console.log("patientId: ", patientId);
  //console.log("numObservations: ", numObservations);
  //console.log("PATIENT ID: ", props.patientId);

  const initialCheckboxState = {
    checkboxServices:
      currentPatientEdit.serviciosEmail &&
      currentPatientEdit.serviciosPostal &&
      currentPatientEdit.serviciosSMS &&
      currentPatientEdit.serviciosTelefono &&
      currentPatientEdit.serviciosWhatsapp,
    checkboxCommercial:
      currentPatientEdit.comercialEmail &&
      currentPatientEdit.comercialPostal &&
      currentPatientEdit.comercialSMS &&
      currentPatientEdit.comercialTelefono &&
      currentPatientEdit.comercialWhatsapp,
  };
  const [checkboxState, setCheckboxState] = useState(initialCheckboxState);

  const openWhatsApp = () => {
    const mobile =
      currentPatient.movil.startsWith("+") ||
      currentPatient.movil.startsWith("34") ||
      currentPatient.movil.startsWith("0034")
        ? currentPatient.movil
        : "+34" + currentPatient.movil;
    const isSupported = window.navigator && window.navigator.msLaunchUri;
    if (isSupported) {
      // Microsoft Edge
      window.navigator.msLaunchUri(
        "whatsapp://send?phone=3464478983",
        null,
        () => {}
      );
    } else {
      // Others
      window.location.href = "whatsapp://send?phone=" + mobile;
    }
  };

  const openEmail = () => {
    const isSupported = window.navigator && window.navigator.msLaunchUri;

    if (isSupported) {
      //  Microsoft Edge
      window.navigator.msLaunchUri("mailto:", null, () => {});
    } else {
      // Others
      window.location.href = "mailto:";
    }
  };

  const uploadNewPatientPic = () => {
    const fileInput = document.querySelector('input[type="file"]');
    const pic = fileInput.files[0];

    if (pic) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const base64String = event.target.result;
        setPatientPicture(base64String);
        setFlagNewPicture(true);
      };

      reader.readAsDataURL(pic);
    }
  };

  const createPatient = async () => {
    setPatientId(-1);
    setCurrentPatient(patientData);
    setCurrentPatientEdit(patientData);
    setHeaderName("NUEVO PACIENTE");
    setPatientPicture(avatarImg);
    /* Reiniciar listados */
    setOptometryHistoryList([]);
    setAudiometryHistoryList([]);
    setLoadingPatient(false);
    setExistPatient(true);
    setNumOptometries({
      optometria: {
        num: 0,
        id: -1,
      },
      anamnesis: {
        num: 0,
        id: -1,
      },
      refractivo: {
        num: 0,
        id: -1,
      },
      oculomotor: {
        num: 0,
        id: -1,
      },
      binocular: {
        num: 0,
        id: -1,
      },
      acomodativo: {
        num: 0,
        id: -1,
      },
      sensorial: {
        num: 0,
        id: -1,
      },
      saludOcular: {
        num: 0,
        id: -1,
      },
    });
    setRecentOptometryIds({
      optometria: -1,
      anamnesis: -1,
      refractivo: -1,
      oculomotor: -1,
      binocular: -1,
      acomodativo: -1,
      sensorial: -1,
      saludOcular: -1,
    });
  };

  const deletePatientPicture = () => {
    setPatientPicture(avatarImg);
    setFlagNewPicture(true);
  };

  const deletePatient = async () => {
    await post("EliminarPaciente.php", {
      token: userData.token,
      idPaciente: patientId,
      optica: selectedOptic.id,
    });
  };

  const calculatePatientAge = (date) => {
    const patientDate = new Date(date);
    if (!isNaN(patientDate) && patientDate.getFullYear() >= 1900) {
      const currentDate = new Date();
      const currentAge = Math.floor(
        (currentDate - patientDate) / (1000 * 60 * 60 * 24 * 365.25)
      );
      setPatientAge(currentAge);
    }
  };

  const toggleAddHobby = () => setAddHobby(!addHobby);

  const toggleAddAmetropia = () => setAddAmetropia(!addAmetropia);

  const getObservations = async (pId) => {
    const response = await post("ListarObservaciones.php", {
      token: userData.token,
      optica: selectedOptic.id,
      id: pId,
      categoria: "Paciente",
    });
    setNumObservations(response ? response.length : numObservations);
  };

  const getCampaignList = async () => {
    const response = await post("ListarCampanas.php", {
      token: userData.token,
    });

    const list = response.map((e) => ({ id: e, text: e }));
    setCampaignList(list);
  };

  const getRecommendedList = async () => {
    const response = await post("ListarRecomendados.php", {
      token: userData.token,
    });

    const list = response.map((e) => ({ id: e, text: e }));
    setRecommendedList(list);
  };

  const getHobbiesList = async () => {
    const response = await post("ListarAficiones.php", {
      token: userData.token,
    });

    const list = response.map((e) => ({ id: e, text: e }));
    setHobbiesList(list);
    setSelectedHobby(list[0].id);
  };

  const getAmetropiaList = async () => {
    const response = await post("ListarAmetropias.php", {
      token: userData.token,
    });

    const list = response.map((e) => ({ id: e, text: e }));
    setAmetropiaList(list);
    setSelectedAmetropia(list[0].id);
  };

  const getPatientData = async (pId) => {
    setForceReload(true);
    if (pId !== -1) {
      setLoadingPatient(true);
      const response = await post("ObtenerDatosPaciente.php", {
        token: userData.token,
        optica: selectedOptic.id,
        idPaciente: pId,
      });
      if (Object.keys(response).length === 0) {
        setExistPatient(false);
      } else if (response) {
        setExistPatient(true);
        setCurrentPatient({ ...response, idPaciente: pId });
        setCurrentPatientEdit({ ...response, idPaciente: pId });
        calculatePatientAge(response.nacimiento);
        getCampaignList();
        getRecommendedList();
        getHobbiesList();
        getAmetropiaList();
        setHeaderName(response.nombre + " " + response.apellidos);
        await getOptometryHistory(pId);
        await getAudiometryHistory(pId);
        await getNumOptometries();
        await getPatientPicture(pId);
      }
      setLoadingPatient(false);
    } else {
      setExistPatient(true);
    }
  };

  const getPatientFiles = async (pId) => {
    const response = await post("ListarFicheros.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: pId,
      categoria: "Optometria",
    });
    setNumFiles(response.length);
  };

  const getPatientPicture = async (pId) => {
    const response = await post("ObtenerFotoPaciente.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: pId,
    });
    setPatientPicture(response.foto === "" ? avatarImg : response.foto);
  };

  const savePatientData = async () => {
    if (patientReadyToSave) {
      await post("GuardarDatosPaciente.php", {
        token: userData.token,
        optica: selectedOptic.id,
        idPaciente: patientId,
        nif: currentPatientEdit.nif,
        idNif: currentPatientEdit.idNif,
        fidelizacion: currentPatientEdit.fidelizacion,
        nombre: currentPatientEdit.nombre,
        apellidos: currentPatientEdit.apellidos,
        direccion: currentPatientEdit.direccion,
        localidad: currentPatientEdit.localidad,
        provincia: currentPatientEdit.provincia,
        email: currentPatientEdit.email,
        campana: currentPatientEdit.campana,
        recomendado: currentPatientEdit.recomendado,
        sexo: currentPatientEdit.sexo,
        nacimiento: currentPatientEdit.nacimiento,
        codigoPostal: currentPatientEdit.codigoPostal,
        telefono: currentPatientEdit.telefono,
        movil: currentPatientEdit.movil,
        fax: currentPatientEdit.fax,
        carnetConducir: currentPatientEdit.carnetConducir,
        tieneHijos: currentPatientEdit.tieneHijos,
        codigoRec: currentPatientEdit.codigoRec,
        personaContactoCodigo: currentPatientEdit.personaContactoCodigo,
        personaContactoNombre: currentPatientEdit.personaContactoNombre,
        personaContactoTelefono: currentPatientEdit.personaContactoTelefono,
        trabajoProfesion: currentPatientEdit.trabajoProfesion,
        trabajoEmpresa: currentPatientEdit.trabajoEmpresa,
        trabajoTelefono: currentPatientEdit.trabajoTelefono,
        consentimientosRGPD: currentPatientEdit.consentimientosRGPD,
        consentimientosAudiologia: currentPatientEdit.consentimientosAudiologia,
        consentimientosLC: currentPatientEdit.consentimientosLC,
        consentimientosORTOK: currentPatientEdit.consentimientosORTOK,
        serviciosWhatsapp: currentPatientEdit.serviciosWhatsapp,
        serviciosSMS: currentPatientEdit.serviciosSMS,
        serviciosPostal: currentPatientEdit.serviciosPostal,
        serviciosEmail: currentPatientEdit.serviciosEmail,
        serviciosTelefono: currentPatientEdit.serviciosTelefono,
        comercialWhatsapp: currentPatientEdit.comercialWhatsapp,
        comercialSMS: currentPatientEdit.comercialSMS,
        comercialPostal: currentPatientEdit.comercialPostal,
        comercialEmail: currentPatientEdit.comercialEmail,
        comercialTelefono: currentPatientEdit.comercialTelefono,
        aficiones: currentPatientEdit.aficiones,
        ametropias: currentPatientEdit.ametropias,
        observaciones: currentPatientEdit.observaciones,
      });
      setHeaderName(
        currentPatientEdit.nombre + " " + currentPatientEdit.apellidos
      );
      setCurrentPatient(currentPatientEdit);
    }
  };

  const savePatientPic = async () => {
    if (flagNewPicture === true) {
      await post("SubirFotoPaciente.php", {
        token: userData.token,
        optica: selectedOptic.id,
        idPaciente: patientId,
        nombreFichero: patientPicture === avatarImg ? "" : patientPictureName,
        imagen: patientPicture === avatarImg ? "" : patientPicture,
      });
    }
  };

  const openCamera = () => {
    setCameraActive(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        setCameraActive(false);
      });
  };

  const createBeforeSaving = async () => {
    if (currentPatientEdit.idPaciente === -1) {
      const responseId = await post("CrearPaciente.php", {
        token: userData.token,
        optica: selectedOptic.id,
      });
      //patientId = responseId.idPaciente;
      setPatientId(responseId.idPaciente);
      patientId = responseId.idPaciente;
      let patient = currentPatientEdit;
      patient.idPaciente = responseId.idPaciente;
      setCurrentPatientEdit(patient);
      setCurrentPatient(patient);
    }
  };

  let scrollTo = (id) => {
    const element = document.querySelectorAll("div.dashboard-options")[0];
    if (element) {
      const el = document.querySelector(id);
      if (el) {
        let scrollDiv = el.offsetTop;
        element.scrollTo({
          top: scrollDiv,
          behavior: "smooth",
        });
      }
    }
  };

  const getOptometryHistory = async (pId) => {
    const response = await post("ListarHistoricoOptometrias.php", {
      token: userData.token,
      idPaciente: pId,
      optica: selectedOptic.id,
      completo: optometrySectionSeeMore ? 1 : 0,
    });
    setOptometryHistoryList(response);
  };

  const getAudiometryHistory = async (pId) => {
    const response = await post("ListarHistoricoAudiometrias.php", {
      token: userData.token,
      idPaciente: pId,
      optica: selectedOptic.id,
      completo: audiometrySectionSeeMore ? 1 : 0,
    });
    setAudiometryHistoryList(response);
  };

  const getNumOptometries = async () => {
    const response = await post("ListarNumOptometrias.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
    });
    setNumOptometries(response);
    setRecentOptometryIds({
      optometria: response.optometria.id,
      anamnesis: response.anamnesis.id,
      refractivo: response.refractivo.id,
      oculomotor: response.oculomotor.id,
      binocular: response.binocular.id,
      acomodativo: response.acomodativo.id,
      sensorial: response.sensorial.id,
      saludOcular: response.saludOcular.id,
    });
  };

  const closeOptoPanel = async (props) => {
    props.closeOptoPanel();
    await getOptometryHistory(patientId);
    await getNumOptometries();
    await getPatientFiles(props.patientId);
  };

  const closeAudiometryPanel = (props) => {
    props.closeAudiometryPanel();
    getAudiometryHistory(patientId);
  };

  const openOptoPanelForNewOpto = () => {
    let aux = recentOptometryIds;
    aux.optometria = -1;
    props.openOptoPanel(
      {
        id: patientId,
        name: currentPatient.nombre + " " + currentPatient.apellidos,
      },
      0,
      aux,
      () => {
        closeOptoPanel(props);
      }
    );
  };

  const openAudioPanelForNewAudio = () => {
    props.openAudiometryPanel(
      {
        id: patientId,
        name: currentPatient.nombre + " " + currentPatient.apellidos,
      },
      -1,
      () => {
        closeAudiometryPanel(props);
      }
    );
  };

  useEffect(() => {
    setPatientDataChanges(
      JSON.stringify(currentPatient) !== JSON.stringify(currentPatientEdit)
    );
    if (props.newOptometry) {
      openOptoPanelForNewOpto();
    }
    if (props.newAudiometry) {
      openAudioPanelForNewAudio();
    }
  }, [
    currentPatient,
    currentPatientEdit,
    props.newOptometry,
    props.newAudiometry,
  ]);

  useEffect(() => {
    if (props.patientId) {
      if (props.patientId === -1) {
        setPatientId(-1);
        createPatient();
        setNumFiles(0);
        setNumObservations(0);
      } else {
        setPatientId(props.patientId);
        getPatientData(props.patientId);
        getPatientFiles(props.patientId);
        getObservations(props.patientId);
      }
    }
  }, [props.patientId, selectedOptic, forceReload]);

  useEffect(() => {
    const allCommercialCheckboxesChecked = [
      currentPatientEdit.comercialEmail,
      currentPatientEdit.comercialTelefono,
      currentPatientEdit.comercialPostal,
      currentPatientEdit.comercialSMS,
      currentPatientEdit.comercialWhatsapp,
    ].some(Boolean);
    const allServicesCheckboxesChecked = [
      currentPatientEdit.serviciosEmail,
      currentPatientEdit.serviciosTelefono,
      currentPatientEdit.serviciosPostal,
      currentPatientEdit.serviciosSMS,
      currentPatientEdit.serviciosWhatsapp,
    ].some(Boolean);

    setCheckboxState({
      ...checkboxState,
      checkboxCommercial: allCommercialCheckboxesChecked,
      checkboxServices: allServicesCheckboxesChecked,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPatientEdit.comercialEmail,
    currentPatientEdit.comercialTelefono,
    currentPatientEdit.comercialPostal,
    currentPatientEdit.comercialSMS,
    currentPatientEdit.comercialWhatsapp,
    currentPatientEdit.serviciosEmail,
    currentPatientEdit.serviciosTelefono,
    currentPatientEdit.serviciosPostal,
    currentPatientEdit.serviciosSMS,
    currentPatientEdit.serviciosWhatsapp,
  ]);

  useEffect(() => {
    if (identityNumberTypes) {
      const popupContainer = document.createElement("div");
      popupContainer.id = "popupContainer";

      const root = createRoot(popupContainer);

      root.render(
        <PopupNIFTypes
          idNif={currentPatientEdit.idNif}
          onClick={() => setIdentityNumberTypes(false)}
          onAccept={(e) => {
            setCurrentPatientEdit({
              ...currentPatientEdit,
              idNif: e.idNif,
              tipoNif: e.description,
            });
          }}
        />
      );

      const rootElement = document.getElementById("root");

      if (rootElement) {
        const firstChild = rootElement.firstChild;
        rootElement.insertBefore(popupContainer, firstChild);
      }
    } else {
      const popupContainer = document.getElementById("popupContainer");
      if (popupContainer) {
        popupContainer.remove();
      }
    }
  }, [identityNumberTypes, currentPatientEdit.tipoNif]);

  useEffect(() => {
    if (cameraActive) {
      const popupContainer = document.createElement("div");
      popupContainer.id = "popupContainer";

      const root = createRoot(popupContainer);

      root.render(
        <WebCam
          videoRef={videoRef}
          canvasRef={canvasRef}
          photoRef={photoRef}
          onTakePhoto={(e) => {
            setPatientPicture(e);
            setCameraActive(false);
            setFlagNewPicture(true);
          }}
          onCancel={() => {
            setCameraActive(false);
          }}
        ></WebCam>
      );

      const rootElement = document.getElementById("root");

      if (rootElement) {
        const firstChild = rootElement.firstChild;
        rootElement.insertBefore(popupContainer, firstChild);
      }
    } else {
      const popupContainer = document.getElementById("popupContainer");
      if (popupContainer) {
        popupContainer.remove();
      }
    }
  }, [cameraActive]);

  useEffect(() => {
    setPatientReadyToSave(
      currentPatientEdit.nombre !== "" && currentPatientEdit.apellidos !== ""
    );
  }, [currentPatientEdit]);

  useEffect(() => {
    getOptometryHistory(patientId);
  }, [optometrySectionSeeMore]);

  useEffect(() => {
    getAudiometryHistory(patientId);
  }, [audiometrySectionSeeMore]);

  if (!props.show || loadingPatient) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Paciente</span>
        <div className="patient-header-buttons">
          <ButtonIcon
            text="Buscar paciente"
            icon="/images/search-white.svg"
            onClick={() => {
              setForceReload(false);
              props.onClickMenuOption("patient-search", null);
            }}
          ></ButtonIcon>
          <ButtonIcon
            text="Nuevo paciente"
            icon="/images/add-white.svg"
            onClick={() => {
              if (currentPatientEdit.idPaciente !== -1) {
                props.createPopupQuestion(
                  patientDataChanges
                    ? `Existen cambios sin guardar en la ficha del paciente actual${
                        currentPatient.idPaciente !== -1
                          ? ` (${
                              currentPatient.nombre +
                              " " +
                              currentPatient.apellidos
                            })`
                          : ``
                      }. ¿Desea guardar los cambios antes de crear una nueva ficha de paciente?`
                    : `Se va a proceder a la creación de una nueva ficha de paciente.`,
                  async () => {
                    if (!patientReadyToSave) {
                      props.createPopupQuestion(
                        `Debe rellenar al menos el nombre y apellidos del paciente.`,
                        async () => {
                          props.removePopupQuestion();
                        },
                        null
                      );
                    } else {
                      if (patientDataChanges) {
                        await savePatientData();
                      }
                      props.removePopupQuestion();
                      await createPatient();
                      props.onClickMenuOption("patient-datos", {
                        patientId: -1,
                      });
                    }
                  },
                  () => {
                    props.removePopupQuestion();
                  }
                );
              } else {
                props.createPopupQuestion(
                  `Ya está creando una nueva ficha de paciente.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null
                );
              }
            }}
          ></ButtonIcon>
        </div>
      </div>
      {existPatient && (
        <div className="patient-data-content">
          <div className="action-bar">
            <div className="action-bar-resume">
              <span>Pacientes</span>
              <span>|</span>
              <span>Búsqueda de pacientes</span>
              <span>|</span>
              <span className="signed-text">Resultado de búsqueda</span>
            </div>
            <button
              className="action-bar-return"
              onClick={() => {
                if (
                  patientDataChanges ||
                  currentPatientEdit.idPaciente === -1
                ) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha del paciente actual${
                      currentPatient.idPaciente !== -1
                        ? ` (${
                            currentPatient.nombre +
                            " " +
                            currentPatient.apellidos
                          })`
                        : ``
                    }. ¿Desea guardar los cambios antes de volver a la búsqueda de pacientes?`,
                    async () => {
                      if (patientReadyToSave) {
                        let reload = false;
                        if (currentPatientEdit.idPaciente === -1) {
                          await createBeforeSaving();
                          reload = true;
                        }
                        await savePatientData();
                        await savePatientPic();
                        setFlagNewPicture(false);
                        props.removePopupQuestion();
                        setForceReload(false);
                        //props.onClickMenuOption("patient-search", null);
                        //props.onGoBack();
                      } else {
                        props.createPopupQuestion(
                          `Debe rellenar al menos el nombre y apellidos del paciente.`,
                          async () => {
                            props.removePopupQuestion();
                          },
                          null
                        );
                      }
                    },
                    () => {
                      setCurrentPatientEdit(currentPatient);
                      props.removePopupQuestion();
                      setForceReload(false);
                      //props.onClickMenuOption("patient-search", null);
                      props.onGoBack();
                    }
                  );
                } else {
                  setForceReload(false);
                  setLoadingPatient(true);
                  //props.onClickMenuOption("patient-search", null);
                  props.onGoBack();
                }
              }}
            >
              <span>Volver</span>
              <img
                src="/images/return.svg"
                loading="lazy"
                alt="Icon"
                className="return-icon"
              />
            </button>
          </div>
          {loadingPatient && (
            <LoadingScreen message="Cargando datos del paciente..."></LoadingScreen>
          )}
          <div className={`patient-info ${loadingPatient ? "hidden" : ""}`}>
            <div className="patient-info-row1">
              <div className="patient-info-1">
                <img
                  src={patientPicture}
                  loading="lazy"
                  alt="Patient Img"
                  className="patient-img"
                />
                {patientPicture !== avatarImg && (
                  <img
                    src="/images/remove.svg"
                    loading="lazy"
                    title="Borrar foto"
                    alt="Icon"
                    className="small-icon delete-icon"
                    onClick={() => {
                      props.createPopupQuestion(
                        `¿Desea eliminar la foto del paciente?`,
                        async () => {
                          deletePatientPicture();
                          props.removePopupQuestion();
                        },
                        () => {
                          props.removePopupQuestion();
                        }
                      );
                    }}
                  />
                )}
                {patientPicture === avatarImg && (
                  <div>
                    <input
                      id="file-input"
                      className="hidden"
                      type="file"
                      accept="image/*"
                      value={""}
                      onChange={(e) => {
                        uploadNewPatientPic(e.target.value);
                        const picName = e.target.value.split("\\");
                        setPatientPictureName(picName[picName.length - 1]);
                      }}
                    ></input>
                    <label htmlFor="file-input">
                      <img
                        id="file-input"
                        src="/images/new-blue.svg"
                        loading="lazy"
                        alt="Icon"
                        title="Nueva foto"
                        className="small-icon new-pic-icon"
                        onClick={() => {}}
                      />
                    </label>
                    <img
                      src="/images/webcam.svg"
                      loading="lazy"
                      title="Tomar foto"
                      alt="Icon"
                      className="small-icon webcam-icon"
                      onClick={() => {
                        openCamera();
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="patient-info-2">
                <div className="patient-name-info">
                  {currentPatientEdit.codigo !== "" && (
                    <span className="patient-code" title="Código de Paciente">
                      ({currentPatientEdit.codigo})
                    </span>
                  )}
                  <span className="patient-name">{headerName}</span>
                </div>

                <div className="sep-line"></div>
                <div className="patient-stats">
                  <div className="stats-container">
                    <span className="stat-title">Preferencias</span>
                    <div className="patient-prefs">
                      {Object.entries(currentPatientEdit.preferencias).map(
                        ([preferencia, valor], index) => {
                          return (
                            valor && (
                              <img
                                key={index}
                                src={`/images/${preferencia}.svg`}
                                loading="lazy"
                                className="icon-pref"
                                alt="Preference"
                              />
                            )
                          );
                        }
                      )}
                    </div>
                    <ButtonIconH
                      text="Dar cita"
                      icon={svgIcons.schedule}
                      onClick={() => {
                        props.onClickMenuOption("agenda", null);
                      }}
                    ></ButtonIconH>
                  </div>
                  <div className="stats-container">
                    <span className="stat-title">Fidelización</span>
                    <div className="icon-list faces-container">
                      <img
                        src={`${
                          currentPatientEdit.fidelizacionCategoria === "0"
                            ? "/images/face1-blue.svg"
                            : "/images/face1-light-blue.svg"
                        }`}
                        loading="lazy"
                        alt="Icon"
                        className="icon-list-icon"
                      ></img>
                      <img
                        src={`${
                          currentPatientEdit.fidelizacionCategoria === "1"
                            ? "/images/face2-blue.svg"
                            : "/images/face2-light-blue.svg"
                        }`}
                        loading="lazy"
                        alt="Icon"
                        className="icon-list-icon"
                      ></img>
                      <img
                        src={`${
                          currentPatientEdit.fidelizacionCategoria === "2"
                            ? "/images/face3-blue.svg"
                            : "/images/face3-light-blue.svg"
                        }`}
                        loading="lazy"
                        alt="Icon"
                        className="icon-list-icon"
                      ></img>
                    </div>
                    <ButtonIconH
                      text="WhatsApp"
                      icon={svgIcons.whatsapp}
                      onClick={() => {
                        openWhatsApp();
                      }}
                    ></ButtonIconH>
                  </div>
                  <div className="stats-container">
                    <span className="stat-title">Descuento especial</span>
                    <div className="icon-list">
                      {currentPatientEdit.descuentoEspecial}%
                    </div>
                    <ButtonIconH
                      text="Email"
                      icon={svgIcons.email}
                      onClick={() => {
                        openEmail();
                      }}
                    ></ButtonIconH>
                  </div>
                  <div className="stats-container">
                    <span className="stat-title">Puntos</span>
                    <div className="icon-list">{currentPatientEdit.puntos}</div>
                    <ButtonIconH text="SMS" icon={svgIcons.sms}></ButtonIconH>
                  </div>
                </div>
              </div>
              <div className="patient-info-3">
                <div className="alert-container">
                  <div className="alert-circle">
                    <img
                      src="/images/alert.svg"
                      loading="lazy"
                      alt="Icon"
                    ></img>
                  </div>
                  <span>Avisos</span>
                </div>
                <div className="alert-list">
                  <div className="alert">
                    <div className="pointer"></div>
                    <span>Encargo</span>
                    <img
                      src="/images/alert-link.svg"
                      loading="lazy"
                      alt="Icon"
                      className="alert-container-icon"
                    ></img>
                  </div>
                  <div className="alert">
                    <div className="pointer"></div>
                    <span>Deuda</span>
                    <img
                      src="/images/alert-link.svg"
                      loading="lazy"
                      alt="Icon"
                      className="alert-container-icon"
                    ></img>
                  </div>
                  <div className="alert">
                    <div className="pointer"></div>
                    <span>Vale de caja</span>
                    <img
                      src="/images/alert-link.svg"
                      loading="lazy"
                      alt="Icon"
                      className="alert-container-icon"
                    ></img>
                  </div>
                  <div className="alert">
                    <div className="pointer"></div>
                    <span>Cita</span>
                    <img
                      src="/images/alert-link.svg"
                      loading="lazy"
                      alt="Icon"
                      className="alert-container-icon"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="patient-info-row2">
              <div className="patient-info-4">
                <div className="patient-actions">
                  <ButtonIconV
                    text="Historial"
                    icon="/images/history-blue.svg"
                    onClick={() => {}}
                  ></ButtonIconV>
                  <ButtonIconV
                    text="Imprimir"
                    icon="/images/print-blue.svg"
                    onClick={() => {}}
                  ></ButtonIconV>
                  <ButtonIconV
                    text="Recargar"
                    icon="/images/update-blue.svg"
                    onClick={async () => {
                      props.createPopupQuestion(
                        `¿Desea recargar los datos del paciente?`,
                        async () => {
                          await getPatientData(patientId);
                          props.removePopupQuestion();
                        },
                        () => {
                          props.removePopupQuestion();
                        }
                      );
                    }}
                  ></ButtonIconV>
                  <ButtonIconV
                    text="Guardar"
                    icon="/images/save-blue.svg"
                    onClick={async () => {
                      if (!patientReadyToSave) {
                        props.createPopupQuestion(
                          `Debe rellenar al menos el nombre y apellidos del paciente.`,
                          async () => {
                            props.removePopupQuestion();
                          },
                          null
                        );
                      } else {
                        // Si el idPaciente es -1, crear el paciente primero
                        // Se recibe su idPaciente creado y se sobreescribe en el que hay con -1
                        await createBeforeSaving();
                        // Luego se guarda
                        await savePatientData();
                        await savePatientPic();
                        setFlagNewPicture(false);
                        props.createPopupQuestion(
                          `Los cambios se han guardado con éxito.`,
                          async () => {
                            props.removePopupQuestion();
                          },
                          null,
                          "Entendido"
                        );
                        props.onClickMenuOption("patient-datos", {
                          patientId,
                        });
                      }
                    }}
                  ></ButtonIconV>
                </div>
                <div className="patient-action-buttons">
                  <ButtonIconH text="Fidelización"></ButtonIconH>
                  <ButtonIconH text="Sanidad"></ButtonIconH>
                </div>
                <div className="delete-patient">
                  <span
                    onClick={() => {
                      props.createPopupQuestion(
                        `¿Desea eliminar la ficha del paciente?`,
                        async () => {
                          await deletePatient();
                          props.removePopupQuestion();
                          setForceReload(false);
                          props.onClickMenuOption("patient-search", null);
                        },
                        () => {
                          props.removePopupQuestion();
                        }
                      );
                    }}
                  >
                    Eliminar
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`patient-sections ${loadingPatient ? "hidden" : ""}`}>
            <div className="patient-section" id="Datos">
              <div className="section-header">
                <img
                  src="/images/patient-data-section.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Datos</span>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleDataSection(!toggleDataSection);
                  }}
                >
                  <img
                    src={
                      toggleDataSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleDataSection && (
                <div className="section-content">
                  <div className="section-content-info sec-1">
                    <span className="section-content-title">
                      Datos personales
                    </span>
                    <div className="section-content-sep"></div>
                    <div className="section-content-data">
                      <div className="data-field-row">
                        <div className="data-field-extra">
                          <div className="data-field d-small-extra d-small-extra-2">
                            <span className="label">Código</span>
                            <InputData
                              id="codigo"
                              size={1}
                              input={currentPatientEdit.codigo}
                              inactive={true}
                              extra="input-field-extra"
                            ></InputData>
                          </div>
                          <div className="data-field">
                            <span className="label">NIF </span>
                            <InputData
                              tabIndex={1}
                              id="nif"
                              type="type-only-charnumbers"
                              size={1}
                              input={currentPatientEdit.nif}
                              nifType={currentPatientEdit.tipoNif}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  nif: e,
                                });
                              }}
                              icon="/images/link.svg"
                              onClick={() => {
                                setIdentityNumberTypes(true);
                              }}
                            ></InputData>
                          </div>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Sexo</span>
                          <Dropdown
                            id="sexo"
                            tabIndex={11}
                            optionList={sexOptions}
                            size={1}
                            op={currentPatientEdit.sexo}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                sexo: e.id,
                              });
                            }}
                          ></Dropdown>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Fidelización</span>
                          <InputData
                            id="fidelizacion"
                            tabIndex={2}
                            size={3}
                            input={"POR DEFINIR"}
                            onChange={() => {}}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Edad</span>
                          <InputData
                            id="edad"
                            tabIndex={12}
                            type="type-integer"
                            size={1}
                            input={patientAge}
                            onChange={() => {}}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Nombre</span>
                          <InputData
                            tabIndex={3}
                            id="nombre"
                            type="type-alpha"
                            size={3}
                            input={currentPatientEdit.nombre}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                nombre: e,
                              });
                            }}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Nacimiento</span>
                          <input
                            type="date"
                            tabIndex={13}
                            className="input-field"
                            value={currentPatientEdit.nacimiento}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                nacimiento: e.target.value,
                              });
                              const date = new Date(e.target.value);
                              calculatePatientAge(date);
                            }}
                            onKeyUp={(e) => inputOnEnter(e)}
                          ></input>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Apellidos</span>
                          <InputData
                            tabIndex={4}
                            id="apellidos"
                            type="type-alpha"
                            size={3}
                            input={currentPatientEdit.apellidos}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                apellidos: e,
                              });
                            }}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Código postal</span>
                          <InputData
                            id="codigopostal"
                            tabIndex={14}
                            type="type-only-numbers"
                            size={1}
                            input={currentPatientEdit.codigoPostal}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                codigoPostal: e,
                              });
                            }}
                            icon="/images/link.svg"
                            onClick={() => {}}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Dirección</span>
                          <InputData
                            tabIndex={5}
                            id="direccion"
                            type="type-alphanum-ext"
                            size={3}
                            input={currentPatientEdit.direccion}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                direccion: e,
                              });
                            }}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Teléfono</span>
                          <InputData
                            id="telefono"
                            tabIndex={15}
                            type="type-phone"
                            size={1}
                            input={currentPatientEdit.telefono}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                telefono: e,
                              });
                            }}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Localidad</span>
                          <InputData
                            tabIndex={6}
                            id="localidad"
                            type="type-alpha"
                            size={3}
                            input={currentPatientEdit.localidad}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                localidad: e,
                              });
                            }}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Móvil</span>
                          <InputData
                            id="movil"
                            tabIndex={16}
                            type="type-phone"
                            size={1}
                            input={currentPatientEdit.movil}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                movil: e,
                              });
                            }}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Provincia</span>
                          <Dropdown
                            tabIndex={7}
                            id="provincia"
                            disabled={false}
                            optionList={provinceOptions}
                            size={3}
                            op={currentPatientEdit.provincia}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                provincia: e.id,
                              });
                            }}
                          ></Dropdown>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Fax</span>
                          <InputData
                            id="fax"
                            tabIndex={17}
                            type="type-phone"
                            size={1}
                            input={currentPatientEdit.fax}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                fax: e,
                              });
                            }}
                          ></InputData>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Email</span>
                          <InputData
                            id="email"
                            tabIndex={8}
                            type="type-email"
                            size={3}
                            input={currentPatientEdit.email}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                email: e,
                              });
                              setErrorEmail(false);
                            }}
                            onMalformed={() => setErrorEmail(true)}
                            error={errorEmail}
                          ></InputData>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Carné de conducir</span>
                          <div className="radio-ops-container">
                            <div className="radio-op">
                              <RadioButton
                                tabIndex={18}
                                active={currentPatientEdit.carnetConducir}
                                onClick={() => {
                                  setCurrentPatientEdit({
                                    ...currentPatientEdit,
                                    carnetConducir:
                                      !currentPatientEdit.carnetConducir,
                                  });
                                }}
                              ></RadioButton>
                              <span className="label">Sí</span>
                            </div>
                            <div className="radio-op">
                              <RadioButton
                                tabIndex={19}
                                active={!currentPatientEdit.carnetConducir}
                                onClick={() => {
                                  setCurrentPatientEdit({
                                    ...currentPatientEdit,
                                    carnetConducir:
                                      !currentPatientEdit.carnetConducir,
                                  });
                                }}
                              ></RadioButton>
                              <span className="label">No</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Campaña</span>
                          <Dropdown
                            id="campana"
                            tabIndex={9}
                            disabled={false}
                            optionList={campaignList}
                            size={3}
                            op={currentPatientEdit.campana}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                campana: e.id,
                              });
                            }}
                          ></Dropdown>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Tiene hijos</span>
                          <div className="radio-ops-container">
                            <div className="radio-op">
                              <RadioButton
                                tabIndex={20}
                                active={currentPatientEdit.tieneHijos}
                                onClick={() => {
                                  setCurrentPatientEdit({
                                    ...currentPatientEdit,
                                    tieneHijos: !currentPatientEdit.tieneHijos,
                                  });
                                }}
                              ></RadioButton>
                              <span className="label">Sí</span>
                            </div>
                            <div className="radio-op">
                              <RadioButton
                                tabIndex={21}
                                active={!currentPatientEdit.tieneHijos}
                                onClick={() => {
                                  setCurrentPatientEdit({
                                    ...currentPatientEdit,
                                    tieneHijos: !currentPatientEdit.tieneHijos,
                                  });
                                }}
                              ></RadioButton>
                              <span className="label">No</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-field-row">
                        <div className="data-field d-large">
                          <span className="label">Recomendado</span>
                          <Dropdown
                            id="recomendado"
                            tabIndex={10}
                            disabled={false}
                            optionList={recommendedList}
                            size={3}
                            op={currentPatientEdit.recomendado}
                            onChange={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                recomendado: e.id,
                              });
                            }}
                          ></Dropdown>
                        </div>
                        <div className="data-field d-small">
                          <span className="label">Código Rec </span>
                          <InputSearch
                            id="codigorec"
                            size={1}
                            tabIndex={22}
                            link={true}
                            op={currentPatientEdit.codigoRec}
                            onSelect={(e) => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                codigoRec: e.codigo,
                                idPacienteCodigoRec: e.idPaciente,
                              });
                            }}
                            onClickLink={() => {
                              if (
                                currentPatientEdit.idPacienteCodigoRec !== ""
                              ) {
                                window.open(
                                  `/patient/${currentPatientEdit.idPacienteCodigoRec}`
                                );
                              }
                            }}
                          ></InputSearch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-content-info sec-2">
                    <span className="section-content-title">
                      Consentimientos
                    </span>
                    <div className="section-content-sep"></div>
                    <div className="section-content-data">
                      <div className="grid-content">
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              consentimientosRGPD:
                                !currentPatientEdit.consentimientosRGPD,
                            });
                          }}
                        >
                          <span className="label">RGPD</span>
                          <CheckBox
                            tabIndex={29}
                            active={currentPatientEdit.consentimientosRGPD}
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              consentimientosAudiologia:
                                !currentPatientEdit.consentimientosAudiologia,
                            });
                          }}
                        >
                          <span className="label">Audiología</span>
                          <CheckBox
                            tabIndex={30}
                            active={
                              currentPatientEdit.consentimientosAudiologia
                            }
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              consentimientosLC:
                                !currentPatientEdit.consentimientosLC,
                            });
                          }}
                        >
                          <span className="label">LC</span>
                          <CheckBox
                            tabIndex={31}
                            active={currentPatientEdit.consentimientosLC}
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              consentimientosORTOK:
                                !currentPatientEdit.consentimientosORTOK,
                            });
                          }}
                        >
                          <span className="label">ORTO-K</span>
                          <CheckBox
                            tabIndex={32}
                            active={currentPatientEdit.consentimientosORTOK}
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                      </div>
                      <div>
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCheckboxState({
                              ...checkboxState,
                              checkboxServices: !checkboxState.checkboxServices,
                            });
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              serviciosEmail: !checkboxState.checkboxServices,
                              serviciosPostal: !checkboxState.checkboxServices,
                              serviciosSMS: !checkboxState.checkboxServices,
                              serviciosTelefono:
                                !checkboxState.checkboxServices,
                              serviciosWhatsapp:
                                !checkboxState.checkboxServices,
                            });
                          }}
                        >
                          <span className="label">Servicios</span>
                          <CheckBox
                            tabIndex={33}
                            active={checkboxState.checkboxServices}
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                        <div className="section-content-sep-2"></div>
                        <div className="grid-content">
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                serviciosWhatsapp:
                                  !currentPatientEdit.serviciosWhatsapp,
                              });
                            }}
                          >
                            <span className="label">Whatsapp</span>
                            <CheckBox
                              tabIndex={34}
                              active={currentPatientEdit.serviciosWhatsapp}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                serviciosEmail:
                                  !currentPatientEdit.serviciosEmail,
                              });
                            }}
                          >
                            <span className="label">Email</span>
                            <CheckBox
                              tabIndex={35}
                              active={currentPatientEdit.serviciosEmail}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                serviciosSMS: !currentPatientEdit.serviciosSMS,
                              });
                            }}
                          >
                            <span className="label">SMS</span>
                            <CheckBox
                              tabIndex={36}
                              active={currentPatientEdit.serviciosSMS}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                serviciosTelefono:
                                  !currentPatientEdit.serviciosTelefono,
                              });
                            }}
                          >
                            <span className="label">Teléfono</span>
                            <CheckBox
                              tabIndex={37}
                              active={currentPatientEdit.serviciosTelefono}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                serviciosPostal:
                                  !currentPatientEdit.serviciosPostal,
                              });
                            }}
                          >
                            <span className="label">Postal</span>
                            <CheckBox
                              tabIndex={38}
                              active={currentPatientEdit.serviciosPostal}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className="data-field c-pointer"
                          onClick={() => {
                            setCheckboxState({
                              ...checkboxState,
                              checkboxCommercial:
                                !checkboxState.checkboxCommercial,
                            });
                            setCurrentPatientEdit({
                              ...currentPatientEdit,
                              comercialEmail: !checkboxState.checkboxCommercial,
                              comercialPostal:
                                !checkboxState.checkboxCommercial,
                              comercialSMS: !checkboxState.checkboxCommercial,
                              comercialTelefono:
                                !checkboxState.checkboxCommercial,
                              comercialWhatsapp:
                                !checkboxState.checkboxCommercial,
                            });
                          }}
                        >
                          <span className="label">Comercial</span>
                          <CheckBox
                            tabIndex={39}
                            active={checkboxState.checkboxCommercial}
                            onClick={() => {}}
                          ></CheckBox>
                        </div>
                        <div className="section-content-sep-2"></div>
                        <div className="grid-content">
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                comercialWhatsapp:
                                  !currentPatientEdit.comercialWhatsapp,
                              });
                            }}
                          >
                            <span className="label">Whatsapp</span>
                            <CheckBox
                              tabIndex={40}
                              active={currentPatientEdit.comercialWhatsapp}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                comercialEmail:
                                  !currentPatientEdit.comercialEmail,
                              });
                            }}
                          >
                            <span className="label">Email</span>
                            <CheckBox
                              tabIndex={41}
                              active={currentPatientEdit.comercialEmail}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                comercialSMS: !currentPatientEdit.comercialSMS,
                              });
                            }}
                          >
                            <span className="label">SMS</span>
                            <CheckBox
                              tabIndex={42}
                              active={currentPatientEdit.comercialSMS}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                comercialTelefono:
                                  !currentPatientEdit.comercialTelefono,
                              });
                            }}
                          >
                            <span className="label">Teléfono</span>
                            <CheckBox
                              tabIndex={43}
                              active={currentPatientEdit.comercialTelefono}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                          <div
                            className="data-field c-pointer"
                            onClick={() => {
                              setCurrentPatientEdit({
                                ...currentPatientEdit,
                                comercialPostal:
                                  !currentPatientEdit.comercialPostal,
                              });
                            }}
                          >
                            <span className="label">Postal</span>
                            <CheckBox
                              tabIndex={44}
                              active={currentPatientEdit.comercialPostal}
                              onClick={() => {}}
                            ></CheckBox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="combined-section">
                    <div className="section-content-info sec-2 combined-section-child">
                      <span className="section-content-title">Aficiones</span>
                      <div className="section-content-sep"></div>
                      <div className="section-content-data">
                        {currentPatientEdit.aficiones &&
                          currentPatientEdit.aficiones.length > 0 && (
                            <div className="tag-content">
                              {currentPatientEdit.aficiones.map((e, i) => {
                                return (
                                  <Tag
                                    text={e}
                                    key={i}
                                    onClick={(e) => {
                                      setCurrentPatientEdit({
                                        ...currentPatientEdit,
                                        aficiones:
                                          currentPatientEdit.aficiones.filter(
                                            (i) => i !== e
                                          ),
                                      });
                                    }}
                                  ></Tag>
                                );
                              })}
                            </div>
                          )}
                        {currentPatientEdit.aficiones &&
                          currentPatientEdit.aficiones.length === 0 && (
                            <span className="section-content-message">
                              Todavía no hay datos registrados.
                            </span>
                          )}
                        <div className="section-content-buttons">
                          {!addHobby && (
                            <ButtonIconH
                              text="Añadir"
                              small={true}
                              onClick={() => {
                                toggleAddHobby();
                              }}
                            ></ButtonIconH>
                          )}
                          {addHobby && (
                            <div className="add-hobby-container">
                              <Dropdown
                                disabled={false}
                                optionList={hobbiesList}
                                size={1}
                                op={selectedHobby}
                                onChange={(e) => {
                                  setSelectedHobby(e.id);
                                }}
                              ></Dropdown>
                              <div
                                className="mini-button fill-blue"
                                onClick={() => {
                                  if (
                                    !currentPatientEdit.aficiones.includes(
                                      selectedHobby
                                    )
                                  ) {
                                    const list = currentPatientEdit.aficiones;
                                    list.push(selectedHobby);
                                    setCurrentPatientEdit({
                                      ...currentPatientEdit,
                                      aficiones: list,
                                    });
                                  }
                                }}
                              >
                                <img
                                  src="/images/accept.svg"
                                  loading="lazy"
                                  alt="Icon"
                                />
                              </div>
                              <div
                                className="mini-button fill-gray"
                                onClick={() => {
                                  toggleAddHobby();
                                }}
                              >
                                <img
                                  src="/images/cancel.svg"
                                  loading="lazy"
                                  alt="Icon"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="section-content-info sec-2 combined-section-child">
                      <span className="section-content-title">Ametropías</span>
                      <div className="section-content-sep"></div>
                      <div className="section-content-data">
                        {currentPatientEdit.ametropias &&
                          currentPatientEdit.ametropias.length > 0 && (
                            <div className="tag-content">
                              {currentPatientEdit.ametropias.map((e, i) => {
                                return (
                                  <Tag
                                    text={e}
                                    key={i}
                                    onClick={() => {
                                      setCurrentPatientEdit({
                                        ...currentPatientEdit,
                                        ametropias:
                                          currentPatientEdit.ametropias.filter(
                                            (i) => i !== e
                                          ),
                                      });
                                    }}
                                  ></Tag>
                                );
                              })}
                            </div>
                          )}
                        {currentPatientEdit.ametropias &&
                          currentPatientEdit.ametropias.length === 0 && (
                            <span className="section-content-message">
                              Todavía no hay datos registrados.
                            </span>
                          )}
                        <div className="section-content-buttons">
                          {!addAmetropia && (
                            <ButtonIconH
                              text="Añadir"
                              small={true}
                              onClick={() => {
                                toggleAddAmetropia();
                              }}
                            ></ButtonIconH>
                          )}
                          {addAmetropia && (
                            <div className="add-hobby-container">
                              <Dropdown
                                disabled={false}
                                optionList={ametropiaList}
                                size={1}
                                op={selectedAmetropia}
                                onChange={(e) => {
                                  setSelectedAmetropia(e.id);
                                }}
                              ></Dropdown>
                              <div
                                className="mini-button fill-blue"
                                onClick={() => {
                                  if (
                                    !currentPatientEdit.ametropias.includes(
                                      selectedAmetropia
                                    )
                                  ) {
                                    const list = currentPatientEdit.ametropias;
                                    list.push(selectedAmetropia);
                                    setCurrentPatientEdit({
                                      ...currentPatientEdit,
                                      ametropias: list,
                                    });
                                  }
                                }}
                              >
                                <img
                                  src="/images/accept.svg"
                                  loading="lazy"
                                  alt="Icon"
                                />
                              </div>
                              <div
                                className="mini-button fill-gray"
                                onClick={() => {
                                  toggleAddAmetropia();
                                }}
                              >
                                <img
                                  src="/images/cancel.svg"
                                  loading="lazy"
                                  alt="Icon"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contacto-trabajo">
                    <div className="section-content-info sec-3">
                      <span className="section-content-title">
                        Persona de contacto
                      </span>
                      <div className="section-content-sep"></div>
                      <div className="section-content-data">
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Nombre</span>
                            <InputSearch
                              id="contactonombre"
                              tabIndex={23}
                              type="type-alpha"
                              size={2}
                              op={currentPatientEdit.personaContactoNombre}
                              onSelect={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  personaContactoCodigo: e.codigo,
                                  personaContactoNombre:
                                    e.nombre + " " + e.apellidos,
                                  personaContactoTelefono: e.telefono,
                                });
                              }}
                            ></InputSearch>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Código</span>
                            <InputData
                              id="contactocodigo"
                              tabIndex={24}
                              size={2}
                              input={currentPatientEdit.personaContactoCodigo}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  personaContactoCodigo: e,
                                });
                              }}
                            ></InputData>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Teléfono</span>
                            <InputData
                              id="contactotelefono"
                              type="type-phone"
                              tabIndex={25}
                              size={2}
                              input={currentPatientEdit.personaContactoTelefono}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  personaContactoTelefono: e,
                                });
                              }}
                            ></InputData>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section-content-info sec-3">
                      <span className="section-content-title">Trabajo</span>
                      <div className="section-content-sep"></div>
                      <div className="section-content-data">
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Profesión</span>
                            <InputData
                              id="trabajoprofesion"
                              tabIndex={26}
                              type="type-alpha"
                              size={2}
                              input={currentPatientEdit.trabajoProfesion}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  trabajoProfesion: e,
                                });
                              }}
                            ></InputData>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Empresa</span>
                            <InputData
                              id="trabajoempresa"
                              type="type-alphanum"
                              tabIndex={27}
                              size={2}
                              input={currentPatientEdit.trabajoEmpresa}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  trabajoEmpresa: e,
                                });
                              }}
                            ></InputData>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Teléfono</span>
                            <InputData
                              id="trabajotelefono"
                              tabIndex={28}
                              type="type-phone"
                              size={2}
                              input={currentPatientEdit.trabajoTelefono}
                              onChange={(e) => {
                                setCurrentPatientEdit({
                                  ...currentPatientEdit,
                                  trabajoTelefono: e,
                                });
                              }}
                            ></InputData>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-content-info sec-4">
                    <span className="section-content-title">Observaciones</span>
                    <div className="section-content-sep"></div>
                    <div className="section-content-data">
                      <span className="section-content-message">
                        Hay&nbsp;
                        <span className="obs-text">
                          {numObservations}&nbsp;observaciones&nbsp;
                        </span>
                        para este paciente.
                      </span>
                      <div className="section-content-buttons">
                        <ButtonIconH
                          text="Abrir"
                          small={true}
                          onClick={() => {
                            if (patientId !== -1) {
                              props.openObservationsPanel(
                                {
                                  id: patientId,
                                  name:
                                    currentPatient.nombre +
                                    " " +
                                    currentPatient.apellidos,
                                },
                                patientId,
                                async () => {
                                  await getObservations(props.patientId);
                                },
                                "Paciente"
                              );
                            } else {
                              props.createPopupQuestion(
                                "Por favor, guarde la ficha que está creando antes de abrir el panel de observaciones.",
                                async () => {
                                  props.removePopupQuestion();
                                },
                                null,
                                "Entendido"
                              );
                            }
                          }}
                        ></ButtonIconH>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="patient-section" id="Compras">
              <div className="section-header">
                <img
                  src="/images/menu-shopping-bag.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Compras</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setTogglePurchasingSection(!togglePurchasingSection);
                  }}
                >
                  <img
                    src={
                      togglePurchasingSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {togglePurchasingSection && (
                <div className="section-content">
                  <span className="section-todo">SECCIÓN POR DEFINIR</span>
                </div>
              )}
            </div>
            <div className="patient-section" id="Optometría">
              <div className="section-header">
                <img
                  src="/images/menu-multiestenope.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Optometría</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                    onClick={() => {
                      if (currentPatientEdit.idPaciente !== -1) {
                        openOptoPanelForNewOpto();
                      }
                    }}
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                      onClick={async () => {
                        if (currentPatientEdit.idPaciente !== -1) {
                          await getOptometryHistory(patientId);
                        }
                      }}
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleOptometrySection(!toggleOptometrySection);
                  }}
                >
                  <img
                    src={
                      toggleOptometrySection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleOptometrySection && (
                <div className="section-content">
                  <div className="section-todo">
                    <div className="optometry-icon-header">
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            0,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.optometria.num > 0
                              ? "/images/optometry-orange.svg"
                              : "/images/optometry-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>
                          Optometría ({numOptometries.optometria.num})
                        </span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            1,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.anamnesis.num > 0
                              ? "images/anamnesis-orange.svg"
                              : "/images/anamnesis-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>Anamnesis ({numOptometries.anamnesis.num})</span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            2,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.refractivo.num > 0
                              ? "/images/rx-orange.svg"
                              : "/images/rx-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>
                          Refractivo ({numOptometries.refractivo.num})
                        </span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            3,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.oculomotor.num > 0
                              ? "/images/accommodative-orange.svg"
                              : "/images/accommodative-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>
                          Oculomotor ({numOptometries.oculomotor.num})
                        </span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            4,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.binocular.num > 0
                              ? "/images/binocular-orange.svg"
                              : "/images/binocular-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>Binocular ({numOptometries.binocular.num})</span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            5,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.acomodativo.num > 0
                              ? "/images/accommodative-orange.svg"
                              : "/images/accommodative-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>
                          Acomodativo ({numOptometries.acomodativo.num})
                        </span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            6,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.sensorial.num > 0
                              ? "/images/sensory-orange.svg"
                              : "/images/sensory-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>Sensorial ({numOptometries.sensorial.num})</span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            7,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numOptometries.saludOcular.num > 0
                              ? "/images/eye-health-orange.svg"
                              : "/images/eye-health-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>
                          Salud Ocular ({numOptometries.saludOcular.num})
                        </span>
                      </button>
                      <button
                        className="optometry-icon-header-icon"
                        onClick={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            8,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            numFiles > 0
                              ? "/images/files-orange.svg"
                              : "/images/files-blue.svg"
                          }
                          loading="lazy"
                          alt="Icon"
                        ></img>
                        <span>Ficheros ({numFiles})</span>
                      </button>
                    </div>
                    {optometryHistoryList.length > 0 && (
                      <span className="section-history-header">
                        {!optometrySectionSeeMore
                          ? "Última optometría"
                          : `Histórico (${optometryHistoryList.length})`}
                      </span>
                    )}
                    {optometryHistoryList.length > 0 && (
                      <div className="section-content-sep"></div>
                    )}
                    {optometryHistoryList.length > 0 && (
                      <OptometryHistoryBox
                        opt={optometryHistoryList[0]}
                        onOpen={() => {
                          props.openOptoPanel(
                            {
                              id: patientId,
                              name:
                                currentPatient.nombre +
                                " " +
                                currentPatient.apellidos,
                            },
                            0,
                            recentOptometryIds,
                            async () => {
                              await closeOptoPanel(props);
                            }
                          );
                        }}
                      ></OptometryHistoryBox>
                    )}
                    {optometrySectionSeeMore &&
                      optometryHistoryList.length > 0 && (
                        <div>
                          {optometryHistoryList.slice(1).map((e, i) => {
                            return (
                              <OptometryHistoryBox
                                key={i}
                                opt={e}
                                onOpen={() => {
                                  let aux = recentOptometryIds;
                                  aux.optometria = e.id;
                                  props.openOptoPanel(
                                    {
                                      id: patientId,
                                      name:
                                        currentPatient.nombre +
                                        " " +
                                        currentPatient.apellidos,
                                    },
                                    0,
                                    aux,
                                    async () => {
                                      await closeOptoPanel(props);
                                    }
                                  );
                                }}
                              ></OptometryHistoryBox>
                            );
                          })}
                        </div>
                      )}
                    {optometryHistoryList.length > 0 && (
                      <div className="section-footer-button">
                        <ButtonIconH
                          text={
                            !optometrySectionSeeMore ? "Ver más" : "Ver menos"
                          }
                          small={true}
                          onClick={() => {
                            setOptometrySectionSeeMore(
                              !optometrySectionSeeMore
                            );
                            if (optometrySectionSeeMore) {
                              scrollTo("[id='Optometría']");
                            }
                          }}
                        ></ButtonIconH>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="patient-section" id="Gafas">
              <div className="section-header">
                <img
                  src="/images/menu-gafas.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Gafas</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleEyeglassesSection(!toggleEyeglassesSection);
                  }}
                >
                  <img
                    src={
                      toggleEyeglassesSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleEyeglassesSection && (
                <div className="section-content">
                  <span className="section-todo">SECCIÓN POR DEFINIR</span>
                </div>
              )}
            </div>
            <div className="patient-section" id="Lentes de contacto">
              <div className="section-header">
                <img
                  src="/images/menu-lentillas.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Lentes de contacto</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleContactLensesSection(!toggleContactLensesSection);
                  }}
                >
                  <img
                    src={
                      toggleContactLensesSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleContactLensesSection && (
                <div className="section-content">
                  <span className="section-todo">SECCIÓN POR DEFINIR</span>
                </div>
              )}
            </div>
            <div className="patient-section" id="Audiometría">
              <div className="section-header">
                <img
                  src="/images/menu-headphones.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Audiometrías</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                    onClick={() => {
                      props.openAudiometryPanel(
                        {
                          id: patientId,
                          name:
                            currentPatient.nombre +
                            " " +
                            currentPatient.apellidos,
                        },
                        -1,
                        () => {
                          closeAudiometryPanel(props);
                        }
                      );
                    }}
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                      onClick={async () => {
                        if (currentPatientEdit.idPaciente !== -1) {
                          await getAudiometryHistory(patientId);
                        }
                      }}
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleAudiometrySection(!toggleAudiometrySection);
                  }}
                >
                  <img
                    src={
                      toggleAudiometrySection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleAudiometrySection && (
                <div className="section-content">
                  <div className="section-todo">
                    {audiometryHistoryList.length > 0 && (
                      <span className="section-history-header">
                        {!audiometrySectionSeeMore
                          ? "Última audiometría"
                          : `Histórico (${audiometryHistoryList.length})`}
                      </span>
                    )}
                    {audiometryHistoryList.length > 0 && (
                      <div className="section-content-sep"></div>
                    )}
                    {audiometryHistoryList.length > 0 && (
                      <div className="history-box">
                        <div className="history-box-header full-border">
                          <div className="history-box-header-fields">
                            <div className="history-box-header-field">
                              <span className="history-box-tag">Fecha</span>
                              <span>
                                {moment(audiometryHistoryList[0].fecha).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              </span>
                            </div>
                            <div className="history-box-header-field">
                              <span className="history-box-tag">
                                Realizada por
                              </span>
                              <span>
                                {audiometryHistoryList[0].audioprotesista !== ""
                                  ? audiometryHistoryList[0].audioprotesista
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <ButtonIconH
                            text="Mostrar"
                            small={true}
                            onClick={() => {
                              props.openAudiometryPanel(
                                {
                                  id: patientId,
                                  name:
                                    currentPatient.nombre +
                                    " " +
                                    currentPatient.apellidos,
                                },
                                audiometryHistoryList[0].idAudiometria,
                                () => {
                                  closeAudiometryPanel(props);
                                }
                              );
                            }}
                          ></ButtonIconH>
                        </div>
                      </div>
                    )}
                    {audiometrySectionSeeMore &&
                      audiometryHistoryList.length > 0 && (
                        <div>
                          {audiometryHistoryList.slice(1).map((e, i) => {
                            return (
                              <div className="history-box" key={i}>
                                <div className="history-box-header full-border">
                                  <div className="history-box-header-fields">
                                    <div className="history-box-header-field">
                                      <span className="history-box-tag">
                                        Fecha
                                      </span>
                                      <span>
                                        {moment(e.fecha).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </span>
                                    </div>
                                    <div className="history-box-header-field">
                                      <span className="history-box-tag">
                                        Realizada por
                                      </span>
                                      <span>
                                        {e.audioprotesista !== ""
                                          ? e.audioprotesista
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <ButtonIconH
                                    text="Mostrar"
                                    small={true}
                                    onClick={() => {
                                      props.openAudiometryPanel(
                                        {
                                          id: patientId,
                                          name:
                                            currentPatient.nombre +
                                            " " +
                                            currentPatient.apellidos,
                                        },
                                        e.idAudiometria,
                                        () => {
                                          closeAudiometryPanel(props);
                                        }
                                      );
                                    }}
                                  ></ButtonIconH>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {audiometryHistoryList.length > 0 && (
                      <div className="section-footer-button">
                        <ButtonIconH
                          text={
                            !audiometrySectionSeeMore ? "Ver más" : "Ver menos"
                          }
                          small={true}
                          onClick={() => {
                            setAudiometrySectionSeeMore(
                              !audiometrySectionSeeMore
                            );
                            if (audiometrySectionSeeMore) {
                              scrollTo("[id='Audiometría']");
                            }
                          }}
                        ></ButtonIconH>
                      </div>
                    )}
                    {audiometryHistoryList.length === 0 && <div>SIN DATOS</div>}
                  </div>
                </div>
              )}
            </div>
            <div className="patient-section" id="Audífonos">
              <div className="section-header">
                <img
                  src="/images/menu-audifonos.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Audífonos</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleHearingAidsgSection(!toggleHearingAidsSection);
                  }}
                >
                  <img
                    src={
                      toggleHearingAidsSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleHearingAidsSection && (
                <div className="section-content">
                  <span className="section-todo">SECCIÓN POR DEFINIR</span>
                </div>
              )}
            </div>
            <div className="patient-section" id="Varios">
              <div className="section-header">
                <img
                  src="/images/menu-varios.svg"
                  loading="lazy"
                  alt="Icon"
                  className="section-header-icon"
                ></img>
                <span>Varios</span>
                <div className="patient-section-actions">
                  <ButtonSectionHeader
                    icon="/images/new-white.svg"
                    text="Nuevo"
                  ></ButtonSectionHeader>
                  <div className="patient-section-actions-extra">
                    <ButtonSectionHeader
                      icon="/images/update-white.svg"
                      text="Actualizar"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/printer-white.svg"
                      text="Imprimir"
                    ></ButtonSectionHeader>
                    <ButtonSectionHeader
                      icon="/images/export-white.svg"
                      text="Exportar"
                    ></ButtonSectionHeader>
                  </div>
                </div>
                <button
                  className="toggle-section-icon"
                  onClick={() => {
                    setToggleOthersSection(!toggleOthersSection);
                  }}
                >
                  <img
                    src={
                      toggleOthersSection
                        ? "/images/arrow-up.svg"
                        : "/images/arrow-down.svg"
                    }
                    loading="lazy"
                    alt="Icon"
                    className="section-header-icon"
                  ></img>
                </button>
              </div>
              {toggleOthersSection && (
                <div className="section-content">
                  <span className="section-todo">SECCIÓN POR DEFINIR</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
