import React from "react";
import Button from "../../components/Button";
import "../../css/Panel.css";
import "../../css/Patient.css";
import "../../css/PatientSearch.css";

import { exportPatientSearch } from "../../js/exports/exports";

export default function PatientSearch(props) {
  if (!props.show) {
    return <></>;
  }

  const patients = [
    {
      idPaciente: "31",
      codigo: "3",
      tieneFoto: true,
      nombre: "ALEJANDRO",
      apellidos: "CRISTO",
      preferencias: {
        preferenciaOptometria: false,
        preferenciaAudiometria: false,
        preferenciaGafasGraduadas: false,
        preferenciaGafasDeSol: true,
        preferenciaAudifonos: false,
        preferenciaLentillas: false,
        preferenciaVarios: false,
      },
      direccion: "AV/ HERNÁN CORTÉS 34, ENTREPLANTA",
      localidad: "CÁCERES",
      telefono: "600600600",
      email: "ACRISTO@EMAIL.COM",
    },
    {
      idPaciente: "7",
      codigo: "2",
      tieneFoto: true,
      nombre: "MARÍA",
      apellidos: "GARCÍA GARCÍA",
      preferencias: {
        preferenciaOptometria: false,
        preferenciaAudiometria: true,
        preferenciaGafasGraduadas: true,
        preferenciaGafasDeSol: false,
        preferenciaAudifonos: false,
        preferenciaLentillas: true,
        preferenciaVarios: false,
      },
      direccion: "CALLEJA 30",
      localidad: "MÉRIDA",
      telefono: "650650650",
      email: "MARIA@EMAIL.COM",
    },
    {
      idPaciente: "8",
      codigo: "55",
      tieneFoto: true,
      nombre: "BELÉN",
      apellidos: "HERNÁNDEZ GONZÁLEZ",
      preferencias: {
        preferenciaOptometria: false,
        preferenciaAudiometria: false,
        preferenciaGafasGraduadas: true,
        preferenciaGafasDeSol: false,
        preferenciaAudifonos: true,
        preferenciaLentillas: true,
        preferenciaVarios: false,
      },
      direccion: "CALLE DE LAS CALLES",
      localidad: "BADAJOZ",
      telefono: "601601601",
      email: "BELEN@EMAIL.COM",
    },
    {
      idPaciente: "9",
      codigo: "84",
      tieneFoto: false,
      nombre: "ANTONIO JUAN",
      apellidos: "RODRÍGUEZ MATEOS",
      preferencias: {
        preferenciaOptometria: false,
        preferenciaAudiometria: false,
        preferenciaGafasGraduadas: true,
        preferenciaGafasDeSol: false,
        preferenciaAudifonos: false,
        preferenciaLentillas: true,
        preferenciaVarios: true,
      },
      direccion: "CALLE ALMUDENA 33 BAJO IZQUIERDA",
      localidad: "SEVILLA",
      telefono: "602602602",
      email: "ANTONIO@EMAIL.COM",
    },
  ];

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Exports</span>
        <div className="patient-header-buttons"></div>
      </div>
      <div className="patient-data-content">
        <div className="action-bar">
          <div className="action-bar-resume">
            <span>Debug</span>
            <span>|</span>
            <span className="signed-text">Exports</span>
          </div>
          <button
            className="action-bar-return"
            onClick={() => {
              props.onGoBack();
            }}
          >
            <span>Volver</span>
            <img
              src="/images/return.svg"
              loading="lazy"
              alt="Icon"
              className="return-icon"
            />
          </button>
        </div>
        <div className="patient-sections">
          <div className="patient-section">
            <div className="section-content">
              <div className="section-todo-tests">
                <Button
                  title="Exportar a CSV"
                  onPress={() =>
                    exportPatientSearch(
                      patients,
                      "OPTICÁCERES S.L.",
                      "csv",
                      "patient-search.csv"
                    )
                  }
                />
                <span style={{ marginRight: 10 }} />
                <Button
                  title="Exportar a Excel"
                  onPress={() =>
                    exportPatientSearch(
                      patients,
                      "OPTICÁCERES S.L.",
                      "excel",
                      "patient-search.xlsx"
                    )
                  }
                />
                <span style={{ marginRight: 10 }} />
                <Button
                  title="Exportar a PDF"
                  onPress={() =>
                    exportPatientSearch(
                      patients,
                      "OPTICÁCERES S.L.",
                      "pdf",
                      "patient-search.pdf"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
