import React from "react";
import { useEffect, useState, useRef } from "react";
import { post } from "../js/post";
import { userData, inputOnEnter, selectedOptic } from "../js/utils";
import "../css/InputSearch.css";

export default function InputSearch({
  id,
  size,
  op,
  onSelect,
  link,
  onClickLink,
  tabIndex,
}) {
  const [optionList, setOptionList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [contactPanel, setContactPanel] = useState(false);
  const dropdownRef = useRef(null);

  const handleSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 0) {
      searchContact(event.target.value);
    } else {
      setContactList([]);
    }
  };

  const searchContact = async (term) => {
    const response = await post("BuscarPersonaContacto.php", {
      token: userData.token,
      optica: 1,
      search: term,
    });

    let responseAux = response;
    responseAux = await Promise.all(
      responseAux.map(async (e) => {
        e.fotoPaciente = await getPatientPicture(e.idPaciente);
        return e;
      })
    );

    setContactPanel(responseAux.length > 0);
    setContactList(responseAux);
  };

  const getPatientPicture = async (idPaciente) => {
    const response = await post("ObtenerFotoPaciente.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente,
    });
    return response.foto ? response.foto : "/images/avatar.svg";
  };

  const getComboboxSize = () => {
    let additionalClass = "";
    switch (size) {
      case 1:
        additionalClass = "combobox-small";
        break;
      case 2:
        additionalClass = "combobox-medium";
        break;
      case 3:
        additionalClass = "combobox-large";
        break;
      default:
        break;
    }
    return additionalClass;
  };

  useEffect(() => {
    setSearchTerm(op);
  }, [op]);

  useEffect(() => {
    const refineOptionList = () => {
      const list =
        contactList.length > 0
          ? contactList.map((e, i) => {
              return (
                <div
                  className="dropdown-op"
                  key={i}
                  onClick={() => {
                    onSelect(e);
                    setSearchTerm(e.nombre + " " + e.apellidos);
                    setContactList([]);
                  }}
                >
                  <img
                    src={e.fotoPaciente}
                    loading="lazy"
                    alt="Patient Img"
                    className="op-img"
                  />
                  <div className="op-info">
                    <span>{`${e.nombre} ${e.apellidos}`}</span>
                    {e.localidad && (
                      <span className="sub-info">{e.localidad}</span>
                    )}
                    {e.email && e.telefono && (
                      <span className="sub-info">{`${e.email} | ${e.telefono}`}</span>
                    )}
                    {!e.email && e.telefono && (
                      <span className="sub-info">{e.telefono}</span>
                    )}
                    {e.email && !e.telefono && (
                      <span className="sub-info">{e.email}</span>
                    )}
                  </div>
                </div>
              );
            })
          : [
              <div className="dropdown-op" key={0}>
                <span className="">No options</span>
              </div>,
            ];
      setOptionList(list);
    };
    refineOptionList();
  }, [contactList, onSelect]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setContactPanel(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`input-search combobox ${getComboboxSize()}`}
      ref={dropdownRef}
    >
      <div
        className={`dropdown ${contactList.length > 0 ? "dropdown-open" : ""}`}
      >
        <input
          id={id}
          tabIndex={tabIndex}
          type="text"
          value={searchTerm}
          className="input-searchterm"
          onChange={handleSearchTerm}
          onKeyUp={(e) => inputOnEnter(e)}
        ></input>
        <div className="downicon">
          <img
            src={
              contactList.length > 0
                ? "/images/close-dark.svg"
                : "/images/search-blue.svg"
            }
            loading="lazy"
            alt="Panel Icon"
            className={`vectors-wrapper-11 ${
              contactList.length > 0 ? "button-op" : ""
            }`}
            onClick={() => {
              if (contactList.length > 0) {
                setContactList([]);
                setContactPanel(false);
                setSearchTerm("");
              }
            }}
          />
        </div>
        {link && (
          <img
            className="button-op"
            alt="Icon"
            src="/images/link.svg"
            onClick={() => {
              onClickLink();
            }}
          ></img>
        )}
      </div>
      {contactList.length > 0 && contactPanel === true && (
        <div className="dropdown-options">{optionList}</div>
      )}
    </div>
  );
}
