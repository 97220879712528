import React, { useEffect, useState } from "react";

import ConfigList from "../components/ConfigList";

import { post } from "../utils/api";

import { userData } from "../../js/utils";

let json_campanias = [];
let json_recomendados = [];
let json_aficiones = [];
let json_ametropias = [];

const tokenSesion = userData ? userData.token : "";

export default function PredeterminadosPaciente(props) {
  const [campanias, setCampanias] = useState(json_campanias);
  const [recomendoPor, setRecomendadoPor] = useState(json_recomendados);
  const [aficiones, setAficiones] = useState(json_aficiones);
  const [ametropias, setAmetropias] = useState(json_ametropias);

  const eliminarCampania = async (idCamp) => {
    props.createPopupQuestion(
      "¿Confirma qué desea eliminar el elemento actual?",
      () => {
        let json_delete = {
          token: tokenSesion,
          id: idCamp,
        };

        post("MotivosVenta/EliminarMotivoVenta.php", json_delete);

        json_campanias = json_campanias.filter((e) => e.id !== idCamp);
        setCampanias(json_campanias);
        props.removePopupQuestion();
      },
      () => props.removePopupQuestion()
    );
  };

  const eliminarRecomendadoPor = async (idRec) => {
    props.createPopupQuestion(
      "¿Confirma qué desea eliminar el elemento actual?",
      () => {
        let json_delete = {
          token: tokenSesion,
          id: idRec,
        };

        post("RecomendadosPor/EliminarRecomendadoPor.php", json_delete);

        json_recomendados = json_recomendados.filter((e) => e.id !== idRec);
        setRecomendadoPor(json_recomendados);
        props.removePopupQuestion();
      },
      () => props.removePopupQuestion()
    );
  };

  const eliminarAficion = async (idAfi) => {
    props.createPopupQuestion(
      "¿Confirma qué desea eliminar el elemento actual?",
      () => {
        let json_delete = {
          token: tokenSesion,
          id: idAfi,
        };

        post("Aficiones/EliminarAficiones.php", json_delete);

        json_aficiones = json_aficiones.filter((e) => e.id !== idAfi);
        setAficiones(json_aficiones);
        props.removePopupQuestion();
      },
      () => props.removePopupQuestion()
    );
  };

  const eliminarAmetropia = async (idAme) => {
    props.createPopupQuestion(
      "¿Confirma qué desea eliminar el elemento actual?",
      () => {
        let json_delete = {
          token: tokenSesion,
          id: idAme,
        };

        post("Ametropias/EliminarAmetropias.php", json_delete);

        json_ametropias = json_ametropias.filter((e) => e.id !== idAme);
        setAmetropias(json_ametropias);
        props.removePopupQuestion();
      },
      () => props.removePopupQuestion()
    );
  };

  const addCampania = async (camp) => {
    if (camp.trim() !== "") {
      let json_insert = {
        token: tokenSesion,
        descripcion: camp,
      };

      let newId = -1;

      await post("MotivosVenta/InsertarMotivoVenta.php", json_insert).then(
        (data) => {
          newId = data.id;
        }
      );

      if (newId !== -1) {
        let newCamp = { id: newId, descripcion: camp };
        json_campanias.push(newCamp);
        setCampanias(Array.from(json_campanias));
      }
    }
  };

  const addRecomendadoPor = async (recPor) => {
    let json_insert = {
      token: tokenSesion,
      descripcion: recPor,
    };

    let newId = -1;

    await post("RecomendadosPor/InsertarRecomendadoPor.php", json_insert).then(
      (data) => {
        newId = data.id;
      }
    );

    if (newId !== -1) {
      let newRec = { id: newId, descripcion: recPor };
      json_recomendados.push(newRec);
      setRecomendadoPor(Array.from(json_recomendados));
    }
  };

  const addAficion = async (afic) => {
    let json_insert = {
      token: tokenSesion,
      aficion: afic,
    };

    let newId = -1;

    await post("Aficiones/InsertarAficiones.php", json_insert).then((data) => {
      newId = data.id;
    });

    if (newId !== -1) {
      let newAfic = { id: newId, descripcion: afic };
      json_aficiones.push(newAfic);
      setAficiones(Array.from(json_aficiones));
    }
  };

  const addAmetropia = async (amet) => {
    let json_insert = {
      token: tokenSesion,
      ametropia: amet,
    };

    let newId = -1;

    await post("Ametropias/InsertarAmetropias.php", json_insert).then(
      (data) => {
        newId = data.id;
      }
    );

    if (newId !== -1) {
      let newAmet = { id: newId, descripcion: amet };
      json_ametropias.push(newAmet);
      setAmetropias(Array.from(json_ametropias));
    }
  };

  useEffect(() => {
    async function obtenerCampanias() {
      json_campanias = [];
      let jsonToken = { token: tokenSesion };
      json_campanias = await post(
        "MotivosVenta/ListarMotivosVenta.php",
        jsonToken
      );
      console.log("------------HOLA");
      console.log(json_campanias);
      setCampanias(json_campanias);
    }
    obtenerCampanias();
  }, []);

  useEffect(() => {
    async function obtenerRecomendadoPor() {
      json_recomendados = [];
      let jsonToken = { token: tokenSesion };
      json_recomendados = await post(
        "RecomendadosPor/ListarRecomendadosPor.php",
        jsonToken
      );
      setRecomendadoPor(json_recomendados);
    }
    obtenerRecomendadoPor();
  }, []);

  useEffect(() => {
    async function obtenerAficiones() {
      json_aficiones = [];
      let jsonToken = { token: tokenSesion };
      json_aficiones = await post("Aficiones/ListarAficiones.php", jsonToken);
      setAficiones(json_aficiones);
    }
    obtenerAficiones();
  }, []);

  useEffect(() => {
    async function obtenerAmetropias() {
      json_ametropias = [];
      let jsonToken = { token: tokenSesion };
      json_ametropias = await post(
        "Ametropias/ListarAmetropias.php",
        jsonToken
      );
      setAmetropias(json_ametropias);
    }
    obtenerAmetropias();
  }, []);

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="settings-body-container">
      <div className="cfg-section-fill">
        <ConfigList
          sectionDescription="Campañas"
          elements={campanias}
          deleteFunction={eliminarCampania}
          newText="Nueva campaña"
          insertFunction={addCampania}
        />
        <ConfigList
          sectionDescription="Recomendados"
          elements={recomendoPor}
          deleteFunction={eliminarRecomendadoPor}
          newText="Nuevo recomendado"
          insertFunction={addRecomendadoPor}
        />
        <ConfigList
          sectionDescription="Aficiones"
          elements={aficiones}
          deleteFunction={eliminarAficion}
          newText="Nueva afición"
          insertFunction={addAficion}
        />
        <ConfigList
          sectionDescription="Ametropías"
          elements={ametropias}
          deleteFunction={eliminarAmetropia}
          newText="Nueva ametropía"
          insertFunction={addAmetropia}
        />
      </div>
    </div>
  );
}
