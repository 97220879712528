import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import ButtonIconH from "./ButtonIconH";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  accommodativeStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
  focusElement,
} from "../js/utils";
import moment from "moment";

export default function AdvancedOptometryAccommodative(props) {
  const [currentAccommodative, setCurrentAccommodative] = useState(
    accommodativeStructure
  );
  const [currentAccommodativeEdit, setCurrentAccommodativeEdit] = useState(
    accommodativeStructure
  );
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [accommodativeId, setAccommodativeId] = useState(props.accommodativeId);
  const [toggleACA, setToggleACA] = useState(false);

  //console.log("*****************");
  //console.log("selectedDate: ", selectedDate);
  //console.log("currentAccommodative: ", currentAccommodative);
  console.log("currentAccommodativeEdit: ", currentAccommodativeEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getAccommodativeHistory = async () => {
    const response = await post("ListarFechasHistoricoAcomodativo.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idAcomodativo === accommodativeId;
      });
      if (found) {
        setSelectedDate({
          id: found.idAcomodativo,
          text: found.fecha,
        });
        setAccommodativeId(found.idAcomodativo);
      }
    }
    setDateHistoryList(response);
  };

  const getAccommodative = async (accommodativeId) => {
    const response = await post("ObtenerAcomodativo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAcomodativo: accommodativeId,
    });

    setCurrentAccommodativeEdit({
      ...response,
      idPaciente: props.patientId,
      idAcomodativo: accommodativeId,
    });
    setCurrentAccommodative({
      ...response,
      idPaciente: props.patientId,
      idAcomodativo: accommodativeId,
    });
  };

  const resetAccommodativeState = () => {
    setCurrentAccommodativeEdit({
      ...accommodativeStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentAccommodative({
      ...accommodativeStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setAccommodativeId(-1);
    props.saveLastAccommodativeIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveAccommodative = async () => {
    const response = await post("GuardarAcomodativo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAcomodativo: accommodativeId,
      idPaciente: props.patientId,
      ...currentAccommodativeEdit,
    });
    setSelectedDate({
      id: response.idAcomodativo,
      text: currentAccommodativeEdit.fecha,
    });
    setAccommodativeId(response.idAcomodativo);
    setCurrentAccommodative(currentAccommodativeEdit);
    props.saveLastAccommodativeIdVisited(response.idAcomodativo);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteAccommodative = async () => {
    await post("EliminarAcomodativo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAcomodativo: accommodativeId,
      idUsuario: userData.id,
    });
  };

  const selectAccommodativeAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoAcomodativo.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idAcomodativo,
        text: response[0].fecha,
      });
      setAccommodativeId(response[0].idAcomodativo);
      setDateHistoryList(response);
      await getAccommodative(response[0].idAcomodativo);
      props.saveLastAccommodativeIdVisited(response[0].idAcomodativo);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setAccommodativeId(-1);
      props.saveLastAccommodativeIdVisited(-1);
      setCurrentAccommodative(accommodativeStructure);
      setCurrentAccommodativeEdit(accommodativeStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (accommodativeId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idAcomodativo,
          text: dateHistoryList[0].fecha,
        });
        setAccommodativeId(dateHistoryList[0].idAcomodativo);
        props.saveLastAccommodativeIdVisited(dateHistoryList[0].idAcomodativo);
        await getAccommodative(dateHistoryList[0].idAcomodativo);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentAccommodativeEdit(currentAccommodative);
      }
    } else {
      setCurrentAccommodativeEdit(currentAccommodative);
    }
  };

  const checkADAAVA = () => {
    if (
      currentAccommodativeEdit.heteroforia === "" &&
      currentAccommodativeEdit.gradiente === ""
    ) {
      props.createPopupQuestion(
        `No es posible hallar el AV/A. Es necesario un valor para AC/A (Heteroforia o Gradiente).`,
        async () => {
          props.removePopupQuestion();
          focusElement(70);
        },
        null
      );
    } else if (!toggleACA && currentAccommodativeEdit.ada === "") {
      props.createPopupQuestion(
        `No es posible hallar el AV/A. Es necesario un valor para AD/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(78);
        },
        null
      );
    } else if (
      (currentAccommodativeEdit.heteroforia !== "" &&
        currentAccommodativeEdit.gradiente === "") ||
      (currentAccommodativeEdit.heteroforia === "" &&
        currentAccommodativeEdit.gradiente !== "")
    ) {
      // REVISAR ESTE CÁLCULO.
      setCurrentAccommodativeEdit({
        ...currentAccommodativeEdit,
        ava:
          currentAccommodativeEdit.heteroforia !== ""
            ? (parseFloat(currentAccommodativeEdit.heteroforia) +
                parseFloat(currentAccommodativeEdit.ada)) /
              2
            : (parseFloat(currentAccommodativeEdit.gradiente) +
                parseFloat(currentAccommodativeEdit.ada)) /
              2,
      });
    } else if (
      currentAccommodativeEdit.heteroforia !== "" &&
      currentAccommodativeEdit.gradiente !== ""
    ) {
      setToggleACA(!toggleACA);
    }
  };

  const calculateHeterophoria = () => {
    if (currentAccommodativeEdit.cr === "") {
      props.createPopupQuestion(
        `La convergencia requerida CR no puede estar vacía para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(70);
        },
        null
      );
    } else if (currentAccommodativeEdit.fl === "") {
      props.createPopupQuestion(
        `La foria de lejos FL no puede estar vacía para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(71);
        },
        null
      );
    } else if (currentAccommodativeEdit.fc === "") {
      props.createPopupQuestion(
        `La foria de cerca FC no puede estar vacía para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(72);
        },
        null
      );
    }
    if (
      currentAccommodativeEdit.cr &&
      currentAccommodativeEdit.fl &&
      currentAccommodativeEdit.fc
    ) {
      setCurrentAccommodativeEdit({
        ...currentAccommodativeEdit,
        heteroforia:
          (parseFloat(currentAccommodativeEdit.cr) -
            parseFloat(currentAccommodativeEdit.fl) +
            parseFloat(currentAccommodativeEdit.fc)) /
          2.5,
      });
    }
  };

  const calculateGradient = () => {
    if (currentAccommodativeEdit.fhvp === "") {
      props.createPopupQuestion(
        `FHvp no puede estar vacío para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(74);
        },
        null
      );
    } else if (currentAccommodativeEdit.fivp === "") {
      props.createPopupQuestion(
        `FIvp no puede estar vacío para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(75);
        },
        null
      );
    } else if (currentAccommodativeEdit.l === "") {
      props.createPopupQuestion(
        `L no puede estar vacío para calcular AC/A.`,
        async () => {
          props.removePopupQuestion();
          focusElement(76);
        },
        null
      );
    }
    if (
      currentAccommodativeEdit.fhvp &&
      currentAccommodativeEdit.fivp &&
      currentAccommodativeEdit.l
    ) {
      setCurrentAccommodativeEdit({
        ...currentAccommodativeEdit,
        gradiente:
          (parseFloat(currentAccommodativeEdit.fhvp) -
            parseFloat(currentAccommodativeEdit.fivp)) /
          parseFloat(currentAccommodativeEdit.l),
      });
    }
  };

  useEffect(() => {
    getAccommodativeHistory();
    getOptometristList();
    if (accommodativeId !== -1) {
      getAccommodative(accommodativeId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAccommodativeEdit({
        ...currentAccommodativeEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAccommodative({
        ...currentAccommodative,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [accommodativeId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentAccommodative, currentAccommodativeEdit),
      dataToSave: currentAccommodativeEdit,
    });
  }, [currentAccommodativeEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentAccommodativeEdit.diagnostico) {
      setCurrentAccommodativeEdit({
        ...currentAccommodativeEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentAccommodativeEdit.fecha}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idAcomodativo,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idAcomodativo,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={accommodativeId === -1}
                onChange={async (e) => {
                  setCurrentAccommodativeEdit(accommodativeStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setAccommodativeId(e.id);
                  props.saveLastAccommodativeIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentAccommodativeEdit.optometrista}
                onChange={(e) => {
                  setCurrentAccommodativeEdit({
                    ...currentAccommodativeEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              accommodativeId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (accommodativeId !== -1) {
                if (
                  !areObjectsEqual(
                    currentAccommodative,
                    currentAccommodativeEdit
                  )
                ) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveAccommodative();
                      props.removePopupQuestion();
                      resetAccommodativeState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetAccommodativeState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetAccommodativeState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              accommodativeStructure.fecha = currentAccommodativeEdit.fecha;
              if (
                areObjectsEqual(
                  currentAccommodativeEdit,
                  accommodativeStructure
                )
              ) {
                props.createPopupQuestion(
                  `La ficha de acomodativo actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveAccommodative();
                await getAccommodativeHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentAccommodative, currentAccommodativeEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(
                  currentAccommodative,
                  currentAccommodativeEdit
                ) ||
                accommodativeId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              accommodativeId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (accommodativeId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de acomodativo?`,
                  async () => {
                    await getAccommodative(accommodativeId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              accommodativeId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (accommodativeId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de acomodativo?`,
                  async () => {
                    await deleteAccommodative();
                    await getAccommodativeHistory();
                    await selectAccommodativeAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              accommodativeId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (accommodativeId !== -1) {
                props.openDiagnosisPopup(currentAccommodativeEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              accommodativeId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (accommodativeId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-30">
          <div className="opt-table-container">
            <div className="opt-table-title">Amplitud de Acomodación (AA)</div>
            <div className="opt-table-content grid-4">
              <div
                className="cell cell-label"
                onMouseEnter={(event) => {
                  const data = {
                    top: event.clientY,
                    left: event.clientX,
                    content: (
                      <div className="opt-table-tooltip grid-5">
                        <div className="cell combined-cells-5 cell-start-content">
                          AAmedia = 18 -1/3 (edad) ± 2
                        </div>
                      </div>
                    ),
                  };
                  props.openTooltip(data);
                }}
                onMouseLeave={() => {
                  props.closeTooltip();
                }}
              >
                AA Media
              </div>
              <div className="cell">
                <InputData
                  tabIndex={54}
                  type="type-float2"
                  size={0}
                  input={currentAccommodativeEdit.amplitud_acomodacion_media}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      amplitud_acomodacion_media: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-2"></div>
              <div
                className="cell cell-label"
                onMouseEnter={(event) => {
                  const data = {
                    top: event.clientY,
                    left: event.clientX,
                    content: (
                      <div className="opt-table-tooltip grid-5">
                        <div className="cell combined-cells-5 cell-start-content">
                          AAmínima = 15 - 1/4 (edad)
                        </div>
                      </div>
                    ),
                  };
                  props.openTooltip(data);
                }}
                onMouseLeave={() => {
                  props.closeTooltip();
                }}
              >
                AA Mínima
              </div>
              <div className="cell">
                <InputData
                  tabIndex={55}
                  type="type-float2"
                  size={0}
                  input={currentAccommodativeEdit.amplitud_acomodacion_minima}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      amplitud_acomodacion_minima: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-2"></div>
              <div className="cell combined-cells-4"></div>
              <div className="cell combined-cells-4 opt-table-subtitle cell-start-content">
                Método de Acercamiento
              </div>
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={56}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.metodo_acercamiento_od}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      metodo_acercamiento_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados Amplitud de Acomodación:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Dentro de la AAmedia
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Dentro de la AAmínima
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={57}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.metodo_acercamiento_oi}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      metodo_acercamiento_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell"></div>
              <div className="cell combined-cells-4"></div>
              <div className="cell combined-cells-4 opt-table-subtitle cell-start-content">
                Método de Lentes Negativas
              </div>
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={58}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.metodo_lentes_negativas_od}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      metodo_lentes_negativas_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={59}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.metodo_lentes_negativas_oi}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      metodo_lentes_negativas_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell"></div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">
              Flexibilidad Acomodativa Binocular (FAB)
            </div>
            <div className="opt-table-content grid-4">
              <div className="cell cell-label">AO</div>
              <div className="cell">
                <InputData
                  tabIndex={60}
                  type="type-integer"
                  size={0}
                  input={currentAccommodativeEdit.fab}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      fab: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">cpm</div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados Felixibilidad Acomodativa:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            De 6 a 12 años: 4 ± 2.5 cpm
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            De 13 a 40 años: 8 ± 5 cpm
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">
              Flexibilidad Acomodativa Monocular (FAM)
            </div>
            <div className="opt-table-content grid-4">
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={61}
                  type="type-integer"
                  size={0}
                  input={currentAccommodativeEdit.famod}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      famod: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">cpm</div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={62}
                  type="type-integer"
                  size={0}
                  input={currentAccommodativeEdit.famoi}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      famoi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">cpm</div>
              <div className="cell"></div>
              <div className="cell combined-cells-4"></div>
              <div className="cell combined-cells-4 opt-table-subtitle cell-start-content">
                ARN | ARP
              </div>
              <div className="cell cell-label">ARN</div>
              <div className="cell">
                <InputData
                  tabIndex={63}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.arn}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      arn: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados ARN / ARP:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            ARN: +2,00 ± 0,50 D
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            ARP: -2,25 ± 1,00 D
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label">ARP</div>
              <div className="cell">
                <InputData
                  tabIndex={64}
                  type="type-optofloat"
                  size={0}
                  input={currentAccommodativeEdit.arp}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      arp: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">D</div>
              <div className="cell"></div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-70">
          <div className="double-col">
            <div className="col-extra w-50">
              <div className="opt-table-container">
                <div className="opt-table-title">Respuesta Acomodativa</div>
                <div className="opt-table-content grid-4">
                  <div className="cell combined-cells-4 opt-table-subtitle cell-start-content">
                    Retinoscopía MEM
                  </div>
                  <div className="cell cell-label">OD</div>
                  <div className="cell">
                    <InputData
                      tabIndex={65}
                      type="type-optofloat"
                      size={0}
                      input={currentAccommodativeEdit.memod}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          memod: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">D</div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                Retinoscopía MEM:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                De +0,25 a +0,50 ± 0,25 D
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                  <div className="cell cell-label">OI</div>
                  <div className="cell">
                    <InputData
                      tabIndex={66}
                      type="type-optofloat"
                      size={0}
                      input={currentAccommodativeEdit.memoi}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          memoi: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">D</div>
                  <div className="cell"></div>
                  <div className="cell combined-cells-4"></div>
                  <div className="cell combined-cells-4 opt-table-subtitle cell-start-content">
                    Retinoscopía NOTT
                  </div>
                  <div className="cell cell-label">OD</div>
                  <div className="cell">
                    <InputData
                      tabIndex={67}
                      type="type-optofloat"
                      size={0}
                      input={currentAccommodativeEdit.nottod}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          nottod: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">D</div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                Retinoscopía NOTT:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                De +0,25 a +0,50 ± 0,50 D
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                  <div className="cell cell-label">OI</div>
                  <div className="cell">
                    <InputData
                      tabIndex={68}
                      type="type-optofloat"
                      size={0}
                      input={currentAccommodativeEdit.nottoi}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          nottoi: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">D</div>
                  <div className="cell"></div>
                </div>
              </div>
              <div className="opt-table-container">
                <div className="opt-table-title">
                  Cilindros Cruzados Fusionados (CCF)
                </div>
                <div className="opt-table-content grid-4">
                  <div className="cell cell-label">AO</div>
                  <div className="cell">
                    <InputData
                      tabIndex={69}
                      type="type-optofloat"
                      size={0}
                      input={currentAccommodativeEdit.ccf}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          ccf: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label cell-start-content">D</div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                Cilindros Cruzados CCF:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                +0,50 ± 0,50 D
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-extra w-50">
              <div className="opt-table-container">
                <div className="opt-table-title">
                  Relación Acomodación/Vergencias AC/A
                </div>
                <div className="opt-table-content grid-7">
                  <div className="cell opt-table-subtitle combined-cells-7 cell-start-content">
                    Método de la Heteroforia
                  </div>
                  <div className="cell cell-label">
                    <span
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                FL = Foria Horizontal Lejos VON GRAEFE
                              </div>
                              <div className="cell combined-cells-5 cell-start-content">
                                FH = Foria Horizontal Cerca VON GRAEFE
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    >
                      AC/A =
                    </span>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={70}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.cr}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          cr: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={71}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.fl}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          fl: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={72}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.fc}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          fc: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label">2,5/2,5</div>
                  <div className="cell">
                    <InputData
                      tabIndex={73}
                      maxLength={10}
                      type="type-optoacomodativo"
                      size={0}
                      input={currentAccommodativeEdit.heteroforia}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          heteroforia: e,
                        });
                      }}
                      considerFocus={true}
                      onFocus={() => {
                        calculateHeterophoria();
                      }}
                    ></InputData>
                  </div>
                  <div className="cell">
                    <img
                      src="/images/info-blue.svg"
                      loading="lazy"
                      alt="Icon"
                      className="cell-icon"
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                AC/A:
                              </div>
                              <div className="cell cell-start-content combined-cells-5"></div>
                              <div className="cell cell-start-content combined-cells-5">
                                4/1 ± 2 Δ/D
                              </div>
                              <div className="cell cell-start-content combined-cells-5">
                                Los datos obtenidos por el método de la
                                Heteroforia serán normalmente mayor que los del
                                Gradiente.
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    ></img>
                  </div>
                  <div className="cell"></div>
                  <div
                    className="cell cell-label combined-cells-3"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: (
                          <div className="opt-table-tooltip grid-5">
                            <div className="cell combined-cells-5 cell-start-content">
                              CR = DI(cm)/D(0,4m)
                            </div>
                          </div>
                        ),
                        style: "combined-cells-3",
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  >
                    (CR - FL + FC) / 2,5
                  </div>
                  <div className="cell combined-cells-3"></div>
                  <div className="cell combined-cells-7"></div>
                  <div className="cell opt-table-subtitle combined-cells-7 cell-start-content">
                    Método del Gradiente
                  </div>
                  <div className="cell cell-label">
                    <span
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="opt-table-tooltip grid-5">
                              <div className="cell combined-cells-5 cell-start-content">
                                FHvp = Foria Habitual Cerca VON GRAEFE
                              </div>
                              <div className="cell combined-cells-5 cell-start-content">
                                FIvp = Foria Inducida Cerca VON GRAEFE
                              </div>
                              <div className="cell combined-cells-5 cell-start-content">
                                L = Lente Adición Negativa
                              </div>
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    >
                      AC/A =
                    </span>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={74}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.fhvp}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          fhvp: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={75}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.fivp}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          fivp: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell">
                    <InputData
                      tabIndex={76}
                      type="type-float2"
                      size={0}
                      input={currentAccommodativeEdit.l}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          l: e,
                        });
                      }}
                    ></InputData>
                  </div>
                  <div className="cell cell-label">L/L</div>
                  <div className="cell">
                    <InputData
                      tabIndex={77}
                      maxLength={10}
                      type="type-optoacomodativo"
                      size={0}
                      input={currentAccommodativeEdit.gradiente}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          gradiente: e,
                        });
                      }}
                      onFocus={() => {
                        calculateGradient();
                      }}
                      considerFocus={true}
                    ></InputData>
                  </div>
                  <div className="cell"></div>
                  <div className="cell"></div>
                  <div className="cell cell-label combined-cells-3">
                    (FHvp - FIvp) / L
                  </div>
                  <div className="cell combined-cells-3"></div>
                  <div className="cell combined-cells-4"></div>
                  <div className="cell combined-cells-2 opt-table-subtitle">
                    {toggleACA && <span>Seleccionar valor AC/A</span>}
                  </div>
                  <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                    AD/A | AV/A
                  </div>
                  <div className="cell combined-cells-2">
                    {toggleACA && (
                      <div className="popup-buttons">
                        <ButtonIconH
                          text="Heteroforia"
                          small={true}
                          onClick={() => {
                            setCurrentAccommodativeEdit({
                              ...currentAccommodativeEdit,
                              ava:
                                (parseFloat(
                                  currentAccommodativeEdit.heteroforia
                                ) +
                                  parseFloat(currentAccommodativeEdit.ada)) /
                                2,
                            });
                            setToggleACA(false);
                          }}
                        ></ButtonIconH>
                        <ButtonIconH
                          text="Gradiente"
                          small={true}
                          onClick={() => {
                            setCurrentAccommodativeEdit({
                              ...currentAccommodativeEdit,
                              ava:
                                (parseFloat(
                                  currentAccommodativeEdit.gradiente
                                ) +
                                  parseFloat(currentAccommodativeEdit.ada)) /
                                2,
                            });
                            setToggleACA(false);
                          }}
                        ></ButtonIconH>
                      </div>
                    )}
                    {!toggleACA && (
                      <button
                        className="cell-icon"
                        onClick={() => {
                          checkADAAVA();
                        }}
                      >
                        <img
                          src="/images/extract-blue.svg"
                          loading="lazy"
                          alt="Icon"
                          className="cell-icon"
                          onMouseEnter={(event) => {
                            const data = {
                              top: event.clientY,
                              left: event.clientX,
                              content: (
                                <div className="cell">Calcular AV/A</div>
                              ),
                            };
                            props.openTooltip(data);
                          }}
                          onMouseLeave={() => {
                            props.closeTooltip();
                          }}
                        ></img>
                      </button>
                    )}
                  </div>
                  <div className="cell"></div>
                  <div className="cell cell-label">
                    <span
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="cell">
                              Diferencia entre Foria de Cerca y Foria de Cerca
                              Inducida con +1.00 D
                            </div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    >
                      AD/A
                    </span>
                  </div>
                  <div className="cell combined-cells-2">
                    <InputData
                      tabIndex={78}
                      maxLength={10}
                      type="type-optoacomodativo"
                      size={0}
                      input={currentAccommodativeEdit.ada}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          ada: e,
                        });
                      }}
                      considerFocus={true}
                    ></InputData>
                  </div>
                  <div className="cell cell-label">
                    <span
                      onMouseEnter={(event) => {
                        const data = {
                          top: event.clientY,
                          left: event.clientX,
                          content: (
                            <div className="cell">AV/A = (AC/A + AD/A) / 2</div>
                          ),
                        };
                        props.openTooltip(data);
                      }}
                      onMouseLeave={() => {
                        props.closeTooltip();
                      }}
                    >
                      AV/A
                    </span>
                  </div>
                  <div className="cell combined-cells-2">
                    <InputData
                      tabIndex={79}
                      maxLength={10}
                      type="type-optoacomodativo"
                      size={0}
                      input={currentAccommodativeEdit.ava}
                      onChange={(e) => {
                        setCurrentAccommodativeEdit({
                          ...currentAccommodativeEdit,
                          ava: e,
                        });
                      }}
                      considerFocus={true}
                    ></InputData>
                  </div>
                  <div className="cell combined-cells-3"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="opt-table-container opt-table-container-npt">
            <div className="opt-table-content grid-6">
              <div className="cell opt-table-subtitle combined-cells-6 cell-start-content">
                Observaciones
              </div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={80}
                  maxLength={400}
                  type="type-full"
                  size={0}
                  input={currentAccommodativeEdit.observaciones}
                  onChange={(e) => {
                    setCurrentAccommodativeEdit({
                      ...currentAccommodativeEdit,
                      observaciones: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
