import React from "react";

export default function Tab(props) {
  return (
    <div
      className={`settings-tab ${
        props.selected ? "settings-tab-selected" : ""
      }`}
      onClick={props.onClick}
      key={props.key}
    >
      <img src={props.icon} alt="img" />
      {props.text}
    </div>
  );
}
