import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import {
  refractiveStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
} from "../js/utils";
import { RETINOSCOPYLIST } from "../js/utils";
import { post } from "../js/post";
import moment from "moment";
import "../css/BasicOptometry.css";
import DropdownFilter from "./DropdownFilter";

export default function AdvancedOptometryRefractive(props) {
  const [currentRefractive, setCurrentRefractive] =
    useState(refractiveStructure);
  const [currentRefractiveEdit, setCurrentRefractiveEdit] =
    useState(refractiveStructure);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [optometristList, setOptometristList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [refractiveId, setRefractiveId] = useState(props.refractiveId);

  console.log("currentRefractiveEdit: ", currentRefractiveEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getRefractiveHistory = async () => {
    const response = await post("ListarFechasHistoricoRefractivo.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idRefractivo === refractiveId;
      });
      if (found) {
        setSelectedDate({
          id: found.idRefractivo,
          text: found.fecha,
        });
        setRefractiveId(found.idRefractivo);
      }
    }
    setDateHistoryList(response);
  };

  const getRefractive = async (refractiveId) => {
    const response = await post("ObtenerRefractivo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idRefractivo: refractiveId,
    });

    setCurrentRefractiveEdit({
      ...response,
      idPaciente: props.patientId,
      idRefractivo: refractiveId,
    });
    setCurrentRefractive({
      ...response,
      idPaciente: props.patientId,
      idRefractivo: refractiveId,
    });
  };

  const saveRefractive = async () => {
    const response = await post("GuardarRefractivo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idRefractivo: refractiveId,
      idPaciente: props.patientId,
      ...currentRefractiveEdit,
    });
    setSelectedDate({
      id: response.idRefractivo,
      text: currentRefractiveEdit.fecha,
    });
    setRefractiveId(response.idRefractivo);
    setCurrentRefractive(currentRefractiveEdit);
    props.saveLastRefractiveIdVisited(response.idRefractivo);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteRefractive = async () => {
    await post("EliminarRefractivo.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idRefractivo: refractiveId,
      idUsuario: userData.id,
    });
  };

  const selectRefractiveAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoRefractivo.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idRefractivo,
        text: response[0].fecha,
      });
      setRefractiveId(response[0].idRefractivo);
      setDateHistoryList(response);
      await getRefractive(response[0].idRefractivo);
      props.saveLastRefractiveIdVisited(response[0].idRefractivo);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setRefractiveId(-1);
      props.saveLastRefractiveIdVisited(-1);
      setCurrentRefractive(refractiveStructure);
      setCurrentRefractiveEdit(refractiveStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (refractiveId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idRefractivo,
          text: dateHistoryList[0].fecha,
        });
        setRefractiveId(dateHistoryList[0].idRefractivo);
        props.saveLastRefractiveIdVisited(dateHistoryList[0].idRefractivo);
        await getRefractive(dateHistoryList[0].idRefractivo);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentRefractiveEdit(currentRefractive);
      }
    } else {
      setCurrentRefractiveEdit(currentRefractive);
    }
  };

  const resetRefractiveState = () => {
    setCurrentRefractiveEdit({
      ...refractiveStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentRefractive({
      ...refractiveStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setRefractiveId(-1);
    props.saveLastRefractiveIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  useEffect(() => {
    getRefractiveHistory();
    getOptometristList();
    if (refractiveId !== -1) {
      getRefractive(refractiveId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentRefractiveEdit({
        ...currentRefractiveEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentRefractive({
        ...currentRefractive,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [refractiveId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentRefractive, currentRefractiveEdit),
      dataToSave: currentRefractiveEdit,
    });
  }, [currentRefractiveEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentRefractiveEdit.diagnostico) {
      setCurrentRefractiveEdit({
        ...currentRefractiveEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentRefractiveEdit.fecha}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idRefractivo,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idRefractivo,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={refractiveId === -1}
                onChange={async (e) => {
                  setCurrentRefractiveEdit(refractiveStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setRefractiveId(e.id);
                  props.saveLastRefractiveIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentRefractiveEdit.optometrista}
                onChange={(e) => {
                  setCurrentRefractiveEdit({
                    ...currentRefractiveEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              refractiveId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (refractiveId !== -1) {
                if (
                  !areObjectsEqual(currentRefractive, currentRefractiveEdit)
                ) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveRefractive();
                      props.removePopupQuestion();
                      resetRefractiveState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetRefractiveState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetRefractiveState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              refractiveStructure.fecha = currentRefractiveEdit.fecha;
              if (areObjectsEqual(currentRefractiveEdit, refractiveStructure)) {
                props.createPopupQuestion(
                  `La ficha de refractivo actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveRefractive();
                await getRefractiveHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentRefractive, currentRefractiveEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentRefractive, currentRefractiveEdit) ||
                refractiveId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              refractiveId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (refractiveId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de refractivo?`,
                  async () => {
                    await getRefractive(refractiveId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              refractiveId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (refractiveId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de refractivo?`,
                  async () => {
                    await deleteRefractive();
                    await getRefractiveHistory();
                    await selectRefractiveAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              refractiveId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (refractiveId !== -1) {
                props.openDiagnosisPopup(currentRefractiveEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              refractiveId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (refractiveId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Refracción Objetiva</div>
            <div className="opt-table-content grid-9">
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3">
                Retinoscopía
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3">
                Autorrefractometría
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell">
                <InputData
                  tabIndex={54}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={55}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={56}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell">
                <InputData
                  tabIndex={61}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={62}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={63}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell">
                <InputData
                  tabIndex={57}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={58}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={59}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.retinoscopia_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retinoscopia_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell">
                <InputData
                  tabIndex={64}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={65}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={66}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.autorrefractometria_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      autorrefractometria_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-4">
                <Dropdown
                  id="retinoscopia"
                  tabIndex={60}
                  optionList={RETINOSCOPYLIST}
                  size={0}
                  op={currentRefractiveEdit.tipo_retinoscopia}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      tipo_retinoscopia: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell combined-cells-4"></div>
              <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                Observaciones
              </div>
              <div className="cell combined-cells-9">
                <InputTextArea
                  tabIndex={67}
                  maxLength={400}
                  id="refraccion-objetiva-observaciones"
                  type="type-full"
                  size={0}
                  input={
                    currentRefractiveEdit.observaciones_refraccion_objetiva
                  }
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      observaciones_refraccion_objetiva: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">
              Refracción Subjetiva Binocular
            </div>
            <div className="opt-table-content grid-9">
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3">
                Filtros Polarizados
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3">
                Retrasado
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell">
                <InputData
                  tabIndex={68}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={69}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={70}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell">
                <InputData
                  tabIndex={74}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retrasado_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={75}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retrasado_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={76}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.retrasado_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell">
                <InputData
                  tabIndex={71}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={72}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={73}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.filtros_polarizados_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      filtros_polarizados_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell">
                <InputData
                  tabIndex={77}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retrasado_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={78}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.retrasado_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={79}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.retrasado_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      retrasado_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell opt-table-subtitle combined-cells-9 cell-start-content">
                Observaciones
              </div>
              <div className="cell combined-cells-9">
                <InputTextArea
                  tabIndex={80}
                  maxLength={400}
                  id="refraccion-subjetiva-observaciones"
                  type="type-full"
                  size={0}
                  input={
                    currentRefractiveEdit.observaciones_refraccion_subjetiva_binocular
                  }
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      observaciones_refraccion_subjetiva_binocular: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Refracción Subjetiva</div>
            <div className="opt-table-content grid-5" id="rx-definitiva">
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                Rx Definitiva
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell cell-label">Add</div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell">
                <InputData
                  tabIndex={81}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={82}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={83}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={84}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_add_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_add_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell">
                <InputData
                  tabIndex={85}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={86}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={87}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={88}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.rx_nueva_add_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      rx_nueva_add_oi: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
            <div className="opt-table-content grid-5" id="avsc-avcc-ae">
              <div className="cell"></div>
              <div className="cell opt-table-subtitle">AVsc</div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle">AVcc</div>
              <div className="cell opt-table-subtitle">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Agujero Estenopeico</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  AE
                </span>
              </div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={89}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_sc_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_sc_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={92}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_cc_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_cc_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-end-content">
                <InputData
                  tabIndex={95}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.ae_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      ae_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={90}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_sc_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_sc_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={93}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_cc_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_cc_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label  cell-end-content">
                <InputData
                  tabIndex={96}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.ae_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      ae_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">AO</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={91}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_sc_ao}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_sc_ao: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">AO</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={94}
                  maxLength={10}
                  id="cell"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentRefractiveEdit.av_cc_ao}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      av_cc_ao: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
            </div>
            <div className="opt-table-content grid-4" id="control-convergencia">
              <div className="cell combined-cells-4"></div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3 cell-start-content">
                Control de Convergencia
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={97}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.control_convergencias_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={98}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={
                    currentRefractiveEdit.control_convergencias_cilindro_od
                  }
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={99}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.control_convergencias_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={100}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.control_convergencias_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={101}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={
                    currentRefractiveEdit.control_convergencias_cilindro_oi
                  }
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={102}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.control_convergencias_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      control_convergencias_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
            <div className="opt-table-content grid-4" id="humphriss">
              <div className="cell combined-cells-4"></div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-3 cell-start-content">
                Humphriss
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell cell-label">Ojo D</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={103}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.humphriss_esfera_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_esfera_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={104}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.humphriss_cilindro_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_cilindro_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={105}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.humphriss_eje_od}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_eje_od: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Ojo I</div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={106}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.humphriss_esfera_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_esfera_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={107}
                  id="cell"
                  type="type-optofloat"
                  size={0}
                  input={currentRefractiveEdit.humphriss_cilindro_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_cilindro_oi: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">
                <InputData
                  tabIndex={108}
                  id="cell"
                  type="type-integer"
                  size={0}
                  input={currentRefractiveEdit.humphriss_eje_oi}
                  onChange={(e) => {
                    setCurrentRefractiveEdit({
                      ...currentRefractiveEdit,
                      humphriss_eje_oi: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
