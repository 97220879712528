import React, { useState } from "react";
import ConfigListItem from "./ConfigListItem";

export default function ConfigList(props) {
  const [valueText, setValueText] = useState("");

  const insertData = (data) => {
    props.insertFunction(data);
    setValueText("");
  };

  return (
    <div className="cfg-card-section">
      <div className="cfg-title">
        <div className="cfg-h3-black">{props.sectionDescription}</div>
        <div className="cfg-section-breaker" />
      </div>
      <div className="cfg-content">
        <div className="cfg-log-content">
          {props.elements.map((el) => {
            return (
              <ConfigListItem
                key={el.id}
                idElement={el.id}
                descripcion={el.descripcion}
                onClickFunction={props.deleteFunction}
              />
            );
          })}
        </div>
        <div className="cfg-add-log">
          <input
            name="data"
            type="text"
            className="cfg-log-input"
            placeholder={props.newText}
            value={valueText}
            onChange={(e) => setValueText(e.target.value.toUpperCase())}
          ></input>
          <div className="cfg-add-button" onClick={() => insertData(valueText)}>
            Añadir
          </div>
        </div>
      </div>
    </div>
  );
}
