import React from "react";
import "../css/FileDisplay.css";

export default function FileDisplay(props) {
  const fileType = props.base64Data.split(";")[0].split(":")[1];

  if (fileType.startsWith("image/")) {
    return <img src={props.base64Data} alt="Base64 File" />;
  } else if (fileType === "application/pdf") {
    return (
      <embed
        src={props.base64Data}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    );
  } else if (fileType.startsWith("video/")) {
    return (
      <video controls width="100%">
        <source src={props.base64Data} type={fileType} />
        Your browser does not support the video tag.
      </video>
    );
  } else if (fileType.startsWith("text/")) {
    const textContent = atob(props.base64Data.split(",")[1]);
    return (
      <div
        style={{
          backgroundColor: "#fff",
          color: "#4a4a4a",
          padding: "10px",
          whiteSpace: "pre-wrap",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        {textContent}
      </div>
    );
  } else {
    return (
      <div className="file-warning-container">
        <img src="/images/advertencia-blue.svg" alt="Icon"></img>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          El fichero no se puede previsualizar.{" "}
          <a href={props.base64Data} download="document">
            Descargar fichero
          </a>
          .
        </span>
      </div>
    );
  }
}
