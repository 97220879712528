import React from "react";
import "../css/InputData.css";
import "../css/InputIconSearch.css";

export default function InputIconSearch(props) {
  return (
    <div className={`input-data input-icon-search`}>
      <img
        src="/images/search-blue.svg"
        loading="lazy"
        width="14"
        height="14"
        alt="icon"
        className="input-icon-search-img"
      />
      <input
        id={props.id}
        type={props.type ? props.type : "text"}
        disabled={props.inactive}
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.input}
      ></input>
      {props.input && (
        <img
          src="/images/close-dark.svg"
          loading="lazy"
          width="14"
          height="14"
          alt="icon"
          className="input-icon-search-clear"
          onClick={() => {
            props.onClear();
          }}
        />
      )}
    </div>
  );
}
