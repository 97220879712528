import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

export default function HessLancasterGrid({ reset, eye, points, finalPoints }) {
  const svgRef = useRef();
  const [pointsData, setPointsData] = useState(points);
  const [lines, setLines] = useState([]);

  const xScale = d3.scaleLinear().domain([0, 390]).range([0, 390]);
  const yScale = d3.scaleLinear().domain([0, 390]).range([0, 390]);

  function updateLines(selection) {
    const svg = d3.select(svgRef.current);

    svg.selectAll(".dynamic-line").remove();

    selection.each(function (d) {
      const line = d3.select(this);
      const point1 = pointsData[d[0]];
      const point2 = pointsData[d[1]];

      svg
        .append("line")
        .attr("class", "dynamic-line")
        .attr("x1", point1.x)
        .attr("y1", point1.y)
        .attr("x2", point2.x)
        .attr("y2", point2.y)
        .style("stroke", "red")
        .style("stroke-width", 2);
    });
  }

  useEffect(() => {
    setPointsData(points);
    const svg = d3.select(svgRef.current);

    const linesData = [
      [0, 1],
      [0, 3],
      [1, 2],
      [1, 4],
      [2, 5],
      [3, 4],
      [3, 6],
      [4, 5],
      [4, 7],
      [5, 8],
      [6, 7],
      [7, 8],
    ];

    setLines(linesData);
  }, [reset]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    svg.selectAll(".line").remove();

    svg
      .selectAll(".line")
      .data(lines)
      .enter()
      .append("line")
      .attr("class", "line")
      .call(updateLines);
  }, [lines]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    svg.selectAll(".point").remove();

    svg
      .selectAll(".point")
      .data(pointsData)
      .enter()
      .append("circle")
      .attr("class", "point")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", 6)
      .style("fill", "red")
      .style("cursor", "pointer")
      .call(dragHandler);
  }, [lines]);

  const dragHandler = d3
    .drag()
    .on("start", function (event, d) {
      d3.select(this).raise().attr("stroke", "red");
    })
    .on("drag", function (event, d) {
      // Verificar límites del área de 400x400
      const newX = Math.max(0, Math.min(400, event.x));
      const newY = Math.max(0, Math.min(400, event.y));

      d3.select(this)
        .attr("cx", (d.x = newX))
        .attr("cy", (d.y = newY));

      updateLines(d3.select(this.parentNode).selectAll(".line"));

      // Re-append circles to bring them to the front
      const svg = d3.select(svgRef.current);
      svg.selectAll(".point").each(function () {
        this.parentNode.appendChild(this);
      });

      const updatedPointsData = [...pointsData];
      updatedPointsData[d.id] = {
        ...updatedPointsData[d.id],
        x: newX,
        y: newY,
      };
      setPointsData(updatedPointsData);
      finalPoints(updatedPointsData);
    })
    .on("end", function (event, d) {
      d3.select(this).attr("stroke", null);
    });

  return (
    <div
      className={`hess-lancaster-data ${eye === 0 ? "left-bg" : "right-bg"}`}
    >
      <div className="grid-svg">
        <svg ref={svgRef} width="100%" height="100%"></svg>
      </div>
    </div>
  );
}
