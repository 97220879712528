import React, { useEffect, useState } from "react";
import { userData, selectedOptic, isLoggedIn } from "../js/utils";
import "../css/OpticSelector.css";

export default function OpticSelector(props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [opticsList, setOpticsList] = useState([]);
  const [currentOptic, setCurrentOptic] = useState(selectedOptic);

  // Escucha eventos de actualización de óptica para actualización en las demás pestañas del navegador abiertas.
  window.addEventListener("storage", (event) => {
    if (event.key === "selectedOptic") {
      setCurrentOptic(JSON.parse(event.newValue));
      props.onChange(JSON.parse(event.newValue));
    }
  });

  useEffect(() => {
    const list = userData.optics.map((e, i) => {
      return (
        <div
          className="optics-op"
          key={i}
          onClick={() => {
            if (isLoggedIn()) {
              setCurrentOptic(e);
              setToggleMenu(false);
              localStorage.setItem("selectedOptic", JSON.stringify(e));
              props.onChange(e);
            } else {
              window.location = "/";
            }
          }}
        >
          <span className={e.id === currentOptic.id ? "selected-op" : ""}>
            {e.nombre}
          </span>
        </div>
      );
    });
    setOpticsList(list);
  }, [currentOptic]);

  return (
    <div className="user-content">
      {props.topbar && (
        <div className="user-info">
          <img
            src={userData.pic !== "" ? userData.pic : "/images/avatar.svg"}
            loading="lazy"
            alt="Avatar"
            className="user-avatar"
          />
          <div className="h4-2">{userData.username}</div>
        </div>
      )}
      {!props.topbar && (
        <div
          className="optica-dropdown"
          onClick={() => {
            setToggleMenu(!toggleMenu);
          }}
        >
          <div className="p1 white">{currentOptic.nombre}</div>
          <img
            src="/images/down-arrow-white.svg"
            loading="lazy"
            width="12"
            height="12"
            alt="Icon"
            className="vectors-wrapper-14"
          />
          {toggleMenu && <div className="optics-container">{opticsList}</div>}
        </div>
      )}
    </div>
  );
}
