import React, { useEffect, useState, useRef } from "react";
import "../css/Dropdown.css";
import { inputOnEnter } from "../js/utils";

export default function Dropdown(props) {
  const [togglePanel, setTogglePanel] = useState(false);
  const [selectedOp, setSelectedOp] = useState(
    props.optionList.length > 0 ? props.optionList[0] : { id: "", text: "" }
  );
  const [optionList, setOptionList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const dropdownRef = useRef(null);

  const getComboboxSize = () => {
    let additionalClass = "";
    switch (props.size) {
      case 0:
        additionalClass = "combobox-full";
        break;
      case 1:
        additionalClass = "combobox-small";
        break;
      case 2:
        additionalClass = "combobox-medium";
        break;
      case 3:
        additionalClass = "combobox-large";
        break;
      case 4:
        additionalClass = "combobox-super-small";
        break;
      default:
        additionalClass = "combobox-default";
        break;
    }
    return additionalClass;
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();

      const currentIndex = props.optionList.findIndex(
        (option) => option.id === selectedOp.id
      );

      const direction = event.key === "ArrowUp" ? -1 : 1;

      let newIndex = (currentIndex + direction) % props.optionList.length;
      if (newIndex < 0) {
        newIndex = props.optionList.length - 1;
      }

      const newSelectedOp = props.optionList[newIndex];

      setSelectedOp(newSelectedOp);
      setInputSearch(newSelectedOp.text);
      props.onChange(newSelectedOp);
    }
  };

  useEffect(() => {
    setInputSearch(selectedOp.text);
  }, [selectedOp]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTogglePanel(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const refineOptionList = () => {
      const list =
        props.optionList.length > 0
          ? props.optionList.map((e, i) => {
              return (
                <div
                  className="dropdown-op"
                  key={i}
                  onClick={() => {
                    props.onChange(e);
                    setSelectedOp(e);
                    setTogglePanel(false);
                    setInputSearch(e.text);
                  }}
                >
                  <span className={e.id === selectedOp.id ? "selected-op" : ""}>
                    {e.text}
                  </span>
                </div>
              );
            })
          : [
              <div className="dropdown-op" key={0}>
                <span className="">No options</span>
              </div>,
            ];
      setOptionList(list);
      const found = props.optionList.find((e) => {
        return e.id === props.op;
      });
      setSelectedOp(found ? found : { id: props.op, text: props.op });
    };
    refineOptionList();
  }, [props, selectedOp.id]);

  useEffect(() => {
    let found = false;
    props.optionList.forEach((op) => {
      if (
        !found &&
        inputSearch !== "" &&
        op.text.toUpperCase().startsWith(inputSearch.toUpperCase())
      ) {
        setSelectedOp(op);
        setInputSearch(op.text);
        props.onChange(op);
        found = true;
      }
    });

    if (!found && inputSearch === "") {
      setInputSearch("");
    }
  }, [inputSearch, props.optionList]);

  return (
    <div
      id={props.id}
      className={`combobox ${getComboboxSize()}`}
      ref={dropdownRef}
    >
      <div
        className={`dropdown ${togglePanel ? "dropdown-open" : ""} ${
          props.disabled ? "dropdown-disabled" : ""
        }`}
        onClick={() => {
          if (!props.disabled) {
            setTogglePanel(!togglePanel);
          }
        }}
      >
        {props.icon && <img src={props.icon} alt="icon"></img>}
        <div className="languagetext">
          <input
            className="text"
            tabIndex={props.tabIndex}
            value={inputSearch}
            onChange={(e) => {
              setInputSearch(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={(e) => inputOnEnter(e)}
            disabled={props.disabled}
          ></input>
        </div>
        <div className="downicon">
          <img
            src="/images/blue-down-arrow.svg"
            loading="lazy"
            alt="Panel Icon"
            className="vectors-wrapper-11"
          />
        </div>
      </div>
      {togglePanel && <div className="dropdown-options">{optionList}</div>}
    </div>
  );
}
