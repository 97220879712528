import React from "react";
import "../css/ButtonIcon.css";

export default function ButtonIcon(props) {
  return (
    <button className="button-img-container" onClick={props.onClick}>
      <div className="icon-circle">
        <img
          src={props.icon}
          loading="lazy"
          alt="Icon"
          className="button-img-icon"
        />
      </div>
      <span className="button-icon-text">{props.text}</span>
    </button>
  );
}
