export const menu = [
  {
    id: "panel",
    screenToShow: "panel", // Screen to show when selected
    text: "Inicio",
    icon: "../../images/menu-home.svg",
    subsections: [],
  },
  {
    id: "patient-search",
    screenToShow: "patient-search",
    text: "Pacientes",
    icon: "../../images/patient-icon.svg",
    subsections: [
      {
        id: "patient-datos",
        screenToShow: "patient-datos",
        text: "Datos",
        icon: "../../images/submenu.svg",
        plusScreen: "patient-datos-nuevo",
      },
      {
        id: "patient-compras",
        screenToShow: "patient-datos",
        text: "Compras",
        icon: "../../images/menu-shopping-bag.svg",
        plusScreen: "patient-compras-nuevo",
      },
      {
        id: "patient-optometria",
        screenToShow: "patient-datos",
        text: "Optometría",
        icon: "../../images/menu-multiestenope.svg",
        plusScreen: "patient-optometria-nuevo",
      },
      {
        id: "patient-gafas",
        screenToShow: "patient-datos",
        text: "Gafas",
        icon: "../../images/menu-gafas.svg",
        plusScreen: "patient-gafas-nuevo",
      },
      /*{
        id: "patient-gafas-sol",
        screenToShow: "patient-datos",
        text: "Gafas de sol",
        icon: "../../images/menu-gafas-sol.svg",
        plusScreen: "patient-gafas-sol-nuevo",
      },*/
      {
        id: "patient-lentes-contacto",
        screenToShow: "patient-datos",
        text: "Lentes de contacto",
        icon: "../../images/menu-lentillas.svg",
        plusScreen: "patient-lentes-contacto-nuevo",
      },
      {
        id: "patient-audiometria",
        screenToShow: "patient-datos",
        text: "Audiometría",
        icon: "../../images/menu-headphones.svg",
        plusScreen: "patient-audiometria-nuevo",
      },
      {
        id: "patient-audifonos",
        screenToShow: "patient-datos",
        text: "Audífonos",
        icon: "../../images/menu-audifonos.svg",
        plusScreen: "patient-audifonos-nuevo",
      },
      {
        id: "patient-varios",
        screenToShow: "patient-datos",
        text: "Varios",
        icon: "../../images/menu-varios.svg",
        plusScreen: "patient-varios-nuevo",
      },
    ],
  },
  {
    id: "agenda",
    screenToShow: "",
    text: "Agenda",
    icon: "../../images/menu-calendar-blue.svg",
    subsections: [],
  },
  {
    id: "ventas",
    screenToShow: "",
    text: "Ventas",
    icon: "../../images/menu-bill-blue.svg",
    subsections: [],
  },
  {
    id: "almacen",
    screenToShow: "",
    text: "Almacén",
    icon: "../../images/menu-box-blue.svg",
    subsections: [],
  },
  {
    id: "crm",
    screenToShow: "",
    text: "CRM",
    icon: "../../images/menu-crm-blue.svg",
    subsections: [],
  },
  {
    id: "inteligencia-negocio",
    screenToShow: "",
    text: "Inteligencia de Negocio",
    icon: "../../images/menu-shop-blue.svg",
    subsections: [],
  },
  {
    id: "gestion-documental",
    screenToShow: "",
    text: "Gestión Documental",
    icon: "../../images/menu-doc-blue.svg",
    subsections: [],
  },
  {
    id: "configuracion",
    screenToShow: "configuracion",
    text: "Configuración",
    icon: "../../images/home-icon.svg",
    subsections: [],
  },
  {
    id: "debug",
    screenToShow: "debug-textfields", // Screen to show when selected
    text: "Debug",
    icon: "../../images/menu-bug.svg",
    subsections: [
      {
        id: "debug-textfields",
        screenToShow: "debug-textfields",
        text: "Textfields",
        icon: "../../images/menu-bug.svg",
        subsections: [],
      },
      {
        id: "debug-exports",
        screenToShow: "debug-exports",
        text: "Exports",
        icon: "../../images/menu-bug.svg",
        subsections: [],
      },
      {
        id: "debug-skeleton",
        screenToShow: "debug-skeleton",
        text: "Skeleton",
        icon: "../../images/menu-bug.svg",
        subsections: [],
      },
    ],
  },
];
