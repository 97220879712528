import React, { useState } from "react";
import ButtonIconH from "./ButtonIconH";
import InputData from "./InputData";
import "../css/CoverTest.css";
import "../css/BasicOptometry.css";

export default function (props) {
  const [currentBinocularEdit, setCurrentBinocularEdit] = useState(
    props.currentBinocular
  );

  return (
    <div className="cover-test-container">
      <div className="cover-test-popup">
        <div className="popup-screen-header">
          <span className="popup-screen-header-title">
            Cover Test en las 9 Posiciones de Mirada
          </span>
        </div>
        <div className="popup-content">
          <div className="opt-table-tooltip grid-7" id="cover-test">
            <div className="cell combined-cells-3 cell-start-content">
              Cover Test Lejos
            </div>
            <div className="cell"></div>
            <div className="cell combined-cells-3 cell-start-content">
              Cover Test Lejos
            </div>
            <div className="cell cell-label combined-cells-3">CC</div>
            <div className="cell"></div>
            <div className="cell cell-label combined-cells-3">SC</div>
            <div className="cell">
              <InputData
                tabIndex={120}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_1}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_1: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={121}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_2}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_2: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={122}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_3}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_3: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={138}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_1}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_1: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={139}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_2}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_2: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={140}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_3}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_3: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={123}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_4}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_4: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={124}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_h}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_h: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={125}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_6}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_6: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={141}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_4}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_4: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={142}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_h}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_h: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={143}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_6}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_6: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={126}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_7}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_7: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={127}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_8}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_8: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={128}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_lejos_9}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_lejos_9: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={144}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_7}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_7: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={145}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_8}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_8: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={146}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_lejos_9}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_lejos_9: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell combined-cells-7"></div>
            <div className="cell combined-cells-3 cell-start-content">
              Cover Test Cerca
            </div>
            <div className="cell"></div>
            <div className="cell combined-cells-3 cell-start-content">
              Cover Test Cerca
            </div>
            <div className="cell cell-label combined-cells-3">CC</div>
            <div className="cell"></div>
            <div className="cell cell-label combined-cells-3">SC</div>
            <div className="cell">
              <InputData
                tabIndex={129}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_1}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_1: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={130}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_2}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_2: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={131}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_3}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_3: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={147}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_1}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_1: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={148}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_2}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_2: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={149}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_3}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_3: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={132}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_4}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_4: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={133}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_h}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_h: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={134}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_6}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_6: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={150}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_4}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_4: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={151}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_h}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_h: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={152}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_6}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_6: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={135}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_7}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_7: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={136}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_8}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_8: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={137}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_cc_cerca_9}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_cc_cerca_9: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputData
                tabIndex={153}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_7}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_7: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={154}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_8}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_8: e,
                  });
                }}
              ></InputData>
            </div>
            <div className="cell">
              <InputData
                tabIndex={155}
                maxLength={10}
                type="type-full"
                size={0}
                input={currentBinocularEdit.cover_test_sc_cerca_9}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    cover_test_sc_cerca_9: e,
                  });
                }}
              ></InputData>
            </div>
          </div>
        </div>
        <div className="cover-test-buttons">
          <ButtonIconH
            text="Aceptar"
            onClick={() => {
              props.onAccept(currentBinocularEdit);
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
