import React, { useEffect, useState, useRef } from "react";
import CheckBox from "./CheckBox";
import "../css/Dropdown.css";
import "../css/DropdownFilter.css";

export default function DropdownFilter(props) {
  const [togglePanel, setTogglePanel] = useState(false);
  const [selectedOp, setSelectedOp] = useState(
    props.optionList.length > 0 ? props.optionList[0] : { id: "", text: "" }
  );
  const [optionList, setOptionList] = useState([]);
  const dropdownRef = useRef(null);

  const getComboboxSize = () => {
    let additionalClass = "";
    switch (props.size) {
      case 1:
        additionalClass = "combobox-small";
        break;
      case 2:
        additionalClass = "combobox-medium";
        break;
      case 3:
        additionalClass = "combobox-large";
        break;
      default:
        additionalClass = "combobox-default";
        break;
    }
    return additionalClass;
  };

  useEffect(() => {
    const refineOptionList = () => {
      const list =
        props.optionList.length > 0
          ? props.optionList.map((e, i) => {
              return (
                <div
                  className="dropdown-op"
                  key={i}
                  onClick={() => {
                    props.onChange(e);
                    setSelectedOp(e);
                  }}
                >
                  <CheckBox active={e.active} onClick={() => {}}></CheckBox>
                  <span className={e.id === selectedOp.id ? "selected-op" : ""}>
                    {e.text}
                  </span>
                </div>
              );
            })
          : [
              <div className="dropdown-op" key={0}>
                <span>No options</span>
              </div>,
            ];
      setOptionList(list);
      const found = props.optionList.find((e) => {
        return e.id === props.op;
      });
      setSelectedOp(found ? found : { id: props.op, text: props.op });
    };
    refineOptionList();
  }, [props, selectedOp.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTogglePanel(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`combobox ${getComboboxSize()} combobox-filter`}
      ref={dropdownRef}
    >
      <div
        className={`dropdown ${togglePanel ? "dropdown-open" : ""} ${
          props.disabled ? "dropdown-disabled" : ""
        }`}
        onClick={() => {
          if (!props.disabled) {
            setTogglePanel(!togglePanel);
          }
        }}
      >
        {props.icon && <img src={props.icon} alt="icon"></img>}
        <div className="languagetext">
          <div className="text">{selectedOp.text}</div>
        </div>
        <div className="downicon">
          <img
            src="/images/blue-down-arrow.svg"
            loading="lazy"
            alt="Panel Icon"
            className="vectors-wrapper-11"
          />
        </div>
      </div>
      {togglePanel && <div className="dropdown-options">{optionList}</div>}
    </div>
  );
}
