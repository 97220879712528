import React, { useState, useRef, useEffect } from "react";
import "../css/SuppliersPanel.css";
import {
  focusElement,
  userData,
  selectedOptic,
  suppliersList,
} from "../js/utils";
import { post } from "../js/post";
import moment from "moment";
import PopupMessage from "./PopupMessage";
import InputData from "./InputData";
import DataTable from "react-data-table-component";
import InputSearch from "./InputSearch";

export default function SuppliersPanel(props) {
  const [popupQuestion, setPopupQuestion] = useState(null);
  const [inputSearch, setInputSearch] = useState("");
  const [supplierList, setSupplierList] = useState(suppliersList);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
      width: "10%",
    },
    {
      name: "CIF",
      selector: (row) => row.cif,
      sortable: true,
      width: "10%",
    },
    {
      name: "Mi código",
      selector: (row) => row.codigo,
      sortable: true,
      width: "10%",
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono,
      sortable: true,
      width: "10%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "10%",
    },
    {
      name: "Contacto",
      selector: (row) => row.contacto,
      sortable: true,
      width: "10%",
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      sortable: true,
      width: "10%",
    },
    {
      name: "CP",
      selector: (row) => row.cp,
      sortable: true,
      width: "10%",
    },
    {
      name: "Localidad",
      selector: (row) => row.localidad,
      sortable: true,
      width: "10%",
    },
    {
      name: "BOTON",
      selector: (row) => {},
      sortable: true,
      width: "10%",
    },
  ];

  const createPopupQuestion = (
    text,
    onClickAccept,
    onClickCancel,
    textAccept,
    textCancel
  ) => {
    setPopupQuestion({
      text,
      onClickCancel,
      onClickAccept,
      textAccept,
      textCancel,
    });
  };

  const removePopupQuestion = () => {
    setPopupQuestion(null);
  };

  const handleSort = (column, sortDirection) => {
    setTimeout(() => {
      setSortColumn(column);
      setSortDirection(sortDirection);

      const sortedData = [...supplierList].sort((a, b) => {
        const isAscending = sortDirection === "asc";
        const valueA = a[column.selector] || "";
        const valueB = b[column.selector] || "";
        return (
          String(valueA).localeCompare(String(valueB)) * (isAscending ? 1 : -1)
        );
      });

      setSupplierList(sortedData);
    }, 0);
  };

  useEffect(() => {}, []);

  return (
    <div className="external-window-bg">
      {popupQuestion && (
        <PopupMessage
          text={popupQuestion.text}
          onClickCancel={popupQuestion.onClickCancel}
          onClickAccept={popupQuestion.onClickAccept}
          textAccept={popupQuestion.textAccept}
          textCancel={popupQuestion.textCancel}
        ></PopupMessage>
      )}
      <div className="external-window external-window-obs">
        <div className="external-window-header">
          <button className="popup-action-icon close-icon">
            <img
              src="/images/close-white.svg"
              loading="lazy"
              alt="Icon"
              onClick={() => {
                props.onClose();
              }}
            ></img>
          </button>
          <span className="popup-screen-header-title">
            Gestión de Proveedores
          </span>
        </div>
        <div className="suppliers-container">
          <div className="opt-table-title">Búsqueda de Proveedor</div>
          <div className="data-field">
            <span className="label">
              Empresa / CIF / Teléfono / Contacto / ...
            </span>
            <InputData
              tabIndex={500}
              type="type-only-charnumbers"
              size={2}
              bkg={true}
              input={inputSearch}
              onChange={(e) => {
                setInputSearch(e);
              }}
            ></InputData>
          </div>
          <div className="opt-table-title">Lista de Proveedores</div>
          <div className="store-products-table">
            <DataTable
              columns={columns}
              data={supplierList}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={handleSort}
              className="custom-datatable"
              noDataComponent={""}
              onRowClicked={(row, event) => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
