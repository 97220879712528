import React from "react";
import "../css/ButtonGrad.css";

export default function ButtonGrad(props) {
  return (
    <button
      className={`button-grad-ver ${props.small ? "button-small" : ""}`}
      onClick={props.onClick}
    >
      {props.icon && <div className="svg-icon">{props.icon}</div>}
      <span>{props.text}</span>
    </button>
  );
}
