import React from "react";
import "../css/RRSSIcon.css";

export default function RRSSIcon(props) {
  return (
    <a className="rrss-icon-a" href={props.link} target="_blank">
      <img
        src={props.icon}
        loading="lazy"
        width="50"
        height="50"
        alt="Icon"
        className="sociallogo"
      />
    </a>
  );
}
