import React, { useEffect, useState } from "react";
import ButtonIconH from "./ButtonIconH";
import "../css/PopupMessage.css";
import "../css/PopupNIFTypes.css";
import RadioButton from "./RadioButton";
import { userData } from "../js/utils";
import { post } from "../js/post";

export default function PopupNIFTypes(props) {
  const [options, setOptions] = useState([]);
  const [selectedNIF, setSelectedNIF] = useState({});

  const getPatientDocumentTypes = async () => {
    const response = await post("ObtenerTiposDocumentoPaciente.php", {
      token: userData.token,
    });
    const list = response.map((e) => ({ ...e, active: false }));
    const selected = list.find((e) => e.idNif === props.idNif);

    if (selected) {
      selected.active = true;
      setSelectedNIF(selected);
    }
    setOptions(list);
  };

  useEffect(() => {
    getPatientDocumentTypes();
  }, []);

  return (
    <div className="popup-container">
      <div className="popup-message">
        <div className="table-container">
          <div className="table-header">
            <span>ID Provincia</span>
            <span>ID Documento</span>
            <span>Descripción</span>
            <span>Seleccionado</span>
          </div>
          {options.map((e, i) => {
            return (
              <div className="table-row" key={i}>
                <span>{e.provinceId}</span>
                <span>{e.documentId}</span>
                <span>{e.description}</span>
                <div className="select-field">
                  <RadioButton
                    active={e.active}
                    onClick={() => {
                      const updatedOptions = options.map((option, index) => ({
                        ...option,
                        active: index === i,
                      }));
                      setOptions(updatedOptions);
                      setSelectedNIF(e);
                    }}
                  ></RadioButton>
                </div>
              </div>
            );
          })}
        </div>
        <div className="popup-buttons">
          <ButtonIconH
            text="Aceptar"
            onClick={() => {
              props.onClick();
              props.onAccept(selectedNIF);
            }}
          ></ButtonIconH>
          <ButtonIconH
            text="Cerrar"
            onClick={() => {
              props.onClick();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
