import React, { useRef, useState, useEffect } from "react";
import ButtonIconH from "./ButtonIconH";
import CheckBox from "./CheckBox";
import moment from "moment";
import "../css/ExternalWindow.css";
import "../css/AudiometryChart.css";
import LineChart from "./LineChart";
import { initialAudiometryPoints } from "../js/utils";
import AudiometryChartData from "./AudiometryChartData";

export default function AudiometryChart(props) {
  const [selectAirL, setSelectAirL] = useState(true);
  const [selectBoneL, setSelectBoneL] = useState(false);
  const [selectThresholdL, setSelectThresholdL] = useState(false);
  const [showAirL, setShowAirL] = useState(true);
  const [showBoneL, setShowBoneL] = useState(true);
  const [showThresholdL, setShowThresholdL] = useState(true);
  const [selectAirR, setSelectAirR] = useState(true);
  const [selectBoneR, setSelectBoneR] = useState(false);
  const [selectThresholdR, setSelectThresholdR] = useState(false);
  const [showAirR, setShowAirR] = useState(true);
  const [showBoneR, setShowBoneR] = useState(true);
  const [showThresholdR, setShowThresholdR] = useState(true);
  const [airPointsL, setAirPointsL] = useState(null);
  const [airPointsR, setAirPointsR] = useState(null);
  const [bonePointsL, setBonePointsL] = useState(null);
  const [bonePointsR, setBonePointsR] = useState(null);
  const [thresholdPointsL, setThresholdPointsL] = useState(null);
  const [thresholdPointsR, setThresholdPointsR] = useState(null);
  const [toggleAddData, setToggleAddData] = useState(false);

  const cleanAllL = () => {
    props.createPopupQuestion(
      `¿Desea inicializar todos los valores del Oído Izquierdo?`,
      async () => {
        setAirPointsL(initialAudiometryPoints);
        setBonePointsL(initialAudiometryPoints);
        setThresholdPointsL(initialAudiometryPoints);
        props.removePopupQuestion();
      },
      async () => {
        props.removePopupQuestion();
      },
      "Sí",
      "No"
    );
  };

  const cleanAllR = () => {
    props.createPopupQuestion(
      `¿Desea inicializar todos los valores del Oído Derecho?`,
      async () => {
        setAirPointsR(initialAudiometryPoints);
        setBonePointsR(initialAudiometryPoints);
        setThresholdPointsR(initialAudiometryPoints);
        props.removePopupQuestion();
      },
      async () => {
        props.removePopupQuestion();
      },
      "Sí",
      "No"
    );
  };

  useEffect(() => {
    setAirPointsR([
      { x: 125, y: props.data.aerea_d_125 },
      { x: 250, y: props.data.aerea_d_250 },
      { x: 500, y: props.data.aerea_d_500 },
      { x: 1000, y: props.data.aerea_d_1000 },
      { x: 1500, y: props.data.aerea_d_1500 },
      { x: 2000, y: props.data.aerea_d_2000 },
      { x: 3000, y: props.data.aerea_d_3000 },
      { x: 4000, y: props.data.aerea_d_4000 },
      { x: 6000, y: props.data.aerea_d_6000 },
      { x: 8000, y: props.data.aerea_d_8000 },
    ]);
    setAirPointsL([
      { x: 125, y: props.data.aerea_i_125 },
      { x: 250, y: props.data.aerea_i_250 },
      { x: 500, y: props.data.aerea_i_500 },
      { x: 1000, y: props.data.aerea_i_1000 },
      { x: 1500, y: props.data.aerea_i_1500 },
      { x: 2000, y: props.data.aerea_i_2000 },
      { x: 3000, y: props.data.aerea_i_3000 },
      { x: 4000, y: props.data.aerea_i_4000 },
      { x: 6000, y: props.data.aerea_i_6000 },
      { x: 8000, y: props.data.aerea_i_8000 },
    ]);
    setBonePointsR([
      { x: 125, y: props.data.osea_d_125 },
      { x: 250, y: props.data.osea_d_250 },
      { x: 500, y: props.data.osea_d_500 },
      { x: 1000, y: props.data.osea_d_1000 },
      { x: 1500, y: props.data.osea_d_1500 },
      { x: 2000, y: props.data.osea_d_2000 },
      { x: 3000, y: props.data.osea_d_3000 },
      { x: 4000, y: props.data.osea_d_4000 },
      { x: 6000, y: props.data.osea_d_6000 },
      { x: 8000, y: props.data.osea_d_8000 },
    ]);
    setBonePointsL([
      { x: 125, y: props.data.osea_i_125 },
      { x: 250, y: props.data.osea_i_250 },
      { x: 500, y: props.data.osea_i_500 },
      { x: 1000, y: props.data.osea_i_1000 },
      { x: 1500, y: props.data.osea_i_1500 },
      { x: 2000, y: props.data.osea_i_2000 },
      { x: 3000, y: props.data.osea_i_3000 },
      { x: 4000, y: props.data.osea_i_4000 },
      { x: 6000, y: props.data.osea_i_6000 },
      { x: 8000, y: props.data.osea_i_8000 },
    ]);
    setThresholdPointsR([
      { x: 125, y: props.data.umbral_d_125 },
      { x: 250, y: props.data.umbral_d_250 },
      { x: 500, y: props.data.umbral_d_500 },
      { x: 1000, y: props.data.umbral_d_1000 },
      { x: 1500, y: props.data.umbral_d_1500 },
      { x: 2000, y: props.data.umbral_d_2000 },
      { x: 3000, y: props.data.umbral_d_3000 },
      { x: 4000, y: props.data.umbral_d_4000 },
      { x: 6000, y: props.data.umbral_d_6000 },
      { x: 8000, y: props.data.umbral_d_8000 },
    ]);
    setThresholdPointsL([
      { x: 125, y: props.data.umbral_i_125 },
      { x: 250, y: props.data.umbral_i_250 },
      { x: 500, y: props.data.umbral_i_500 },
      { x: 1000, y: props.data.umbral_i_1000 },
      { x: 1500, y: props.data.umbral_i_1500 },
      { x: 2000, y: props.data.umbral_i_2000 },
      { x: 3000, y: props.data.umbral_i_3000 },
      { x: 4000, y: props.data.umbral_i_4000 },
      { x: 6000, y: props.data.umbral_i_6000 },
      { x: 8000, y: props.data.umbral_i_8000 },
    ]);
  }, []);

  return (
    <div className="external-window-bg">
      <div className="external-window h-auto">
        <div className="external-window-header">
          <span className="external-window-header-title">Audiometría</span>
        </div>
        <div className="external-window-body">
          <div className="audiometry-info-container">
            <div>
              <span className="cell-label">Cliente:</span>
              <span className="opt-table-subtitle">{props.patientName}</span>
            </div>
            <div>
              <span className="cell-label">Audiometría:</span>
              <span className="opt-table-subtitle">
                {moment(props.data.fecha).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </div>
          </div>
          <div className="external-window-content">
            <div className="external-window-col">
              <div className="external-window-row">
                <LineChart
                  color={"#d81438"}
                  showAir={showAirR}
                  showBone={showBoneR}
                  showThreshold={showThresholdR}
                  selectAir={selectAirR}
                  selectBone={selectBoneR}
                  selectThreshold={selectThresholdR}
                  bkgColor={"#d814380a"}
                  airPoints={airPointsR}
                  bonePoints={bonePointsR}
                  thresholdPoints={thresholdPointsR}
                  handlePoints={(data) => {
                    if (selectAirR) {
                      setAirPointsR(data);
                    } else if (selectBoneR) {
                      setBonePointsR(data);
                    } else if (selectThresholdR) {
                      setThresholdPointsR(data);
                    }
                  }}
                ></LineChart>
                <div className="grid-options">
                  <div className="chart-options-container">
                    <div className="c-title">
                      <span>OÍDO DERECHO</span>
                    </div>
                    <div className="chart-options-content">
                      <div
                        className="opt-table-content grid-5"
                        id="audiometry-chart-opt"
                      >
                        <div className="cell opt-table-subtitle cell-start-content">
                          Seleccionar
                        </div>
                        <div className="cell opt-table-subtitle cell-start-content"></div>
                        <div className="cell opt-table-subtitle cell-start-content">
                          Mostrar
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectAirR) {
                                setSelectAirR(true);
                                setSelectBoneR(false);
                                setSelectThresholdR(false);
                              }
                            }}
                          >
                            Aérea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectAirR}
                                onClick={() => {
                                  if (!selectAirR) {
                                    setSelectAirR(true);
                                    setSelectBoneR(false);
                                    setSelectThresholdR(false);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowAirR(!showAirR);
                            }}
                          >
                            Aérea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showAirR}
                                onClick={() => {
                                  setShowAirR(!showAirR);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectAirR) {
                                setAirPointsR(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="red-line-1"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectBoneR) {
                                setSelectBoneR(true);
                                setSelectAirR(false);
                                setSelectThresholdR(false);
                              }
                            }}
                          >
                            Ósea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectBoneR}
                                onClick={() => {
                                  if (!selectBoneR) {
                                    setSelectBoneR(true);
                                    setSelectAirR(false);
                                    setSelectThresholdR(false);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowBoneR(!showBoneR);
                            }}
                          >
                            Ósea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showBoneR}
                                onClick={() => {
                                  setShowBoneR(!showBoneR);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectBoneR) {
                                setBonePointsR(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="red-line-2"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectThresholdR) {
                                setSelectThresholdR(true);
                                setSelectAirR(false);
                                setSelectBoneR(false);
                              }
                            }}
                          >
                            Umbral
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectThresholdR}
                                onClick={() => {
                                  if (!selectThresholdR) {
                                    setSelectThresholdR(true);
                                    setSelectAirR(false);
                                    setSelectBoneR(false);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowThresholdR(!showThresholdR);
                            }}
                          >
                            Umbral
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showThresholdR}
                                onClick={() => {
                                  setShowThresholdR(!showThresholdR);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectThresholdR) {
                                setThresholdPointsR(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="red-line-3"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                      </div>
                      <div className="external-window-buttons">
                        <ButtonIconH
                          text="Añadir datos OD"
                          small={true}
                          onClick={() => {
                            setToggleAddData(true);
                          }}
                        ></ButtonIconH>
                        <ButtonIconH
                          text="Limpiar todo"
                          small={true}
                          onClick={() => {
                            cleanAllR();
                          }}
                        ></ButtonIconH>
                      </div>
                    </div>
                    {toggleAddData && (
                      <AudiometryChartData
                        airPointsR={airPointsR}
                        bonePointsR={bonePointsR}
                        thresholdPointsR={thresholdPointsR}
                        airPointsL={airPointsL}
                        bonePointsL={bonePointsL}
                        thresholdPointsL={thresholdPointsL}
                        onAccept={(
                          airR,
                          boneR,
                          thresholdR,
                          airL,
                          boneL,
                          thresholdL
                        ) => {
                          setAirPointsR(airR);
                          setBonePointsR(boneR);
                          setThresholdPointsR(thresholdR);
                          setAirPointsL(airL);
                          setBonePointsL(boneL);
                          setThresholdPointsL(thresholdL);
                        }}
                        onClose={() => {
                          setToggleAddData(false);
                        }}
                        createPopupQuestion={(
                          text,
                          onClickAccept,
                          onClickCancel,
                          textAccept,
                          textCancel
                        ) =>
                          props.createPopupQuestion(
                            text,
                            onClickAccept,
                            onClickCancel,
                            textAccept,
                            textCancel
                          )
                        }
                        removePopupQuestion={() => {
                          props.removePopupQuestion();
                        }}
                      ></AudiometryChartData>
                    )}
                  </div>
                </div>
              </div>
              <div className="external-window-row">
                <LineChart
                  color={"#069dde"}
                  showAir={showAirL}
                  showBone={showBoneL}
                  showThreshold={showThresholdL}
                  selectAir={selectAirL}
                  selectBone={selectBoneL}
                  selectThreshold={selectThresholdL}
                  bkgColor={"#0e98d70f"}
                  airPoints={airPointsL}
                  bonePoints={bonePointsL}
                  thresholdPoints={thresholdPointsL}
                  handlePoints={(data) => {
                    if (selectAirL) {
                      setAirPointsL(data);
                    } else if (selectBoneL) {
                      setBonePointsL(data);
                    } else if (selectThresholdL) {
                      setThresholdPointsL(data);
                    }
                  }}
                ></LineChart>
                <div className="grid-options">
                  <div className="chart-options-container">
                    <div className="c-title">
                      <span>OÍDO IZQUIERDO</span>
                    </div>
                    <div className="chart-options-content">
                      <div
                        className="opt-table-content grid-5"
                        id="audiometry-chart-opt"
                      >
                        <div className="cell opt-table-subtitle cell-start-content">
                          Seleccionar
                        </div>
                        <div className="cell opt-table-subtitle cell-start-content"></div>
                        <div className="cell opt-table-subtitle cell-start-content">
                          Mostrar
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectAirL) {
                                setSelectAirL(true);
                                setSelectBoneL(false);
                                setSelectThresholdL(false);
                              }
                            }}
                          >
                            Aérea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectAirL}
                                onClick={() => {
                                  if (!selectAirL) {
                                    setSelectAirL(true);
                                    setSelectBoneL(false);
                                    setSelectThresholdL(false);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowAirL(!showAirL);
                            }}
                          >
                            Aérea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showAirL}
                                onClick={() => {
                                  setShowAirL(!showAirL);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectAirL) {
                                setAirPointsL(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="blue-line-1"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectBoneL) {
                                setSelectAirL(false);
                                setSelectBoneL(true);
                                setSelectThresholdL(false);
                              }
                            }}
                          >
                            Ósea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectBoneL}
                                onClick={() => {
                                  if (!selectBoneL) {
                                    setSelectAirL(false);
                                    setSelectBoneL(true);
                                    setSelectThresholdL(false);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowBoneL(!showBoneL);
                            }}
                          >
                            Ósea
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showBoneL}
                                onClick={() => {
                                  setShowBoneL(!showBoneL);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectBoneL) {
                                setBonePointsL(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="blue-line-2"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              if (!selectThresholdL) {
                                setSelectAirL(false);
                                setSelectBoneL(false);
                                setSelectThresholdL(true);
                              }
                            }}
                          >
                            Umbral
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={selectThresholdL}
                                onClick={() => {
                                  if (!selectThresholdL) {
                                    setSelectAirL(false);
                                    setSelectBoneL(false);
                                    setSelectThresholdL(true);
                                  }
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell cell-label cell-start-content">
                          <div
                            className="check-container-content"
                            onClick={() => {
                              setShowThresholdL(!showThresholdL);
                            }}
                          >
                            Umbral
                            <div className="radio-op">
                              <CheckBox
                                tabIndex={55}
                                active={showThresholdL}
                                onClick={() => {
                                  setShowThresholdL(!showThresholdL);
                                }}
                              ></CheckBox>
                            </div>
                          </div>
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                          <ButtonIconH
                            text="Limpiar"
                            small={true}
                            onClick={() => {
                              if (selectThresholdL) {
                                setThresholdPointsL(initialAudiometryPoints);
                              }
                            }}
                          ></ButtonIconH>
                        </div>
                        <div className="cell combined-cells-3">
                          <div className="blue-line-3"></div>
                        </div>
                        <div className="cell combined-cells-2"></div>
                      </div>
                      <div className="external-window-buttons">
                        <ButtonIconH
                          text="Añadir datos OI"
                          small={true}
                          onClick={() => {
                            setToggleAddData(true);
                          }}
                        ></ButtonIconH>
                        <ButtonIconH
                          text="Limpiar todo"
                          small={true}
                          onClick={() => {
                            cleanAllL();
                          }}
                        ></ButtonIconH>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="external-window-buttons">
          <ButtonIconH text="Imprimir" onClick={() => {}}></ButtonIconH>
          <ButtonIconH
            text="Guardar"
            onClick={() => {
              props.onSave(
                airPointsR.concat(
                  bonePointsR,
                  thresholdPointsR,
                  airPointsL,
                  bonePointsL,
                  thresholdPointsL
                )
              );
              props.onClose();
            }}
          ></ButtonIconH>
          <ButtonIconH
            text="Salir"
            onClick={() => {
              props.onClose();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
