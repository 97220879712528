import React from "react";
import "../css/LoadingScreen.css";

export default function LoadingScreen(props) {
  return (
    <div className="loading-info-container">
      <span className="loader-spinner"></span>
      <span>{props.message}</span>
    </div>
  );
}
