import React from "react";

export default function EmpresasOpticas(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="settings-body-container">
      <span>Empresas y Ópticas</span>
    </div>
  );
}
