import React, { useState } from "react";
import InputTextArea from "./InputTextArea";
import ButtonIconH from "./ButtonIconH";
import "../css/Diagnosis.css";

export default function Diagnosis(props) {
  const [input, setInput] = useState(props.value);

  return (
    <div className="external-window-bg">
      <div className="external-window diagnosis-window">
        <div className="external-window-header">
          <span className="external-window-header-title">Diagnóstico</span>
        </div>
        <div className="external-window-body diagnosis-content">
          <InputTextArea
            maxLength={600}
            type="type-full"
            size={0}
            input={input}
            onChange={(e) => {
              setInput(e);
            }}
          ></InputTextArea>
        </div>
        <div className="external-window-buttons">
          <ButtonIconH
            text="Guardar"
            onClick={() => {
              props.onSave(input);
              props.onClose();
            }}
          ></ButtonIconH>
          <ButtonIconH
            text="Salir"
            onClick={() => {
              props.onClose();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
