import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { framesList } from "../js/utils";

export default function FramesFamily(props) {
  const [productList, setProductList] = useState(framesList);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const columns = [
    {
      name: "Código",
      selector: (row) => row.codigo,
      sortable: true,
      width: "auto",
    },
    {
      name: "Marca",
      selector: (row) => row.marca,
      sortable: true,
      width: "15%",
    },
    {
      name: "Modelo",
      selector: (row) => row.modelo,
      sortable: true,
      width: "10%",
    },
    {
      name: "Color",
      selector: (row) => row.color,
      sortable: true,
      width: "12%",
    },
    {
      name: "Calibre",
      selector: (row) => row.calibre,
      sortable: true,
      width: "auto",
    },
    {
      name: "Puente",
      selector: (row) => row.puente,
      sortable: true,
      width: "auto",
    },
    {
      name: "Varilla",
      selector: (row) => row.varilla,
      sortable: true,
      width: "auto",
    },
    {
      name: "Material",
      selector: (row) => row.material,
      sortable: true,
      width: "auto",
    },
    {
      name: "Forma",
      selector: (row) => row.forma,
      sortable: true,
      width: "auto",
    },
    {
      name: "PVP",
      selector: (row) => row.pvp + " €",
      sortable: true,
      width: "auto",
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      sortable: true,
      width: "auto",
    },
    /*     {
      name: "Proveedor",
      selector: (row) => row.proveedor,
      sortable: true,
      width: "10%",
    }, */
  ];

  console.log("columns: ", columns);

  const handleSort = (column, sortDirection) => {
    setTimeout(() => {
      setSortColumn(column);
      setSortDirection(sortDirection);

      const sortedData = [...productList].sort((a, b) => {
        const isAscending = sortDirection === "asc";
        const valueA = a[column.selector] || "";
        const valueB = b[column.selector] || "";
        return (
          String(valueA).localeCompare(String(valueB)) * (isAscending ? 1 : -1)
        );
      });

      setProductList(sortedData);
    }, 0);
  };

  return (
    <div className="store-products-table">
      <DataTable
        columns={columns}
        data={productList}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}
        className="custom-datatable"
        noDataComponent={""}
        onRowClicked={(row, event) => {}}
      />
    </div>
  );
}
