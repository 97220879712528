import React, { useState } from "react";
import ActionCard from "../components/ActionCard";
import "../css/Panel.css";
import { svgIcons, userData, disconnect } from "../js/utils";

export default function Panel(props) {
  const [popupQuestion, setPopupQuestion] = useState(false);
  const [popupQuestionAccept, setPopupQuestionAccept] = useState(() => {});

  const popupQuestionAcceptFunctionManager = async () => {
    switch (popupQuestionAccept) {
      case "logout":
        disconnect();
        break;
      default:
        break;
    }
    setPopupQuestion(!popupQuestion);
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="dashboard-options">
      <div className="welcome-text">
        <div className="h1-5">
          <div className="h1 h1">
            <span className="h1-5">¡Hola, </span>
            <span className="h1-5 blue bold">{userData.username}</span>
            <span className="h1-5">!</span>
          </div>
        </div>
        <div className="h0 margin-top">¿Qué quieres hacer hoy?</div>
      </div>
      <div className="cards-content">
        <div className="cards-row">
          <ActionCard
            icon={svgIcons.user}
            title="Pacientes"
            onClick={() => {
              props.onClickMenuOption("patient-search", null);
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/patient-search", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.calendar}
            title="Agenda"
            onClick={() => props.onClickMenuOption("agenda", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/agenda", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.bill}
            title="Ventas"
            onClick={() => props.onClickMenuOption("ventas", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/ventas", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.box}
            title="Almacén"
            onClick={() => props.onClickMenuOption("almacen", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/almacen", "_blank");
            }}
          ></ActionCard>
        </div>
        <div className="cards-row">
          <ActionCard
            icon={svgIcons.crm}
            title="CRM"
            onClick={() => props.onClickMenuOption("crm", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/crm", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.shop}
            title="Inteligencia de negocio"
            onClick={() =>
              props.onClickMenuOption("inteligencia-negocio", null)
            }
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/inteligencia-negocio", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.doc}
            title="Gestión documental"
            onClick={() => props.onClickMenuOption("gestion-documental", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/gestion-documental", "_blank");
            }}
          ></ActionCard>
          <ActionCard
            icon={svgIcons.config}
            title="Configuración"
            onClick={() => props.onClickMenuOption("configuracion", null)}
            onContextMenu={(e) => {
              e.preventDefault();
              window.open("/configuracion", "_blank");
            }}
          ></ActionCard>
        </div>
      </div>
    </div>
  );
}
