import React from "react";

import Button from "../../components/Button";

export default function PerfilesControlInformacion(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="settings-body-container">
      <span>Perfiles de Control de Información</span>
      <br />
      <br />
      <Button
        title="Abrir popup"
        onPress={() =>
          props.createPopupQuestion(
            "Ejemplo de popup 2 (dos botones)",
            () => {
              console.log("Pulsado Aceptar.");
              props.removePopupQuestion();
            },
            () => {
              console.log("Pulsado Cancelar.");
              props.removePopupQuestion();
            }
          )
        }
      />
    </div>
  );
}
