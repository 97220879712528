const SERVER = "https://dev.opticasweb.com/rest/support/";
const DEBUG = false;

export let post = async (funct, body) => {
  let endpoint = SERVER;
  endpoint = endpoint + funct;

  try {
    if (DEBUG) {
      console.log(endpoint);
      console.log(body);
      console.log(JSON.stringify(body));
    }

    const response = await fetch(endpoint, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (DEBUG) {
      console.log(response);
    }

    const data = await response.json();

    if (DEBUG) {
      console.log(data);
    }

    return data;
  } catch (e) {
    console.log(e);
  }
};
