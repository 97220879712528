export const exportCSV = (data, filename) => {
  const headers = Object.keys(data[0]);
  const headerRow = headers.join(";") + "\n";
  const csvRows = data.map((obj) => headers.map((key) => obj[key]).join(";"));
  const csvData = headerRow + csvRows.join("\n");
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename || "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
