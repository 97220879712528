import React from "react";

export default function AgentesPermisos(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="settings-body-container">
      <span>Agentes y Permisos</span>
      <br />
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span style={{ color: "red" }}>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span style={{ color: "blue" }}>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
      <span>Prueba Scroll</span>
      <br />
    </div>
  );
}
