import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import InputData from "./InputData";
import CheckBox from "./CheckBox";
import ButtonIconV from "./ButtonIconV";
import CanvasDraw from "react-canvas-draw";
import ButtonIconH from "./ButtonIconH";
import {
  inputOnEnter,
  basicOptometry,
  selectedOptic,
  userData,
  CORRECCIONLIST,
  BASELIST,
  previousRXData,
  areObjectsEqual,
} from "../js/utils";
import { post } from "../js/post";
import "../css/BasicOptometry.css";
import moment from "moment";

export default function BasicOptometry(props) {
  const [selectedDate, setSelectedDate] = useState({
    id: "",
    text: "",
  });
  const [currentBasicOptometry, setCurrentBasicOptometry] =
    useState(basicOptometry);
  const [currentBasicOptometryEdit, setCurrentBasicOptometryEdit] =
    useState(basicOptometry);
  const [optometryDateHistoryList, setOptometryDateHistoryList] = useState([]);
  const [previousRX, setPreviousRX] = useState(previousRXData);
  const [optometristList, setOptometristList] = useState([]);
  const [ophthalmologistList, setOphthalmologistList] = useState([]);
  const [optometryId, setOptometryId] = useState(props.optometryId);
  const [toggleObs, setToggleObs] = useState(false);
  const [numObservations, setNumObservations] = useState(0);

  const canvasRef = useRef(null);

  //console.log("currentBasicOptometryEdit: ", currentBasicOptometryEdit);

  const getOptometryDateHistoryList = async () => {
    const response = await post("ListarFechasHistoricoOptometriaBasica.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idOptometria === optometryId;
      });
      if (found) {
        setSelectedDate({
          id: found.idOptometria,
          text: found.fecha,
        });
      }
    }
    setOptometryDateHistoryList(response);
  };

  const getBasicOptometry = async (optometryId) => {
    const response = await post("ObtenerOptometriaBasica.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOptometria: optometryId,
    });
    setCurrentBasicOptometryEdit({
      ...response,
      idPaciente: props.patientId,
      idOptometria: optometryId,
    });
    setCurrentBasicOptometry({
      ...response,
      idPaciente: props.patientId,
      idOptometria: optometryId,
    });
    canvasRef.current.loadSaveData(JSON.stringify(response.esquema));
    await getObservations(optometryId);
  };

  const getObservations = async (optId) => {
    const response = await post("ListarObservaciones.php", {
      token: userData.token,
      optica: selectedOptic.id,
      id: optId,
      categoria: "OptometriaBasica",
    });
    setNumObservations(response.length);
  };

  const getPreviousRX = async () => {
    const response = await post("ObtenerRXAnterior.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
      fecha: selectedDate.text,
    });
    if (response !== "") {
      setPreviousRX(response);
    } else {
      setPreviousRX(previousRXData);
    }
  };

  const getPreviousBasicOptometryData = async () => {
    const response = await post("ObtenerDatosOptometriaBasicaAnterior.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    if (response !== "") {
      setCurrentBasicOptometryEdit({
        ...basicOptometry,
        av_sc_lejos_d: response.av_sc_lejos_d,
        av_sc_lejos_i: response.av_sc_lejos_i,
        av_sc_lejos: response.av_sc_lejos,
        av_sc_cerca_d: response.av_sc_cerca_d,
        av_sc_cerca_i: response.av_sc_cerca_i,
        av_sc_cerca: response.av_sc_cerca,
        av_cc_lejos_d: response.av_cc_lejos_d,
        av_cc_lejos_i: response.av_cc_lejos_i,
        av_cc_lejos: response.av_cc_lejos,
        av_cc_cerca_d: response.av_cc_cerca_d,
        av_cc_cerca_i: response.av_cc_cerca_i,
        av_cc_cerca: response.av_cc_cerca,
        nasopupilar_lejos_d: response.nasopupilar_lejos_d,
        nasopupilar_lejos_i: response.nasopupilar_lejos_i,
        interpupilar_lejos: response.interpupilar_lejos,
        nasopupilar_cerca_d: response.nasopupilar_cerca_d,
        nasopupilar_cerca_i: response.nasopupilar_cerca_i,
        interpupilar_cerca: response.interpupilar_cerca,
        pio_hora: response.pio_hora,
        pio_d: response.pio_d,
        pio_i: response.pio_i,
        h_od_dp: response.h_od_dp,
        h_od_mm: response.h_od_mm,
        ejedh: response.ejedh,
        v_od_dp: response.v_od_dp,
        v_od_mm: response.v_od_mm,
        ejedv: response.ejedv,
        h_oi_dp: response.h_oi_dp,
        h_oi_mm: response.h_oi_mm,
        ejeih: response.ejeih,
        v_oi_dp: response.v_oi_dp,
        v_oi_mm: response.v_oi_mm,
        ejeiv: response.ejeih,
      });
      setCurrentBasicOptometry({
        ...basicOptometry,
        av_sc_lejos_d: response.av_sc_lejos_d,
        av_sc_lejos_i: response.av_sc_lejos_i,
        av_sc_lejos: response.av_sc_lejos,
        av_sc_cerca_d: response.av_sc_cerca_d,
        av_sc_cerca_i: response.av_sc_cerca_i,
        av_sc_cerca: response.av_sc_cerca,
        av_cc_lejos_d: response.av_cc_lejos_d,
        av_cc_lejos_i: response.av_cc_lejos_i,
        av_cc_lejos: response.av_cc_lejos,
        av_cc_cerca_d: response.av_cc_cerca_d,
        av_cc_cerca_i: response.av_cc_cerca_i,
        av_cc_cerca: response.av_cc_cerca,
        nasopupilar_lejos_d: response.nasopupilar_lejos_d,
        nasopupilar_lejos_i: response.nasopupilar_lejos_i,
        interpupilar_lejos: response.interpupilar_lejos,
        nasopupilar_cerca_d: response.nasopupilar_cerca_d,
        nasopupilar_cerca_i: response.nasopupilar_cerca_i,
        interpupilar_cerca: response.interpupilar_cerca,
        pio_hora: response.pio_hora,
        pio_d: response.pio_d,
        pio_i: response.pio_i,
        h_od_dp: response.h_od_dp,
        h_od_mm: response.h_od_mm,
        ejedh: response.ejedh,
        v_od_dp: response.v_od_dp,
        v_od_mm: response.v_od_mm,
        ejedv: response.ejedv,
        h_oi_dp: response.h_oi_dp,
        h_oi_mm: response.h_oi_mm,
        ejeih: response.ejeih,
        v_oi_dp: response.v_oi_dp,
        v_oi_mm: response.v_oi_mm,
        ejeiv: response.ejeih,
      });
    }
  };

  const copyToNewOptometry = () => {
    setCurrentBasicOptometryEdit({
      ...currentBasicOptometryEdit,
      esfera_d: previousRX.esfera_d,
      esfera_i: previousRX.esfera_i,
      cilindro_d: previousRX.cilindro_d,
      cilindro_i: previousRX.cilindro_i,
      eje_d: previousRX.eje_d,
      eje_i: previousRX.eje_i,
      adicion_d: previousRX.adicion_d,
      adicion_i: previousRX.adicion_i,
      intermedia_d: previousRX.intermedia_d,
      intermedia_i: previousRX.intermedia_i,
      cerca_d: previousRX.cerca_d,
      cerca_i: previousRX.cerca_i,
      total_d: previousRX.total_d,
      total_i: previousRX.total_i,
      //optometrista: previousRX.optometrista,
    });
  };

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getOphthalmologistList = async () => {
    const response = await post("ListarOftalmologos.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOphthalmologistList([
      { id: "", text: "" },
      ...response.map((e) => ({
        id: e.nombreApellidos,
        text: e.nombreApellidos,
      })),
    ]);
  };

  const saveBasicOptometry = async () => {
    const response = await post("GuardarOptometriaBasica.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOptometria: optometryId,
      idPaciente: props.patientId,
      ...currentBasicOptometryEdit,
      fecha_actualizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    // Actualizamos la fecha_actualizacion
    setCurrentBasicOptometryEdit({
      ...currentBasicOptometryEdit,
      fecha_actualizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentBasicOptometry({
      ...currentBasicOptometryEdit,
      fecha_actualizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDate({
      id: response.idOptometria,
      text: currentBasicOptometryEdit.fecha,
    });
    setOptometryId(response.idOptometria);
    props.saveLastOptIdVisited(response.idOptometria);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteBasicOptometry = async () => {
    await post("EliminarOptometriaBasica.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idOptometria: optometryId,
      idUsuario: userData.id,
    });
  };

  const selectOptometryAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoOptometriaBasica.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idOptometria,
        text: response[0].fecha,
      });
      setOptometryId(response[0].idOptometria);
      setOptometryDateHistoryList(response);
      await getBasicOptometry(response[0].idOptometria);
      props.saveLastOptIdVisited(response[0].idOptometria);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setOptometryId(-1);
      props.saveLastOptIdVisited(-1);
      setCurrentBasicOptometry(basicOptometry);
      setCurrentBasicOptometryEdit(basicOptometry);
    }
  };

  const cancelChanges = async () => {
    if (optometryDateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (optometryId === -1) {
        setSelectedDate({
          id: optometryDateHistoryList[0].idOptometria,
          text: optometryDateHistoryList[0].fecha,
        });
        setOptometryId(optometryDateHistoryList[0].idOptometria);
        props.saveLastOptIdVisited(optometryDateHistoryList[0].idOptometria);
        await getBasicOptometry(optometryDateHistoryList[0].idOptometria);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentBasicOptometryEdit(currentBasicOptometry);
      }
    } else {
      setCurrentBasicOptometryEdit(currentBasicOptometry);
    }
  };

  const resetOptometryState = async () => {
    basicOptometry.fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    basicOptometry.fecha_revision = moment()
      .add(1, "years")
      .format("YYYY-MM-DD");
    basicOptometry.fecha_actualizacion = moment().format("YYYY-MM-DD HH:mm:ss");
    setCurrentBasicOptometryEdit(basicOptometry);
    setCurrentBasicOptometry(basicOptometry);
    setOptometryId(-1);
    props.saveLastOptIdVisited(-1);
    // Establecer la nueva fecha (de creación) para el selectedDate
    setSelectedDate({
      id: -1,
      text: basicOptometry.fecha,
    });
    // Llamar al RX Anterior
    await getPreviousRX();
    // Obtener los datos de la optometría anterior (si hay) y actualizar la estructura.
    await getPreviousBasicOptometryData();
    canvasRef.current.loadSaveData(JSON.stringify(basicOptometry.esquema));
  };

  const transpositionRightEye = () => {
    // IMPLEMENTADA POR OPTIWIN.
  };

  const transpositionLeftEye = () => {
    // IMPLEMENTADA POR OPTIWIN.
  };

  useEffect(() => {
    getOptometryDateHistoryList();
    getOptometristList();
    getOphthalmologistList();
    if (optometryId !== -1) {
      getBasicOptometry(optometryId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentBasicOptometryEdit({
        ...currentBasicOptometryEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentBasicOptometry({
        ...currentBasicOptometry,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, []);

  useEffect(() => {
    getPreviousRX();
    if (selectedDate.id === -1) {
      // Obtener los datos de la optometría anterior (si hay) y actualizar la estructura.
      getPreviousRX();
      getPreviousBasicOptometryData();
    }
  }, [selectedDate]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(
        currentBasicOptometry,
        currentBasicOptometryEdit
      ),
      dataToSave: currentBasicOptometryEdit,
    });
  }, [currentBasicOptometryEdit]);

  useEffect(() => {
    if (props.updateOpt) {
      props.updateOptAction();
      setOptometryId(props.optometryId);
      getBasicOptometry(props.optometryId);
    }
  }, [props.updateOpt]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="data-field">
            <span className="label">
              Histórico Optometrías ({optometryDateHistoryList.length})
            </span>
            <DropdownFilter
              optionList={optometryDateHistoryList.map((item) => ({
                id: item.idOptometria,
                text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                active: selectedDate.id === item.idOptometria,
              }))}
              op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
              disabled={optometryId === -1}
              onChange={async (e) => {
                await getBasicOptometry(e.id);
                await getOptometryDateHistoryList();
                setSelectedDate({
                  id: e.id,
                  text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                });
                props.saveLastOptIdVisited(e.id);
                setOptometryId(e.id);
              }}
            ></DropdownFilter>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nueva"
            icon={
              optometryId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={async () => {
              if (optometryId !== -1) {
                if (
                  !areObjectsEqual(
                    currentBasicOptometry,
                    currentBasicOptometryEdit
                  )
                ) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva optometría?`,
                    async () => {
                      await saveBasicOptometry();
                      props.removePopupQuestion();
                      resetOptometryState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetOptometryState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetOptometryState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              if (
                currentBasicOptometryEdit.optometrista !== "" ||
                currentBasicOptometryEdit.oftalmologo !== ""
              ) {
                await saveBasicOptometry();
                await getOptometryDateHistoryList();
                //props.updateTab();
              } else {
                props.createPopupQuestion(
                  `Debe seleccionar un optometrista o un oftalmólogo.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={async () => {
              if (
                optometryDateHistoryList.length === 0 &&
                areObjectsEqual(
                  currentBasicOptometry,
                  currentBasicOptometryEdit
                )
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(
                  currentBasicOptometry,
                  currentBasicOptometryEdit
                ) ||
                optometryId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              optometryId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (optometryId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de optometría?`,
                  async () => {
                    await getBasicOptometry(optometryId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              optometryId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (optometryId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de optometría?`,
                  async () => {
                    await deleteBasicOptometry();
                    await getOptometryDateHistoryList();
                    await selectOptometryAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Informe"
            icon={
              optometryId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (optometryId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">RX Anterior</div>
            <div className="opt-table-content" id="rx-anterior">
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell"></div>
              <div className="cell cell-label">Add I</div>
              <div className="cell cell-label">Total I</div>
              <div className="cell cell-label">Add C</div>
              <div className="cell cell-label">Total C</div>
              <div className="cell"></div>
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.esfera_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.cilindro_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-integer"
                  size={0}
                  input={previousRX.eje_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.adicion_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.intermedia_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.cerca_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.total_d}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.esfera_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.cilindro_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-integer"
                  size={0}
                  input={previousRX.eje_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.adicion_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.intermedia_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.cerca_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  type="type-optofloat"
                  size={0}
                  input={previousRX.total_i}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell cell-left-content">
                <button
                  className="cell-icon"
                  onClick={() => {
                    if (
                      Object.values(previousRX).some((value) => value !== "")
                    ) {
                      copyToNewOptometry();
                    }
                  }}
                >
                  <img
                    src="/images/extract-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: <div className="cell">Copiar a RX Nueva</div>,
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell cell-label combined-cells-2">
                Optometrista
              </div>
              <div className="cell combined-cells-7">
                <InputData
                  type="type-alphaext"
                  size={0}
                  input={previousRX.optometrista}
                  onChange={(e) => {}}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell"></div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">RX Nueva</div>
            <div className="opt-table-content" id="rx-nueva">
              <div className="cell"></div>
              <div className="cell cell-label">Esf</div>
              <div className="cell cell-label">Cil</div>
              <div className="cell cell-label">Eje</div>
              <div className="cell"></div>
              <div className="cell cell-label">Add I</div>
              <div className="cell cell-label">Total I</div>
              <div className="cell cell-label">Add C</div>
              <div className="cell cell-label">Total C</div>
              <div className="cell"></div>
              <div className="cell cell-label cell-left-content">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={45}
                  id="cell-45"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.esfera_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      esfera_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={46}
                  id="cell-46"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.cilindro_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      cilindro_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={47}
                  id="cell-47"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.eje_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      eje_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={48}
                  id="cell-48"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.adicion_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      adicion_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={49}
                  id="cell-49"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.intermedia_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      intermedia_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={50}
                  id="cell-50"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.cerca_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      cerca_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={51}
                  id="cell-51"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.total_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      total_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-left-content">
                <button
                  className="cell-icon"
                  onClick={() => {
                    transpositionRightEye();
                  }}
                >
                  <img
                    src="/images/update-fill-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: (
                          <div className="cell">Transposición Ojo D</div>
                        ),
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell cell-label cell-left-content">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={52}
                  id="cell-52"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.esfera_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      esfera_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={53}
                  id="cell-53"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.cilindro_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      cilindro_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={54}
                  id="cell-54"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.eje_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      eje_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={55}
                  id="cell-55"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.adicion_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      adicion_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={56}
                  id="cell-56"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.intermedia_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      intermedia_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={57}
                  id="cell-57"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.cerca_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      cerca_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={58}
                  id="cell-58"
                  type="type-optofloat"
                  size={0}
                  input={currentBasicOptometryEdit.total_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      total_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-left-content">
                <button
                  className="cell-icon"
                  onClick={() => {
                    transpositionLeftEye();
                  }}
                >
                  <img
                    src="/images/update-fill-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: (
                          <div className="cell">Transposición Ojo I</div>
                        ),
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell cell-label combined-cells-9  cell-start-content">
                Optometrista
                <Dropdown
                  id="rx-nueva-optometrista"
                  tabIndex={59}
                  optionList={optometristList}
                  size={0}
                  op={currentBasicOptometryEdit.optometrista}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      optometrista: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-9  cell-start-content">
                Oftalmólogo/a
                <Dropdown
                  id="rx-nueva-oftalmologo"
                  tabIndex={60}
                  optionList={ophthalmologistList}
                  size={0}
                  op={currentBasicOptometryEdit.oftalmologo}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      oftalmologo: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-6  cell-start-content">
                Corrección
                <Dropdown
                  id="rx-nueva-correccion"
                  tabIndex={61}
                  optionList={CORRECCIONLIST}
                  size={0}
                  op={currentBasicOptometryEdit.tipo_correccion}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      tipo_correccion: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label combined-cells-3 cell-end-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      remitir_oftalmologo:
                        !currentBasicOptometryEdit.remitir_oftalmologo,
                    });
                  }}
                >
                  Derivar a Oftalmología
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={62}
                      active={currentBasicOptometryEdit.remitir_oftalmologo}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell"></div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title opt-table-title-grid-10">
              <div className="cell">AV</div>
              <div className="cell combined-cells-2">SC</div>
              <div className="cell"></div>
              <div className="cell combined-cells-2">CC</div>
              <div className="cell"></div>
              <div className="cell combined-cells-2">DIP</div>
            </div>
            <div className="opt-table-content" id="av">
              <div className="cell"></div>
              <div className="cell cell-label">VL</div>
              <div className="cell cell-label">VP</div>
              <div className="cell"></div>
              <div className="cell cell-label">VL</div>
              <div className="cell cell-label">VP</div>
              <div className="cell"></div>
              <div className="cell cell-label">VL</div>
              <div className="cell cell-label">VP</div>
              <div className="cell"></div>
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={63}
                  maxLength={9}
                  id="cell-63"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_lejos_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_lejos_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={66}
                  maxLength={9}
                  id="cell-66"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_cerca_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_cerca_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={69}
                  maxLength={9}
                  id="cell-69"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_lejos_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_lejos_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={72}
                  maxLength={9}
                  id="cell-72"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_cerca_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_cerca_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-right-content">NPD</div>
              <div className="cell">
                <InputData
                  tabIndex={75}
                  id="cell-75"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.nasopupilar_lejos_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      nasopupilar_lejos_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={78}
                  id="cell-78"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.nasopupilar_cerca_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      nasopupilar_cerca_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={64}
                  maxLength={9}
                  id="cell-64"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_lejos_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_lejos_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={67}
                  maxLength={9}
                  id="cell-67"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_cerca_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_cerca_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={70}
                  maxLength={9}
                  id="cell-70"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_lejos_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_lejos_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={73}
                  maxLength={9}
                  id="cell-73"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_cerca_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_cerca_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-right-content">DIP</div>
              <div className="cell">
                <InputData
                  tabIndex={77}
                  id="cell-77"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.interpupilar_lejos}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      interpupilar_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={80}
                  id="cell-80"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.interpupilar_cerca}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      interpupilar_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">AO</div>
              <div className="cell">
                <InputData
                  tabIndex={65}
                  maxLength={9}
                  id="cell-65"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_lejos}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={68}
                  maxLength={9}
                  id="cell-68"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_sc_cerca}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_sc_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={71}
                  maxLength={9}
                  id="cell-71"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_lejos}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={74}
                  maxLength={9}
                  id="cell-74"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentBasicOptometryEdit.av_cc_cerca}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      av_cc_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-right-content">NIP</div>
              <div className="cell">
                <InputData
                  tabIndex={76}
                  id="cell-76"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.nasopupilar_lejos_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      nasopupilar_lejos_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={79}
                  id="cell-79"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.nasopupilar_cerca_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      nasopupilar_cerca_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title opt-table-title-grid-10">
              <div className="cell">Prisma</div>
              <div className="cell combined-cells-6"></div>
              <div className="cell combined-cells-2">Tonometría</div>
              <div className="cell"></div>
            </div>
            <div className="opt-table-content" id="prisma">
              <div className="cell"></div>
              <div className="cell cell-label">Prisma</div>
              <div className="cell cell-label combined-cells-3">Base</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">OD</div>
              <div className="cell">
                <InputData
                  tabIndex={81}
                  id="cell-81"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.prisma_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      prisma_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-3">
                <Dropdown
                  id="prisma-base-od"
                  tabIndex={82}
                  optionList={BASELIST}
                  size={0}
                  op={currentBasicOptometryEdit.base_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      base_d: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={83}
                  id="cell-83"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.base_d2}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      base_d2: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell">
                <InputData
                  tabIndex={87}
                  id="cell-87"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.pio_d}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      pio_d: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">Hora</div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell">
                <InputData
                  tabIndex={84}
                  id="cell-84"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.prisma_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      prisma_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell combined-cells-3">
                <Dropdown
                  id="prisma-base-oi"
                  tabIndex={85}
                  optionList={BASELIST}
                  size={0}
                  op={currentBasicOptometryEdit.base_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      base_i: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={86}
                  id="cell-86"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.base_i2}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      base_i2: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell">
                <InputData
                  tabIndex={88}
                  id="cell-88"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.pio_i}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      pio_i: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <input
                  type="time"
                  tabIndex={89}
                  id="cell-89"
                  className="input-field input-cell"
                  name="hora"
                  min="00:00"
                  max="23:59"
                  step="60"
                  value={currentBasicOptometryEdit.pio_hora}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      pio_hora: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => inputOnEnter(e)}
                ></input>
              </div>
              <div className="cell"></div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Queratometría</div>
            <div className="opt-table-content" id="queratometria">
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">OD</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">H</div>
              <div className="cell">
                <InputData
                  tabIndex={90}
                  id="cell-90"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.h_od_dp}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      h_od_dp: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">dp</div>
              <div className="cell">
                <InputData
                  tabIndex={91}
                  id="cell-91"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.h_od_mm}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      h_od_mm: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">m/m</div>
              <div className="cell">
                <InputData
                  tabIndex={92}
                  id="cell-92"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.ejedh}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      ejedh: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell cell-label">V</div>
              <div className="cell">
                <InputData
                  tabIndex={93}
                  id="cell-93"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.v_od_dp}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      v_od_dp: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">dp</div>
              <div className="cell">
                <InputData
                  tabIndex={94}
                  id="cell-94"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.v_od_mm}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      v_od_mm: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">m/m</div>
              <div className="cell">
                <InputData
                  tabIndex={95}
                  id="cell-95"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.ejedv}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      ejedv: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">OI</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label">H</div>
              <div className="cell">
                <InputData
                  tabIndex={96}
                  id="cell-96"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.h_oi_dp}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      h_oi_dp: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">dp</div>
              <div className="cell">
                <InputData
                  tabIndex={97}
                  id="cell-97"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.h_oi_mm}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      h_oi_mm: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">m/m</div>
              <div className="cell">
                <InputData
                  tabIndex={98}
                  id="cell-98"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.ejeih}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      ejeih: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell cell-label">V</div>
              <div className="cell">
                <InputData
                  tabIndex={99}
                  id="cell-99"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.v_oi_dp}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      v_oi_dp: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">dp</div>
              <div className="cell">
                <InputData
                  tabIndex={100}
                  id="cell-100"
                  type="type-float2"
                  size={0}
                  input={currentBasicOptometryEdit.v_oi_mm}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      v_oi_mm: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">m/m</div>
              <div className="cell">
                <InputData
                  tabIndex={101}
                  id="cell-101"
                  type="type-integer"
                  size={0}
                  input={currentBasicOptometryEdit.ejeiv}
                  onChange={(e) => {
                    setCurrentBasicOptometryEdit({
                      ...currentBasicOptometryEdit,
                      ejeiv: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-left-content">º</div>
              <div className="cell combined-cells-7"></div>
              <div className="cell"></div>
              <div className="cell cell-label cell-start-content">Creación</div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                <div className="input-data input-full">
                  <input
                    type="datetime-local"
                    step="1"
                    tabIndex={102}
                    id="cell-102"
                    className="input-field input-cell"
                    value={currentBasicOptometryEdit.fecha}
                    onChange={(e) => {
                      setCurrentBasicOptometryEdit({
                        ...currentBasicOptometryEdit,
                        fecha: e.target.value,
                      });
                    }}
                    onKeyUp={(e) => inputOnEnter(e)}
                  ></input>
                </div>
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label cell-start-content">Revisión</div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                <div className="input-data input-full">
                  <input
                    type="date"
                    tabIndex={103}
                    id="cell-103"
                    className="input-field input-cell"
                    value={currentBasicOptometryEdit.fecha_revision}
                    onChange={(e) => {
                      setCurrentBasicOptometryEdit({
                        ...currentBasicOptometryEdit,
                        fecha_revision: e.target.value,
                      });
                    }}
                    onKeyUp={(e) => inputOnEnter(e)}
                  ></input>
                </div>
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div> <div className="cell"></div>
              <div className="cell cell-label cell-start-content">
                Actualización
              </div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                <InputData
                  size={0}
                  input={moment(
                    currentBasicOptometryEdit.fecha_actualizacion
                  ).format("DD/MM/YYYY HH:mm:ss")}
                  inactive={true}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell"></div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Observaciones</div>
            <div className="section-content-data section-content-data-obs">
              <span className="section-content-message">
                Hay&nbsp;
                <span className="obs-text">
                  {optometryId !== -1
                    ? /*numObservations*/ props.numObservations
                    : 0}
                  &nbsp;observaciones&nbsp;
                </span>
                para esta optometría básica.
              </span>
              <div className="section-content-buttons">
                <ButtonIconH
                  text="Abrir"
                  small={true}
                  onClick={() => {
                    if (optometryId !== -1) {
                      setToggleObs(true);
                      props.toggleObservationsPanel(
                        true,
                        currentBasicOptometryEdit.idOptometria
                      );
                    } else {
                      props.createPopupQuestion(
                        "Por favor, guarde la ficha que está creando antes de abrir el panel de observaciones.",
                        async () => {
                          props.removePopupQuestion();
                        },
                        null,
                        "Entendido"
                      );
                    }
                  }}
                ></ButtonIconH>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Esquema</div>
            <button
              className="popup-screen-eye-schema"
              onClick={() => {
                props.toggleEyeSchema(currentBasicOptometryEdit);
              }}
            >
              <img
                src="/images/eye-dark.svg"
                loading="lazy"
                alt="Icon"
                className="eye-schema-icon"
              ></img>
              <img
                src="/images/eye-dark.svg"
                loading="lazy"
                alt="Icon"
                className="eye-schema-icon"
              ></img>
              <div className="canvas-container">
                <CanvasDraw
                  ref={canvasRef}
                  lazyRadius={0}
                  canvasWidth={450}
                  canvasHeight={300}
                  hideGrid={true}
                  hideInterface={false}
                  onChange={() => {}}
                  disabled={true}
                  style={{
                    background: "transparent",
                  }}
                  canvasBackgroundColor="rgba(0, 0, 0, 0)"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
