import React, { useEffect, useState, useRef } from "react";
import CheckBox from "../components/CheckBox";
import { focusElement } from "../js/utils";
import "../css/InputData.css";
import "../css/InputIconSearch.css";
import "../css/InputIconSearchPanel.css";
import "../css/InputIconSearchPanelMultiSelection.css";

export default function InputIconSearchPanelMultiSelection(props) {
  const [togglePanel, setTogglePanel] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [inputSearch, setInputSearch] = useState("");
  const [optionsFiltered, setOptionsFiltered] = useState(props.options);

  const panelRef = useRef(null);
  const inputRef = useRef(null);
  const optionRefs = useRef([]);

  const getInputSize = () => {
    const sizeClass = {
      0: "input-full",
      1: "input-small",
      2: "input-medium",
      3: "input-large",
      4: "input-super-small",
    };
    return sizeClass[props.size] || "";
  };

  const searchOptions = () => {
    let optionListAux = props.options;
    setOptionsFiltered(optionListAux);
    if (props.input === optionSelected) {
      setTogglePanel(false);
    }
  };

  const getSearchMatches = () => {
    let matches = 0;
    if (inputSearch !== "") {
      matches = props.options.filter((e) => e.includes(inputSearch)).length;
    }
    return matches;
  };

  useEffect(() => {
    if (props.inactive) {
      setTogglePanel(false);
    }
  }, [props.inactive]);

  useEffect(() => {
    searchOptions();
    setOptionSelected(props.input);
  }, [props.input]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (togglePanel) {
        if (e.key === "ArrowDown") {
          setHighlightedIndex((prevIndex) =>
            prevIndex < optionsFiltered.length - 1 ? prevIndex + 1 : 0
          );
        } else if (e.key === "ArrowUp") {
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : optionsFiltered.length - 1
          );
        } else if (e.key === "Enter") {
          if (highlightedIndex >= 0) {
            const selectedOption = optionsFiltered[highlightedIndex];
            handleSelectOption(selectedOption);
          }
        } else if (e.key === "Escape") {
          setTogglePanel(false);
        }
      }
    };

    const handleClickOutside = (e) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(e.target) &&
        !inputRef.current.contains(e.target)
      ) {
        setTogglePanel(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [togglePanel, highlightedIndex, optionsFiltered]);

  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      const highlightedOption = optionRefs.current[highlightedIndex];
      const panel = panelRef.current;
      if (panel && highlightedOption) {
        const optionTop = highlightedOption.offsetTop;
        const optionBottom = optionTop + highlightedOption.clientHeight;
        const panelTop = panel.scrollTop;
        const panelBottom = panelTop + panel.clientHeight;

        if (optionTop < panelTop) {
          panel.scrollTop = optionTop;
        } else if (optionBottom > panelBottom) {
          panel.scrollTop = optionBottom - panel.clientHeight;
        }
      }
    }
  }, [highlightedIndex]);

  const handleSelectOption = (selectedOption) => {
    setOptionSelected((prev) =>
      !prev.includes(selectedOption)
        ? [...prev, selectedOption]
        : prev.filter((brand) => brand !== selectedOption)
    );
    props.onChange((prev) =>
      !prev.includes(selectedOption)
        ? [...prev, selectedOption]
        : prev.filter((brand) => brand !== selectedOption)
    );
  };

  return (
    <div className="input-search-panel-container">
      <div
        className={`input-data input-icon-search ${getInputSize()} ${
          props.inactive ? "input-search-inactive" : ""
        }`}
        ref={inputRef}
      >
        <img
          src="/images/search-blue.svg"
          loading="lazy"
          width="14"
          height="14"
          alt="icon"
          className="input-icon-search-img"
        />
        <input
          id={props.id}
          tabIndex={2}
          type={props.type ? props.type : "text"}
          disabled={props.inactive}
          placeholder={props.placeholder}
          onChange={(e) => {
            setInputSearch(e.target.value.toUpperCase());
            setHighlightedIndex(-1);
          }}
          onClick={() => {
            setTogglePanel(true);
          }}
          value={inputSearch}
        ></input>
        {inputSearch !== "" && (
          <img
            src="/images/close-dark.svg"
            loading="lazy"
            width="14"
            height="14"
            alt="icon"
            className="input-icon-search-clear"
            onClick={() => {
              setInputSearch("");
              setHighlightedIndex(-1);
            }}
          />
        )}
        <img
          src="/images/list-blue.svg"
          loading="lazy"
          width="14"
          height="14"
          alt="icon"
          className="input-icon-search-clear"
          style={{ marginLeft: "10px" }}
          onClick={(e) => {
            e.stopPropagation();
            if (!props.inactive) {
              setTogglePanel((prevToggle) => !prevToggle);
              setHighlightedIndex(-1);
              setInputSearch("");
              focusElement(2);
            }
          }}
        />
      </div>
      {togglePanel && (
        <div className="input-search-panel" ref={panelRef}>
          <div className="input-search-panel-header">
            <span style={{ fontWeight: "500" }}>
              Coincidencias de búsqueda: {getSearchMatches()}
            </span>
            <button
              style={{
                color: `var(--dark-blue)`,
                fontWeight: 500,
                cursor: "pointer",
                right: "0px",
              }}
              onClick={() => {
                setOptionSelected([]);
                props.onChange([]);
              }}
            >
              Limpiar selección
            </button>
          </div>
          {optionsFiltered.map((e, i) => {
            return (
              <div
                className={`search-result-info ${
                  props.input.includes(e) ||
                  highlightedIndex === i ||
                  (e.includes(inputSearch) && inputSearch !== "")
                    ? "search-result-info-selected"
                    : ""
                }`}
                style={{ justifyContent: "flex-start", gap: "8px" }}
                key={i}
                ref={(el) => (optionRefs.current[i] = el)}
              >
                <CheckBox
                  tabIndex={i}
                  active={optionSelected.includes(e)}
                  onClick={() => {
                    handleSelectOption(e);
                  }}
                ></CheckBox>
                {e}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
