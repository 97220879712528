import React, { useState } from "react";
import ButtonIconH from "./ButtonIconH";
import InputTextArea from "./InputTextArea";
import Dropdown from "./Dropdown";
import HessLancasterGrid from "./HessLancasterGrid";
import "../css/HessLancaster.css";
import {
  HESSLANCASTERLIST,
  areObjectsEqual,
  deviationFactor,
  hessLancasterStructure,
} from "../js/utils";

export default function HessLancaster(props) {
  const [reset, setReset] = useState(false);
  const [currentHessLancasterEdit, setCurrentHessLancasterEdit] = useState(
    props.data
  );
  const [testHL, setTestHL] = useState(props.test);
  const [hessLancasterLeftEyePoints, setHessLancasterLeftEyePoints] = useState({
    punto1_oi_x: props.data.punto1_oi_x,
    punto1_oi_y: props.data.punto1_oi_y,
    punto2_oi_x: props.data.punto2_oi_x,
    punto2_oi_y: props.data.punto2_oi_y,
    punto3_oi_x: props.data.punto3_oi_x,
    punto3_oi_y: props.data.punto3_oi_y,
    punto4_oi_x: props.data.punto4_oi_x,
    punto4_oi_y: props.data.punto4_oi_y,
    punto5_oi_x: props.data.punto5_oi_x,
    punto5_oi_y: props.data.punto5_oi_y,
    punto6_oi_x: props.data.punto6_oi_x,
    punto6_oi_y: props.data.punto6_oi_y,
    punto7_oi_x: props.data.punto7_oi_x,
    punto7_oi_y: props.data.punto7_oi_y,
    punto8_oi_x: props.data.punto8_oi_x,
    punto8_oi_y: props.data.punto8_oi_y,
    punto9_oi_x: props.data.punto9_oi_x,
    punto9_oi_y: props.data.punto9_oi_y,
  });
  const [hessLancasterRightEyePoints, setHessLancasterRightEyePoints] =
    useState({
      punto1_od_x: props.data.punto1_od_x,
      punto1_od_y: props.data.punto1_od_y,
      punto2_od_x: props.data.punto2_od_x,
      punto2_od_y: props.data.punto2_od_y,
      punto3_od_x: props.data.punto3_od_x,
      punto3_od_y: props.data.punto3_od_y,
      punto4_od_x: props.data.punto4_od_x,
      punto4_od_y: props.data.punto4_od_y,
      punto5_od_x: props.data.punto5_od_x,
      punto5_od_y: props.data.punto5_od_y,
      punto6_od_x: props.data.punto6_od_x,
      punto6_od_y: props.data.punto6_od_y,
      punto7_od_x: props.data.punto7_od_x,
      punto7_od_y: props.data.punto7_od_y,
      punto8_od_x: props.data.punto8_od_x,
      punto8_od_y: props.data.punto8_od_y,
      punto9_od_x: props.data.punto9_od_x,
      punto9_od_y: props.data.punto9_od_y,
    });

  const resetPoints = () => {
    setReset(!reset);
  };

  //console.log("currentHessLancasterEdit HL: ", currentHessLancasterEdit);

  return (
    <div className="hess-lancaster-container">
      <div className="hess-lancaster-popup">
        <div className="popup-screen-header">
          <span className="popup-screen-header-title">
            Rejilla de Hess-Lancaster
          </span>
        </div>
        <div className="popup-content">
          <div className="opt-table-tooltip grid-3" id="hess-lancaster">
            <div className="cell cell-label">OI</div>
            <div className="cell"></div>
            <div className="cell cell-label">OD</div>
            <div className="cell">
              <HessLancasterGrid
                eye={0}
                reset={reset}
                points={[
                  {
                    id: 0,
                    x: hessLancasterLeftEyePoints.punto1_oi_x,
                    y: hessLancasterLeftEyePoints.punto1_oi_y,
                  },
                  {
                    id: 1,
                    x: hessLancasterLeftEyePoints.punto2_oi_x,
                    y: hessLancasterLeftEyePoints.punto2_oi_y,
                  },
                  {
                    id: 2,
                    x: hessLancasterLeftEyePoints.punto3_oi_x,
                    y: hessLancasterLeftEyePoints.punto3_oi_y,
                  },
                  {
                    id: 3,
                    x: hessLancasterLeftEyePoints.punto4_oi_x,
                    y: hessLancasterLeftEyePoints.punto4_oi_y,
                  },
                  {
                    id: 4,
                    x: hessLancasterLeftEyePoints.punto5_oi_x,
                    y: hessLancasterLeftEyePoints.punto5_oi_y,
                  },
                  {
                    id: 5,
                    x: hessLancasterLeftEyePoints.punto6_oi_x,
                    y: hessLancasterLeftEyePoints.punto6_oi_y,
                  },
                  {
                    id: 6,
                    x: hessLancasterLeftEyePoints.punto7_oi_x,
                    y: hessLancasterLeftEyePoints.punto7_oi_y,
                  },
                  {
                    id: 7,
                    x: hessLancasterLeftEyePoints.punto8_oi_x,
                    y: hessLancasterLeftEyePoints.punto8_oi_y,
                  },
                  {
                    id: 8,
                    x: hessLancasterLeftEyePoints.punto9_oi_x,
                    y: hessLancasterLeftEyePoints.punto9_oi_y,
                  },
                ]}
                finalPoints={(points) => {
                  setHessLancasterLeftEyePoints({
                    ...hessLancasterLeftEyePoints,
                    punto1_oi_x: points[0].x,
                    punto1_oi_y: points[0].y,
                    punto2_oi_x: points[1].x,
                    punto2_oi_y: points[1].y,
                    punto3_oi_x: points[2].x,
                    punto3_oi_y: points[2].y,
                    punto4_oi_x: points[3].x,
                    punto4_oi_y: points[3].y,
                    punto5_oi_x: points[4].x,
                    punto5_oi_y: points[4].y,
                    punto6_oi_x: points[5].x,
                    punto6_oi_y: points[5].y,
                    punto7_oi_x: points[6].x,
                    punto7_oi_y: points[6].y,
                    punto8_oi_x: points[7].x,
                    punto8_oi_y: points[7].y,
                    punto9_oi_x: points[8].x,
                    punto9_oi_y: points[8].y,
                  });
                }}
              />
            </div>
            <div className="cell"></div>
            <div className="cell">
              <HessLancasterGrid
                eye={1}
                reset={reset}
                points={[
                  {
                    id: 0,
                    x: hessLancasterRightEyePoints.punto1_od_x,
                    y: hessLancasterRightEyePoints.punto1_od_y,
                  },
                  {
                    id: 1,
                    x: hessLancasterRightEyePoints.punto2_od_x,
                    y: hessLancasterRightEyePoints.punto2_od_y,
                  },
                  {
                    id: 2,
                    x: hessLancasterRightEyePoints.punto3_od_x,
                    y: hessLancasterRightEyePoints.punto3_od_y,
                  },
                  {
                    id: 3,
                    x: hessLancasterRightEyePoints.punto4_od_x,
                    y: hessLancasterRightEyePoints.punto4_od_y,
                  },
                  {
                    id: 4,
                    x: hessLancasterRightEyePoints.punto5_od_x,
                    y: hessLancasterRightEyePoints.punto5_od_y,
                  },
                  {
                    id: 5,
                    x: hessLancasterRightEyePoints.punto6_od_x,
                    y: hessLancasterRightEyePoints.punto6_od_y,
                  },
                  {
                    id: 6,
                    x: hessLancasterRightEyePoints.punto7_od_x,
                    y: hessLancasterRightEyePoints.punto7_od_y,
                  },
                  {
                    id: 7,
                    x: hessLancasterRightEyePoints.punto8_od_x,
                    y: hessLancasterRightEyePoints.punto8_od_y,
                  },
                  {
                    id: 8,
                    x: hessLancasterRightEyePoints.punto9_od_x,
                    y: hessLancasterRightEyePoints.punto9_od_y,
                  },
                ]}
                finalPoints={(points) => {
                  setHessLancasterRightEyePoints({
                    ...hessLancasterRightEyePoints,
                    punto1_od_x: points[0].x,
                    punto1_od_y: points[0].y,
                    punto2_od_x: points[1].x,
                    punto2_od_y: points[1].y,
                    punto3_od_x: points[2].x,
                    punto3_od_y: points[2].y,
                    punto4_od_x: points[3].x,
                    punto4_od_y: points[3].y,
                    punto5_od_x: points[4].x,
                    punto5_od_y: points[4].y,
                    punto6_od_x: points[5].x,
                    punto6_od_y: points[5].y,
                    punto7_od_x: points[6].x,
                    punto7_od_y: points[6].y,
                    punto8_od_x: points[7].x,
                    punto8_od_y: points[7].y,
                    punto9_od_x: points[8].x,
                    punto9_od_y: points[8].y,
                  });
                }}
              />
            </div>
            <div className="cell">
              <InputTextArea
                tabIndex={0}
                maxLength={400}
                type="type-full"
                size={0}
                input={currentHessLancasterEdit.observaciones_oi}
                onChange={(e) => {
                  setCurrentHessLancasterEdit({
                    ...currentHessLancasterEdit,
                    observaciones_oi: e,
                  });
                }}
              />
            </div>
            <div className="cell"></div>
            <div className="cell">
              <InputTextArea
                tabIndex={0}
                maxLength={400}
                type="type-full"
                size={0}
                input={currentHessLancasterEdit.observaciones_od}
                onChange={(e) => {
                  setCurrentHessLancasterEdit({
                    ...currentHessLancasterEdit,
                    observaciones_od: e,
                  });
                }}
              />
            </div>
            <div className="cell cell-label cell-start-content">
              Test
              <Dropdown
                tabIndex={0}
                optionList={HESSLANCASTERLIST}
                size={0}
                op={testHL}
                onChange={(e) => {
                  setTestHL(e.text);
                }}
              />
            </div>
            <div className="cell"></div>
            <div className="cell"></div>
          </div>
        </div>
        <div className="hess-lancaster-buttons">
          <ButtonIconH
            text="Limpiar"
            onClick={() => {
              const idHL = currentHessLancasterEdit.idHessLancaster;
              setCurrentHessLancasterEdit({
                ...hessLancasterStructure,
                idHessLancaster: idHL,
              });
              setHessLancasterLeftEyePoints({
                punto1_oi_x: hessLancasterStructure.punto1_oi_x,
                punto1_oi_y: hessLancasterStructure.punto1_oi_y,
                punto2_oi_x: hessLancasterStructure.punto2_oi_x,
                punto2_oi_y: hessLancasterStructure.punto2_oi_y,
                punto3_oi_x: hessLancasterStructure.punto3_oi_x,
                punto3_oi_y: hessLancasterStructure.punto3_oi_y,
                punto4_oi_x: hessLancasterStructure.punto4_oi_x,
                punto4_oi_y: hessLancasterStructure.punto4_oi_y,
                punto5_oi_x: hessLancasterStructure.punto5_oi_x,
                punto5_oi_y: hessLancasterStructure.punto5_oi_y,
                punto6_oi_x: hessLancasterStructure.punto6_oi_x,
                punto6_oi_y: hessLancasterStructure.punto6_oi_y,
                punto7_oi_x: hessLancasterStructure.punto7_oi_x,
                punto7_oi_y: hessLancasterStructure.punto7_oi_y,
                punto8_oi_x: hessLancasterStructure.punto8_oi_x,
                punto8_oi_y: hessLancasterStructure.punto8_oi_y,
                punto9_oi_x: hessLancasterStructure.punto9_oi_x,
                punto9_oi_y: hessLancasterStructure.punto9_oi_y,
              });
              setHessLancasterRightEyePoints({
                punto1_od_x: hessLancasterStructure.punto1_od_x,
                punto1_od_y: hessLancasterStructure.punto1_od_y,
                punto2_od_x: hessLancasterStructure.punto2_od_x,
                punto2_od_y: hessLancasterStructure.punto2_od_y,
                punto3_od_x: hessLancasterStructure.punto3_od_x,
                punto3_od_y: hessLancasterStructure.punto3_od_y,
                punto4_od_x: hessLancasterStructure.punto4_od_x,
                punto4_od_y: hessLancasterStructure.punto4_od_y,
                punto5_od_x: hessLancasterStructure.punto5_od_x,
                punto5_od_y: hessLancasterStructure.punto5_od_y,
                punto6_od_x: hessLancasterStructure.punto6_od_x,
                punto6_od_y: hessLancasterStructure.punto6_od_y,
                punto7_od_x: hessLancasterStructure.punto7_od_x,
                punto7_od_y: hessLancasterStructure.punto7_od_y,
                punto8_od_x: hessLancasterStructure.punto8_od_x,
                punto8_od_y: hessLancasterStructure.punto8_od_y,
                punto9_od_x: hessLancasterStructure.punto9_od_x,
                punto9_od_y: hessLancasterStructure.punto9_od_y,
              });
              setTestHL("");
              resetPoints();
            }}
          />
          <ButtonIconH
            text="Aceptar"
            onClick={() => {
              props.onAccept(
                {
                  ...currentHessLancasterEdit,
                  punto1_oi_x: hessLancasterLeftEyePoints.punto1_oi_x,
                  punto1_oi_y: hessLancasterLeftEyePoints.punto1_oi_y,
                  punto2_oi_x: hessLancasterLeftEyePoints.punto2_oi_x,
                  punto2_oi_y: hessLancasterLeftEyePoints.punto2_oi_y,
                  punto3_oi_x: hessLancasterLeftEyePoints.punto3_oi_x,
                  punto3_oi_y: hessLancasterLeftEyePoints.punto3_oi_y,
                  punto4_oi_x: hessLancasterLeftEyePoints.punto4_oi_x,
                  punto4_oi_y: hessLancasterLeftEyePoints.punto4_oi_y,
                  punto5_oi_x: hessLancasterLeftEyePoints.punto5_oi_x,
                  punto5_oi_y: hessLancasterLeftEyePoints.punto5_oi_y,
                  punto6_oi_x: hessLancasterLeftEyePoints.punto6_oi_x,
                  punto6_oi_y: hessLancasterLeftEyePoints.punto6_oi_y,
                  punto7_oi_x: hessLancasterLeftEyePoints.punto7_oi_x,
                  punto7_oi_y: hessLancasterLeftEyePoints.punto7_oi_y,
                  punto8_oi_x: hessLancasterLeftEyePoints.punto8_oi_x,
                  punto8_oi_y: hessLancasterLeftEyePoints.punto8_oi_y,
                  punto9_oi_x: hessLancasterLeftEyePoints.punto9_oi_x,
                  punto9_oi_y: hessLancasterLeftEyePoints.punto9_oi_y,
                  punto1_od_x: hessLancasterRightEyePoints.punto1_od_x,
                  punto1_od_y: hessLancasterRightEyePoints.punto1_od_y,
                  punto2_od_x: hessLancasterRightEyePoints.punto2_od_x,
                  punto2_od_y: hessLancasterRightEyePoints.punto2_od_y,
                  punto3_od_x: hessLancasterRightEyePoints.punto3_od_x,
                  punto3_od_y: hessLancasterRightEyePoints.punto3_od_y,
                  punto4_od_x: hessLancasterRightEyePoints.punto4_od_x,
                  punto4_od_y: hessLancasterRightEyePoints.punto4_od_y,
                  punto5_od_x: hessLancasterRightEyePoints.punto5_od_x,
                  punto5_od_y: hessLancasterRightEyePoints.punto5_od_y,
                  punto6_od_x: hessLancasterRightEyePoints.punto6_od_x,
                  punto6_od_y: hessLancasterRightEyePoints.punto6_od_y,
                  punto7_od_x: hessLancasterRightEyePoints.punto7_od_x,
                  punto7_od_y: hessLancasterRightEyePoints.punto7_od_y,
                  punto8_od_x: hessLancasterRightEyePoints.punto8_od_x,
                  punto8_od_y: hessLancasterRightEyePoints.punto8_od_y,
                  punto9_od_x: hessLancasterRightEyePoints.punto9_od_x,
                  punto9_od_y: hessLancasterRightEyePoints.punto9_od_y,
                },
                testHL
              );
            }}
          />
          <ButtonIconH
            text="Salir"
            onClick={() => {
              const leftAux = {
                punto1_oi_x: props.data.punto1_oi_x,
                punto1_oi_y: props.data.punto1_oi_y,
                punto2_oi_x: props.data.punto2_oi_x,
                punto2_oi_y: props.data.punto2_oi_y,
                punto3_oi_x: props.data.punto3_oi_x,
                punto3_oi_y: props.data.punto3_oi_y,
                punto4_oi_x: props.data.punto4_oi_x,
                punto4_oi_y: props.data.punto4_oi_y,
                punto5_oi_x: props.data.punto5_oi_x,
                punto5_oi_y: props.data.punto5_oi_y,
                punto6_oi_x: props.data.punto6_oi_x,
                punto6_oi_y: props.data.punto6_oi_y,
                punto7_oi_x: props.data.punto7_oi_x,
                punto7_oi_y: props.data.punto7_oi_y,
                punto8_oi_x: props.data.punto8_oi_x,
                punto8_oi_y: props.data.punto8_oi_y,
                punto9_oi_x: props.data.punto9_oi_x,
                punto9_oi_y: props.data.punto9_oi_y,
              };
              const rightAux = {
                punto1_od_x: props.data.punto1_od_x,
                punto1_od_y: props.data.punto1_od_y,
                punto2_od_x: props.data.punto2_od_x,
                punto2_od_y: props.data.punto2_od_y,
                punto3_od_x: props.data.punto3_od_x,
                punto3_od_y: props.data.punto3_od_y,
                punto4_od_x: props.data.punto4_od_x,
                punto4_od_y: props.data.punto4_od_y,
                punto5_od_x: props.data.punto5_od_x,
                punto5_od_y: props.data.punto5_od_y,
                punto6_od_x: props.data.punto6_od_x,
                punto6_od_y: props.data.punto6_od_y,
                punto7_od_x: props.data.punto7_od_x,
                punto7_od_y: props.data.punto7_od_y,
                punto8_od_x: props.data.punto8_od_x,
                punto8_od_y: props.data.punto8_od_y,
                punto9_od_x: props.data.punto9_od_x,
                punto9_od_y: props.data.punto9_od_y,
              };
              if (
                !areObjectsEqual(leftAux, hessLancasterLeftEyePoints) ||
                !areObjectsEqual(rightAux, hessLancasterRightEyePoints) ||
                props.data.observaciones_od !==
                  currentHessLancasterEdit.observaciones_od ||
                props.data.observaciones_oi !==
                  currentHessLancasterEdit.observaciones_oi ||
                props.test !== testHL
              ) {
                props.createPopupQuestion(
                  `Se han realizado cambios en la información. ¿Desea guardarlos?`,
                  async () => {
                    props.onAccept(
                      {
                        ...currentHessLancasterEdit,
                        punto1_oi_x: hessLancasterLeftEyePoints.punto1_oi_x,
                        punto1_oi_y: hessLancasterLeftEyePoints.punto1_oi_y,
                        punto2_oi_x: hessLancasterLeftEyePoints.punto2_oi_x,
                        punto2_oi_y: hessLancasterLeftEyePoints.punto2_oi_y,
                        punto3_oi_x: hessLancasterLeftEyePoints.punto3_oi_x,
                        punto3_oi_y: hessLancasterLeftEyePoints.punto3_oi_y,
                        punto4_oi_x: hessLancasterLeftEyePoints.punto4_oi_x,
                        punto4_oi_y: hessLancasterLeftEyePoints.punto4_oi_y,
                        punto5_oi_x: hessLancasterLeftEyePoints.punto5_oi_x,
                        punto5_oi_y: hessLancasterLeftEyePoints.punto5_oi_y,
                        punto6_oi_x: hessLancasterLeftEyePoints.punto6_oi_x,
                        punto6_oi_y: hessLancasterLeftEyePoints.punto6_oi_y,
                        punto7_oi_x: hessLancasterLeftEyePoints.punto7_oi_x,
                        punto7_oi_y: hessLancasterLeftEyePoints.punto7_oi_y,
                        punto8_oi_x: hessLancasterLeftEyePoints.punto8_oi_x,
                        punto8_oi_y: hessLancasterLeftEyePoints.punto8_oi_y,
                        punto9_oi_x: hessLancasterLeftEyePoints.punto9_oi_x,
                        punto9_oi_y: hessLancasterLeftEyePoints.punto9_oi_y,
                        punto1_od_x: hessLancasterRightEyePoints.punto1_od_x,
                        punto1_od_y: hessLancasterRightEyePoints.punto1_od_y,
                        punto2_od_x: hessLancasterRightEyePoints.punto2_od_x,
                        punto2_od_y: hessLancasterRightEyePoints.punto2_od_y,
                        punto3_od_x: hessLancasterRightEyePoints.punto3_od_x,
                        punto3_od_y: hessLancasterRightEyePoints.punto3_od_y,
                        punto4_od_x: hessLancasterRightEyePoints.punto4_od_x,
                        punto4_od_y: hessLancasterRightEyePoints.punto4_od_y,
                        punto5_od_x: hessLancasterRightEyePoints.punto5_od_x,
                        punto5_od_y: hessLancasterRightEyePoints.punto5_od_y,
                        punto6_od_x: hessLancasterRightEyePoints.punto6_od_x,
                        punto6_od_y: hessLancasterRightEyePoints.punto6_od_y,
                        punto7_od_x: hessLancasterRightEyePoints.punto7_od_x,
                        punto7_od_y: hessLancasterRightEyePoints.punto7_od_y,
                        punto8_od_x: hessLancasterRightEyePoints.punto8_od_x,
                        punto8_od_y: hessLancasterRightEyePoints.punto8_od_y,
                        punto9_od_x: hessLancasterRightEyePoints.punto9_od_x,
                        punto9_od_y: hessLancasterRightEyePoints.punto9_od_y,
                      },
                      testHL
                    );
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                    props.onCancel();
                  },
                  "Sí",
                  "No"
                );
              } else props.onCancel();
            }}
          />
        </div>
      </div>
    </div>
  );
}
