import React, { useEffect, useState, useRef } from "react";
import { focusElement } from "../js/utils";
import "../css/InputData.css";
import "../css/InputIconSearch.css";
import "../css/InputIconSearchPanel.css";

export default function InputIconSearchPanel(props) {
  const [togglePanel, setTogglePanel] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [optionsFiltered, setOptionsFiltered] = useState(props.options);

  const panelRef = useRef(null);
  const inputRef = useRef(null);
  const optionRefs = useRef([]);

  const getInputSize = () => {
    const sizeClass = {
      0: "input-full",
      1: "input-small",
      2: "input-medium",
      3: "input-large",
      4: "input-super-small",
    };
    return sizeClass[props.size] || "";
  };

  const searchOptions = () => {
    let optionListAux = props.options;
    optionListAux = optionListAux.filter((e) => e.name.includes(props.input));
    setOptionsFiltered(optionListAux);
    if (props.input !== "" && optionListAux.length > 0) {
      setTogglePanel(true);
    }
    if (props.input === optionSelected) {
      setTogglePanel(false);
    }
  };

  const getActiveFamilyIcon = (id) => {
    let icon = "";
    switch (id) {
      case 0:
        icon = "/images/preferenciaAudifonos.svg";
        break;
      case 1:
        icon = "/images/preferenciaGafasGraduadas.svg";
        break;
      case 2:
        icon = "/images/preferenciaLentillas.svg";
        break;
      case 3:
        icon = "/images/preferenciaLiquido.svg";
        break;
      case 4:
        icon = "/images/accommodative-blue.svg";
        break;
      case 5:
        icon = "/images/preferenciaVarios.svg";
        break;
      case 6:
        icon = "/images/preferenciaGafasDeSol.svg";
        break;
      default:
        break;
    }
    return icon;
  };

  useEffect(() => {
    if (props.inactive) {
      setTogglePanel(false);
    }
  }, [props.inactive]);

  useEffect(() => {
    searchOptions();
  }, [props.input]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (togglePanel) {
        if (e.key === "ArrowDown") {
          setHighlightedIndex((prevIndex) =>
            prevIndex < optionsFiltered.length - 1 ? prevIndex + 1 : 0
          );
        } else if (e.key === "ArrowUp") {
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : optionsFiltered.length - 1
          );
        } else if (e.key === "Enter") {
          if (highlightedIndex >= 0) {
            const selectedOption = optionsFiltered[highlightedIndex];
            setOptionSelected(selectedOption.name);
            props.onChange(selectedOption.name);
            setTogglePanel(false);
          }
        } else if (e.key === "Escape") {
          setTogglePanel(false);
        }
      }
    };

    const handleClickOutside = (e) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(e.target) &&
        !inputRef.current.contains(e.target)
      ) {
        setTogglePanel(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [togglePanel, highlightedIndex, optionsFiltered]);

  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      const highlightedOption = optionRefs.current[highlightedIndex];
      const panel = panelRef.current;
      const optionTop = highlightedOption.offsetTop;
      const optionBottom = optionTop + highlightedOption.clientHeight;
      const panelTop = panel.scrollTop;
      const panelBottom = panelTop + panel.clientHeight;

      if (optionTop < panelTop) {
        panel.scrollTop = optionTop;
      } else if (optionBottom > panelBottom) {
        panel.scrollTop = optionBottom - panel.clientHeight;
      }
    }
  }, [highlightedIndex]);

  return (
    <div className="input-search-panel-container">
      <div
        className={`input-data input-icon-search ${getInputSize()} ${
          props.inactive ? "input-search-inactive" : ""
        }`}
        ref={inputRef}
      >
        <img
          src="/images/search-blue.svg"
          loading="lazy"
          width="14"
          height="14"
          alt="icon"
          className="input-icon-search-img"
        />
        <input
          id={props.id}
          tabIndex={1}
          type={props.type ? props.type : "text"}
          disabled={props.inactive}
          placeholder={props.placeholder}
          onChange={(e) => {
            props.onChange(e.target.value.toUpperCase());
            setHighlightedIndex(-1);
          }}
          onClick={() => {
            setTogglePanel(true);
          }}
          value={props.input}
        ></input>
        {props.input && (
          <img
            src="/images/close-dark.svg"
            loading="lazy"
            width="14"
            height="14"
            alt="icon"
            className="input-icon-search-clear"
            onClick={() => {
              props.onClear();
              setHighlightedIndex(-1);
            }}
          />
        )}
        <img
          src="/images/list-blue.svg"
          loading="lazy"
          width="14"
          height="14"
          alt="icon"
          className="input-icon-search-clear"
          style={{ marginLeft: "10px" }}
          onClick={(e) => {
            e.stopPropagation();
            if (!props.inactive) {
              setTogglePanel((prevToggle) => !prevToggle);
              setHighlightedIndex(-1);
              focusElement(1);
            }
          }}
        />
      </div>
      {togglePanel && (
        <div className="input-search-panel" ref={panelRef}>
          {optionsFiltered.map((e, i) => {
            return (
              <div
                className={`search-result-info ${
                  props.input === e.name || highlightedIndex === i
                    ? "search-result-info-selected"
                    : ""
                }`}
                key={i}
                onClick={() => {
                  setOptionSelected(e.name);
                  props.onChange(e.name);
                  setTogglePanel(false);
                }}
                ref={(el) => (optionRefs.current[i] = el)}
              >
                {e.name}
                <div className="active-families-icon">
                  {e.activeFamilies.map((id, index) => {
                    return (
                      <img
                        src={getActiveFamilyIcon(id)}
                        loading="lazy"
                        alt="icon"
                        style={{ width: "12px" }}
                        key={index}
                      />
                    );
                  })}
                </div>
                <img
                  src="/images/eye-orange.svg"
                  loading="lazy"
                  alt="icon"
                  className="icon-pref"
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log("SE ABRIRÁ POPUP CON LA INFORMACIÓN.");
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
