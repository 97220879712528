import React, { useEffect } from "react";
import "../css/InputData.css";
import {
  formatField,
  reformatField,
  checkFormat,
  onFocusField,
} from "../js/format";
import { inputOnEnter } from "../js/utils";

export default function InputData(props) {
  let inputRef = React.createRef();

  const getInputSize = () => {
    const sizeClass = {
      0: "input-full",
      1: "input-small",
      2: "input-medium",
      3: "input-large",
      4: "input-super-small",
    };
    return sizeClass[props.size] || "";
  };

  useEffect(() => {
    inputRef.current.value = reformatField(
      inputRef.current,
      inputRef.current.value,
      props.type,
      true
    );
  });

  return (
    <div
      className={`input-data ${getInputSize()} ${
        props.extra ? props.extra : ""
      } ${props.error ? "input-error" : ""} ${props.bkg ? "marked-bkg" : ""}`}
    >
      <input
        ref={inputRef}
        id={props.id}
        tabIndex={props.tabIndex}
        type={props.type ? props.type : "text"}
        disabled={props.inactive}
        maxLength={props.maxLength}
        onChange={(e) => {
          e.target.value = formatField(
            inputRef.current,
            e.target.value,
            props.type,
            props.dontForceUppercase
          );
          props.onChange(e.target.value);
        }}
        value={props.input}
        onBlur={(e) => {
          e.target.value = reformatField(
            inputRef.current,
            e.target.value,
            props.type,
            true
          );
          if (props.onMalformed) {
            if (!checkFormat(e.target.value, props.type)) {
              props.onMalformed();
            }
          }
        }}
        onKeyUp={(e) => inputOnEnter(e)}
        onFocus={(e) => {
          if (props.considerFocus) {
            e.target.value = onFocusField(
              inputRef.current,
              props.type,
              e.target.value
            );
          }
          if (props.onFocus) {
            props.onFocus();
          }
        }}
      ></input>
      {props.icon && (
        <button
          onClick={() => {
            props.onClick();
          }}
        >
          <img src={props.icon} loading="lazy" alt="Icon" />
        </button>
      )}
    </div>
  );
}
