import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  binocularStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
  FREQUENCYLIST,
  LATERALITYLIST,
  EYELIST,
  COMITANCELIST,
  COMITANCETYPELIST,
  VERGENCELIST,
  SUBJETIVEDESVIATIONLIST,
  DISPARITYNEARLIST,
  DISPARITYFARLIST,
  CYCLODEVLIST,
  PPCLIST,
  focusElement,
} from "../js/utils";
import moment from "moment";

export default function AdvancedOptometryBinocular(props) {
  const [currentBinocular, setCurrentBinocular] = useState(binocularStructure);
  const [currentBinocularEdit, setCurrentBinocularEdit] = useState(
    props.currentBinocularEdit
  );
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [binocularId, setBinocularId] = useState(props.binocularId);

  //console.log("*****************");
  //console.log("selectedDate: ", selectedDate);
  console.log("currentBinocularEdit: ", currentBinocularEdit);
  //console.log("binocularId: ", binocularId);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getBinocularHistory = async () => {
    const response = await post("ListarFechasHistoricoBinocular.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idBinocular === binocularId;
      });
      if (found) {
        setSelectedDate({
          id: found.idBinocular,
          text: found.fecha,
        });
        setBinocularId(found.idBinocular);
      }
    }
    setDateHistoryList(response);
  };

  const getBinocular = async (binocularId) => {
    const response = await post("ObtenerBinocular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idBinocular: binocularId,
    });

    setCurrentBinocularEdit({
      ...response,
      idPaciente: props.patientId,
      idBinocular: binocularId,
    });
    setCurrentBinocular({
      ...response,
      idPaciente: props.patientId,
      idBinocular: binocularId,
    });
  };

  const resetBinocularState = () => {
    setCurrentBinocularEdit({
      ...binocularStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentBinocular({
      ...binocularStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setBinocularId(-1);
    props.saveLastBinocularIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveBinocular = async () => {
    const response = await post("GuardarBinocular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idBinocular: binocularId,
      idPaciente: props.patientId,
      ...currentBinocularEdit,
    });
    setSelectedDate({
      id: response.idBinocular,
      text: currentBinocularEdit.fecha,
    });
    setBinocularId(response.idBinocular);
    setCurrentBinocular(currentBinocularEdit);
    props.saveLastBinocularIdVisited(response.idBinocular);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteBinocular = async () => {
    await post("EliminarBinocular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idBinocular: binocularId,
      idUsuario: userData.id,
    });
  };

  const selectBinocularAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoBinocular.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idBinocular,
        text: response[0].fecha,
      });
      setBinocularId(response[0].idBinocular);
      setDateHistoryList(response);
      await getBinocular(response[0].idBinocular);
      props.saveLastBinocularIdVisited(response[0].idBinocular);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setBinocularId(-1);
      props.saveLastBinocularIdVisited(-1);
      setCurrentBinocular(binocularStructure);
      setCurrentBinocularEdit(binocularStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (binocularId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idBinocular,
          text: dateHistoryList[0].fecha,
        });
        setBinocularId(dateHistoryList[0].idBinocular);
        props.saveLastBinocularIdVisited(dateHistoryList[0].idBinocular);
        await getBinocular(dateHistoryList[0].idBinocular);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentBinocularEdit(currentBinocular);
      }
    } else {
      setCurrentBinocularEdit(currentBinocular);
    }
  };

  const calculatePrismRequired = () => {
    if (currentBinocularEdit.criterio_sheard_vfp_e === "") {
      props.createPopupQuestion(
        `VFP E de lejos no puede estar vacío para calcular Sheard.`,
        async () => {
          focusElement(113);
          props.removePopupQuestion();
        },
        null
      );
    } else if (
      currentBinocularEdit.criterio_sheard_vfp_e &&
      currentBinocularEdit.criterio_sheard_xf === ""
    ) {
      props.createPopupQuestion(
        `XF no puede estar vacío para calcular Sheard.`,
        async () => {
          focusElement(114);
          props.removePopupQuestion();
        },
        null
      );
    } else if (
      !(
        currentBinocularEdit.criterio_sheard_vfp_e <
        2 * currentBinocularEdit.criterio_sheard_xf
      )
    ) {
      props.createPopupQuestion(
        `El criterio de Sheard no es aplicable. No se cumple que VFP E < 2 x XF`,
        async () => {
          focusElement(113);
          props.removePopupQuestion();
        },
        null
      );
    } else {
      // CÁLCULO DE PRISMA REQUERIDO POR OPTIWIN
      // IMPLEMENTAR AQUÍ
    }
  };

  const calculatePercival = () => {
    if (currentBinocularEdit.h_vfp_ro_lejos === "") {
      props.createPopupQuestion(
        `VFP Ro de lejos no puede estar vacío para calcular Percival.`,
        async () => {
          focusElement(93);
          props.removePopupQuestion();
        },
        null
      );
    } else if (currentBinocularEdit.h_vfn_ro_lejos === "") {
      props.createPopupQuestion(
        `VFN Ro de lejos no puede estar vacío para calcular Percival.`,
        async () => {
          focusElement(90);
          props.removePopupQuestion();
        },
        null
      );
    } else {
      // CÁLCULO DE PRISMA REQUERIDO POR OPTIWIN
      // IMPLEMENTAR AQUÍ
    }
  };

  useEffect(() => {
    getBinocularHistory();
    getOptometristList();
    if (binocularId !== -1) {
      getBinocular(binocularId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentBinocularEdit({
        ...currentBinocularEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentBinocular({
        ...currentBinocular,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [binocularId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentBinocular, currentBinocularEdit),
      dataToSave: currentBinocularEdit,
    });
  }, [currentBinocularEdit]);

  useEffect(() => {
    setCurrentBinocularEdit(props.currentBinocularEdit);
  }, [props.currentBinocularEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentBinocularEdit.diagnostico) {
      setCurrentBinocularEdit({
        ...currentBinocularEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentBinocularEdit.fecha}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idBinocular,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idBinocular,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={binocularId === -1}
                onChange={async (e) => {
                  setCurrentBinocularEdit(binocularStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setBinocularId(e.id);
                  props.saveLastBinocularIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentBinocularEdit.optometrista}
                onChange={(e) => {
                  setCurrentBinocularEdit({
                    ...currentBinocularEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              binocularId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (binocularId !== -1) {
                if (!areObjectsEqual(currentBinocular, currentBinocularEdit)) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveBinocular();
                      props.removePopupQuestion();
                      resetBinocularState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetBinocularState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetBinocularState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              binocularStructure.fecha = currentBinocularEdit.fecha;
              if (areObjectsEqual(currentBinocularEdit, binocularStructure)) {
                props.createPopupQuestion(
                  `La ficha de binocular actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveBinocular();
                await getBinocularHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentBinocular, currentBinocularEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentBinocular, currentBinocularEdit) ||
                binocularId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              binocularId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (binocularId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de binocular?`,
                  async () => {
                    await getBinocular(binocularId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              binocularId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (binocularId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de binocular?`,
                  async () => {
                    await deleteBinocular();
                    await getBinocularHistory();
                    await selectBinocularAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              binocularId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (binocularId !== -1) {
                props.openDiagnosisPopup(currentBinocularEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              binocularId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (binocularId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Desviaciones Objetivas</div>
            <div className="opt-table-content grid-6">
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Con compensación</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  CC
                </span>
              </div>
              <div className="cell opt-table-subtitle combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Con compensación</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  SC
                </span>
              </div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle">Cover Test</div>
              <div className="cell cell-label">H</div>
              <div className="cell cell-label">V</div>
              <div className="cell cell-label">H</div>
              <div className="cell cell-label">V</div>
              <div className="cell"></div>
              <div className="cell cell-label">Lejos</div>
              <div className="cell">
                <InputData
                  tabIndex={54}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_cc_lejos_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_cc_lejos_h: e,
                    });
                  }}
                  icon="/images/link.svg"
                  onClick={() => {
                    props.onCoverTest(currentBinocularEdit);
                    props.toggleCoverTest();
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={55}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_cc_lejos_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_cc_lejos_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={56}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_sc_lejos_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_sc_lejos_h: e,
                    });
                  }}
                  icon="/images/link.svg"
                  onClick={() => {
                    props.onCoverTest(currentBinocularEdit);
                    props.toggleCoverTest();
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={57}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_sc_lejos_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_sc_lejos_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados Desviación Objetiva:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Lejos: 1 XF ± 2 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Cerca: 3 XF ± 3 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Vertical: Ortodesviación
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label">Cerca</div>
              <div className="cell">
                <InputData
                  tabIndex={58}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_cc_cerca_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_cc_cerca_h: e,
                    });
                  }}
                  icon="/images/link.svg"
                  onClick={() => {
                    props.onCoverTest(currentBinocularEdit);
                    props.toggleCoverTest();
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={59}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_cc_cerca_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_cc_cerca_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={60}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_sc_cerca_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_sc_cerca_h: e,
                    });
                  }}
                  icon="/images/link.svg"
                  onClick={() => {
                    props.onCoverTest(currentBinocularEdit);
                    props.toggleCoverTest();
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={61}
                  maxLength={10}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.cover_test_sc_cerca_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      cover_test_sc_cerca_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-6"></div>
            </div>
            <div
              className="opt-table-content grid-8"
              id="desviaciones-objetivas-frec"
            >
              <div className="cell cell-label combined-cells-3 cell-start-content">
                Frecuencia
                <Dropdown
                  id="desviacion-objetiva-frecuencia"
                  tabIndex={62}
                  optionList={FREQUENCYLIST}
                  size={0}
                  op={currentBinocularEdit.frecuencia}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      frecuencia: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell combined-cells-5"></div>
              <div className="cell cell-label combined-cells-3 cell-start-content">
                Lateralidad
                <Dropdown
                  id="desviacion-objetiva-lateralidad"
                  tabIndex={63}
                  optionList={LATERALITYLIST}
                  size={0}
                  op={currentBinocularEdit.lateralidad}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      lateralidad: e.text,
                      lateralidad_ojo:
                        e.text === ""
                          ? e.text
                          : currentBinocularEdit.lateralidad_ojo,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label combined-cells-3">
                Ojo
                <Dropdown
                  id="desviacion-objetiva-ojo"
                  tabIndex={64}
                  optionList={EYELIST}
                  size={4}
                  disabled={currentBinocularEdit.lateralidad === ""}
                  op={currentBinocularEdit.lateralidad_ojo}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      lateralidad_ojo: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell combined-cells-2"></div>
              <div className="cell cell-label combined-cells-3 cell-start-content">
                Comitancia
                <Dropdown
                  id="desviacion-objetiva-comitancia"
                  tabIndex={65}
                  optionList={COMITANCELIST}
                  size={0}
                  op={currentBinocularEdit.comitancia}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      comitancia: e.text,
                      tipo_comitancia:
                        e.text === ""
                          ? e.text
                          : currentBinocularEdit.tipo_comitancia,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label combined-cells-3">
                Tipo
                <Dropdown
                  id="desviacion-objetiva-tipo"
                  tabIndex={66}
                  optionList={COMITANCETYPELIST}
                  size={4}
                  disabled={currentBinocularEdit.comitancia === ""}
                  op={currentBinocularEdit.tipo_comitancia}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      tipo_comitancia: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell combined-cells-2"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Por cada mm de desviación 14 Δ
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  Test de Hirschberg
                </span>
              </div>
              <div className="cell opt-table-subtitle combined-cells-3 cell-start-content">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Por cada mm de desviación 14 Δ
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  Test de Krimsky
                </span>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados Desviación Objetiva:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Lejos: 1 XF ± 2 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Cerca: 3 XF ± 3 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Vertical: Ortodesviación
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={67}
                  maxLength={200}
                  id="test-hirschberg"
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.test_hirschberg}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      test_hirschberg: e,
                    });
                  }}
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={68}
                  maxLength={200}
                  id="test-krimsky"
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.test_krimsky}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      test_krimsky: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Vergencias</div>
            <div className="opt-table-content grid-8">
              <div className="cell cell-label combined-cells-3 cell-start-content">
                Test
                <Dropdown
                  tabIndex={88}
                  optionList={VERGENCELIST}
                  size={0}
                  op={currentBinocularEdit.vergencias}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      vergencias: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Horizontales:
                          </div>
                          <div className="cell"></div>
                          <div className="cell combined-cells-2">Lejos</div>
                          <div className="cell combined-cells-2">Cerca</div>
                          <div className="cell">VFN:</div>
                          <div className="cell combined-cells-2">
                            -/7/4 ± -/3/2
                          </div>
                          <div className="cell combined-cells-2">
                            13/21/13 ± 4/4/5
                          </div>
                          <div className="cell">VFP:</div>
                          <div className="cell combined-cells-2">
                            9/19/10 ± 4/8/4
                          </div>
                          <div className="cell combined-cells-2">
                            17/21/11 ± 5/6/7
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Verticales:
                          </div>
                          <div className="cell"></div>
                          <div className="cell combined-cells-2">Lejos</div>
                          <div className="cell combined-cells-2">Cerca</div>
                          <div className="cell">SUPRA:</div>
                          <div className="cell combined-cells-2">3/1</div>
                          <div className="cell combined-cells-2">3/1</div>
                          <div className="cell">INFRA:</div>
                          <div className="cell combined-cells-2">3/1</div>
                          <div className="cell combined-cells-2">3/1</div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell combined-cells-4"></div>
            </div>
            <div className="opt-table-content grid-7">
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Vergencia Fusional Negativa</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  VFN
                </span>
              </div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-2">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">Vergencia Fusional Positiva</div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  VFP
                </span>
              </div>
              <div className="cell opt-table-subtitle">H</div>
              <div className="cell cell-label">E</div>
              <div className="cell cell-label">Ro</div>
              <div className="cell cell-label">Re</div>
              <div className="cell cell-label">E</div>
              <div className="cell cell-label">Ro</div>
              <div className="cell cell-label">Re</div>
              <div className="cell cell-label">Lejos</div>
              <div className="cell">
                <InputData
                  tabIndex={89}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_e_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_e_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={90}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_ro_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_ro_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={91}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_re_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_re_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={92}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_e_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_e_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={93}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_ro_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_ro_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={94}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_re_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_re_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">Cerca</div>
              <div className="cell">
                <InputData
                  tabIndex={95}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_e_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_e_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={96}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_ro_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_ro_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={97}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfn_re_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfn_re_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={98}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_e_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_e_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={99}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_ro_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_ro_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={100}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.h_vfp_re_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      h_vfp_re_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-2">
                SUPRA
              </div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle combined-cells-2">
                INFRA
              </div>
              <div className="cell"></div>
              <div className="cell opt-table-subtitle">V</div>
              <div className="cell cell-label">Ro</div>
              <div className="cell cell-label">Re</div>
              <div className="cell"></div>
              <div className="cell cell-label">Ro</div>
              <div className="cell cell-label">Re</div>
              <div className="cell"></div>
              <div className="cell cell-label">Lejos</div>
              <div className="cell">
                <InputData
                  tabIndex={101}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_supra_ro_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_supra_ro_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={102}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_supra_re_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_supra_re_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={105}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_infra_ro_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_infra_ro_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={106}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_infra_re_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_infra_re_lejos: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label">Cerca</div>
              <div className="cell">
                <InputData
                  tabIndex={103}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_supra_ro_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_supra_ro_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={104}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_supra_re_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_supra_re_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell">
                <InputData
                  tabIndex={107}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_infra_ro_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_infra_ro_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={108}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.v_infra_re_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      v_infra_re_cerca: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Desviaciones Subjetivas</div>
            <div className="opt-table-content grid-8">
              <div className="cell combined-cells-5 cell-start-content opt-table-subtitle">
                Horizontales y Verticales
              </div>
              <div className="cell cell-label">H</div>
              <div className="cell cell-label">V</div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Test
                <Dropdown
                  id="desviacion-objetiva-frecuencia"
                  tabIndex={69}
                  optionList={SUBJETIVEDESVIATIONLIST}
                  size={0}
                  op={currentBinocularEdit.desviacion_hor_ver_test_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_test_lejos: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label">Lejos</div>
              <div className="cell">
                <InputData
                  tabIndex={70}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.desviacion_hor_ver_lejos_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_lejos_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={71}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.desviacion_hor_ver_lejos_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_lejos_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados Desviación Subjetiva:
                          </div>
                          <div className="cell cell-start-content combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Lejos: 1 XF ± 2 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Cerca: 3 XF ± 3 Δ
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Vertical: Ortodesviación
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Test
                <Dropdown
                  tabIndex={72}
                  optionList={SUBJETIVEDESVIATIONLIST}
                  size={0}
                  op={currentBinocularEdit.desviacion_hor_ver_test_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_test_cerca: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label">Cerca</div>
              <div className="cell">
                <InputData
                  tabIndex={73}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.desviacion_hor_ver_cerca_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_cerca_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={74}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.desviacion_hor_ver_cerca_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      desviacion_hor_ver_cerca_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell combined-cells-5 cell-start-content opt-table-subtitle">
                Disparidad de Fijación y Foria Asociada
              </div>
              <div className="cell cell-label">H</div>
              <div className="cell cell-label">V</div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Lejos
                <Dropdown
                  tabIndex={75}
                  optionList={DISPARITYFARLIST}
                  size={0}
                  op={currentBinocularEdit.disparidad_foria_test_lejos}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_foria_test_lejos: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label">Disp.</div>
              <div className="cell">
                <InputData
                  tabIndex={76}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.disparidad_lejos_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_lejos_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={77}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.disparidad_lejos_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_lejos_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Ortodisparidad para todas las distancias
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell cell-label combined-cells-4 cell-start-content"></div>
              <div className="cell cell-label">Foria</div>
              <div className="cell">
                <InputData
                  tabIndex={78}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.foria_lejos_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      foria_lejos_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={79}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.foria_lejos_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      foria_lejos_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Cerca
                <Dropdown
                  tabIndex={80}
                  optionList={DISPARITYNEARLIST}
                  size={0}
                  op={currentBinocularEdit.disparidad_foria_test_cerca}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_foria_test_cerca: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label">Disp.</div>
              <div className="cell">
                <InputData
                  tabIndex={81}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.disparidad_cerca_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_cerca_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={82}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.disparidad_cerca_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      disparidad_cerca_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-4 cell-start-content"></div>
              <div className="cell cell-label">Foria</div>
              <div className="cell">
                <InputData
                  tabIndex={83}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.foria_cerca_h}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      foria_cerca_h: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={84}
                  maxLength={20}
                  type="type-full"
                  size={0}
                  input={currentBinocularEdit.foria_cerca_v}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      foria_cerca_v: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell combined-cells-8 cell-start-content opt-table-subtitle">
                Ciclodesviaciones
              </div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                Ojo
                <Dropdown
                  tabIndex={85}
                  optionList={EYELIST}
                  size={4}
                  op={currentBinocularEdit.ciclo_desviaciones_ojo}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ciclo_desviaciones_ojo: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={86}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.ciclo_desviaciones_grados}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ciclo_desviaciones_grados: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-start-content">º</div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                <Dropdown
                  tabIndex={87}
                  optionList={CYCLODEVLIST}
                  size={0}
                  op={currentBinocularEdit.ciclo_desviaciones_tipo}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ciclo_desviaciones_tipo: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Ortodisparidad torsional para todas las distancias
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell opt-table-subtitle combined-cells-4 cell-start-content">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="cell">
                          Punto Próximo de Convergencia
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  PPC
                </span>
              </div>
              <div className="cell cell-label">Ro</div>
              <div className="cell cell-label">Re</div>
              <div className="cell"></div>
              <div className="cell"></div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Test
                <Dropdown
                  tabIndex={109}
                  optionList={PPCLIST}
                  size={0}
                  op={currentBinocularEdit.ppc_test}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ppc_test: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={110}
                  type="type-float2"
                  size={0}
                  input={currentBinocularEdit.ppc_ro}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ppc_ro: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={111}
                  type="type-float2"
                  size={0}
                  input={currentBinocularEdit.ppc_re}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      ppc_re: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Estímulo Acomodativo
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            2,5/4,5 ± 2,5 cm
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Luz Puntual y Filtro Rojo:
                          </div>
                          <div className="cell cell-start-content combined-cells-5">
                            Los valores aumentan aprox. 0,5cm respecto a los
                            anteriores
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell opt-table-subtitle combined-cells-8 cell-start-content">
                Flexibilidad de Vergencias
              </div>
              <div className="cell">
                <InputData
                  tabIndex={112}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.flexibilidad_vergencias}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      flexibilidad_vergencias: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">cpm</div>
              <div className="cell">
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            7 ± 1 cpm
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell combined-cells-4"></div>
              <div className="cell combined-cells-8"></div>
              <div className="cell opt-table-subtitle combined-cells-6 cell-start-content">
                Criterio de Sheard
              </div>
              <div className="cell">
                <button
                  className="cell-icon"
                  onClick={() => calculatePrismRequired()}
                >
                  <img
                    src="/images/extract-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: <div className="cell">Prisma Requerido</div>,
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell"></div>
              <div className="cell cell-label cell-start-content">Si VFP E</div>
              <div className="cell">
                <InputData
                  tabIndex={113}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.criterio_sheard_vfp_e}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      criterio_sheard_vfp_e: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label cell-start-content">
                {"< 2 X"}
              </div>
              <div className="cell cell-label">
                <span
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: <div className="cell">Cover Test LH si XF</div>,
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                >
                  XF
                </span>
              </div>
              <div className="cell">
                <InputData
                  tabIndex={114}
                  type="type-integer"
                  size={0}
                  input={currentBinocularEdit.criterio_sheard_xf}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      criterio_sheard_xf: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label">=</div>
              <div className="cell">
                <InputData
                  tabIndex={115}
                  type="type-float2"
                  size={0}
                  input={currentBinocularEdit.criterio_sheard_valor}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      criterio_sheard_valor: e,
                    });
                  }}
                ></InputData>
              </div>

              <div className="cell">
                Δ
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Si es menor o igual a cero, el paciente no necesita
                            compensación prismática.
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
              <div className="cell combined-cells-8"></div>
              <div className="cell opt-table-subtitle combined-cells-6 cell-start-content">
                Criterio de Percival
              </div>
              <div className="cell">
                <button
                  className="cell-icon"
                  onClick={() => calculatePercival()}
                >
                  <img
                    src="/images/extract-blue.svg"
                    loading="lazy"
                    alt="Icon"
                    className="cell-icon"
                    onMouseEnter={(event) => {
                      const data = {
                        top: event.clientY,
                        left: event.clientX,
                        content: <div className="cell">Prisma Requerido</div>,
                      };
                      props.openTooltip(data);
                    }}
                    onMouseLeave={() => {
                      props.closeTooltip();
                    }}
                  ></img>
                </button>
              </div>
              <div className="cell cell-label combined-cells-5">
                Si Foria NO está en el 1/3 Medio del Rango de Vergencias
              </div>
              <div className="cell cell-label">=</div>
              <div className="cell">
                <InputData
                  tabIndex={116}
                  type="type-float2"
                  size={0}
                  input={currentBinocularEdit.criterio_percival_valor}
                  onChange={(e) => {
                    setCurrentBinocularEdit({
                      ...currentBinocularEdit,
                      criterio_percival_valor: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell">
                Δ
                <img
                  src="/images/info-blue.svg"
                  loading="lazy"
                  alt="Icon"
                  className="cell-icon"
                  onMouseEnter={(event) => {
                    const data = {
                      top: event.clientY,
                      left: event.clientX,
                      content: (
                        <div className="opt-table-tooltip grid-5">
                          <div className="cell combined-cells-5 cell-start-content">
                            Valores Normalizados:
                          </div>
                          <div className="cell combined-cells-5"></div>
                          <div className="cell cell-start-content combined-cells-5">
                            Si es menor o igual a cero, el paciente no necesita
                            compensación prismática.
                          </div>
                        </div>
                      ),
                    };
                    props.openTooltip(data);
                  }}
                  onMouseLeave={() => {
                    props.closeTooltip();
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
