import React from "react";
import "../css/Button.css";

export default function Button(props) {
  return (
    <button
      className={`button-4 ${props.login ? "login" : ""}`}
      onClick={props.onPress}
    >
      <div className="p1 bold">{props.title}</div>
    </button>
  );
}
