import React, { useState, useEffect } from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import { gridXValues, gridYValues, initialAudiometryPoints } from "../js/utils";

export default function MyInteractiveLineChart({
  color,
  showAir,
  showBone,
  showThreshold,
  selectAir,
  selectBone,
  selectThreshold,
  bkgColor,
  airPoints,
  bonePoints,
  thresholdPoints,
  handlePoints,
}) {
  const [airData, setAirData] = useState(airPoints);
  const [boneData, setBoneData] = useState(bonePoints);
  const [thresholdData, setThresholdData] = useState(thresholdPoints);

  useEffect(() => {
    setAirData(airPoints);
  }, [airPoints]);

  useEffect(() => {
    setBoneData(bonePoints);
  }, [bonePoints]);

  useEffect(() => {
    setThresholdData(thresholdPoints);
  }, [thresholdPoints]);

  const xValues = gridXValues;
  const yValues = gridYValues;

  const yMin = -120;
  const yMax = 20;

  const handlePlotClick = (event) => {
    const {
      nativeEvent: { offsetX, offsetY },
    } = event;

    const plotWidth = 480;
    const plotHeight = 460;
    const marginLeft = 70;
    const marginTop = 10;

    // Ajustar las coordenadas del clic para tener en cuenta los márgenes
    const adjustedOffsetX = offsetX - marginLeft;
    const adjustedOffsetY = offsetY - marginTop;

    // Asegurarse de que el clic esté dentro del área interactiva
    if (
      adjustedOffsetX < 0 ||
      adjustedOffsetX > plotWidth ||
      adjustedOffsetY < 0 ||
      adjustedOffsetY > plotHeight
    ) {
      return;
    }

    // Calcular la coordenada X del clic
    const xIndex = Math.round(
      (adjustedOffsetX / plotWidth) * (xValues.length - 1)
    );
    const newXValue = xValues[xIndex];

    // Calcular la coordenada Y del clic
    const newYValue = yMax - (adjustedOffsetY / plotHeight) * (yMax - yMin);

    // Actualiza el punto seleccionado
    if (selectAir) {
      const newAirData = airData.map((point) => {
        if (point.x === newXValue) {
          return { ...point, y: Math.floor(newYValue) };
        }
        return point;
      });
      setAirData(newAirData);
      handlePoints(newAirData);
    } else if (selectBone) {
      const newBoneData = boneData.map((point) => {
        if (point.x === newXValue) {
          return { ...point, y: Math.floor(newYValue) };
        }
        return point;
      });
      handlePoints(newBoneData);
      setBoneData(newBoneData);
    } else if (selectThreshold) {
      const newThresholdData = thresholdData.map((point) => {
        if (point.x === newXValue) {
          return { ...point, y: Math.floor(newYValue) };
        }
        return point;
      });
      handlePoints(newThresholdData);
      setThresholdData(newThresholdData);
    }
  };

  return (
    <div className="chart-container">
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 66,
          width: "488px",
          height: "460px",
          backgroundColor: bkgColor,
          pointerEvents: "none",
        }}
      ></div>
      <XYPlot
        width={600}
        height={500}
        xType="ordinal"
        yType="linear"
        xDomain={xValues}
        yDomain={[Math.min(...yValues), Math.max(...yValues)]}
        onClick={handlePlotClick}
        margin={{ left: 40, right: 20, top: 10, bottom: 30 }}
        style={{ position: "relative" }}
      >
        <HorizontalGridLines style={{ stroke: "#a6dfff" }} />
        <VerticalGridLines style={{ stroke: "#a6dfff" }} />
        <XAxis
          title="Hz"
          style={{
            line: { stroke: "#a6dfff" },
            text: { fontSize: "12px" },
          }}
          tickValues={xValues}
          tickFormat={(v) => `${v}`}
        />
        <YAxis
          title="dB"
          style={{
            line: { stroke: "#a6dfff" },
            text: { fontSize: "12px" },
          }}
          tickValues={yValues}
          tickFormat={(v) => `${v > 0 ? "+" + v : v}`}
          orientation="left"
        />
        {showAir && (
          <LineSeries
            className="first-series"
            data={airData}
            color={color}
            style={{
              strokeLinejoin: "round",
              strokeWidth: 3,
              fill: "transparent",
            }}
          />
        )}
        {showBone && (
          <LineSeries
            className="second-series"
            data={boneData}
            color={color}
            style={{
              strokeLinejoin: "round",
              strokeWidth: 3,
              fill: "transparent",
            }}
            strokeDasharray="12, 5"
          />
        )}
        {showThreshold && (
          <LineSeries
            className="third-series"
            data={thresholdData}
            color={color}
            style={{
              strokeLinejoin: "round",
              strokeWidth: 2,
              fill: "transparent",
            }}
            strokeDasharray="4, 4"
          />
        )}
        {!showAir && !showBone && !showThreshold && (
          <LineSeries data={initialAudiometryPoints} color={"transparent"} />
        )}
      </XYPlot>
    </div>
  );
}
