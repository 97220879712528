import React from "react";
import "../css/InputTextArea.css";

import { formatField, reformatField, checkFormat } from "../js/format";
/* import { inputOnEnter } from "../js/utils"; */

export default function InputTextArea(props) {
  let inputRef = React.createRef();

  const getInputSize = () => {
    const sizeClass = {
      0: "input-full",
      1: "input-small",
      2: "input-medium",
      3: "input-large",
      4: "input-super-small",
    };
    return sizeClass[props.size] || "";
  };

  return (
    <div
      className={`input-data input-text-area ${getInputSize()} ${
        props.extra ? props.extra : ""
      } ${props.inactive ? "inactive-textarea" : ""} ${
        props.bkg ? "marked-bkg" : ""
      }`}
    >
      <textarea
        ref={inputRef}
        id={props.id}
        rows={props.rows}
        cols={props.cols}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        tabIndex={props.tabIndex}
        type={props.type ? props.type : "text"}
        disabled={props.inactive}
        onChange={(e) => {
          e.target.value = formatField(
            inputRef.current,
            e.target.value,
            props.type,
            props.dontForceUppercase
          );
          props.onChange(e.target.value);
        }}
        value={props.input}
        onBlur={(e) => {
          e.target.value = reformatField(
            inputRef.current,
            e.target.value,
            props.type,
            true
          );
          if (props.onMalformed) {
            if (!checkFormat(e.target.value, props.type)) {
              props.onMalformed();
            }
          }
        }}
        onKeyUp={(e) => /* inputOnEnter(e) */ {}}
      ></textarea>
    </div>
  );
}
