import React from "react";
import "../css/ActionCard.css";

export default function ActionCard(props) {
  return (
    <button
      className="action-card"
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
    >
      <div className="info-card">
        {props.icon}
        <div className="action-text">
          <div className="h4-p2">{props.title}</div>
        </div>
      </div>
    </button>
  );
}
