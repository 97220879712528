import React from "react";
import "../css/Tag.css";

export default function Tag(props) {
  return (
    <div className="tag">
      <span>{props.text}</span>
      <button
        onClick={() => {
          props.onClick(props.text);
        }}
      >
        <img
          src="/images/close-blue.svg"
          loading="lazy"
          alt="Icon"
          title="Eliminar"
        ></img>
      </button>
    </div>
  );
}
