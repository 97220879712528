import React from "react";
import MenuSubOption from "./MenuSubOption";

export default function MenuOption(props) {
  return (
    <div className="menu-item">
      <div
        className={`menu-inicio ${
          props.item.id === props.currentScreen ? "active-op" : ""
        }`}
        onClick={() => {
          props.clickMenuOption(props.item.id);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          window.open("/" + props.item.id, "_blank");
        }}
      >
        <div className={`section-title`}>
          <img
            src={props.item.icon}
            loading="lazy"
            width="14"
            height="15"
            alt="Icon"
            className="vectors-wrapper"
          />
          <div className="h4-p2 white">{props.item.text}</div>
        </div>
      </div>
      {
        /*e.id === parentSection &&*/
        props.item.submenuOpen &&
          props.item.subsections.map((s, j) => {
            return (
              <MenuSubOption
                key={j}
                item={s}
                currentScreen={props.currentScreen}
                clickMenuOption={props.clickMenuOption}
              />
            );
          })
      }
    </div>
  );
}
