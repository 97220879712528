import React, { useEffect, useState, useRef } from "react";
import CheckBox from "./CheckBox";
import ButtonIconV from "./ButtonIconV";
import "../css/FileViewer.css";
import { selectedOptic, userData } from "../js/utils";
import { post } from "../js/post";
import FileDisplay from "./FileDisplay";
import LoadingScreen from "./LoadingScreen";

export default function FileViewer(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState(null);
  const fileInputRef = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");

  /*   console.log("**********");
  console.log("fileList: ", fileList);
  console.log("selectedFile: ", selectedFile);
  console.log("selectedFileIndex: ", selectedFileIndex); */

  const handleCheck = (e) => {
    setSelectedFile(selectedFile === e ? null : e);
    setSelectedFileIndex(
      selectedFile ? fileList.findIndex((e) => e === selectedFile) : null
    );
  };

  const getFiles = async () => {
    const response = await post("ListarFicheros.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
      categoria: props.category,
    });
    setFileList(response.sort().reverse());
    handleCheck(response[0]);
  };

  const getFile = async () => {
    setLoadingMessage("Cargando fichero...");
    setLoadingFile(true);
    const response = await post("ObtenerFichero.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
      nombreFichero: selectedFile,
      categoria: props.category,
    });
    setFileData(response.fichero);
    setLoadingFile(false);
  };

  const uploadFile = async (file, name) => {
    if (props.patientId !== -1) {
      setLoadingFile(true);
      const response = await post("SubirFichero.php", {
        token: userData.token,
        optica: selectedOptic.id,
        idPaciente: props.patientId,
        nombreFichero: name,
        fichero: file,
        categoria: props.category,
      });
      await getFiles();
      handleCheck(response.nombreFichero);
      setLoadingFile(false);
    } else {
      props.createPopupQuestion(
        `Guarde primero la ficha del paciente.`,
        async () => {
          props.removePopupQuestion();
        },
        null,
        "Entendido"
      );
      setSelectedFile(null);
      setSelectedFileIndex(null);
    }
  };

  const deleteFile = async () => {
    await post("EliminarFichero.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientId,
      nombreFichero: selectedFile,
      categoria: props.category,
    });
  };

  const handleNext = () => {
    if (selectedFile) {
      if (selectedFileIndex + 1 < fileList.length) {
        setAnimationClass("fade-exit");
        setTimeout(() => {
          setSelectedFileIndex(selectedFileIndex + 1);
          setSelectedFile(fileList[selectedFileIndex + 1]);
          setAnimationClass("fade-enter");
        }, 500);
      }
    }
  };

  const handlePrev = () => {
    if (selectedFile) {
      if (selectedFileIndex - 1 >= 0) {
        setAnimationClass("fade-exit");
        setTimeout(() => {
          setSelectedFileIndex(selectedFileIndex - 1);
          setSelectedFile(fileList[selectedFileIndex - 1]);
          setAnimationClass("fade-enter");
        }, 500);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file.name);
      convertToBase64(file);
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setLoadingMessage("Subiendo fichero...");
      await uploadFile(reader.result, file.name);
      setSelectedFileIndex(fileList.findIndex((e) => e === selectedFile));
    };
  };

  const handleDownload = () => {
    if (selectedFile) {
      // Crear un enlace temporal
      const link = document.createElement("a");
      link.href = fileData;
      link.download = selectedFile;

      // Añadir el enlace al documento y simular un clic
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del documento
      document.body.removeChild(link);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file.name);
      convertToBase64(file);
    }
  };

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setSelectedFileIndex(fileList.findIndex((e) => e === selectedFile));
      getFile();
    } else {
      setFileData(null);
      setSelectedFileIndex(null);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (animationClass === "fade-enter") {
      setTimeout(() => {
        setAnimationClass("");
      }, 500);
    }
  }, [animationClass]);

  return (
    <div className="tab-content">
      <div className="file-viewer-container">
        <div className="category-list">
          <span className="section-history-header">
            Ficheros ({fileList.length})
          </span>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              overflowY: "auto",
              height: "100%",
            }}
          >
            {fileList.map((e, i) => {
              return (
                <li
                  className="cell cell-label cell-start-content file-check-content"
                  key={i}
                  onClick={() => {
                    handleCheck(e);
                  }}
                  style={{ paddingRight: "10px" }}
                >
                  <div className="check-container-content">
                    <CheckBox
                      active={selectedFile === e}
                      onClick={() => {
                        handleCheck(e);
                      }}
                    ></CheckBox>
                  </div>
                  {e}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="file-info-container">
          <div className="file-info-info">
            <img src="/images/info-blue.svg"></img>
            <span>
              Para subir un fichero, pulse el botón Añadir o arrástrelo hasta el
              área de visualización.
            </span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <div
            className="file-info"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {loadingFile && (
              <LoadingScreen message={loadingMessage}></LoadingScreen>
            )}
            {!loadingFile && fileData && (
              <FileDisplay base64Data={fileData}></FileDisplay>
            )}
          </div>
          <div className="file-options-container">
            {fileList.length > 1 && (
              <ButtonIconV
                text="Anterior"
                icon={
                  selectedFile
                    ? "/images/prev-blue.svg"
                    : "/images/prev-gray.svg"
                }
                onClick={() => {
                  handlePrev();
                }}
              ></ButtonIconV>
            )}
            <ButtonIconV
              text="Añadir"
              icon={
                props.patientId !== -1
                  ? "/images/create-blue.svg"
                  : "/images/create-gray.svg"
              }
              onClick={() => {
                if (props.patientId !== -1) {
                  fileInputRef.current.click();
                } else {
                  props.createPopupQuestion(
                    `Guarde primero la ficha del paciente.`,
                    async () => {
                      props.removePopupQuestion();
                    },
                    null,
                    "Entendido"
                  );
                }
              }}
            ></ButtonIconV>
            <ButtonIconV
              text="Eliminar"
              icon={
                selectedFile
                  ? "/images/remove-blue.svg"
                  : "/images/remove-gray.svg"
              }
              onClick={() => {
                if (selectedFile) {
                  props.createPopupQuestion(
                    `¿Desea eliminar el fichero ${selectedFile}?`,
                    async () => {
                      await deleteFile();
                      await getFiles();
                      props.removePopupQuestion();
                    },
                    async () => {
                      props.removePopupQuestion();
                    },
                    "Sí",
                    "No"
                  );
                }
              }}
            ></ButtonIconV>
            <ButtonIconV
              text="Descargar"
              icon={
                selectedFile
                  ? "/images/download-blue.svg"
                  : "/images/download-gray.svg"
              }
              onClick={() => {
                handleDownload();
              }}
            ></ButtonIconV>
            {fileList.length > 1 && (
              <ButtonIconV
                text="Siguiente"
                icon={
                  selectedFile
                    ? "/images/next-blue.svg"
                    : "/images/next-gray.svg"
                }
                onClick={() => {
                  handleNext();
                }}
              ></ButtonIconV>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
