const SERVER = "https://dev.opticasweb.com/rest/";
const DEBUG = false;

export let post = async (funct, body) => {
  let endpoint = SERVER;
  endpoint = endpoint + funct;
  try {
    if (DEBUG) {
      console.log(endpoint);
      console.log(body);
    }
    const response = await fetch(endpoint, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (DEBUG) {
      console.log(data);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
};
