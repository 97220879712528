import React from "react";

export default function ButtonIconV(props) {
  return (
    <button className="action" onClick={props.onClick}>
      <img
        src={props.icon}
        loading="lazy"
        alt="Icon"
        className="patient-action-icon"
        style={{ pointerEvents: "none" }}
      ></img>
      <span>{props.text}</span>
    </button>
  );
}
