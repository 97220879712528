import React, { useState } from "react";
import "../css/Input.css";

export default function Input(props) {
  const [input, setInput] = useState("");
  const [isPassword, setIsPassword] = useState(props.pwd);
  return (
    <div className="logininput">
      <div className="info">
        <div className="fieldicon">
          <img
            src={props.error ? props.iconError : props.icon}
            loading="lazy"
            alt="Icon"
            className="vectors-wrapper-8"
          />
        </div>
        <input
          id={props.id}
          type={isPassword ? "password" : "text"}
          className="body-p2"
          value={input}
          placeholder={props.placeholder}
          onChange={(e) => {
            setInput(e.target.value);
            props.onAccess(e.target.value);
          }}
        ></input>
      </div>
      {props.error && (
        <img
          src="/images/icon-error.svg"
          loading="lazy"
          alt="Icon"
          title="Este campo no puede estar vacío"
          className="vectors-wrapper-8 info-icon-right"
        />
      )}
      {!props.error && props.pwd && input !== "" && (
        <img
          src={
            isPassword ? "/images/eye-passwd.png" : "/images/no-eye-passwd.png"
          }
          loading="lazy"
          alt="Icon"
          className="vectors-wrapper-8 info-icon-right eye-pass"
          onClick={() => {
            setIsPassword(!isPassword);
          }}
        />
      )}
    </div>
  );
}
