import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import CheckBox from "./CheckBox";
import InputData from "./InputData";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  anamnesisStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
} from "../js/utils";
import {
  PRESCRIPTIONGLASSESLIST,
  CONTACTLENSESLIST,
  PRISMALIST,
} from "../js/utils";
import moment from "moment";

export default function AdvancedOptometryAnamnesis(props) {
  const [currentAnamnesis, setCurrentAnamnesis] = useState(anamnesisStructure);
  const [currentAnamnesisEdit, setCurrentAnamnesisEdit] =
    useState(anamnesisStructure);
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [anamnesisId, setAnamnesisId] = useState(props.anamnesisId);

  //console.log("selectedDate: ", selectedDate);
  console.log("currentAnamnesisEdit: ", currentAnamnesisEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getAnamnesisHistory = async () => {
    const response = await post("ListarFechasHistoricoAnamnesis.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idAnamnesis === anamnesisId;
      });
      if (found) {
        setSelectedDate({
          id: found.idAnamnesis,
          text: found.fecha,
        });
        setAnamnesisId(found.idAnamnesis);
      }
    }
    setDateHistoryList(response);
  };

  const getAnamnesis = async (anamnesisId) => {
    const response = await post("ObtenerAnamnesis.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAnamnesis: anamnesisId,
    });

    setCurrentAnamnesisEdit({
      ...response,
      idPaciente: props.patientId,
      idAnamnesis: anamnesisId,
    });
    setCurrentAnamnesis({
      ...response,
      idPaciente: props.patientId,
      idAnamnesis: anamnesisId,
    });
  };

  const checkEyePathology = () => {
    if (currentAnamnesisEdit.patologia_ocular !== "") {
      props.createPopupQuestion(
        "Existe información de patología ocular. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            patologia_ocular: "",
          }));
          props.removePopupQuestion();
          checkTreatmentEyePathology();
        },
        () => {
          props.removePopupQuestion();
          checkTreatmentEyePathology();
        },
        "Sí",
        "No"
      );
    } else if (currentAnamnesisEdit.tratamiento_patologia_ocular != "") {
      checkTreatmentEyePathology();
    }
  };

  const checkTreatmentEyePathology = () => {
    if (currentAnamnesisEdit.tratamiento_patologia_ocular !== "") {
      props.createPopupQuestion(
        "Existe información de tratamiento ocular. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            tratamiento_patologia_ocular: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const checkSystemicDisease = () => {
    if (currentAnamnesisEdit.enfermedad_sistemica !== "") {
      props.createPopupQuestion(
        "Existe información de enfermedad sistémica. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            enfermedad_sistemica: "",
          }));
          props.removePopupQuestion();
          checkTreatmentSystemicDisease();
        },
        () => {
          props.removePopupQuestion();
          checkTreatmentSystemicDisease();
        },
        "Sí",
        "No"
      );
    } else if (currentAnamnesisEdit.tratamiento_enfermedad_sistemica != "") {
      checkTreatmentSystemicDisease();
    }
  };

  const checkTreatmentSystemicDisease = () => {
    if (currentAnamnesisEdit.tratamiento_enfermedad_sistemica !== "") {
      props.createPopupQuestion(
        "Existe información de tratamiento de enfermedad sistémica. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            tratamiento_enfermedad_sistemica: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const checkPrescriptionGlasses = () => {
    if (currentAnamnesisEdit.tipo_gafa_graduadas !== "") {
      props.createPopupQuestion(
        "Existe información de tipo gafa. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            tipo_gafa_graduadas: "",
          }));
          props.removePopupQuestion();
          checkPrescriptionLens();
        },
        () => {
          props.removePopupQuestion();
          checkPrescriptionLens();
        },
        "Sí",
        "No"
      );
    } else if (currentAnamnesisEdit.cristal != "") {
      checkPrescriptionLens();
    }
  };

  const checkPrescriptionLens = () => {
    if (currentAnamnesisEdit.cristal !== "") {
      props.createPopupQuestion(
        "Existe información de tipo de cristal. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            cristal: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const checkPrism = () => {
    if (currentAnamnesisEdit.tipo_prisma !== "") {
      props.createPopupQuestion(
        "Existe información de tipo de prisma. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            tipo_prisma: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const checkContactLens = () => {
    if (currentAnamnesisEdit.tipo_lentes_contacto !== "") {
      props.createPopupQuestion(
        "Existe información de tipo de LC. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            tipo_lentes_contacto: "",
          }));
          props.removePopupQuestion();
          checkContactLensBrand();
        },
        () => {
          props.removePopupQuestion();
          checkContactLensBrand();
        },
        "Sí",
        "No"
      );
    } else if (currentAnamnesisEdit.cristal != "") {
      checkContactLensBrand();
    }
  };

  const checkContactLensBrand = () => {
    if (currentAnamnesisEdit.marca_lentes_contacto !== "") {
      props.createPopupQuestion(
        "Existe información de marca de LC. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            marca_lentes_contacto: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const checkFamilyHistory = () => {
    if (currentAnamnesisEdit.antecedentes_familiares !== "") {
      props.createPopupQuestion(
        "Existe información de antecedentes familiares. ¿Desea eliminarla?",
        () => {
          setCurrentAnamnesisEdit((prev) => ({
            ...prev,
            antecedentes_familiares: "",
          }));
          props.removePopupQuestion();
        },
        () => {
          props.removePopupQuestion();
        },
        "Sí",
        "No"
      );
    }
  };

  const resetAnamnesisState = () => {
    setCurrentAnamnesisEdit({
      ...anamnesisStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentAnamnesis({
      ...anamnesisStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setAnamnesisId(-1);
    props.saveLastAnamnesisIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveAnamnesis = async () => {
    const response = await post("GuardarAnamnesis.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAnamnesis: anamnesisId,
      idPaciente: props.patientId,
      ...currentAnamnesisEdit,
    });
    setSelectedDate({
      id: response.idAnamnesis,
      text: currentAnamnesisEdit.fecha,
    });
    setAnamnesisId(response.idAnamnesis);
    setCurrentAnamnesis(currentAnamnesisEdit);
    props.saveLastAnamnesisIdVisited(response.idAnamnesis);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteAnamnesis = async () => {
    await post("EliminarAnamnesis.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idAnamnesis: anamnesisId,
      idUsuario: userData.id,
    });
  };

  const selectAnamnesisAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoAnamnesis.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idAnamnesis,
        text: response[0].fecha,
      });
      setAnamnesisId(response[0].idAnamnesis);
      setDateHistoryList(response);
      await getAnamnesis(response[0].idAnamnesis);
      props.saveLastAnamnesisIdVisited(response[0].idAnamnesis);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setAnamnesisId(-1);
      props.saveLastAnamnesisIdVisited(-1);
      setCurrentAnamnesis(anamnesisStructure);
      setCurrentAnamnesisEdit(anamnesisStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (anamnesisId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idAnamnesis,
          text: dateHistoryList[0].fecha,
        });
        setAnamnesisId(dateHistoryList[0].idAnamnesis);
        props.saveLastAnamnesisIdVisited(dateHistoryList[0].idAnamnesis);
        await getAnamnesis(dateHistoryList[0].idAnamnesis);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentAnamnesisEdit(currentAnamnesis);
      }
    } else {
      setCurrentAnamnesisEdit(currentAnamnesis);
    }
  };

  useEffect(() => {
    getAnamnesisHistory();
    getOptometristList();
    if (anamnesisId !== -1) {
      getAnamnesis(anamnesisId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAnamnesisEdit({
        ...currentAnamnesisEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentAnamnesis({
        ...currentAnamnesis,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [anamnesisId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentAnamnesis, currentAnamnesisEdit),
      dataToSave: currentAnamnesisEdit,
    });
  }, [currentAnamnesisEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentAnamnesisEdit.diagnostico) {
      setCurrentAnamnesisEdit({
        ...currentAnamnesisEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentAnamnesisEdit.fecha}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idAnamnesis,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idAnamnesis,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={anamnesisId === -1}
                onChange={async (e) => {
                  setCurrentAnamnesisEdit(anamnesisStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setAnamnesisId(e.id);
                  props.saveLastAnamnesisIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentAnamnesisEdit.optometrista}
                onChange={(e) => {
                  setCurrentAnamnesisEdit({
                    ...currentAnamnesisEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              anamnesisId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (anamnesisId !== -1) {
                if (!areObjectsEqual(currentAnamnesis, currentAnamnesisEdit)) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveAnamnesis();
                      props.removePopupQuestion();
                      resetAnamnesisState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetAnamnesisState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetAnamnesisState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              anamnesisStructure.fecha = currentAnamnesisEdit.fecha;
              if (areObjectsEqual(currentAnamnesisEdit, anamnesisStructure)) {
                props.createPopupQuestion(
                  `La ficha de anamnesis actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveAnamnesis();
                await getAnamnesisHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentAnamnesis, currentAnamnesisEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentAnamnesis, currentAnamnesisEdit) ||
                anamnesisId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              anamnesisId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (anamnesisId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de anamnesis?`,
                  async () => {
                    await getAnamnesis(anamnesisId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              anamnesisId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (anamnesisId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de anamnesis?`,
                  async () => {
                    await deleteAnamnesis();
                    await getAnamnesisHistory();
                    await selectAnamnesisAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              anamnesisId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (anamnesisId !== -1) {
                props.openDiagnosisPopup(currentAnamnesisEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              anamnesisId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (anamnesisId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Síntomas y Signos</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={54}
                  maxLength={400}
                  id="sintomas-signos"
                  type="type-full"
                  size={0}
                  input={currentAnamnesisEdit.sintomas_signos}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      sintomas_signos: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Gafas Graduadas</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      gafas_graduadas_si:
                        !currentAnamnesisEdit.gafas_graduadas_si,
                      gafas_graduadas_no:
                        currentAnamnesisEdit.gafas_graduadas_si
                          ? false
                          : currentAnamnesisEdit.gafas_graduadas_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={55}
                      active={currentAnamnesisEdit.gafas_graduadas_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkPrescriptionGlasses();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      gafas_graduadas_no:
                        !currentAnamnesisEdit.gafas_graduadas_no,
                      gafas_graduadas_si:
                        currentAnamnesisEdit.gafas_graduadas_no
                          ? false
                          : currentAnamnesisEdit.gafas_graduadas_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={56}
                      active={currentAnamnesisEdit.gafas_graduadas_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Tipo
                <Dropdown
                  id="gafas-graduadas-tipos"
                  tabIndex={57}
                  disabled={!currentAnamnesisEdit.gafas_graduadas_si}
                  optionList={PRESCRIPTIONGLASSESLIST}
                  size={0}
                  op={currentAnamnesisEdit.tipo_gafa_graduadas}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      tipo_gafa_graduadas: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                Cristal
                <InputData
                  tabIndex={58}
                  id="gafas-graduadas-cristal"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentAnamnesisEdit.cristal}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      cristal: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Prisma</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      prisma_si: !currentAnamnesisEdit.prisma_si,
                      prisma_no: currentAnamnesisEdit.prisma_si
                        ? false
                        : currentAnamnesisEdit.prisma_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={59}
                      active={currentAnamnesisEdit.prisma_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkPrism();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      prisma_no: !currentAnamnesisEdit.prisma_no,
                      prisma_si: currentAnamnesisEdit.prisma_no
                        ? false
                        : currentAnamnesisEdit.prisma_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={60}
                      active={currentAnamnesisEdit.prisma_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Tipo
                <Dropdown
                  id="prisma-tipo"
                  tabIndex={61}
                  disabled={!currentAnamnesisEdit.prisma_si}
                  optionList={PRISMALIST}
                  size={0}
                  op={currentAnamnesisEdit.tipo_prisma}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      tipo_prisma: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Lentes de Contacto</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      lentes_contacto_si:
                        !currentAnamnesisEdit.lentes_contacto_si,
                      lentes_contacto_no:
                        currentAnamnesisEdit.lentes_contacto_si
                          ? false
                          : currentAnamnesisEdit.lentes_contacto_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={62}
                      active={currentAnamnesisEdit.lentes_contacto_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkContactLens();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      lentes_contacto_no:
                        !currentAnamnesisEdit.lentes_contacto_no,
                      lentes_contacto_si:
                        currentAnamnesisEdit.lentes_contacto_no
                          ? false
                          : currentAnamnesisEdit.lentes_contacto_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={63}
                      active={currentAnamnesisEdit.lentes_contacto_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4 cell-start-content">
                Tipo
                <Dropdown
                  id="lentes-contacto-tipo"
                  tabIndex={64}
                  disabled={!currentAnamnesisEdit.lentes_contacto_si}
                  optionList={CONTACTLENSESLIST}
                  size={0}
                  op={currentAnamnesisEdit.tipo_lentes_contacto}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      tipo_lentes_contacto: e.text,
                    });
                  }}
                ></Dropdown>
              </div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                Marca
                <InputData
                  tabIndex={65}
                  id="lentes-contacto-marca"
                  type="type-alphanum-ext"
                  size={0}
                  input={currentAnamnesisEdit.marca_lentes_contacto}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      marca_lentes_contacto: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Hábitos</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label combined-cells-2 cell-start-content">
                Horas TV
                <InputData
                  tabIndex={66}
                  id="horas-tv"
                  type="type-integer"
                  size={4}
                  input={currentAnamnesisEdit.horas_tv}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      horas_tv: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                Horas PC
                <InputData
                  tabIndex={67}
                  id="horas-pc"
                  type="type-integer"
                  size={4}
                  input={currentAnamnesisEdit.horas_pc}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      horas_pc: e,
                    });
                  }}
                ></InputData>
              </div>
              <div className="cell cell-label combined-cells-2 cell-start-content">
                Horas Lectura
                <InputData
                  tabIndex={68}
                  id="horas-lectura"
                  type="type-integer"
                  size={4}
                  input={currentAnamnesisEdit.horas_lectura}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      horas_lectura: e,
                    });
                  }}
                ></InputData>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Observaciones</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={69}
                  maxLength={400}
                  id="observaciones"
                  type="type-full"
                  size={0}
                  input={currentAnamnesisEdit.observaciones}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      observaciones: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-title">Antecedentes Familiares</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      antecedentes_familiares_si:
                        !currentAnamnesisEdit.antecedentes_familiares_si,
                      antecedentes_familiares_no:
                        currentAnamnesisEdit.antecedentes_familiares_si
                          ? false
                          : currentAnamnesisEdit.antecedentes_familiares_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={70}
                      active={currentAnamnesisEdit.antecedentes_familiares_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkFamilyHistory();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      antecedentes_familiares_no:
                        !currentAnamnesisEdit.antecedentes_familiares_no,
                      antecedentes_familiares_si:
                        currentAnamnesisEdit.antecedentes_familiares_no
                          ? false
                          : currentAnamnesisEdit.antecedentes_familiares_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={71}
                      active={currentAnamnesisEdit.antecedentes_familiares_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4"></div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={72}
                  maxLength={400}
                  inactive={!currentAnamnesisEdit.antecedentes_familiares_si}
                  id="antecedentes-familiares"
                  type="type-full"
                  size={0}
                  input={currentAnamnesisEdit.antecedentes_familiares}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      antecedentes_familiares: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Patología Ocular</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      patologia_ocular_si:
                        !currentAnamnesisEdit.patologia_ocular_si,
                      patologia_ocular_no:
                        currentAnamnesisEdit.patologia_ocular_si
                          ? false
                          : currentAnamnesisEdit.patologia_ocular_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={73}
                      active={currentAnamnesisEdit.patologia_ocular_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkEyePathology();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      patologia_ocular_no:
                        !currentAnamnesisEdit.patologia_ocular_no,
                      patologia_ocular_si:
                        currentAnamnesisEdit.patologia_ocular_no
                          ? false
                          : currentAnamnesisEdit.patologia_ocular_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={74}
                      active={currentAnamnesisEdit.patologia_ocular_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4"></div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={75}
                  maxLength={400}
                  id="patologia-ocular"
                  type="type-full"
                  size={0}
                  inactive={!currentAnamnesisEdit.patologia_ocular_si}
                  input={currentAnamnesisEdit.patologia_ocular}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      patologia_ocular: e,
                    });
                  }}
                ></InputTextArea>
              </div>
              <div className="cell opt-table-subtitle combined-cells-6 cell-start-content">
                Tratamiento
              </div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={76}
                  maxLength={400}
                  id="patologia-ocular-tratamiento"
                  type="type-full"
                  size={0}
                  inactive={!currentAnamnesisEdit.patologia_ocular_si}
                  input={currentAnamnesisEdit.tratamiento_patologia_ocular}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      tratamiento_patologia_ocular: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
          <div className="opt-table-container">
            <div className="opt-table-title">Enfermedad Sistémica</div>
            <div className="opt-table-content grid-6">
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      enfermedad_sistemica_si:
                        !currentAnamnesisEdit.enfermedad_sistemica_si,
                      enfermedad_sistemica_no:
                        currentAnamnesisEdit.enfermedad_sistemica_si
                          ? false
                          : currentAnamnesisEdit.enfermedad_sistemica_si,
                    });
                  }}
                >
                  Sí
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={77}
                      active={currentAnamnesisEdit.enfermedad_sistemica_si}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label cell-start-content">
                <div
                  className="check-container-content"
                  onClick={() => {
                    checkSystemicDisease();
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      enfermedad_sistemica_no:
                        !currentAnamnesisEdit.enfermedad_sistemica_no,
                      enfermedad_sistemica_si:
                        currentAnamnesisEdit.enfermedad_sistemica_no
                          ? false
                          : currentAnamnesisEdit.enfermedad_sistemica_no,
                    });
                  }}
                >
                  No
                  <div className="radio-op">
                    <CheckBox
                      tabIndex={78}
                      active={currentAnamnesisEdit.enfermedad_sistemica_no}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
              </div>
              <div className="cell cell-label combined-cells-4"></div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={79}
                  maxLength={400}
                  id="enfermedad-sistemica"
                  type="type-full"
                  size={0}
                  inactive={!currentAnamnesisEdit.enfermedad_sistemica_si}
                  input={currentAnamnesisEdit.enfermedad_sistemica}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      enfermedad_sistemica: e,
                    });
                  }}
                ></InputTextArea>
              </div>
              <div className="cell opt-table-subtitle combined-cells-6 cell-start-content">
                Tratamiento
              </div>
              <div className="cell cell-label combined-cells-6 cell-start-content">
                <InputTextArea
                  tabIndex={80}
                  maxLength={400}
                  id="patologia-ocular-tratamiento"
                  type="type-full"
                  size={0}
                  inactive={!currentAnamnesisEdit.enfermedad_sistemica_si}
                  input={currentAnamnesisEdit.tratamiento_enfermedad_sistemica}
                  onChange={(e) => {
                    setCurrentAnamnesisEdit({
                      ...currentAnamnesisEdit,
                      tratamiento_enfermedad_sistemica: e,
                    });
                  }}
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
