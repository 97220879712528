import React, { useState, useEffect } from "react";
import ButtonIconH from "./ButtonIconH";
import "../css/ExternalWindow.css";
import "../css/AudiometryChart.css";
import InputData from "./InputData";
import { focusElement } from "../js/utils";

export default function AudiometryChartData(props) {
  const [airPointsR, setAirPointsR] = useState(props.airPointsR);
  const [bonePointsR, setBonePointsR] = useState(props.bonePointsR);
  const [thresholdPointsR, setThresholdPointsR] = useState(
    props.thresholdPointsR
  );
  const [airPointsL, setAirPointsL] = useState(props.airPointsL);
  const [bonePointsL, setBonePointsL] = useState(props.bonePointsL);
  const [thresholdPointsL, setThresholdPointsL] = useState(
    props.thresholdPointsL
  );

  const checkValuesAccept = () => {
    const combinedValues = airPointsR.concat(
      bonePointsR,
      thresholdPointsR,
      airPointsL,
      bonePointsL,
      thresholdPointsL
    );
    const index = combinedValues.findIndex((e) => e.y > 20 || e.y < -120);
    if (index != -1) {
      props.createPopupQuestion(
        `Valor incorrecto.`,
        async () => {
          focusElement(index + 100);
          props.removePopupQuestion();
        },
        null,
        "Aceptar"
      );
    } else {
      props.onAccept(
        airPointsR,
        bonePointsR,
        thresholdPointsR,
        airPointsL,
        bonePointsL,
        thresholdPointsL
      );
      props.onClose();
    }
  };

  useEffect(() => {
    setAirPointsR(props.airPointsR);
  }, [props.airPointsR]);

  useEffect(() => {
    setBonePointsR(props.bonePointsR);
  }, [props.bonePointsR]);

  useEffect(() => {
    setThresholdPointsR(props.thresholdPointsR);
  }, [props.thresholdPointsR]);

  useEffect(() => {
    setAirPointsL(props.airPointsL);
  }, [props.airPointsL]);

  useEffect(() => {
    setBonePointsL(props.bonePointsL);
  }, [props.bonePointsL]);

  useEffect(() => {
    setThresholdPointsL(props.thresholdPointsL);
  }, [props.thresholdPointsL]);

  return (
    <div className="external-window-bg">
      <div className="external-window external-window-extra w-auto">
        <div className="external-window-header">
          <span className="external-window-header-title">
            Valores de la Gráfica
          </span>
        </div>
        <div className="external-window-body external-window-body-extra">
          {/* OIDO DERECHO */}
          <div className="chart-data-container red">
            <span className="c-title">OÍDO DERECHO</span>
            <div className="external-window-content">
              <div className="opt-table-content grid-4">
                <div className="cell opt-table-subtitle">Hz</div>
                <div className="cell opt-table-subtitle">Aérea</div>
                <div className="cell opt-table-subtitle">Ósea</div>
                <div className="cell opt-table-subtitle">Umbral</div>
                <div className="cell cell-label">125</div>
                <div className="cell">
                  <InputData
                    tabIndex={100}
                    type="type-integer"
                    size={0}
                    input={airPointsR[0].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={110}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[0].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={120}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[0].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">250</div>
                <div className="cell">
                  <InputData
                    tabIndex={101}
                    type="type-integer"
                    size={0}
                    input={airPointsR[1].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={111}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[1].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={121}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[1].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">500</div>
                <div className="cell">
                  <InputData
                    tabIndex={102}
                    type="type-integer"
                    size={0}
                    input={airPointsR[2].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={112}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[2].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={122}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[2].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">1000</div>
                <div className="cell">
                  <InputData
                    tabIndex={103}
                    type="type-integer"
                    size={0}
                    input={airPointsR[3].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={113}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[3].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={123}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[3].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">1500</div>
                <div className="cell">
                  <InputData
                    tabIndex={104}
                    type="type-integer"
                    size={0}
                    input={airPointsR[4].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={114}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[4].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={124}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[4].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">2000</div>
                <div className="cell">
                  <InputData
                    tabIndex={105}
                    type="type-integer"
                    size={0}
                    input={airPointsR[5].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={115}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[5].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={125}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[5].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">3000</div>
                <div className="cell">
                  <InputData
                    tabIndex={106}
                    type="type-integer"
                    size={0}
                    input={airPointsR[6].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={116}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[6].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={126}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[6].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">4000</div>
                <div className="cell">
                  <InputData
                    tabIndex={107}
                    type="type-integer"
                    size={0}
                    input={airPointsR[7].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={117}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[7].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={127}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[7].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">6000</div>
                <div className="cell">
                  <InputData
                    tabIndex={108}
                    type="type-integer"
                    size={0}
                    input={airPointsR[8].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={118}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[8].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={128}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[8].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">8000</div>
                <div className="cell">
                  <InputData
                    tabIndex={109}
                    type="type-integer"
                    size={0}
                    input={airPointsR[9].y}
                    onChange={(e) => {
                      setAirPointsR(
                        airPointsR.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={119}
                    type="type-integer"
                    size={0}
                    input={bonePointsR[9].y}
                    onChange={(e) => {
                      setBonePointsR(
                        bonePointsR.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={129}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsR[9].y}
                    onChange={(e) => {
                      setThresholdPointsR(
                        thresholdPointsR.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
              </div>
            </div>
          </div>
          {/* OIDO IZQUIERDO */}
          <div className="chart-data-container blue">
            <span className="c-title">OÍDO IZQUIERDO</span>
            <div className="external-window-content">
              <div className="opt-table-content grid-4">
                <div className="cell opt-table-subtitle">Hz</div>
                <div className="cell opt-table-subtitle">Aérea</div>
                <div className="cell opt-table-subtitle">Ósea</div>
                <div className="cell opt-table-subtitle">Umbral</div>
                <div className="cell cell-label">125</div>
                <div className="cell">
                  <InputData
                    tabIndex={130}
                    type="type-integer"
                    size={0}
                    input={airPointsL[0].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={140}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[0].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={150}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[0].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 0) {
                            return { x: 125, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">250</div>
                <div className="cell">
                  <InputData
                    tabIndex={131}
                    type="type-integer"
                    size={0}
                    input={airPointsL[1].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={141}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[1].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={151}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[1].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 1) {
                            return { x: 250, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">500</div>
                <div className="cell">
                  <InputData
                    tabIndex={132}
                    type="type-integer"
                    size={0}
                    input={airPointsL[2].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={142}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[2].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={152}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[2].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 2) {
                            return { x: 500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">1000</div>
                <div className="cell">
                  <InputData
                    tabIndex={133}
                    type="type-integer"
                    size={0}
                    input={airPointsL[3].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={143}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[3].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={153}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[3].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 3) {
                            return { x: 1000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">1500</div>
                <div className="cell">
                  <InputData
                    tabIndex={134}
                    type="type-integer"
                    size={0}
                    input={airPointsL[4].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={144}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[4].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={154}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[4].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 4) {
                            return { x: 1500, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">2000</div>
                <div className="cell">
                  <InputData
                    tabIndex={135}
                    type="type-integer"
                    size={0}
                    input={airPointsL[5].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={145}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[5].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={155}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[5].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 5) {
                            return { x: 2000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">3000</div>
                <div className="cell">
                  <InputData
                    tabIndex={136}
                    type="type-integer"
                    size={0}
                    input={airPointsL[6].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={146}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[6].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={156}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[6].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 6) {
                            return { x: 3000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">4000</div>
                <div className="cell">
                  <InputData
                    tabIndex={137}
                    type="type-integer"
                    size={0}
                    input={airPointsL[7].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={147}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[7].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={157}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[7].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 7) {
                            return { x: 4000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">6000</div>
                <div className="cell">
                  <InputData
                    tabIndex={138}
                    type="type-integer"
                    size={0}
                    input={airPointsL[8].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={148}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[8].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={158}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[8].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 8) {
                            return { x: 6000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell cell-label">8000</div>
                <div className="cell">
                  <InputData
                    tabIndex={139}
                    type="type-integer"
                    size={0}
                    input={airPointsL[9].y}
                    onChange={(e) => {
                      setAirPointsL(
                        airPointsL.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={149}
                    type="type-integer"
                    size={0}
                    input={bonePointsL[9].y}
                    onChange={(e) => {
                      setBonePointsL(
                        bonePointsL.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
                <div className="cell">
                  <InputData
                    tabIndex={159}
                    type="type-integer"
                    size={0}
                    input={thresholdPointsL[9].y}
                    onChange={(e) => {
                      setThresholdPointsL(
                        thresholdPointsL.map((point, index) => {
                          if (index === 9) {
                            return { x: 8000, y: e };
                          }
                          return point;
                        })
                      );
                    }}
                  ></InputData>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="external-window-buttons">
          <ButtonIconH
            text="Aceptar"
            onClick={() => {
              checkValuesAccept();
            }}
          ></ButtonIconH>
          <ButtonIconH
            text="Salir"
            onClick={() => {
              props.onClose();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
