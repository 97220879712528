import React, { useState, useEffect } from "react";
import InputData from "../components/InputData";
import InputIconSearchPanel from "../components/InputIconSearchPanel";
import InputIconSearchPanelMultiSelection from "../components/InputIconSearchPanelMultiSelection";
import Tag from "../components/Tag";
import HearingAidsFamily from "../components/HearingAidsFamily";
import LensesFamily from "../components/LensesFamily";
import ContactLensesFamily from "../components/ContactLensesFamily";
import LiquidsFamily from "../components/LiquidsFamily";
import FramesFamily from "../components/FramesFamily";
import VariousFamily from "../components/VariousFamily";
import SunglassesFamily from "../components/SunglassesFamily";
import CheckBox from "../components/CheckBox";
import InputIconSearch from "../components/InputIconSearch";
import ButtonIconV from "../components/ButtonIconV";
import { brandList, focusElement, supplierList } from "../js/utils";
import "../css/Store.css";

export default function Store(props) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [code, setCode] = useState("");
  const [supplier, setSupplier] = useState("");
  const [brands, setBrands] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [positiveStock, setPositiveStock] = useState(true);
  const [discontinued, setDiscontinued] = useState(false);

  console.log("selectedTab: ", selectedTab);

  let tabs = [
    {
      label: "Audífonos",
      content: <HearingAidsFamily />,
      icon:
        selectedTab === 0
          ? "/images/preferenciaAudifonos-white.svg"
          : "/images/preferenciaAudifonos.svg",
    },
    {
      label: "Lentes",
      content: <LensesFamily />,
      icon:
        selectedTab === 1
          ? "/images/preferenciaGafasGraduadas-white.svg"
          : "/images/preferenciaGafasGraduadas.svg",
    },
    {
      label: "L. Contacto",
      content: <ContactLensesFamily />,
      icon:
        selectedTab === 2
          ? "/images/preferenciaLentillas-white.svg"
          : "/images/preferenciaLentillas.svg",
    },
    {
      label: "Líquidos",
      content: <LiquidsFamily />,
      icon:
        selectedTab === 3
          ? "/images/preferenciaLiquido-white.svg"
          : "/images/preferenciaLiquido.svg",
    },
    {
      label: "Monturas",
      content: <FramesFamily />,
      icon:
        selectedTab === 4
          ? "/images/accommodative-white.svg"
          : "/images/accommodative-blue.svg",
    },
    {
      label: "Varios",
      content: <VariousFamily />,
      icon:
        selectedTab === 5
          ? "/images/preferenciaVarios-white.svg"
          : "/images/preferenciaVarios.svg",
    },
    {
      label: "Sol",
      content: <SunglassesFamily />,
      icon:
        selectedTab === 6
          ? "/images/preferenciaGafasDeSol-white.svg"
          : "/images/preferenciaGafasDeSol.svg",
    },
  ];

  useEffect(() => {
    focusElement(500);
  }, [props]);

  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Almacén</span>
        <div className="patient-header-buttons"></div>
      </div>
      <div className="patient-data-content store-data-content">
        <div className="action-bar">
          <div className="action-bar-resume">
            <span>Almacén</span>
            <span>|</span>
            <span className="signed-text">Gestión Almacén</span>
          </div>
        </div>
        <div className="patient-info store-info">
          <div className="opt-table-title">Búsqueda de Producto</div>
          <div className="data-field">
            <span className="label">Familia + Código / EAN / UPC / REF</span>
            <InputData
              tabIndex={500}
              id="code"
              type="type-only-charnumbers"
              size={2}
              bkg={true}
              input={code}
              onChange={(e) => {
                setCode(e);
              }}
            ></InputData>
          </div>
        </div>
        <div className="store-panel">
          <div className="store-tab-container">
            {tabs.map((e, i) => {
              return (
                <div
                  className={`store-tab ${
                    selectedTab === i ? "store-tab-selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedTab(selectedTab === i ? null : i);
                  }}
                  key={i}
                >
                  <img src={e.icon} alt="img" />
                  {e.label}
                </div>
              );
            })}
          </div>
          <div className="store-body">
            <div className="store-body-cols">
              <div className="store-body-sec">
                <div className="opt-table-title">Búsqueda de Proveedores</div>
                <div className="data-field">
                  <span className="label">Proveedor</span>
                  <InputIconSearchPanel
                    id="prov"
                    size={0}
                    options={supplierList}
                    input={supplier}
                    inactive={selectedTab === null}
                    onChange={(e) => {
                      setSupplier(e);
                    }}
                    onClear={() => {
                      setSupplier("");
                    }}
                  ></InputIconSearchPanel>
                </div>
                <div className="supplier-card-info">
                  <div className="supplier-card">
                    <button className="popup-action-icon close-icon">
                      <img
                        src="/images/close-dark-blue.svg"
                        loading="lazy"
                        alt="Icon"
                        onClick={() => {}}
                      ></img>
                    </button>
                    <span className="section-content-title">LUXOTTICA</span>
                    <div className="section-content-sep"></div>
                    <div className="section-content-data store-content-data">
                      <div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Código</span>
                            <span>3470AS</span>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Teléfono</span>
                            <span>930 026 001</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Email</span>
                            <span>PEDIDOS@LUXOTTICA.COM</span>
                          </div>
                        </div>
                        <div className="data-field-row">
                          <div className="data-field d-medium">
                            <span className="label">Contacto</span>
                            <span>JOANA DOMINGUET GARCIA</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="store-body-sec">
                <div className="opt-table-title">Búsqueda de Marcas</div>
                <div className="data-field">
                  <span className="label">Marca</span>
                  <InputIconSearchPanelMultiSelection
                    id="brand"
                    size={0}
                    input={brands}
                    options={brandList}
                    inactive={selectedTab === null}
                    onChange={(e) => {
                      setBrands(e);
                    }}
                    onDelete={(e) => {
                      setBrands(e);
                    }}
                    onClear={() => {
                      setBrands([]);
                    }}
                  ></InputIconSearchPanelMultiSelection>
                </div>
                {brands.length > 0 && (
                  <span style={{ fontWeight: "500" }}>
                    Marcas seleccionadas: {brands.length}
                  </span>
                )}
                <div className="tag-content">
                  {brands.map((e, i) => {
                    return (
                      <Tag
                        text={e}
                        key={i}
                        onClick={(e) => {
                          setBrands((prevBrands) =>
                            prevBrands.filter((brand) => brand !== e)
                          );
                        }}
                      ></Tag>
                    );
                  })}
                </div>
              </div>
            </div>
            {selectedTab !== null && (
              <div className="store-product-info">
                <div className="opt-table-title">Filtrar Productos</div>
                <div className="store-product-filter">
                  <div className="data-field">
                    <span className="label">
                      Modelo / Color / Calibre / Material
                    </span>
                    <InputIconSearch
                      size={3}
                      input={inputSearch}
                      onChange={async (e) => {
                        setInputSearch(e);
                      }}
                      onClear={async () => {
                        setInputSearch("");
                      }}
                    ></InputIconSearch>
                  </div>
                  <div
                    className="data-field"
                    onClick={() => {
                      setPositiveStock(!positiveStock);
                    }}
                  >
                    <span className="label">Stock positivo</span>
                    <CheckBox
                      tabIndex={32}
                      active={positiveStock}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                  <div
                    className="data-field"
                    onClick={() => {
                      setDiscontinued(!discontinued);
                    }}
                  >
                    <span className="label">Descatalogados</span>
                    <CheckBox
                      tabIndex={32}
                      active={discontinued}
                      onClick={() => {}}
                    ></CheckBox>
                  </div>
                </div>
                <div className="opt-table-title">Lista de Productos</div>
                {tabs[selectedTab].content}
              </div>
            )}
            <div className="store-product-buttons">
              <ButtonIconV
                text="Utilidades"
                icon={"/images/settings-blue.svg"}
                onClick={() => {
                  props.openSuppliersPanel(() => {});
                }}
              ></ButtonIconV>
              <ButtonIconV
                text="Pedidos"
                icon={"/images/order-blue.svg"}
                onClick={() => {}}
              ></ButtonIconV>
              <ButtonIconV
                text="Imprimir"
                icon={"/images/print-blue.svg"}
                onClick={() => {}}
              ></ButtonIconV>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
