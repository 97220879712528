import React from "react";
import ButtonIconH from "./ButtonIconH";
import moment from "moment";
import "../css/OptometryHistoryBox.css";

export default function OptometryHistoryBox({ opt, onOpen }) {
  const formatOptValue = (value, sign) => {
    if (value === "") {
      return "-";
    }
    return value > 0 && sign ? `+${value}` : value;
  };

  return (
    <div className="history-box">
      <div className="history-box-header">
        <div className="history-box-header-fields">
          <div className="history-box-header-field">
            <span className="history-box-tag">Fecha</span>
            <span>{moment(opt.fecha).format("DD/MM/YYYY HH:mm:ss")}</span>
          </div>
          <div className="history-box-header-field">
            <span className="history-box-tag">Realizada por</span>
            <span>{opt.realizadaPor !== "" ? opt.realizadaPor : "-"}</span>
          </div>
        </div>
        <ButtonIconH
          text="Mostrar"
          small={true}
          onClick={() => {
            onOpen();
          }}
        ></ButtonIconH>
      </div>
      <div className="history-box-content">
        <div className="history-box-content-header">
          <span className="history-box-tag"></span>
          <span className="history-box-tag">ESF</span>
          <span className="history-box-tag">CIL</span>
          <span className="history-box-tag">EJE</span>
          <span className="history-box-tag">AV Lejos</span>
          <span className="history-box-tag">ADD</span>
          <span className="history-box-tag">Cerca</span>
          <span className="history-box-tag">AV Cerca</span>
        </div>
        <div className="history-box-content-data">
          <span className="history-box-data">OD</span>
          <span className="history-box-data">
            {formatOptValue(opt.esfera_d, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.cilindro_d, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.eje_d, false)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.av_cc_lejos_d, false)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.cerca_d, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.total_d, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.av_cc_cerca_d, false)}
          </span>
        </div>
        <div className="history-box-content-data">
          <span className="history-box-data">OI</span>
          <span className="history-box-data">
            {formatOptValue(opt.esfera_i, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.esfera_i, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.eje_i, false)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.av_cc_lejos_i, false)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.cerca_i, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.total_i, true)}
          </span>
          <span className="history-box-data">
            {formatOptValue(opt.av_cc_cerca_i, false)}
          </span>
        </div>
      </div>
    </div>
  );
}
