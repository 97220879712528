const recoverCursorPos = (input, position) => {
  setTimeout(() => {
    input.selectionEnd = position;
  }, 1);
};

const charCounter = (text, char) => {
  let counter = 0;
  for (let i = 0; i < text.length; i++) {
    if (text[i] === char) {
      counter++;
    }
  }
  return counter;
};

export const formatField = (input, text, type, dontForceUppercase) => {
  let position = input.selectionStart;
  let textPrev = text;
  switch (type) {
    case "type-only-chars":
      text = text.replace(/[^a-zA-ZñÑ]/g, "");
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-only-numbers":
      text = text.replace(/[^0-9]/g, "");
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-only-charnumbers":
      text = text.replace(/[^a-zA-Z0-9]/g, "");
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-alpha":
      text = text.replace(
        /[^a-zA-ZñÑçÇáÁàÀâÂäÄéÉèÈêÊëËíÍìÌîÎïÏóÓòÒôÔöÖúÚùÙûÛüÜºª \.\-\,]/g,
        ""
      );
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-alphanum":
      text = text.replace(
        /[^a-zA-ZñÑçÇáÁàÀâÂäÄéÉèÈêÊëËíÍìÌîÎïÏóÓòÒôÔöÖúÚùÙûÛüÜºª \.\-\,0-9]/g,
        ""
      );
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-alpha-ext":
      text = text.replace(
        /[^a-zA-ZñÑçÇáÁàÀâÂäÄéÉèÈêÊëËíÍìÌîÎïÏóÓòÒôÔöÖúÚùÙûÛüÜºª\\\/\(\) \.\-\,]/g,
        ""
      );
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-alphanum-ext":
      text = text.replace(
        /[^a-zA-ZñÑçÇáÁàÀâÂäÄéÉèÈêÊëËíÍìÌîÎïÏóÓòÒôÔöÖúÚùÙûÛüÜºª\\\/\(\) \.\-\,0-9]/g,
        ""
      );
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-alphanum-ext-2":
      text = text.replace(
        /[^a-zA-ZñÑçÇáÁàÀâÂäÄéÉèÈêÊëËíÍìÌîÎïÏóÓòÒôÔöÖúÚùÙûÛüÜºª\\\/\(\) \.\-\,0-9\n]/g,
        ""
      );
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-full":
      text = text.replace(/[^\s\S]/g, "");
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-integer":
      text = text.replace(/[^\d-]/g, "");
      if (text[position - 1] === "-" && text.length > 1) {
        if (position - 1 > 0) {
          const token1 = text.substring(0, position - 1);
          const token2 = text.substring(position, text.length);
          text = token1 + token2;
        }
      }
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-float2":
    case "type-float":
      text = text.replace(",", ".");
      if (text === ".") {
        text = "0.";
      } else {
        text = text.replace(/[^\d.-]/g, "");
        if (text[position - 1] === "-" && text.length > 1) {
          if (position - 1 > 0) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        }
        if (text[position - 1] === ".") {
          if (position === 1) {
            text = "0" + text;
            position++;
          }
          let counter = charCounter(text, ".");
          if (counter > 1) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        }
        if (text !== textPrev) {
          recoverCursorPos(input, position - 1);
        } else {
          recoverCursorPos(input, position);
        }
      }
      break;
    case "type-phone":
      text = text.replace(/[^0-9\+]/g, "");
      if (text[position - 1] === "+" && text.length > 1) {
        if (position - 1 > 0) {
          const token1 = text.substring(0, position - 1);
          const token2 = text.substring(position, text.length);
          text = token1 + token2;
        }
      }
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-email":
      text = text.replace(/[^a-zA-Z\.\-\_@0-9]/g, "");
      if (text[position - 1] === "@") {
        let counter = charCounter(text, "@");
        if (counter > 1) {
          const token1 = text.substring(0, position - 1);
          const token2 = text.substring(position, text.length);
          text = token1 + token2;
        }
      }
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
    case "type-optofloat":
      text = text.replace(",", ".");
      if (text === ".") {
        text = "0.";
      } else {
        text = text.replace(/[^\d.\+-]/g, "");
        if (text[position - 1] === "-" && text.length > 1) {
          if (position - 1 > 0) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        }
        if (text[position - 1] === "+" && text.length > 1) {
          if (position - 1 > 0) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        }
        if (text[position - 1] === ".") {
          if (position === 1) {
            text = "0" + text;
            position++;
          }
          let counter = charCounter(text, ".");
          if (counter > 1) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        }
        if (text !== textPrev) {
          recoverCursorPos(input, position - 1);
        } else {
          recoverCursorPos(input, position);
        }
      }
      break;
    case "type-optoacomodativo":
      text = text.replace(",", ".");
      if (text === ".") {
        text = "0.";
        position = 3;
      } else {
        text = text.replace(/[^\d.\-\/]/g, "");
        if (text[position - 1] === "/") {
          let counter = charCounter(text, "/");
          if (counter > 1) {
            const token1 = text.substring(0, position - 1);
            const token2 = text.substring(position, text.length);
            text = token1 + token2;
          }
        } else {
          let tokens = text.split("/");
          tokens[0] = formatField(input, tokens[0], "type-float", false);
          text = tokens[0];
          if (tokens.length > 1) {
            text = tokens[0] + "/" + tokens[1];
          }
        }
      }
      if (text !== textPrev) {
        recoverCursorPos(input, position - 1);
      } else {
        recoverCursorPos(input, position);
      }
      break;
  }

  if (!dontForceUppercase) {
    text = text.toUpperCase();
  }

  return text;
};

export const reformatField = (input, text, type, considerCursor) => {
  let position = -1;
  if (text !== "") {
    if (considerCursor) {
      position = input.selectionStart;
    }
    switch (type) {
      case "type-integer":
        if (text === "-") {
          text = "0";
        } else {
          text = parseInt(text).toString();
        }
        break;
      case "type-float":
        if (text === "-") {
          text = "0";
        } else {
          text = parseFloat(text).toString();
        }
        break;
      case "type-float2":
        if (text === "-") {
          text = "0";
        } else {
          text = parseFloat(text).toFixed(2).toString();
        }
        break;
      case "type-optofloat":
        if (text === "+") {
          text = "+0.00";
        } else {
          if (text === "-") {
            text = "-0.00";
          } else {
            text = parseFloat(text).toFixed(2).toString();
            if (text[0] !== "-") {
              if (text[0] !== "+") {
                text = "+" + text;
              }
            }
          }
        }
        break;
      case "type-optoacomodativo":
        const tokens = text.split("/");
        if (tokens[0] !== "") {
          if (tokens[0] === "-") {
            tokens[0] = "0.00";
          } else {
            tokens[0] = parseFloat(tokens[0]).toFixed(2).toString();
          }
        }
        text = tokens[0] + "/1";
        if (text === "/1") {
          text = "";
        }
        break;
    }
    if (considerCursor) {
      input.selectionEnd = position;
    }
  }
  return text;
};

export const checkFormat = (text, type) => {
  let ok = true;
  if (text !== "") {
    switch (type) {
      case "type-email":
        ok = /^\S+@\S+\.\S+$/.test(text);
        break;
    }
  }
  return ok;
};

export const onFocusField = (input, type, text) => {
  let newText = text;
  switch (type) {
    case "type-optoacomodativo":
      if (text === "") {
        newText = "/1";
      }
      const tokens = newText.split("/");
      recoverCursorPos(input, tokens[0].length);
      break;
  }
  return newText;
};
