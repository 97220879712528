import React from "react";
import "../css/Patient.css";

export default function ButtonSectionHeader(props) {
  return (
    <button className="button-section-content" onClick={props.onClick}>
      <img
        src={props.icon}
        loading="lazy"
        alt="Icon"
        className="section-header-icon"
      ></img>
      <span>{props.text}</span>
    </button>
  );
}
