import React from "react";
import "../../css/Patient.css";
import "../../css/Panel.css";
import Button from "../../components/Button";

export default function Settings(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="patient-data">
      <div className="patient-data-header">
        <span className="header-text">Skeleton</span>
        <div className="patient-header-buttons"></div>
      </div>
      <div className="patient-data-content">
        <div className="action-bar">
          <div className="action-bar-resume">
            <span>Debug</span>
            <span>|</span>
            <span className="signed-text">Skeleton</span>
          </div>
          <button
            className="action-bar-return"
            onClick={() => {
              props.onGoBack();
            }}
          >
            <span>Volver</span>
            <img
              src="/images/return.svg"
              loading="lazy"
              alt="Icon"
              className="return-icon"
            />
          </button>
        </div>
        <div className="patient-sections">
          <div className="patient-section">
            <div className="section-content">
              <div className="section-todo-tests">
                <Button
                  title="Abrir popup 1"
                  onPress={() =>
                    props.createPopupQuestion(
                      "Ejemplo de popup 1 (un botón)",
                      () => {
                        console.log("Pulsado Aceptar.");
                        props.removePopupQuestion();
                      },
                      null
                    )
                  }
                />
                <span style={{ marginRight: 10 }} />
                <Button
                  title="Abrir popup 2"
                  onPress={() =>
                    props.createPopupQuestion(
                      "Ejemplo de popup 2 (dos botones)",
                      () => {
                        console.log("Pulsado Aceptar.");
                        props.removePopupQuestion();
                      },
                      () => {
                        console.log("Pulsado Cancelar.");
                        props.removePopupQuestion();
                      }
                    )
                  }
                />
                <span style={{ marginRight: 10 }} />
                <Button title="Volver" onPress={() => props.onGoBack()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
