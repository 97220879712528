import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import DropdownFilter from "./DropdownFilter";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import "../css/BasicOptometry.css";
import { post } from "../js/post";
import {
  eyeHealthStructure,
  userData,
  selectedOptic,
  inputOnEnter,
  areObjectsEqual,
} from "../js/utils";

import moment from "moment";

export default function AdvancedOptometryEyeHealth(props) {
  const [currentEyeHealth, setCurrentEyeHealth] = useState(eyeHealthStructure);
  const [currentEyeHealthEdit, setCurrentEyeHealthEdit] =
    useState(eyeHealthStructure);
  const [optometristList, setOptometristList] = useState([]);
  const [dateHistoryList, setDateHistoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ id: "", text: "" });
  const [eyeHealthId, setEyeHealthId] = useState(props.eyeHealthId);
  const [diagnosisPanel, setDiagnosisPanel] = useState(false);

  //console.log("selectedDate: ", selectedDate);
  console.log("currentEyeHealthEdit: ", currentEyeHealthEdit);

  const getOptometristList = async () => {
    let response = await post("ListarOptometristas.php", {
      token: userData.token,
      optica: selectedOptic.id,
    });
    setOptometristList([
      { id: "", text: "" },
      ...response.map((e) => ({ id: e, text: e })),
    ]);
  };

  const getEyeHealthHistory = async () => {
    const response = await post("ListarFechasHistoricoSaludOcular.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      const found = response.find((e) => {
        return e.idSaludOcular === eyeHealthId;
      });
      if (found) {
        setSelectedDate({
          id: found.idSaludOcular,
          text: found.fecha,
        });
        setEyeHealthId(found.idSaludOcular);
      }
    }
    setDateHistoryList(response);
  };

  const getEyeHealth = async (eyeHealthId) => {
    const response = await post("ObtenerSaludOcular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSaludOcular: eyeHealthId,
    });

    setCurrentEyeHealth({
      ...response,
      idPaciente: props.patientId,
      idSaludOcular: eyeHealthId,
    });
    setCurrentEyeHealthEdit({
      ...response,
      idPaciente: props.patientId,
      idSaludOcular: eyeHealthId,
    });
  };

  const resetEyeHealthState = () => {
    setCurrentEyeHealthEdit({
      ...eyeHealthStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setCurrentEyeHealth({
      ...eyeHealthStructure,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setEyeHealthId(-1);
    props.saveLastEyeHealthIdVisited(-1);
    setSelectedDate({
      id: -1,
      text: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveEyeHealth = async () => {
    const response = await post("GuardarSaludOcular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSaludOcular: eyeHealthId,
      idPaciente: props.patientId,
      ...currentEyeHealthEdit,
    });
    setSelectedDate({
      id: response.idSaludOcular,
      text: currentEyeHealthEdit.fecha,
    });
    setEyeHealthId(response.idSaludOcular);
    setCurrentEyeHealth(currentEyeHealthEdit);
    props.saveLastEyeHealthIdVisited(response.idSaludOcular);
    props.createPopupQuestion(
      `Los cambios se han guardado con éxito.`,
      async () => {
        props.removePopupQuestion();
      },
      null,
      "Entendido"
    );
  };

  const deleteEyeHealth = async () => {
    await post("EliminarSaludOcular.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSaludOcular: eyeHealthId,
      idUsuario: userData.id,
    });
  };

  const selectEyeHealthAfterDeleting = async () => {
    const response = await post("ListarFechasHistoricoSaludOcular.php", {
      token: userData.token,
      idPaciente: props.patientId,
      optica: selectedOptic.id,
    });
    if (response.length > 0) {
      setSelectedDate({
        id: response[0].idSaludOcular,
        text: response[0].fecha,
      });
      setEyeHealthId(response[0].idSaludOcular);
      setDateHistoryList(response);
      await getEyeHealth(response[0].idSaludOcular);
      props.saveLastEyeHealthIdVisited(response[0].idSaludOcular);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setEyeHealthId(-1);
      props.saveLastEyeHealthIdVisited(-1);
      setCurrentEyeHealth(eyeHealthStructure);
      setCurrentEyeHealthEdit(eyeHealthStructure);
    }
  };

  const cancelChanges = async () => {
    if (dateHistoryList.length > 0) {
      // 1. Si estamos creando una ficha, cargamos la última.
      if (eyeHealthId === -1) {
        setSelectedDate({
          id: dateHistoryList[0].idSaludOcular,
          text: dateHistoryList[0].fecha,
        });
        setEyeHealthId(dateHistoryList[0].idSaludOcular);
        props.saveLastEyeHealthIdVisited(dateHistoryList[0].idSaludOcular);
        await getEyeHealth(dateHistoryList[0].idSaludOcular);
      } else {
        // 2. Si estamos viendo una ficha, cancelamos los cambios.
        setCurrentEyeHealthEdit(currentEyeHealth);
      }
    } else {
      setCurrentEyeHealthEdit(currentEyeHealth);
    }
  };

  useEffect(() => {
    getEyeHealthHistory();
    getOptometristList();
    if (eyeHealthId !== -1) {
      getEyeHealth(eyeHealthId);
    } else {
      setSelectedDate({
        id: -1,
        text: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentEyeHealthEdit({
        ...currentEyeHealthEdit,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      setCurrentEyeHealth({
        ...currentEyeHealth,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [eyeHealthId]);

  useEffect(() => {
    props.pendingChanges({
      changes: !areObjectsEqual(currentEyeHealth, currentEyeHealthEdit),
      dataToSave: currentEyeHealthEdit,
    });
  }, [currentEyeHealthEdit]);

  useEffect(() => {
    if (props.diagnosisValue !== currentEyeHealthEdit.diagnostico) {
      setCurrentEyeHealthEdit({
        ...currentEyeHealthEdit,
        diagnostico: props.diagnosisValue,
      });
    }
  }, [props.diagnosisValue]);

  return (
    <div className="tab-content">
      <div className="popup-screen-actions">
        <div className="popup-screen-box">
          <div className="popup-screen-box-info">
            <div className="cell-extra">
              <span className="label">Creación</span>
            </div>
            <div className="cell-extra">
              <div className="input-data input-full">
                <input
                  type="datetime-local"
                  step="1"
                  tabIndex={51}
                  className="input-field"
                  value={currentEyeHealthEdit.fecha}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      fecha: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    inputOnEnter(e);
                  }}
                ></input>
              </div>
            </div>
            <div className="cell-extra">
              <span className="label">
                Histórico ({dateHistoryList.length})
              </span>
            </div>
            <div className="cell-extra">
              <DropdownFilter
                tabIndex={52}
                optionList={dateHistoryList.map((item) => ({
                  id: item.idSaludOcular,
                  text: moment(item.fecha, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  active: selectedDate.id === item.idSaludOcular,
                }))}
                size={0}
                op={moment(selectedDate.text, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                disabled={eyeHealthId === -1}
                onChange={async (e) => {
                  setCurrentEyeHealthEdit(eyeHealthStructure);
                  setSelectedDate({
                    id: e.id,
                    text: moment(e.text, "DD/MM/YYYY HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  });
                  setEyeHealthId(e.id);
                  props.saveLastEyeHealthIdVisited(e.id);
                }}
              ></DropdownFilter>
            </div>
            <div className="cell-extra cell-extra-optometrista">
              <span className="label">Optometrista</span>
            </div>
            <div className="cell-extra combined-cells-3">
              <Dropdown
                tabIndex={53}
                optionList={optometristList}
                size={0}
                op={currentEyeHealthEdit.optometrista}
                onChange={(e) => {
                  setCurrentEyeHealthEdit({
                    ...currentEyeHealthEdit,
                    optometrista: e.text,
                  });
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className="popup-screen-box">
          <ButtonIconV
            text="Nuevo"
            icon={
              eyeHealthId !== -1
                ? "/images/create-blue.svg"
                : "/images/create-gray.svg"
            }
            onClick={() => {
              if (eyeHealthId !== -1) {
                if (!areObjectsEqual(currentEyeHealth, currentEyeHealthEdit)) {
                  props.createPopupQuestion(
                    `Existen cambios sin guardar en la ficha actual. ¿Desea guardar los cambios antes de crear una nueva ficha?`,
                    async () => {
                      await saveEyeHealth();
                      props.removePopupQuestion();
                      resetEyeHealthState();
                    },
                    async () => {
                      props.removePopupQuestion();
                      resetEyeHealthState();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  resetEyeHealthState();
                }
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Guardar"
            icon="/images/save-blue.svg"
            onClick={async () => {
              eyeHealthStructure.fecha = currentEyeHealthEdit.fecha;
              if (areObjectsEqual(currentEyeHealthEdit, eyeHealthStructure)) {
                props.createPopupQuestion(
                  `La ficha de salud ocular actual no contiene datos para guardar.`,
                  async () => {
                    props.removePopupQuestion();
                  },
                  null,
                  "Aceptar",
                  null
                );
              } else {
                await saveEyeHealth();
                await getEyeHealthHistory();
                props.updateTab();
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Cancelar"
            icon="/images/cancel-blue.svg"
            onClick={() => {
              if (
                dateHistoryList.length === 0 &&
                areObjectsEqual(currentEyeHealth, currentEyeHealthEdit)
              ) {
                props.removePopupQuestion();
              } else if (
                !areObjectsEqual(currentEyeHealth, currentEyeHealthEdit) ||
                eyeHealthId === -1
              ) {
                props.createPopupQuestion(
                  `¿Desea cancelar los cambios realizados?`,
                  async () => {
                    await cancelChanges();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Recargar"
            icon={
              eyeHealthId !== -1
                ? "/images/update-blue.svg"
                : "/images/update-gray.svg"
            }
            onClick={() => {
              if (eyeHealthId !== -1) {
                props.createPopupQuestion(
                  `¿Desea recargar esta ficha de salud ocular?`,
                  async () => {
                    await getEyeHealth(eyeHealthId);
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Eliminar"
            icon={
              eyeHealthId !== -1
                ? "/images/remove-blue.svg"
                : "/images/remove-gray.svg"
            }
            onClick={() => {
              if (eyeHealthId !== -1) {
                props.createPopupQuestion(
                  `¿Desea eliminar esta ficha de salud ocular?`,
                  async () => {
                    await deleteEyeHealth();
                    await getEyeHealthHistory();
                    await selectEyeHealthAfterDeleting();
                    props.updateTab();
                    props.removePopupQuestion();
                  },
                  async () => {
                    props.removePopupQuestion();
                  },
                  "Sí",
                  "No"
                );
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Notas"
            icon={
              eyeHealthId !== -1
                ? "/images/write-blue.svg"
                : "/images/write-gray.svg"
            }
            onClick={() => {
              if (eyeHealthId !== -1) {
                props.openDiagnosisPopup(currentEyeHealthEdit.diagnostico);
              }
            }}
          ></ButtonIconV>
          <ButtonIconV
            text="Imprimir"
            icon={
              eyeHealthId !== -1
                ? "/images/print-blue.svg"
                : "/images/print-gray.svg"
            }
            onClick={() => {
              if (eyeHealthId !== -1) {
              }
            }}
          ></ButtonIconV>
        </div>
      </div>
      <div className="sep-line"></div>
      <div className="popup-screen-content">
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-content grid-10">
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                Oftalmoscopia Directa
              </div>
              <div className="cell combined-cells-2"></div>
              <div className="cell cell-label combined-cells-4">OD</div>
              <div className="cell cell-label combined-cells-4">OI</div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Papila
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={54}
                  type="type-full"
                  size={0}
                  maxLength={100}
                  input={currentEyeHealthEdit.papila_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      papila_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={55}
                  type="type-full"
                  size={0}
                  maxLength={100}
                  input={currentEyeHealthEdit.papila_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      papila_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Vasos
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={56}
                  type="type-full"
                  size={0}
                  maxLength={100}
                  input={currentEyeHealthEdit.vasos_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      vasos_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={57}
                  type="type-full"
                  size={0}
                  maxLength={100}
                  input={currentEyeHealthEdit.vasos_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      vasos_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Retina
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={58}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.retina_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      retina_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={59}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.retina_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      retina_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Mácula
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={60}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.macula_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      macula_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={61}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.macula_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      macula_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Fijación
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={62}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.fijacion_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      fijacion_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={63}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.fijacion_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      fijacion_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Otros
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={64}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.otros_oftalmoscopia_od}
                  bkg={true}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      otros_oftalmoscopia_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={65}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.otros_oftalmoscopia_oi}
                  bkg={true}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      otros_oftalmoscopia_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-10"></div>
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                PIRRLA
              </div>
              <div className="cell cell-label cell-start-content combined-cells-3">
                Tamaño Pupilar
              </div>
              <div className="cell combined-cells-7">
                <InputTextArea
                  tabIndex={66}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.tamanio_pupilar}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      tamanio_pupilar: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-3">
                Reacción Pupilar a la Luz
              </div>
              <div className="cell combined-cells-7">
                <InputTextArea
                  tabIndex={67}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.reaccion_pupilar_luz}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      reaccion_pupilar_luz: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-3">
                Respuesta al Acercamiento
              </div>
              <div className="cell combined-cells-7">
                <InputTextArea
                  tabIndex={68}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.respuesta_al_acercamiento}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      respuesta_al_acercamiento: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-3">
                Otros
              </div>
              <div className="cell combined-cells-7">
                <InputTextArea
                  tabIndex={69}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.otros_pirla}
                  bkg={true}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      otros_pirla: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-screen-content-col w-50">
          <div className="opt-table-container">
            <div className="opt-table-content grid-10">
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                Biomicroscopía
              </div>
              <div className="cell combined-cells-2"></div>
              <div className="cell cell-label combined-cells-4">OD</div>
              <div className="cell cell-label combined-cells-4">OI</div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Párpados
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={70}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.parpados_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      parpados_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={71}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.parpados_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      parpados_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Pestañas
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={72}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.pestanias_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      pestanias_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={73}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.pestanias_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      pestanias_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Conjuntiva
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={74}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.conjuntiva_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      conjuntiva_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={75}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.conjuntiva_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      conjuntiva_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Córnea
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={76}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.cornea_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      cornea_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={77}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.cornea_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      cornea_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Iris
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={78}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.iris_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      iris_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={79}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.iris_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      iris_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Cristalino
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={80}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.cristalino_od}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      cristalino_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={81}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.cristalino_oi}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      cristalino_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell cell-label cell-start-content combined-cells-2">
                Otros
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={82}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.otros_bio_od}
                  bkg={true}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      otros_bio_od: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-4">
                <InputTextArea
                  tabIndex={83}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.otros_bio_oi}
                  bkg={true}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      otros_bio_oi: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-10"></div>
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                Campimetría
              </div>
              <div className="cell combined-cells-10">
                <InputTextArea
                  tabIndex={84}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.campimetria}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      campimetria: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-10"></div>
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                Confrontación de Campos
              </div>
              <div className="cell combined-cells-10">
                <InputTextArea
                  tabIndex={85}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.confrontacion_de_campos}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      confrontacion_de_campos: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
              <div className="cell combined-cells-10"></div>
              <div className="cell opt-table-subtitle combined-cells-10 cell-start-content">
                Pantalla Tangente
              </div>
              <div className="cell combined-cells-10">
                <InputTextArea
                  tabIndex={86}
                  maxLength={100}
                  type="type-full"
                  size={0}
                  input={currentEyeHealthEdit.pantalla_tangente}
                  onChange={(e) => {
                    setCurrentEyeHealthEdit({
                      ...currentEyeHealthEdit,
                      pantalla_tangente: e,
                    });
                  }}
                  extra="small-height"
                ></InputTextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
