import React from "react";

export default function MenuSubOption(props) {
  return (
    <div
      className={`menu-inicio ${
        props.item.id === props.currentScreen ? "active-op" : ""
      }`}
      onClick={() => {
        props.clickMenuOption(props.item.id);
      }}
      /*onContextMenu={(e) => {
        e.preventDefault();
        window.open("/" + props.item.id, "_blank");
      }}*/
    >
      <div className={`section-title subsection-op`}>
        <div className="subsection-row">
          <img
            src={props.item.icon}
            loading="lazy"
            width="14"
            height="15"
            alt="Icon"
            className="vectors-wrapper"
          />
          <div className="h4-p2 white">{props.item.text}</div>
        </div>
        {props.item.plusScreen && props.item.plusScreen !== "" && (
          <img
            className="new-section"
            src="/images/add-white.svg"
            onClick={(e) => {
              e.stopPropagation();
              props.clickMenuOption(props.item.plusScreen);
            }}
          />
        )}
      </div>
    </div>
  );
}
