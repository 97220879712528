import React from "react";
import "../css/TopBarButton.css";

export default function TopBarButton(props) {
  return (
    <button className="top-bar-link">
      <div className="icon">
        <img
          src={props.icon}
          loading="lazy"
          alt="Icon"
          className="top-bar-icon"
        />
      </div>
      <div className="p1 white">{props.title}</div>
    </button>
  );
}
