import React, { useEffect, useState } from "react";
import BasicOptometry from "./BasicOptometry";
import AdvancedOptometryAnamnesis from "./AdvancedOptometryAnamnesis";
import AdvancedOptometryRefractive from "./AdvancedOptometryRefractive";
import AdvancedOptometryOculomotor from "./AdvancedOptometryOculomotor";
import AdvancedOptometryBinocular from "./AdvancedOptometryBinocular";
import AdvancedOptometryAccommodative from "./AdvancedOptometryAccommodative";
import AdvancedOptometrySensory from "./AdvancedOptometrySensory";
import AdvancedOptometryEyeHealth from "./AdvancedOptometryEyeHealth";
import EyeSchema from "./EyeSchema";
import CoverTest from "./CoverTest";
import HessLancaster from "./HessLancaster";
import FileViewer from "./FileViewer";
import PopupMessage from "./PopupMessage";
import { post } from "../js/post";
import {
  userData,
  selectedOptic,
  binocularStructure,
  basicOptometry,
  testScheme,
  hessLancasterStructure,
  deviationFactor,
} from "../js/utils";
import "../css/OptometryScreen.css";
import moment from "moment";
import Tooltip from "./Tooltip";
import Diagnosis from "./Diagnosis";

export default function OptometryScreen(props) {
  const [activeTab, setActiveTab] = useState(props.tabId);
  const [eyeSchema, setEyeSchema] = useState(false);
  const [coverTest, setCoverTest] = useState(false);
  const [hessLancaster, setHessLancaster] = useState(false);
  const [diagnosisPanel, setDiagnosisPanel] = useState(false);
  const [popupQuestion, setPopupQuestion] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const [currentBinocularEdit, setCurrentBinocularEdit] =
    useState(binocularStructure);
  const [currentBasicOptometryEdit, setCurrentBasicOptometryEdit] =
    useState(basicOptometry);
  const [updateOpt, setUpdateOpt] = useState(false);
  const [currentEyeSchemaEdit, setCurrentEyeSchemaEdit] = useState(testScheme);
  const [currentHessLancasterEdit, setCurrentHessLancasterEdit] = useState(
    hessLancasterStructure
  );
  const [testHL, setTestHL] = useState(null);
  const [diagnosisValue, setDiagnosisValue] = useState("");
  const [files, setFiles] = useState([]);
  const [numObservations, setNumObservations] = useState(0);

  const [elements, setElements] = useState({
    optometria: 0,
    anamnesis: 0,
    refractivo: 0,
    oculomotor: 0,
    binocular: 0,
    acomodativo: 0,
    sensorial: 0,
    saludOcular: 0,
  });

  const [lastVisitedId, setLastVisitedId] = useState({
    optometria: props.optIds.optometria,
    anamnesis: props.optIds.anamnesis,
    refractivo: props.optIds.refractivo,
    oculomotor: props.optIds.oculomotor,
    binocular: props.optIds.binocular,
    acomodativo: props.optIds.acomodativo,
    sensorial: props.optIds.sensorial,
    saludOcular: props.optIds.saludOcular,
  });

  //console.log("currentBasicOptometryEdit: ", currentBasicOptometryEdit);
  //console.log("updateOpt: ", updateOpt);
  //console.log("currentEyeSchemaEdit: ", currentEyeSchemaEdit);
  //console.log("lastVisitedId: ", lastVisitedId);
  //console.log("currentHessLancasterEdit OS: ", currentHessLancasterEdit);

  let pendingChanges = {
    changes: false,
    dataToSave: null,
  };

  let setPendingChanges = (value) => {
    pendingChanges = value;
  };

  const copyCurrentBasicOptometryEdit = (e) => {
    setCurrentBasicOptometryEdit(e);
  };

  const copyCurrentBinocularEdit = (e) => {
    setCurrentBinocularEdit(e);
  };

  const checkActiveTabForSaving = async () => {
    switch (activeTab) {
      case 0:
        // Optometría Básica
        await saveBasicOptometry();
        break;
      case 1:
        // Anamnesis
        await saveAnamnesis();
        break;
      case 2:
        // Refractivo
        await saveRefractive();
        break;
      case 3:
        // Oculomotor
        await saveOculomotor();
        break;
      case 4:
        // Binocular
        await saveBinocular();
        break;
      case 5:
        // Acomodativo
        await saveAccommodative();
        break;
      case 6:
        // Sensorial
        await saveSensory();
        break;
      case 7:
        // Salud Ocular
        await saveEyeHealth();
        break;

      default:
        break;
    }
  };

  const handleTabClick = (index) => {
    if (pendingChanges.changes && activeTab !== index) {
      if (
        (activeTab === 0 && lastVisitedId.optometria === -1) ||
        (activeTab === 1 && lastVisitedId.anamnesis === -1) ||
        (activeTab === 2 && lastVisitedId.refractivo === -1) ||
        (activeTab === 3 && lastVisitedId.oculomotor === -1) ||
        (activeTab === 4 && lastVisitedId.binocular === -1) ||
        (activeTab === 5 && lastVisitedId.acomodativo === -1) ||
        (activeTab === 6 && lastVisitedId.sensorial === -1) ||
        (activeTab === 7 && lastVisitedId.saludOcular === -1)
      ) {
        createPopupQuestion(
          "Por favor, guarde o cancele la ficha que está creando.",
          async () => {
            removePopupQuestion();
          },
          null,
          "Entendido"
        );
      } else {
        createPopupQuestion(
          "Existen cambios que no han sido guardados. ¿Desea guardar antes de salir?",
          async () => {
            await checkActiveTabForSaving();
            setActiveTab(index);
            removePopupQuestion();
          },
          () => {
            setActiveTab(index);
            removePopupQuestion();
          }
        );
      }
    } else {
      setActiveTab(index);
    }
  };

  const createPopupQuestion = (
    text,
    onClickAccept,
    onClickCancel,
    textAccept,
    textCancel
  ) => {
    setPopupQuestion({
      text,
      onClickCancel,
      onClickAccept,
      textAccept,
      textCancel,
    });
  };

  const checkChanges = (value) => {
    setPendingChanges(value);
  };

  const removePopupQuestion = () => {
    setPopupQuestion(null);
  };

  const openTooltip = (e) => {
    setTooltip(e);
  };

  const closeTooltip = () => {
    setTooltip(null);
  };

  const getOptometryHistory = async () => {
    const response = await post("ListarHistoricoOptometrias.php", {
      token: userData.token,
      idPaciente: props.patientInfo.id,
      optica: selectedOptic.id,
      completo: 0,
    });
    setElements((prevElements) => ({
      ...prevElements,
      optometria: response.length,
    }));
  };

  const getNumOptometries = async () => {
    const response = await post("ListarNumOptometrias.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientInfo.id,
    });
    setElements((prevElements) => ({
      ...prevElements,
      optometria: response.optometria.num,
      anamnesis: response.anamnesis.num,
      refractivo: response.refractivo.num,
      oculomotor: response.oculomotor.num,
      binocular: response.binocular.num,
      acomodativo: response.acomodativo.num,
      sensorial: response.sensorial.num,
      saludOcular: response.saludOcular.num,
    }));
    setLastVisitedId({
      optometria: response.optometria.id,
      anamnesis: response.anamnesis.id,
      refractivo: response.refractivo.id,
      oculomotor: response.oculomotor.id,
      binocular: response.binocular.id,
      acomodativo: response.acomodativo.id,
      sensorial: response.sensorial.id,
      saludOcular: response.saludOcular.id,
    });
  };

  const getOptometryFiles = async () => {
    const response = await post("ListarFicheros.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idPaciente: props.patientInfo.id,
      categoria: "Optometria",
    });
    setFiles(response);
  };

  const saveBasicOptometry = async () => {
    await post("GuardarOptometriaBasica.php", {
      token: userData.token,
      optica: selectedOptic.id,
      ...pendingChanges.dataToSave,
      fecha_actualizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const saveAdvancedOptometry = async (phpFile) => {
    const requestData = {
      token: userData.token,
      optica: selectedOptic.id,
      ...pendingChanges.dataToSave,
    };
    await post(phpFile, requestData);
  };

  const saveAnamnesis = async () => {
    await saveAdvancedOptometry("GuardarAnamnesis.php");
  };

  const saveRefractive = async () => {
    await saveAdvancedOptometry("GuardarRefractivo.php");
  };

  const saveOculomotor = async () => {
    await saveAdvancedOptometry("GuardarOculomotor.php");
  };

  const saveBinocular = async () => {
    await saveAdvancedOptometry("GuardarBinocular.php");
  };

  const saveAccommodative = async () => {
    await saveAdvancedOptometry("GuardarAcomodativo.php");
  };

  const saveSensory = async () => {
    await saveAdvancedOptometry("GuardarSensorial.php");
  };

  const saveHessLancaster = async (sensoryId) => {
    const response = await post("GuardarHessLancaster.php", {
      token: userData.token,
      optica: selectedOptic.id,
      idSensorial: sensoryId,
      idHessLancaster: currentHessLancasterEdit.idHessLancaster,
      punto1_od_x: currentHessLancasterEdit.punto1_od_x - deviationFactor,
      punto1_od_y: currentHessLancasterEdit.punto1_od_y - deviationFactor,
      punto2_od_x: currentHessLancasterEdit.punto2_od_x - deviationFactor,
      punto2_od_y: currentHessLancasterEdit.punto2_od_y - deviationFactor,
      punto3_od_x: currentHessLancasterEdit.punto3_od_x - deviationFactor,
      punto3_od_y: currentHessLancasterEdit.punto3_od_y - deviationFactor,
      punto4_od_x: currentHessLancasterEdit.punto4_od_x - deviationFactor,
      punto4_od_y: currentHessLancasterEdit.punto4_od_y - deviationFactor,
      punto5_od_x: currentHessLancasterEdit.punto5_od_x - deviationFactor,
      punto5_od_y: currentHessLancasterEdit.punto5_od_y - deviationFactor,
      punto6_od_x: currentHessLancasterEdit.punto6_od_x - deviationFactor,
      punto6_od_y: currentHessLancasterEdit.punto6_od_y - deviationFactor,
      punto7_od_x: currentHessLancasterEdit.punto7_od_x - deviationFactor,
      punto7_od_y: currentHessLancasterEdit.punto7_od_y - deviationFactor,
      punto8_od_x: currentHessLancasterEdit.punto8_od_x - deviationFactor,
      punto8_od_y: currentHessLancasterEdit.punto8_od_y - deviationFactor,
      punto9_od_x: currentHessLancasterEdit.punto9_od_x - deviationFactor,
      punto9_od_y: currentHessLancasterEdit.punto9_od_y - deviationFactor,
      observaciones_od: currentHessLancasterEdit.observaciones_od,
      punto1_oi_x: currentHessLancasterEdit.punto1_oi_x - deviationFactor,
      punto1_oi_y: currentHessLancasterEdit.punto1_oi_y - deviationFactor,
      punto2_oi_x: currentHessLancasterEdit.punto2_oi_x - deviationFactor,
      punto2_oi_y: currentHessLancasterEdit.punto2_oi_y - deviationFactor,
      punto3_oi_x: currentHessLancasterEdit.punto3_oi_x - deviationFactor,
      punto3_oi_y: currentHessLancasterEdit.punto3_oi_y - deviationFactor,
      punto4_oi_x: currentHessLancasterEdit.punto4_oi_x - deviationFactor,
      punto4_oi_y: currentHessLancasterEdit.punto4_oi_y - deviationFactor,
      punto5_oi_x: currentHessLancasterEdit.punto5_oi_x - deviationFactor,
      punto5_oi_y: currentHessLancasterEdit.punto5_oi_y - deviationFactor,
      punto6_oi_x: currentHessLancasterEdit.punto6_oi_x - deviationFactor,
      punto6_oi_y: currentHessLancasterEdit.punto6_oi_y - deviationFactor,
      punto7_oi_x: currentHessLancasterEdit.punto7_oi_x - deviationFactor,
      punto7_oi_y: currentHessLancasterEdit.punto7_oi_y - deviationFactor,
      punto8_oi_x: currentHessLancasterEdit.punto8_oi_x - deviationFactor,
      punto8_oi_y: currentHessLancasterEdit.punto8_oi_y - deviationFactor,
      punto9_oi_x: currentHessLancasterEdit.punto9_oi_x - deviationFactor,
      punto9_oi_y: currentHessLancasterEdit.punto9_oi_y - deviationFactor,
      observaciones_oi: currentHessLancasterEdit.observaciones_oi,
    });
    setCurrentHessLancasterEdit({
      ...currentHessLancasterEdit,
      idHessLancaster: response.idHessLancaster,
    });
  };

  const saveEyeHealth = async () => {
    await saveAdvancedOptometry("GuardarSaludOcular.php");
  };

  const openDiagnosisPanel = (e) => {
    setDiagnosisValue(e);
    setDiagnosisPanel(true);
  };

  const closeDiagnosisPanel = () => {
    setDiagnosisPanel(false);
    setDiagnosisValue("");
  };

  const getObservations = async (optId) => {
    const response = await post("ListarObservaciones.php", {
      token: userData.token,
      optica: selectedOptic.id,
      id: optId,
      categoria: "OptometriaBasica",
    });
    setNumObservations(response.length);
  };

  const TabContent = (props) => {
    return (
      <div className="tab-content">Contenido de pantalla: {props.label}</div>
    );
  };

  let tabs = [
    {
      label: "Optometría",
      content: (
        <BasicOptometry
          toggleEyeSchema={async (basicOptometry) => {
            setEyeSchema(!eyeSchema);
            if (!eyeSchema) {
              copyCurrentBasicOptometryEdit(basicOptometry);
              if (
                JSON.stringify(currentEyeSchemaEdit) ===
                  JSON.stringify(testScheme) ||
                currentBasicOptometryEdit.idOptometria !==
                  basicOptometry.idOptometria
              ) {
                setCurrentEyeSchemaEdit(basicOptometry.esquema);
              }
            }
          }}
          patientId={props.patientInfo.id}
          optometryId={
            lastVisitedId.optometria
              ? lastVisitedId.optometria
              : props.optIds.optometria
          }
          saveLastOptIdVisited={async (id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              optometria: id,
            }));
          }}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          updateOpt={updateOpt}
          updateOptAction={() => {
            setUpdateOpt(false);
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          toggleObservationsPanel={(e, optId) => {
            if (e) {
              props.openObservationsPanel(
                props.patientInfo,
                optId,
                async () => {
                  await getObservations(optId);
                },
                "OptometriaBasica"
              );
            } else {
              props.closeObservationsPanel();
            }
          }}
          numObservations={numObservations}
        />
      ),
      icon:
        elements.optometria > 0
          ? "/images/optometry-orange.svg"
          : "/images/optometry-blue.svg",
    },
    {
      label: "Anamnesis",
      content: (
        <AdvancedOptometryAnamnesis
          anamnesisId={lastVisitedId.anamnesis}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastAnamnesisIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              anamnesis: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        />
      ),
      icon:
        elements.anamnesis > 0
          ? "/images/anamnesis-orange.svg"
          : "/images/anamnesis-blue.svg",
    },
    {
      label: "Refractivo",
      content: (
        <AdvancedOptometryRefractive
          refractiveId={lastVisitedId.refractivo}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastRefractiveIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              refractivo: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometryRefractive>
      ),
      icon:
        elements.refractivo > 0
          ? "/images/rx-orange.svg"
          : "/images/rx-blue.svg",
    },
    {
      label: "Oculomotor",
      content: (
        <AdvancedOptometryOculomotor
          oculomotorId={lastVisitedId.oculomotor}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastOculomotorIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              oculomotor: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometryOculomotor>
      ),
      icon:
        elements.oculomotor > 0
          ? "/images/accommodative-orange.svg"
          : "/images/accommodative-blue.svg",
    },
    {
      label: "Binocular",
      content: (
        <AdvancedOptometryBinocular
          binocularId={lastVisitedId.binocular}
          currentBinocularEdit={currentBinocularEdit}
          toggleCoverTest={() => {
            setCoverTest(!coverTest);
          }}
          coverTest={coverTest}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastBinocularIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              binocular: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          onCoverTest={(e) => {
            copyCurrentBinocularEdit(e);
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometryBinocular>
      ),
      icon:
        elements.binocular > 0
          ? "/images/binocular-orange.svg"
          : "/images/binocular-blue.svg",
    },
    {
      label: "Acomodativo",
      content: (
        <AdvancedOptometryAccommodative
          accommodativeId={lastVisitedId.acomodativo}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastAccommodativeIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              acomodativo: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometryAccommodative>
      ),
      icon:
        elements.acomodativo > 0
          ? "/images/accommodative-orange.svg"
          : "/images/accommodative-blue.svg",
    },
    {
      label: "Sensorial",
      content: (
        <AdvancedOptometrySensory
          sensoryId={lastVisitedId.sensorial}
          toggleHessLancaster={(hessLancasterData, e) => {
            setHessLancaster(!hessLancaster);
            if (
              !hessLancaster &&
              currentHessLancasterEdit.idHessLancaster === -1
            ) {
              setCurrentHessLancasterEdit(
                lastVisitedId.sensorial !== -1
                  ? hessLancasterData
                  : currentHessLancasterEdit
              );
            }
            setTestHL(e);
          }}
          testHL={testHL}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastSensoryIdVisited={(id) => {
            setCurrentHessLancasterEdit(hessLancasterStructure);
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              sensorial: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          saveHessLancaster={async (e) => {
            await saveHessLancaster(e);
          }}
          openTooltip={(e) => {
            openTooltip(e);
          }}
          closeTooltip={() => {
            closeTooltip();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometrySensory>
      ),
      icon:
        elements.sensorial > 0
          ? "/images/sensory-orange.svg"
          : "/images/sensory-blue.svg",
    },
    {
      label: "Salud Ocular",
      content: (
        <AdvancedOptometryEyeHealth
          eyeHealthId={lastVisitedId.saludOcular}
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          saveLastEyeHealthIdVisited={(id) => {
            setLastVisitedId((prevLastVisitedId) => ({
              ...prevLastVisitedId,
              saludOcular: id,
            }));
          }}
          pendingChanges={(value) => {
            checkChanges(value);
          }}
          updateTab={async () => {
            await getNumOptometries();
          }}
          openDiagnosisPopup={(e) => {
            openDiagnosisPanel(e);
          }}
          closeDiagnosisPopup={() => {
            closeDiagnosisPanel();
          }}
          diagnosisValue={diagnosisValue}
        ></AdvancedOptometryEyeHealth>
      ),
      icon:
        elements.saludOcular > 0
          ? "/images/eye-health-orange.svg"
          : "/images/eye-health-blue.svg",
    },
    {
      label: "Ficheros",
      content: (
        <FileViewer
          /* files={files} */
          category="Optometria"
          patientId={props.patientInfo.id}
          createPopupQuestion={(
            text,
            onClickAccept,
            onClickCancel,
            textAccept,
            textCancel
          ) =>
            createPopupQuestion(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            )
          }
          removePopupQuestion={() => {
            removePopupQuestion();
          }}
          reloadFiles={async () => {
            await getOptometryFiles();
          }}
        ></FileViewer>
      ),
      icon:
        files.length > 0
          ? "/images/files-orange.svg"
          : "/images/files-blue.svg",
    },
  ];

  useEffect(() => {
    getNumOptometries();
    getOptometryFiles();
  }, []);

  useEffect(() => {
    if (lastVisitedId.sensorial === -1) {
      setCurrentHessLancasterEdit(hessLancasterStructure);
    }
  }, [lastVisitedId.sensorial]);

  useEffect(() => {
    getObservations(lastVisitedId.optometria);
  }, [lastVisitedId.optometria]);

  return (
    <div className="popup-container" id="popup-optometry">
      {popupQuestion && (
        <PopupMessage
          text={popupQuestion.text}
          onClickCancel={popupQuestion.onClickCancel}
          onClickAccept={popupQuestion.onClickAccept}
          textAccept={popupQuestion.textAccept}
          textCancel={popupQuestion.textCancel}
        ></PopupMessage>
      )}
      {tooltip && (
        <Tooltip
          top={tooltip.top}
          left={tooltip.left}
          content={tooltip.content}
          style={tooltip.style}
        ></Tooltip>
      )}
      <div className="popup-screen">
        <div className="popup-screen-header">
          <button className="popup-action-icon close-icon">
            <img
              src="/images/close-white.svg"
              loading="lazy"
              alt="Icon"
              onClick={async () => {
                if (pendingChanges.changes) {
                  createPopupQuestion(
                    "Existen cambios que no han sido guardados. ¿Desea guardar antes de salir?",
                    async () => {
                      await checkActiveTabForSaving();
                      removePopupQuestion();
                      props.onClose();
                    },
                    () => {
                      removePopupQuestion();
                      props.onClose();
                    },
                    "Sí",
                    "No"
                  );
                } else {
                  props.onClose();
                }
                await getOptometryHistory();
              }}
            ></img>
          </button>
          <span className="popup-screen-header-title">
            Ficha de Optometría del Cliente {props.patientInfo.name} (
            {props.patientInfo.id})
          </span>
        </div>
        <div className="popup-screen-tab-list">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${
                index === activeTab ? "active-tab" : "inactive-tab"
              }`}
              onClick={() => {
                handleTabClick(index);
              }}
            >
              <img
                src={tabs[index].icon}
                loading="lazy"
                alt="Icon"
                className="tab-icon"
              ></img>
              <span>{tab.label}</span>
              <div className="no-shadow-div"></div>
            </div>
          ))}
        </div>
        {tabs[activeTab].content}
        {eyeSchema && (
          <EyeSchema
            optometry={currentBasicOptometryEdit}
            eyeSchema={currentEyeSchemaEdit}
            updateEyeSchema={(eyeSchema) => {
              setCurrentEyeSchemaEdit(eyeSchema);
            }}
            onAccept={async (basicOptometry) => {
              if (
                basicOptometry.optometrista === "" &&
                basicOptometry.oftalmologo === ""
              ) {
                createPopupQuestion(
                  `Debe seleccionar un optometrista o un oftalmólogo.`,
                  async () => {
                    setEyeSchema(false);
                    removePopupQuestion();
                  },
                  null
                );
              } else {
                if (!basicOptometry.idOptometria) {
                  basicOptometry.idOptometria = "-1";
                  basicOptometry.idPaciente = props.patientInfo.id;
                }
                const response = await post("GuardarOptometriaBasica.php", {
                  token: userData.token,
                  optica: selectedOptic.id,
                  ...basicOptometry,
                  fecha_actualizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
                setCurrentBasicOptometryEdit(basicOptometry);
                setEyeSchema(false);
                // Hay que guardar el id creado.
                setLastVisitedId((prevLastVisitedId) => ({
                  ...prevLastVisitedId,
                  optometria: response.idOptometria,
                }));
                //
                removePopupQuestion();
                createPopupQuestion(
                  `Los cambios se han guardado con éxito.`,
                  async () => {
                    setUpdateOpt(true);
                    setCurrentEyeSchemaEdit(testScheme);
                    removePopupQuestion();
                  },
                  null,
                  "Entendido"
                );
              }
            }}
            onCancel={() => {
              setEyeSchema(false);
            }}
          ></EyeSchema>
        )}
        {coverTest && (
          <CoverTest
            currentBinocular={currentBinocularEdit}
            onAccept={(e) => {
              // GUARDAR DATOS DE COVERTEST
              copyCurrentBinocularEdit(e);
              setCoverTest(false);
            }}
          ></CoverTest>
        )}
        {hessLancaster && (
          <HessLancaster
            data={currentHessLancasterEdit}
            test={testHL}
            onAccept={(e, t) => {
              setCurrentHessLancasterEdit(e);
              setTestHL(t);
              setHessLancaster(false);
            }}
            onCancel={() => {
              setHessLancaster(false);
            }}
            createPopupQuestion={(
              text,
              onClickAccept,
              onClickCancel,
              textAccept,
              textCancel
            ) =>
              createPopupQuestion(
                text,
                onClickAccept,
                onClickCancel,
                textAccept,
                textCancel
              )
            }
            removePopupQuestion={() => {
              removePopupQuestion();
            }}
          ></HessLancaster>
        )}
        {diagnosisPanel && (
          <Diagnosis
            value={diagnosisValue}
            onClose={() => {
              setDiagnosisPanel(false);
            }}
            onSave={(e) => {
              setDiagnosisValue(e);
            }}
          ></Diagnosis>
        )}
      </div>
    </div>
  );
}
