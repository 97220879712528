import React from "react";
import ButtonIconH from "./ButtonIconH";
import "../css/PopupMessage.css";

export default function PopupMessage(props) {
  return (
    <div className="popup-container">
      <div className="popup-message">
        <span className="popup-text">{props.text}</span>
        <div className="popup-buttons">
          {props.onClickAccept && (
            <ButtonIconH
              text={!props.textAccept ? "Sí" : props.textAccept}
              onClick={() => {
                props.onClickAccept();
              }}
            />
          )}
          {props.onClickCancel && (
            <ButtonIconH
              text={!props.textCancel ? "No" : props.textCancel}
              onClick={() => {
                props.onClickCancel();
              }}
            ></ButtonIconH>
          )}
        </div>
      </div>
    </div>
  );
}
