import React, { useRef, useState, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import ButtonIconH from "./ButtonIconH";
import InputTextArea from "./InputTextArea";
import ButtonIconV from "./ButtonIconV";
import "../css/EyeSchema.css";

export default function EyeSchema(props) {
  const [brushColor, setBrushColor] = useState("#000000");
  const [brushRadius, setBrushRadius] = useState(4);
  const [history, setHistory] = useState([]);
  const canvasRef = useRef(null);
  const [currentBasicOptometryEdit, setCurrentBasicOptometryEdit] = useState(
    props.optometry
  );

  useEffect(() => {
    setTimeout(() => {
      canvasRef.current.loadSaveData(JSON.stringify(props.eyeSchema));
    }, 250);
  }, []);

  const handleUndo = () => {
    canvasRef.current.undo();
  };

  const handleClearAll = () => {
    setHistory([]);
    canvasRef.current.clear();
  };

  const handleSetColor = (color) => {
    setBrushColor(color);
  };

  const handleCanvasChange = () => {
    const canvasData = canvasRef.current.getSaveData();
    setCurrentBasicOptometryEdit({
      ...currentBasicOptometryEdit,
      esquema: JSON.parse(canvasData),
    });
    props.updateEyeSchema(JSON.parse(canvasData));

    setHistory([...history, canvasData]);
  };

  return (
    <div className="eye-schema-container">
      <div className="eye-schema-popup">
        <div className="popup-screen-header">
          <span className="popup-screen-header-title">Esquema</span>
        </div>
        <div className="eye-schema-options-container">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <input
              type="range"
              min="1"
              max="20"
              value={brushRadius}
              onChange={(e) => setBrushRadius(parseInt(e.target.value))}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#000000")}
                style={{ backgroundColor: "#000000" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#FF0000")}
                style={{ backgroundColor: "#FF0000" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#ffff00")}
                style={{ backgroundColor: "#ffff00" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#0000FF")}
                style={{ backgroundColor: "#0000FF" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#00FF00")}
                style={{ backgroundColor: "#00FF00" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#FF00FF")}
                style={{ backgroundColor: "#FF00FF" }}
              ></button>
              <button
                className="canvas-color-button"
                onClick={() => handleSetColor("#FF8000")}
                style={{ backgroundColor: "#FF8000" }}
              ></button>
            </div>
          </div>
          <ButtonIconV
            text="Deshacer"
            icon="/images/undo-blue.svg"
            onClick={handleUndo}
          ></ButtonIconV>
          <ButtonIconV
            text="Limpiar"
            icon="/images/clear-all-blue.svg"
            onClick={handleClearAll}
          ></ButtonIconV>
        </div>
        <div className="eye-schema-content">
          <div className="eye-schema-eyes">
            <img
              src="/images/eye-dark.svg"
              loading="lazy"
              alt="Icon"
              className="eye-schema-icon"
            ></img>
            <img
              src="/images/eye-dark.svg"
              loading="lazy"
              alt="Icon"
              className="eye-schema-icon"
            ></img>
            <div className="eye-schema-canvas">
              <CanvasDraw
                ref={canvasRef}
                brushColor={brushColor}
                brushRadius={brushRadius}
                lazyRadius={0}
                canvasWidth={450}
                canvasHeight={300}
                hideGrid={true}
                hideInterface={false}
                onChange={() => {
                  handleCanvasChange();
                }}
                style={{
                  background: "transparent",
                }}
                canvasBackgroundColor="rgba(0, 0, 0, 0)"
              />
            </div>
          </div>
        </div>
        <div className="eye-schema-info">
          <span className="opt-table-subtitle">Observaciones</span>
          <InputTextArea
            tabIndex={0}
            type="type-full"
            size={0}
            input={currentBasicOptometryEdit.observaciones_esquema}
            onChange={(e) => {
              setCurrentBasicOptometryEdit({
                ...currentBasicOptometryEdit,
                observaciones_esquema: e,
              });
            }}
          ></InputTextArea>
        </div>
        <div className="eye-schema-buttons">
          <ButtonIconH
            text="Guardar"
            onClick={() => {
              props.onAccept(currentBasicOptometryEdit);
            }}
          ></ButtonIconH>
          <ButtonIconH
            text="Salir"
            onClick={() => {
              handleClearAll();
              handleCanvasChange();
              props.onCancel();
            }}
          ></ButtonIconH>
        </div>
      </div>
    </div>
  );
}
